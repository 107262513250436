import React from 'react';

const UserIconSecondarySVG = ({ width, height, fill, style }) => {
    const viewBoxValues = "0 0 24 24";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style} >
            <path fill={fill} d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
            {/* Background */}
            {/* <path d="M0 0h24v24H0z" fill={fill} /> */}
        </svg>
    );
}

export default UserIconSecondarySVG;
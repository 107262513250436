import React, { Component } from 'react';
import { Row, Col, Button, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { connect } from 'react-redux';

import i18n from '../../../../i18next';
import { parseNumber, formatNumber, getCountryCallingCode } from 'libphonenumber-js';
import { isValidNumber, getNumberType } from 'libphonenumber-js/custom';
import metadata from 'libphonenumber-js/metadata.full.json';

import phoneIconSrc from '../../../../images/icons/icon-phone-grey.png';
import TickSVG from '../../common/svgComponents/TickSVG';
import { ARABIC } from '../../../../utils/Constants';
import { Link, NavLink } from 'react-router-dom';
import { resetErrMsg } from '../../../../actions';

class PhoneNumberCommon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            phone: null,
            isPhoneTyped: false,
            countryCodeCoppiedToPhoneInput: false,
            submitDisabled: true,
            countryCallingCode: null,
            countryCodeDisabled: true,
            customerEmail: null
        };

        this.changeState = this.changeState.bind(this);
        this.countryCodeRef = React.createRef();
    }

    componentDidMount() {
        
        let countryCallingCode = this.props.brand.countryCode;

        if (countryCallingCode) {
            countryCallingCode = getCountryCallingCode(this.props.brand.countryCode);

            this.setState({
                countryCallingCode: countryCallingCode
            });
        }
    }

    componentDidUpdate(_, prevState) {
        if (prevState.phone !== this.state.phone && this.props.customer.phoneNumberErrMsg) {
            this.props.resetPhoneNumberErrMsg();
        }
    }

    componentWillUnmount() {
        this.props.resetErrMsg()
    }

    submitLogin(state) {
        if (state.isPhoneTyped) {
            return this.props.submitLogin(state.phone, this.props.isOrderDone, this.props.isPWA, this.state.countryCallingCode, this.state.customerEmail);
        }
    }


    changeState({ target }) {

        if(target.name === 'customerEmail') {
            this.setState({
                [target.name]: target.value
            })
        } else {
            this.setState({
                [target.name]: target.value
            },
                () => {

                    let phoneVariable = !this.state.phone && this.state.phone !== 0 ? '' : this.state.phone; // Needed because parseNumber demands a string
                    let phoneNumber = null;
                    let phoneNumbers = [parseNumber(`+${this.state.countryCallingCode}${phoneVariable}`), parseNumber(`${phoneVariable}`), parseNumber(`+${phoneVariable}`), parseNumber(`${phoneVariable}`, this.props.brand.countryCode)];

                    for (let i = 0; i < phoneNumbers.length; i++) {
                        let num = phoneNumbers[i];

                        if (Object.keys(num).length !== 0) {
                            const formatNumberToInternational = formatNumber(num, 'International');
                            const validatedNumber = isValidNumber(formatNumberToInternational, metadata);
                            const isMobileNumber = getNumberType(formatNumberToInternational, metadata);
                            if (validatedNumber && isMobileNumber.includes("MOBILE")) {
                                phoneNumber = num;
                                break;
                            }
                        }
                    }

                    if (!phoneNumber) { // Checks if phoneNumber is too long and exits the function
                        this.setState({
                            submitBtnDisabled: true,
                            isPhoneTyped: false
                        });
                        return;
                    } else {
                        this.setState({
                            submitBtnDisabled: false,
                            isPhoneTyped: true
                        })
                    }
                });
        }
        
        if (this.props.customer.phoneNumberErrMsg) {
            this.props.resetErrMsg()
        }
    } 

    openTermsAndConditionsPage() {
        let termsAndConditionsPage = window.open('https://menuu.com/terms-of-service', '_blank');
        termsAndConditionsPage.focus();
    }

    renderContent() {
        const { subHeading, inputGroup, input, bodyColThree, phoneIconStyle, phoneIconStyleRTL, checkIconStyle, checkIconStyleRTL, customBodyInnerContent, termsAndConditions, subMsg } = this.props.styles;
        const { type, primaryColor, secondaryColor, primaryFontColor, inputsFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const { phoneNumberHeadingCol } = this.props.styles;
        const { phone, countryCallingCode } = this.state;
        const { isPWA, brand, startOrder } = this.props;
        let isHidden = (this.state.isPhoneTyped) ? false : true;
        const isArabic = this.props.language === ARABIC;

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const titleStyle = {
            margin: isPWA ? '20px auto 5px' : 'auto',
            textAlign: 'center',
            fontSize: 22,
            color: primaryFontColor,
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        const countryCallingCodeStyle = {
            width: 55, 
            maxWidth: 55, 
            borderRadius: 0, 
            borderRightWidth: 0, 
            textAlign: 'start', 
            paddingLeft: 16, 
            color: inputsFontColor 
        }
        const countryCallingCodeStyleRTL = {
            width: 55, 
            maxWidth: 55, 
            borderRadius: 0, 
            borderLeftWidth: 0, 
            textAlign: 'start', 
            paddingRight: 16, 
            color: inputsFontColor 
        }

        return (
            <div className='modal-body-custom'>
                <Col sm={12} className='modal-custom-body-title' style={phoneNumberHeadingCol}>
                    <p className='title-style' style={titleStyle}>{i18n.t('screens:phoneNumberScreen.heading')}</p>
                </Col>
                <Col xs={12} className='modal-custom-body-inner-content' style={customBodyInnerContent}>
                    <Col xs={12} md={12} style={subHeading}>{i18n.t('screens:phoneNumberScreen.subHeading')}</Col>
                    <Col xs={12} md={9} style={bodyColThree}>
                        <InputGroup style={{ ...inputGroup, ...{ marginBottom: 3 } }}>
                            <div style={{ position: 'absolute', zIndex: 4, padding: '13px 8px', pointerEvents: 'none', color: inputsFontColor }}>+</div>
                            <Input
                                id='countryCallingCode' disabled={this.state.countryCodeDisabled}
                                name='countryCallingCode' type='text'
                                autoFocus
                                onFocus={e => e.currentTarget.select()}
                                ref={this.countryCodeRef}
                                style={ isArabic ? { ...input, ...countryCallingCodeStyleRTL} : { ...input, ...countryCallingCodeStyle}}
                                // onChange={(e) => this.setState({ ...this.state, countryCallingCode: e.target.value })}
                                onChange={this.changeState}
                                value={`${this.state.countryCallingCode}`} />
                            <img src={phoneIconSrc} width="30" height="auto" style={isArabic ? phoneIconStyleRTL : phoneIconStyle} alt='' />
                            <Input
                                id="phone" type="text"
                                name="phone"
                                placeholder='123456789'
                                onFocus={(e) => e.target.placeholder = ''}
                                onBlur={(e) => e.target.placeholder = '123456789'}
                                value={this.state.phone}
                                onChange={this.changeState}
                                style={input}
                                className='addressInputs'
                            />
                            <InputGroupAddon addonType="append">
                                <TickSVG
                                    width={30} height={30}
                                    fill={type === 'DARK' ? secondaryColor : primaryColor}
                                    style={isArabic ? checkIconStyleRTL : checkIconStyle} hidden={isHidden} />
                            </InputGroupAddon>
                        </InputGroup>
                        <div
                            hidden={!this.state.countryCodeDisabled}
                            onClick={() => this.setState({ ...this.state, countryCodeDisabled: !this.state.countryCodeDisabled }, () => {
                                this.countryCodeRef.current.focus();
                                document.getElementById('countryCallingCode').select();
                            })}
                            style={{
                                ...{ display: 'flex', alignSelf: 'flex-start', paddingLeft: 10, color: primaryColor, width: 'fit-content', marginBottom: '3px' },
                                ...termsAndConditions
                            }}>{i18n.t('screens:phoneNumberScreen.editCountryCode')}</div>
                    </Col>
                    <Col xs={12} md={9} style={bodyColThree}>
                        <InputGroup style={{...inputGroup, marginBottom: '3px'}}>
                            <Input id="yourEmail" type="text" name="customerEmail" placeholder="Email"
                                state={this.state.customerEmail}
                                value={this.state.customerEmail}
                                onChange={this.changeState}
                                style={input} 
                                className='addressInputs'
                                maxLength={64} />
                        </InputGroup>
                        <div
                            hidden={!this.props.customer.phoneNumberErrMsg}
                            style={{ color: 'red', textAlign: 'left', padding: '0 9px' }}
                        >{this.props.customer.phoneNumberErrMsg}</div>
                    </Col>
                    <Col xs={12} md={9} style={{...bodyColThree, display: 'flex', justifyContent: 'space-between', marginBottom: '30px'}}>
                        <div onClick={() => this.props.redirectToForgottenEmailPath(startOrder.currentPagePath, isPWA)} style={{color: primaryColor, paddingLeft: 10, cursor: 'pointer'}}>{i18n.t('screens:forgottenEmailScreen.forgottenEmail')}</div>
                        <div  onClick={() => this.props.redirectToUserCredentialsPath(startOrder.currentPagePath, isPWA)} style={{ color: primaryColor, paddingRight: 10, cursor: 'pointer'}}>{i18n.t('screens:phoneNumberScreen.verifySignUp')}</div>
                    </Col>
                </Col>
                { this.renderModalFooter()}
            </div >
        )
    }

    renderModalFooter() {
        const { submitBtn, submitBtnDisabled, subMsg, termsAndConditions, footerColOne, footerColTwo, modalCustomSubmitBody } = this.props.styles;
        const { primaryColor, inactiveColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        let selectSubtmitButtonStyle, submitBtnText, submitBtnClassName, activeInactiveBtn;
        let regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;

        let isEmailInValidFormat = this.state.customerEmail ? this.state.customerEmail.match(regex) : false;
        const isDisabled = this.state.isPhoneTyped && isEmailInValidFormat ? false : true;

        if (!isDisabled) {
            selectSubtmitButtonStyle = submitBtn;
            submitBtnClassName = "submitNumber";
            activeInactiveBtn = { backgroundColor: primaryColor, color: buttonsFont };
        } else {
            selectSubtmitButtonStyle = submitBtnDisabled;
            submitBtnClassName = "";
            activeInactiveBtn = { backgroundColor: inactiveColor, color: buttonsFont };
        }

        submitBtnText = i18n.t('screens:phoneNumberScreen.submit');

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const submitBtnFontStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <React.Fragment>
                <Row className='modal-custom-body-footer' style={modalCustomSubmitBody}>
                    <Col xs={12} sm={10} style={{ display: 'flex', justifyContent: 'center' }} >
                        <Col xs={12} sm={8} className="submitNumberBtn">
                            <Button disabled={isDisabled} className={submitBtnClassName} style={Object.assign({}, selectSubtmitButtonStyle, activeInactiveBtn, submitBtnFontStyle, { padding: 0 })} onClick={() => this.submitLogin(this.state)}>{submitBtnText}</Button>
                        </Col>
                    </Col>
                    <Col sm={12} md={12} style={footerColTwo}>
                        <p style={subMsg} className="termsAndCondMsg"> {i18n.t('screens:phoneNumberScreen.footerTxt')}
                            <span style={Object.assign({}, termsAndConditions, { color: primaryColor })} onClick={() => this.openTermsAndConditionsPage()}> {i18n.t('screens:phoneNumberScreen.termsAndConditions')}</span>
                        </p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderContent()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, {resetErrMsg})(PhoneNumberCommon);

import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import i18n from '../../i18next';

class ConfirmationModal extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    toggle() {
        this.props.onToggle();
    }

    onConfirm() {
        this.props.onConfirm();

        if (this.props.dontCloseOnSuccess) return;
        
        this.toggle();
    }

    onCancel() {

        if (this.props.onCancel) {

            this.props.onCancel();
        }

        this.toggle();
    }

    renderConfirmButton() {
        return (
            <Button
                style={{ float: 'right', marginRight: 5, borderRadius: 0 }}
                outline color="secondary"
                onClick={this.onConfirm}>{this.props.yesBtnText ? this.props.yesBtnText : i18n.t('common:buttons.yes')}</Button>
        );
    }

    renderCancelButton() {
        return (
            <Button
                hidden={this.props.hideCancelBtn}
                style={{ float: 'right', marginRight: 5, borderRadius: 0 }}
                outline color="danger"
                onClick={this.onCancel}>{i18n.t('common:buttons.no')}</Button>
        );
    }

    render() {

        const { confirmationMessage } = styles;

        const informationMsg = (this.props.message !== undefined && this.props.message !== null) ? this.props.message.informationMessage : null;
        const confirmationMsg = (this.props.message !== undefined && this.props.message !== null) ? this.props.message.confirmationMessage : null;
        const { menuItemsBackground } = this.props.customerThemes.selectedTheme;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggle}>
                    <ModalBody style={{ backgroundColor: menuItemsBackground }}>
                        {(informationMsg !== null) ? <p style={confirmationMessage}> {informationMsg}</p> : null}
                        {(confirmationMsg !== null) ? <p style={{ fontSize: 15 }}> {confirmationMsg} </p> : null}
                        {this.renderConfirmButton()}
                        {this.renderCancelButton()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ConfirmationModal;

let styles = {
    confirmationMessage: {
        fontSize: 16
    }
};



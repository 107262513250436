import React from 'react';

const TrendingUpSVG = ({ width, height, fill, style }) => {
    const viewBoxValues = "0 0 24 24";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style}>
            <path fill={fill} d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z"/>
            {/* Background */}
            {/* <path fill={fill} d="M0 0h24v24H0z" /> */}
        </svg>
    );
}

export default TrendingUpSVG;
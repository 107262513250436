import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import i18n from '../../i18next';

class DeactivateConfirmationModal extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    toggle() {
        this.props.onToggle(true);
    }

    onConfirm() {
        this.props.onConfirm();
    }

    renderConfirmButton() {
        return (
            <Button style={{ float: 'right', marginRight: 5, borderRadius: 0 }} outline color="secondary" onClick={this.onConfirm}>{i18n.t('common:buttons.yes')}</Button>
        );
    }

    renderCancelButton() {
        return (
            <Button style={{ float: 'right', marginRight: 5, borderRadius: 0 }} outline color="danger" onClick={() => this.toggle()}>{i18n.t('common:buttons.no')}</Button>
        );
    }

    render() {

        const { confirmationMessage } = styles;
        const {menuItemsBackground} = this.props.customerThemes.selectedTheme;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggle}>
                    <ModalBody style={{backgroundColor: menuItemsBackground}}>
                        <p style={confirmationMessage}>{i18n.t('common:deletionModal.confirmationMessage')}</p>
                        <p style={{fontSize: 15}}>{i18n.t('common:deletionModal.secondMsg')} </p>
                        {this.renderConfirmButton()}
                        {this.renderCancelButton()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default DeactivateConfirmationModal;

let styles = {
    confirmationMessage: {
        fontSize: 16
    }
};
import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';

class FreeProduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
            freeProduct: null,
            freeProductIndex: null
        }
    }

    componentDidMount() {
        this.applyTheme();

        this.props.shoppingCart.orderProducts.map((product, index) => {
            if (product.promotionFreeProduct) {
                this.setState({
                    ...this.state,
                    freeProduct: product,
                    freeProductIndex: index
                })
            }
        })
    }

    renderModalHeader() {
        return (
            <div style={styles.headerStyle}>
                <h3>{this.props.shoppingCart.appliedPromotions[0].name}</h3>
                {!this.props.isPwa ? <div
                    id="customCloseBtn"
                    className='available-discounts'
                    style={{ marginTop: 5, position: 'absolute', top: 0, right: 5 }} onClick={() => this.props.onToggle()}></div> : null}
            </div>
        )
    }

    renderModalBody() {
        const productName = this.state.freeProduct ? this.state.freeProduct.name : '';
        const isCheckoutScreen = window.location.href.includes('/checkout');

        return (
            <Fragment>
                <p style={isCheckoutScreen ? {...styles.bodyContainer, marginBottom: '0px'} : styles.bodyContainer}>
                    {`Congratulations! You received a free ${productName}.`}
                </p>
            </Fragment>
        )
    }

    renderModalFooter() {
        const { buttonsMainContainer, buttonsContainer, editButton, buttonsStyle } = styles;
        
        return (
            <div style={buttonsMainContainer}>
                <div style={buttonsContainer}>
                    <Button
                        style={{ ...buttonsStyle, ...editButton }}
                        onClick={() => {
                            this.props.onEdit(this.state.freeProduct, this.state.freeProductIndex);
                            this.props.onToggle();
                        }}>
                        {'View Product'}
                    </Button>
                </div>
            </div>
        )
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const {
            primaryColor,
            primaryFontColor,
            mainTextColor,
            menuItemsBackground,
            buttonsFont,
            fontFamilyType,
            fontBold,
            fontItalic,
            uppercase
        } = this.props.customerThemes.selectedTheme;

        customizedStyles.mainContainer.backgroundColor = menuItemsBackground;
        customizedStyles.headerStyle.color = primaryFontColor;
        customizedStyles.buttonsStyle.color = buttonsFont;
        customizedStyles.bodyContainer.color = mainTextColor;
        customizedStyles.editButton.backgroundColor = primaryColor;
        customizedStyles.buttonsStyle.fontFamily = fontFamilyType;
        customizedStyles.buttonsStyle.fontWeight = fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300;
        customizedStyles.buttonsStyle.fontStyle = fontItalic;
        customizedStyles.buttonsStyle.textTransform = uppercase;

        styles = customizedStyles;
        return styles;
    }

    render() {
        const isCheckoutScreen = window.location.href.includes('/checkout');
        return (
            <div style={styles.mainContainer}>
                {this.renderModalHeader()}
                {this.renderModalBody()}
                {!isCheckoutScreen ? this.renderModalFooter() : null}
            </div>
        )
    }
}

export default FreeProduct;

let styles = {
    headerStyle: {
        display: 'flex',
        padding: 20,
        justifyContent: 'center'
    },
    bodyContainer: {
        padding: 20
    },
    buttonsMainContainer: {
        width: '100%'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
        textAlign: 'center'
    },
    buttonsStyle: {
        borderRadius: 0,
        border: 'none',
        minWidth: 170,
        paddingTop: 10,
        paddingBottom: 10
    },
    editButton: {},
    mainContainer: {}
}
import {
    CLEAR_APP_STATE,
    CLEAR_ORDER_WIZARD, CLEAR_STATE,
    GET_USER_ADDRESS_DELIVERY_ZONE
} from '../../../actions/types';

const INITIAL_STATE = {};

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_USER_ADDRESS_DELIVERY_ZONE:
                // console.log('GET_USER_ADDRESS_DELIVERY_ZONE');

            return action.payload;
        case CLEAR_ORDER_WIZARD:
                // console.log('CLEAR_ORDER_WIZARD');

            return INITIAL_STATE;
        case CLEAR_STATE:
                // console.log('CLEAR_STATE');

            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            // console.log('CLEAR_APP_STATE');
            return INITIAL_STATE;
        default:
            return state;
    }
}
import {
    LOGIN_SUCCESS,
    SAVE_CUSTOMER_PHONE,
    SAVE_CUSTOMER_ID,
    LOAD_CUSTOMER_FROM_LOCAL_STORAGE,
    SAVE_CUSTOMER_DELIVERY_ADDRESS,
    LOG_OUT_CUSTOMER,
    CLEAR_ORDER_WIZARD,
    SAVE_CAR_DETAILS,
    GET_CUSTOMER_SAVED_PAYMENT_CARDS_SUCCESS,
    CLEAR_STATE, SAVE_START_ORDER_TIME, UPDATE_CUSTOMER_SUCCESS, CLEAR_APP_STATE, GET_CUSTOMER_CAR, SAVE_CC_PREFIX,
    SHOW_ERROR_MSG,
    RESET_PHONE_NUMBER_ERR_MSG,
    IS_LOGIN_CONFIRMATION,
    IS_FORGOTTEN_EMAIL_CONFIRMATION,
    REGISTER_SUCCESS,
    RESEND_SUCCESS_MESSAGE,
    SAVE_CUSTOMER_EMAIL,
    RESET_LOGIN_CONFIRMATION,
    RESET_FORGOTTEN_EMAIL_CONFIRMATION,
    GET_CAPTCHA_SUCCESS
} from '../../actions/types';

const INITIAL_STATE = {
    id: -1,
    email: null,
    token: '',
    name: null,
    phone: '',
    formattedPhone: '',
    countryCode: '',
    paymentId: null,
    selectedDeliveryAddress: {},//clear
    addresses: [],
    savedPaymentCards: [],
    startOrderTime: '',
    customerCar: {},
    phoneNumberErrMsg: null,
    isLoginConfirmation: false,
    isForgottenEmailConfirmation: false,
    isResendSuccessMesage: false,
    captcha: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_CUSTOMER_ID:
            return { ...state, id: action.payload };
        case SAVE_CUSTOMER_PHONE:
            return { ...state, phone: action.payload };
        case SAVE_CC_PREFIX:
            return {
                ...state, countryCodePrefix: action.payload
            };
        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                active: action.payload.active,
                countryCode: action.payload.countryCode,
                email: action.payload.email,
                formattedPhone: action.payload.formattedPhone,
                id: action.payload.id,
                name: action.payload.name,
                phone: action.payload.phone
            };
        case LOGIN_SUCCESS:
            const response = action.payload;

            if (!response) {
                return INITIAL_STATE;
            }

            const customer = response.data[0];

            return {
                ...state,
                token: response.token,
                id: customer.id,
                name: customer.name,
                email: customer.email,
                phone: customer.phone,
                formattedPhone: customer.formattedPhone,
                countryCode: customer.countryCode,
                paymentId: customer.paymentId,
                addresses: customer.address,
                autoApplyLoyalty: customer.autoApplyLoyalty
            };
        case LOAD_CUSTOMER_FROM_LOCAL_STORAGE:
            return {
                ...state,
                id: Number(action.payload.id),
                token: action.payload.token,
                name: action.payload.name,
                email: action.payload.email,
                phone: action.payload.phone,
                countryCode: action.payload.countryCode,
                addresses: action.payload.address,
                autoApplyLoyalty: Boolean(action.payload.autoApplyLoyalty)
            };
        case SAVE_CUSTOMER_DELIVERY_ADDRESS:
            return { ...state, selectedDeliveryAddress: action.payload };
        case LOG_OUT_CUSTOMER:
            return INITIAL_STATE;
        case CLEAR_ORDER_WIZARD:
            if (action.fromPwa) {
                return state;
            } else {
                let updatedCustomerState = { ...state };
                updatedCustomerState.selectedDeliveryAddress = INITIAL_STATE.selectedDeliveryAddress;
                return updatedCustomerState;
            }
        case GET_CUSTOMER_SAVED_PAYMENT_CARDS_SUCCESS:
            return { ...state, savedPaymentCards: action.payload };
        case SAVE_START_ORDER_TIME:
            return { ...state, startOrderTime: action.payload };
        case SAVE_CAR_DETAILS:
            return {
                ...state,
                customerCar: action.payload
            };
        case SHOW_ERROR_MSG:
            return {
                ...state,
                phoneNumberErrMsg: action.payload
            };
        case RESET_PHONE_NUMBER_ERR_MSG:
            return {
                ...state,
                phoneNumberErrMsg: action.payload
            };
        case CLEAR_STATE:
            if (action.fromPwa) {
                const initialStateWithSavedDeliveryAddress = {
                    id: state.id,
                    email: state.email,
                    token: null,
                    name: state.name,
                    phone: state.phone,
                    formattedPhone: state.formattedPhone,
                    countryCode: state.countryCode,
                    paymentId: state.paymentId,
                    selectedDeliveryAddress: state.selectedDeliveryAddress,
                    addresses: state.addresses,
                    savedPaymentCards: state.savedPaymentCards,
                    startOrderTime: '',
                    autoApplyLoyalty: state.autoApplyLoyalty
                };

                return initialStateWithSavedDeliveryAddress;

            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        case GET_CUSTOMER_CAR:
            return { ...state, customerCar: action.payload };
        case IS_LOGIN_CONFIRMATION: 
            return {
                ...state,
                isLoginConfirmation: action.payload
            }
        case IS_FORGOTTEN_EMAIL_CONFIRMATION: 
            return {
                ...state,
                isForgottenEmailConfirmation: action.payload
        }
        case REGISTER_SUCCESS:
            return {
                ...state,
                phone: action.payload.phone,
                countryCodePrefix: action.payload.countryCallingCode
            }
        case RESEND_SUCCESS_MESSAGE:
            return {
                ...state,
                isResendSuccessMesage: action.payload
            }
        case SAVE_CUSTOMER_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case RESET_LOGIN_CONFIRMATION:
            return {
                ...state,
                isLoginConfirmation: action.payload
            }
        case RESET_FORGOTTEN_EMAIL_CONFIRMATION:
            return {
                ...state,
                isForgottenEmailConfirmation: action.payload
            }
        case GET_CAPTCHA_SUCCESS:
            return {
                ...state,
                captcha: action.payload
            }
        default:
            return state;
    }
};

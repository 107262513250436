import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18next';
import { Button, Col } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import {
    getSelectedProduct,
    editProduct,
    addProductToCart,
    toggleShoppingCartModal,
    togglePromotionsModal
} from "../../../actions/index";
import ShoppingCart from '../../../components/order/shoppingCart/ShoppingCart';
import { pushDataToGoogleTagManager, getProductCategory } from '../../../utils/AnalyticsUtils';
import { ARABIC } from '../../../utils/Constants';
import ProductWizard from '../product/ProductWizard';
import CommonModal from '../../common/CommonModal';

class ShoppingCartScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isProductWizardOpen: false
        }

        this.toggleProductWizard = this.toggleProductWizard.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.editProductFromCart = this.editProductFromCart.bind(this);
    }

    toggleProductWizard() {
        this.setState({
            ...this.state,
            isProductWizardOpen: !this.state.isProductWizardOpen
        });
    }

    selectProduct(product) {
        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.products, this.props.categories)
        }
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);

        this.setState({ ...this.state, selectedProductId: product.id });
        this.props.getSelectedProduct(product.id, () => {
            this.setState({
                ...this.state,
                isProductWizardOpen: !this.state.isProductWizardOpen
            });
        });
    }

    editProductFromCart(product, index) {
        const filteredProduct = {
            id: product.id,
            name: product.name,
            price: product.price,
            category: getProductCategory(product.id, this.props.products, this.props.categories)
        };
        pushDataToGoogleTagManager('event', 'view-item', this.props.pwaAppRunning, filteredProduct);

        if (product.promotionFreeProduct) {
            this.props.getSelectedProduct(product.id, (productUpdated) => {
                this.props.editProduct(productUpdated, index, () => this.setState({ ...this.state, isProductWizardOpen: !this.state.isProductWizardOpen }), product)
            }, product.quantity);
        } else {
            this.props.editProduct(product, index, () => this.setState({ ...this.state, isProductWizardOpen: !this.state.isProductWizardOpen }));
        }
    }

    renderShoppingCartHeader() {
        const { primaryFontColor, fontFamilyType } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        return (
            <div id="headerText" style={{ minWidth: '100%', padding: '7px 0', display: 'flex', justifyContent: 'center' }}>
                <Col xs={12} sm={12} md={12} style={{ color: primaryFontColor, fontFamily: fontFamilyType, display: 'flex', alignItems: 'center' }}>
                    <Col xs={2} sm={2} md={2} style={isArabic ? { padding: '0px', position: 'absolute', top: 0, right: 7, zIndex: 10 } : { padding: '0px', position: 'absolute', top: 0, left: 7, zIndex: 10 }}>
                        <Button className="backButton" style={{ color: primaryFontColor, padding: '0px' }} onClick={() => this.props.toggleShoppingCartModal()}>
                            <span className="backButtonIcon" style={{ fontWeight: 300 }}>
                                <MaterialIcon icon="navigate_before" color="#000" size={32} />
                            </span>
                            <span className="backButtonText" style={{ color: primaryFontColor }}>{i18n.t('common:buttons.backBtn')}</span>
                        </Button>
                    </Col>
                    <Col xs={10} sm={10} md={10} style={{ margin: '0 auto' }}>
                        <h3 style={{ marginBottom: '0px', color: primaryFontColor, display: 'flex', justifyContent: 'center', fontSize: '24px' }}>{i18n.t('screens:shoppingCart.basket')}</h3>
                    </Col>
                </Col>
            </div>
        );
    }

    renderShoppingCartBody() {
        const isMobileView = window.innerWidth <= 865;
        return (
            <div style={isMobileView ? { width: '100%' } : null}>
                <ShoppingCart
                    isCheckout={false}
                    history={this.props.history}
                    editProductFromCart={this.editProductFromCart.bind(this)}
                    selectProduct={this.selectProduct}
                    serviceNote={this.props.menuDescription.serviceNote}
                />
                {
                    this.state.isProductWizardOpen &&
                    <CommonModal isOpen={this.state.isProductWizardOpen} dialogName='products' toggle={this.toggleProductWizard} className='productWizard'>
                        <ProductWizard
                            toggle={this.toggleProductWizard}
                            selectedProduct={this.props.selectedProduct}
                            selectedRestaurant={this.props.selectedRestaurant}
                            selectedMenuId={this.props.selectedMenuId}
                            menus={this.props.menus}
                            categories={this.props.categories}
                            products={this.props.products}
                            selectedTheme={this.props.customerThemes.selectedTheme}
                            addProductToCart={this.props.addProductToCart}
                            currency={this.props.selectedRestaurant.currency}
                            pwaAppRunning={this.props.pwaAppRunning}
                            isLoadedFromIntegration={this.props.isLoadedFromIntegration}
                        />
                    </CommonModal>
                }
            </div>
        )
    }

    render() {

        const { menuBackground } = this.props.customerThemes.selectedTheme;
        return (
            <div style={{ backgroundColor: menuBackground, height: '100vh' }}>
                {this.renderShoppingCartHeader()}
                {this.renderShoppingCartBody()}
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {
        customer: state.customer,
        products: state.restaurantProducts,
        categories: state.restaurantCategories,
        shoppingCart: state.shoppingCart,
        pwaAppRunning: state.pwaAppRunning,
        menuDescription: state.menuDescription,
        selectedRestaurant: state.selectedRestaurant,
        storeLocalStorage: state.storeLocalStorage,
        brand: state.brand,
        promotionPartners: state.promotionPartners,
        selectedProduct: state.selectedProduct,
        customerThemes: state.customerThemes,
        menus: state.restaurantMenus.menus,
        isPromotionsModalOpen: state.commonReducer.isPromotionsModalOpen,
        isShoppingCartModalOpen: state.commonReducer.isShoppingCartModalOpen,
        como: state.como,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, {
    getSelectedProduct,
    editProduct,
    addProductToCart,
    togglePromotionsModal,
    toggleShoppingCartModal
})(ShoppingCartScreen);
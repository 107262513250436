import React, { Fragment } from 'react';
import { Row, Col, Badge } from 'reactstrap';

// TODO - delete and complete the rest when refactorings is oficially sanctioned
const ItemCardContent = props => {
    const { cardContentItem, cardContentItemIndex, mobileOrApp, imagePath, isBadgeAllowed } = props;
    // 1, 1, 0, 1, 1 - checked params
    const { fontFamilyType, fontBold, fontItalic, uppercase, mainTextColor, primaryColor, primaryFontColor } = props.customerThemes.selectedTheme;

    // const { product, currency, productIndex, mobileOrApp, productFoundInCart, productQuantityInCart } = props;  - OLD

    // const { productPriceStyle, badgeColMobile, badge } = props.styles;
    const { priceCol, descriptionStyles, nutrientStyles } = constantStyles;

    const isEmptyDescription = cardContentItem.description === '';
    // const productPrice = transformProductPrice(product.price);
    // const priceWithCurrency = getPriceWithCurrencySymbol(productPrice, currency);

    const fontStylesName = {
        fontFamily: fontFamilyType,
        fontWeight: fontBold,
        fontStyle: fontItalic,
        textTransform: uppercase,
        color: primaryFontColor,
        fontSize: '1.125rem',
        padding: props.isArabic ? '0 0 0 5px' : '0 5px 0 0'
    };

    // const fontStylesPrice = {
    //     fontFamily: fontFamilyType,
    //     fontWeight: fontBold === 'bold' ? fontBold : 300,
    //     fontStyle: fontItalic,
    //     textTransform: uppercase,
    //     color: primaryColor
    // };

    const renderDescription = (description, index) => {
        if (document.getElementById(`productDescription${index}`)) {
            document.getElementById(`productDescription${index}`).innerHTML = description;
        }
    }

    const nameStype = mobileOrApp ? { ...fontStylesName, maxWidth: imagePath ? '98%' : '89%' } : fontStylesName;

    return (
        <Fragment>
            <Row>
                <p id={'#' + cardContentItem.name} className="long-text" style={
                    {
                        ...nameStype,
                        ...fontStylesName
                    }
                }>{cardContentItem.name}</p>

                {/* {
                     isBadgeAllowed && mobileOrApp && !product.thumbnailPath ?
                        <Col style={badgeColMobile} hidden={productFoundInCart}>
                            <Badge style={Object.assign({}, badge, { background: primaryColor })}>{productQuantityInCart}x</Badge>
                        </Col> : null
                } */}

            </Row>

            <Row
                className='productDescription' // TODO change if problems occur or better - add dynamicaly
                hidden={isEmptyDescription}>
                <p
                    id={`productDescription${cardContentItemIndex}`}
                    style={{
                        ...descriptionStyles,
                        color: mainTextColor,
                    }}>
                    {/* {renderDescription(cardContentItem.description, cardContentItemIndex)} */}
                    {cardContentItem.description}
                </p>
            </Row>

            <Row className='productRow'>
                {/* <Col sm={mobileOrApp ? 8 : 12} style={priceCol}>
                    <p hidden={product.price === 0} style={{
                        ...productPriceStyle,
                        ...fontStylesPrice
                    }}>{priceWithCurrency}</p>
                    <p hidden={product.nutrients && !product.nutrients.length} style={{
                        ...fontStylesName,
                        ...nutrientStyles,
                        color: mainTextColor
                    }}>{nutriValueTotal(product.nutrients)} {i18n.t('common:nutritions.kcal')}</p>
                </Col> */}
            </Row>
        </Fragment>
    );
}

export default ItemCardContent;

const constantStyles = {
    // priceCol: {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'space-between',
    //     paddingLeft: 0
    // },
    descriptionStyles: {
        maxWidth: '100%',
        height: 42,
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        marginBottom: 0,
        fontSize: '0.875rem',
        padding: 0,
    },
    // nutrientStyles: {
    //     marginBottom: 0,
    //     alignSelf: 'center',
    //     fontSize: 12,
    // }
}
import {
    CLEAR_ORDER_WIZARD
} from '../types';

export const clearWizardData = () => {
    return (dispatch, getState) => {

        const pwaAppRunning = getState().pwaAppRunning;

        dispatch({ type: CLEAR_ORDER_WIZARD, fromPwa: pwaAppRunning });
    };
};
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Col, Modal, ModalBody } from "reactstrap";
import MaterialIcon from "material-icons-react";
import moment from "moment";
import i18n from '../../i18next';

import { INSTORE_INDEX } from '../../utils/Constants';
import { changeWantedTime, clearState, clearWizardData } from "../../actions";
import { isRestaurantAboutToClose } from "../../utils/CommonUtils";

class TimeWarningPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            showWarningPopup: false,
            showRestaurantClosedPopup: false,
            isWarningPopupAlreadyShowed: false,
            updateState: false,
            remainingTime: 0
        };

        this.toggle = this.toggle.bind(this);
    }

    intervalID = 0;

    componentDidMount() {

        this.intervalID = setInterval(() => {
            this.handleTimeWarning();
        }, 60 * 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isTimeWarningAllowed !== this.props.isTimeWarningAllowed) {
            this.handleTimeWarning();
            if (!this.props.isTimeWarningAllowed) {
                this.setState({ ...this.state, isWarningPopupAlreadyShowed: false })
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    confirmOrderReset() {
        this.setState({
            ...this.state,
            modal: false
        });
        this.props.clearWizardData();
        this.props.clearState();
        if (this.props.pwaAppRunning) {
            const brandId = this.props.brand.id;
            this.props.history.push(`/brand/${brandId}/start-order/main-screen`);
        } else {
            if (this.props.orderTypes.selectedOrderType.id === INSTORE_INDEX) {
                this.props.history.push(`/dine-pay`);
            } else {
                this.props.history.push('/start-order/order-type');
            }
        }

    }

    handleTimeWarning() {
        if (this.props.isTimeWarningAllowed) {
            // console.log(this.props.selectedRestaurant, 'Selected Restaurant');
            let checkRestaurantClosingTime = isRestaurantAboutToClose(this.props.estimateOrderTime, this.props.selectedRestaurant)
            // let checkRestaurantClosingTime = isRestaurantAboutToClose(this.props.estimateOrderTime);
            // console.log(checkRestaurantClosingTime, 'Check Restaurant Closing Time');

            if (checkRestaurantClosingTime.isRestaurantAboutToClose) {
                // console.log(1);
                this.setState({
                    ...this.state,
                    modal: true,
                    showWarningPopup: true,
                    showRestaurantClosedPopup: false,
                    updateState: !this.state.updateState,
                    remainingTime: checkRestaurantClosingTime.remainingTime
                });
                // console.log(this.state, 'STATE 1')
            } else if (checkRestaurantClosingTime.isRestaurantClosed) {
                // console.log(2);
                this.setState({
                    ...this.state,
                    modal: true,
                    showWarningPopup: false,
                    showRestaurantClosedPopup: true,
                    updateState: !this.state.updateState
                });
                // console.log(this.state, 'STATE 2')
            } else {
                // console.log(3);
                this.setState({
                    ...this.state,
                    modal: false,
                    updateState: !this.state.updateState
                });
            }
            this.updateOrderTime(this.props.estimateOrderTime);
        }
    }

    updateOrderTime(estimateOrderTime) {

        if (estimateOrderTime.time !== null && !estimateOrderTime.timeSlotBased) {

            let currentDate = moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds');
            let orderTimeDate = estimateOrderTime.wantedTime.slice(0, 10);
            let orderDate = moment(orderTimeDate, 'DD-MM-YYYY');
            let notSameDay = moment(orderDate, 'DD-MM-YYYY').isAfter(currentDate, 'day');

            let earliestReadyTime = moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds').add(estimateOrderTime.estimateOrderTime, 'minutes');
            let currentOrderTime = moment(estimateOrderTime.wantedTime, 'DD-MM-YYYY HH-mm-ss');

            let orderHour = parseInt(estimateOrderTime.time.slice(0, 2));
            if (orderHour >= 0 && orderHour <= 6) {
                currentOrderTime.add(1, 'day');
            }
            if (!notSameDay && earliestReadyTime.isAfter(currentOrderTime)) {
                let newWantedTime = `${estimateOrderTime.wantedTime.slice(0, 10)} ${earliestReadyTime.format('HH:mm')}:00`;
                this.props.changeWantedTime(newWantedTime);
            }
        } else {
            this.props.changeWantedTime(null);
        }
    };

    toggle() {
        if (!this.state.showRestaurantClosedPopup) {
            this.setState({
                ...this.state,
                modal: !this.state.modal,
                isWarningPopupAlreadyShowed: true
            });
        }
    }

    render() {
        if (this.props.isTimeWarningAllowed && ((this.state.showWarningPopup && !this.state.isWarningPopupAlreadyShowed) || this.state.showRestaurantClosedPopup)) {
            const { showWarningPopup, remainingTime } = this.state;
            const { buttonContainer, closeButton, message, subMessage } = styles;
            const { primaryFontColor } = this.props.customerThemes.selectedTheme;
            const { timeSlotBased } = this.props.estimateOrderTime;
            return (
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody>
                        {!this.state.showRestaurantClosedPopup ?
                            <Button style={{ ...closeButton, borderColor: primaryFontColor }} onClick={() => {
                                this.toggle()
                            }}>
                                <MaterialIcon icon="close" color={primaryFontColor} size={18} />
                            </Button> :
                            null
                        }

                        {showWarningPopup ?
                            <React.Fragment>
                                <Col xs={12} sm={12} style={{ textAlign: 'center' }}>
                                    <MaterialIcon icon="error_outline" size="62"
                                        color="yellow" />
                                </Col>
                                <p style={{ ...message, color: primaryFontColor }}>{i18n.t(`common:alerts.${timeSlotBased ? 'timeSlotWarning' : 'timeWarning'}`)}</p>
                                <p style={{ ...subMessage, color: primaryFontColor }}>{i18n.t('common:alerts.timeWarningSubMsgOne')} {remainingTime} {i18n.t(`common:alerts.${timeSlotBased ? 'timeSlotWarningSubMsgTwo' : 'timeWarningSubMsgTwo'}`)}</p>
                                <Col xs={12} sm={12} style={{ textAlign: 'center', marginTop: 12 }}>
                                    <button className="btn btn-outline-secondary timeWarningBtn" onClick={() => {
                                        this.toggle()
                                    }}> {i18n.t('common:buttons.okay')}
                                    </button>
                                </Col>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Col xs={12} sm={12} style={{ textAlign: 'center' }}>
                                    <MaterialIcon icon="error_outline" size="62"
                                        color="red" />
                                </Col>
                                <p style={{ ...message, color: primaryFontColor }}>{i18n.t(`common:alerts.${timeSlotBased ? 'timeSlotWarningClosed' : 'timeWarningClosed'}`)}</p>
                                <div style={buttonContainer}>
                                    <button className="btn btn-outline-secondary timeWarningBtn" style={{ borderRadius: 0 }} onClick={() => {
                                        this.confirmOrderReset()
                                    }}> {i18n.t('common:alerts.startOver')}
                                    </button>
                                </div>
                            </React.Fragment>
                        }
                    </ModalBody>
                </Modal>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        pwaAppRunning: state.pwaAppRunning,
        customer: state.customer,
        customerThemes: state.customerThemes,
        selectedRestaurant: state.selectedRestaurant,
        estimateOrderTime: state.estimateOrderTime,
        isTimeWarningAllowed: state.timeWarningPopup.isTimeWarningAllowed,
        brand: state.brand,
        orderTypes: state.orderTypes
    };
};

export default connect(mapStateToProps, { changeWantedTime, clearWizardData, clearState })(TimeWarningPopup);

let styles = {
    buttonContainer: {
        textAlign: 'center'
    },
    closeButton: {
        zIndex: 2,
        position: 'absolute',
        background: 'transparent',
        top: 10,
        right: 10,
        width: 32,
        height: 32,
        borderRadius: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    message: {
        fontSize: 30,
        fontFamily: 'Roboto',
        fontWeight: 300,
        margin: 'auto',
        textAlign: 'center'
    },
    subMessage: {
        // color: 'rgb(167, 167, 167)',
        color: '#333',
        fontFamily: 'Roboto',
        fontWeight: 300,
        margin: 'auto',
        textAlign: 'center'
    }
};
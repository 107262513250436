import React, { Component } from 'react';


import ApplicationStep from '../../common/ApplicationStep';
import ForgottenEmailCommon from './ForgottenEmailCommon';

export default class ForgottenEmailPWA extends Component {

    componentWillMount() {
        this.applyTheme();
        this.props.loadCaptcha();
    }

    componentWillUnmount() {
        this.props.resetErrMsg()
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, textDecoration, fontWeight, startOrderFontSize, mainTextColor, chefNoteBackground } = this.props.customerThemes.selectedTheme;
        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.input.fontWeight = fontWeight;
        customizedStyles.input.backgroundColor = chefNoteBackground;
        customizedStyles.submitBtn.backgroundColor = primaryColor;
        customizedStyles.termsAndConditions.color = primaryColor;
        customizedStyles.termsAndConditions.textDecoration = textDecoration;
        customizedStyles.subMsg.color = mainTextColor;
        styles = customizedStyles;
        return styles;
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <ApplicationStep
                hideBackButton={false}
                transparentHeader={false}
                text={'Forgotten Email'}
                goBack={this.goBack.bind(this)}
                content={<ForgottenEmailCommon
                    {...this.props}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                    customer={this.props.customer}
                    brand={this.props.brand}
                    isPWA={true}
                    resetErrMsg={this.props.resetErrMsg}
                    forgottenEmail={this.props.forgottenEmail}
                    loadCaptcha={this.props.loadCaptcha}
                />}
            />
        );

    }
}

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    modalFooter: {},
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 14,
        color: '#939393',
        paddingBottom: 12,
        margin: 'auto',
        // textAlign: 'center'
    },
    login: {
        fontSize: 22,
        marginLeft: 30,
        marginRight: 12
    },
    loginTextStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center',
        paddingRight: 55
    },
    inputGroup: {
        margin: 'auto',
        height: 50,
        marginBottom: 10
    },
    input: {
        height: 50,
        borderRadius: 0,
        backgroundColor: '#EFEFEF',
        textAlign: 'center'
    },
    submitBtn: {
        marginBottom: 12,
        height: 50,
        borderRadius: 0,
        width: '100%',
        backgroundColor: '#61B522',
        border: 'none'
    },
    submitBtnDisabled: {
        marginBottom: 12,
        height: 50,
        borderRadius: 0,
        width: '100%',
        backgroundColor: '#a8a8a8',
        border: 'none'
    },
    subMsg: {
        fontSize: 14,
        color: '#9a9a9a',
        marginBottom: 5
    },
    termsAndConditions: {
        color: "#61B522",
        cursor: 'pointer'
    },
    headerColumn: {
        // paddingLeft: 0,
        // width: 72
    },
    footerColOne: {
        display: 'flex',
        justifyContent: 'center',
    },
    footerColTwo: {
        textAlign: 'center'
    },
    bodyColThree: {
        margin: 'auto'
    },
    phoneIconStyle: {
        position: 'absolute',
        padding: '8px 5px',
        marginLeft: 70,
        zIndex: 1
    },
    phoneIconStyleRTL: {
        position: 'absolute',
        padding: '8px 5px',
        marginRight: 70,
        zIndex: 1
    },
    checkIconStyle: {
        position: 'absolute',
        padding: 4,
        marginLeft: -30,
        zIndex: 1,
        marginTop: 12
    },
    checkIconStyleRTL: {
        position: 'absolute',
        padding: 4,
        marginRight: -30,
        zIndex: 1,
        marginTop: 12
    },
    imageStyle: {
        paddingRight: 18,
        marginTop: 3
    },
    modalCustomSubmitBody: {
        display: 'flex',
        justifyContent: 'center',
        margin: '50px 0 0 0'
    },
    customBodyInnerContent: {
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1
    },
    marginAuto: {
        margin: 'auto',
        position: 'relative',
        bottom: 20,
        top: '20px'
    }
}
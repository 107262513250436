import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialIcon from "material-icons-react";
import i18n from '../../../i18next';
import { isMobileOnly, isIOS } from 'mobile-device-detect';

import {
    transformProductPrice,
    getPriceWithCurrencySymbol,
    checkIfMenuIsAvailable,
    checkIfCategoryIsAvailable,
    checkIfProductIsAvailable
} from '../../../utils/CommonUtils';
import { calculateProductPrice } from '../../../utils/ProductWizardUtils';
import { ARABIC } from '../../../utils/Constants';

class Footer extends Component {
    navigateBack() {
        if (!this.props.blockBackBtn) this.props.setMinMaxValidation(false, () => this.props.navigate(-1));
    }

    navigateForth() {
        const { allowValidationClick, isLastStep } = this.props;

        if (allowValidationClick) {
            // call style setter flag
            this.props.setMinMaxValidation(allowValidationClick);
        } else if (!isLastStep) {
            this.props.navigate(1);
        } else {
            this.props.addToCart();
        }
    }

    preventNegativeQty() {
        if (this.props.productQty === 1) return;
        this.props.incDecProductQty(-1);
    }

    renderNextBtn() {
        const { primaryColor, inactiveColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.selectedTheme;
        const { restaurant, productPrice, productQty, product, currency, allowValidationClick, blockNextBtn, isLastStep } = this.props;
        const isArabic = this.props.language === ARABIC;
        const totalProductPrice = calculateProductPrice(restaurant.allowToppingSubstitution, productPrice, productQty, product);
        const formattedTotal = getPriceWithCurrencySymbol(transformProductPrice(totalProductPrice), currency, isArabic);
        let isDisabled = blockNextBtn && !allowValidationClick;
        let buttonStyle = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '55%',
            cursor: blockNextBtn ? 'default' : 'pointer',
            padding: '0px 15px 0px 0px',
            marginRight: 15,
            height: 45,
            border: 'none',
            color: '#fff',
            backgroundColor: blockNextBtn ? inactiveColor : primaryColor,
            opacity: blockNextBtn ? '0.65' : 1,
            color: buttonsFont,
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        };
        let buttonStyleRTL = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '55%',
            cursor: blockNextBtn ? 'default' : 'pointer',
            padding: '0px 0px 0px 15px',
            marginLeft: 15,
            height: 45,
            border: 'none',
            color: '#fff',
            backgroundColor: blockNextBtn ? inactiveColor : primaryColor,
            opacity: blockNextBtn ? '0.65' : 1,
            color: buttonsFont,
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        };

        if (allowValidationClick) {
            buttonStyle.backgroundColor = inactiveColor;
            buttonStyleRTL.backgroundColor = inactiveColor;
        }
        const isUnAvailable = this.renderMessage() ? this.renderMessage().includes(i18n.t('common:wizard.depositMsgPartTwo')) ? false : true : false;
        if (isUnAvailable && isLastStep) {
            isDisabled = true;
            buttonStyle.backgroundColor = inactiveColor;
            buttonStyleRTL.backgroundColor = inactiveColor;
            buttonStyle.cursor = 'default';
            buttonStyleRTL.cursor = 'default';
        }

        return (
            <button
                className='wizardButton'
                disabled={isDisabled}
                onClick={() => this.navigateForth()}
                style={isArabic ? buttonStyleRTL : buttonStyle}>
                <div style={isArabic ? { fontWeight: fontBold === 'bold' ? fontBold : 300, lineHeight: 'normal', paddingRight: '10px' } : { fontWeight: fontBold === 'bold' ? fontBold : 300, lineHeight: 'normal', paddingLeft: '10px' }}>
                    {!isLastStep ? i18n.t('common:buttons.nextBtn') : window.innerWidth <= 768 ? i18n.t('common:buttons:addToCartSmallDevices') : i18n.t('common:buttons.addToCart')}
                </div>
                <div style={{ fontWeight: fontBold === 'bold' ? fontBold : 300 }}>
                    {formattedTotal}
                </div>
            </button>
        );
    }

    renderMessage() {
        const { menus, categories, products, selectedMenuId, product, currency, step } = this.props;
        let unavailableMsg = null, productDeposit = 0;
        const isMenuAvailable = checkIfMenuIsAvailable(menus, selectedMenuId);
        const isCategoryAvailable = checkIfCategoryIsAvailable(categories, products, product);
        const isProductAvailable = checkIfProductIsAvailable(products, product);
        const isArabic = this.props.language === ARABIC;
        if (!isMenuAvailable) {
            unavailableMsg = i18n.t('common:wizard.unAvailableMenuMsg');
        } else if (!isCategoryAvailable) {
            unavailableMsg = i18n.t('common:wizard.unAvailableCategoryMsg');
        } else if (!isProductAvailable) {
            unavailableMsg = i18n.t('common:wizard.unAvailableProductMsg');
        }

        if (product.mealDeal) {
            //check all questions for deposits if any calculate them
            product.questions.map(question => {
                if (question.selectedProduct) {
                    if (question.selectedProduct.deposit) {
                        productDeposit += question.selectedProduct.deposit;
                    }
                }
            });
        } else {
            productDeposit = product.deposit;
        }

        if (!unavailableMsg && productDeposit) {
            let productQty = product.quantity ? product.quantity : 1;
            let depositPrice = productDeposit * productQty;
            // if no product was selected
            if (product.questions.length) {
                // if ((product.questions[step].selectedAnswers && !product.mealDeal) || (product.questions[step].selectedProduct && product.mealDeal)) {
                unavailableMsg = `${i18n.t('common:wizard.depositMsgPartOne')} ${getPriceWithCurrencySymbol(transformProductPrice(depositPrice), currency, isArabic)} ${i18n.t('common:wizard.depositMsgPartTwo')}.`;
                // }
            } else {
                unavailableMsg = `${i18n.t('common:wizard.depositMsgPartOne')} ${getPriceWithCurrencySymbol(transformProductPrice(depositPrice), currency, isArabic)} ${i18n.t('common:wizard.depositMsgPartTwo')}.`;
            }
        }

        return unavailableMsg;
    }

    renderChefNotePlaceholder() {
        const { product, step } = this.props;
        let placeholder = '';

        if (this.props.disableNotes) {
            if (this.props.product.mealDeal && product.questions.length) {
                if (product.questions[step].selectedProduct) {
                    if (product.questions[step].selectedProduct.disableNotes) {
                        placeholder = i18n.t('common:wizard.notSupportNotes');
                    } else {
                        placeholder = `${i18n.t('common:wizard.chefsNote')}...`;
                    }
                } else {
                    placeholder = i18n.t('common:wizard.selectProduct');
                }
            } else {
                placeholder = i18n.t('common:wizard.notSupportNotes');
            }
        } else {
            placeholder = `${i18n.t('common:wizard.chefsNote')}...`;
        }

        return placeholder
    }

    render() {
        const { instructions, blockBackBtn, product, productQty, isLowResTabletPortraitMode } = this.props;
        const { footerMainStyles, incDecQtyContainer, incDecQtyContainerRTL, chefsNoteInput, chefsNoteInputRTL, incQtyIcon, redStarMsg, redStarMsgRTL } = styles;
        const isArabic = this.props.language === ARABIC;
        const { type, menuItemsBackground, chefNoteBackground, inputsFontColor, primaryFontColor } = this.props.selectedTheme;
        const isIosAppMsg = this.props.pwaAppRunning && isIOS && isMobileOnly && this.renderMessage();
        const placeholder = this.renderChefNotePlaceholder();
        const qtyBtnColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? primaryFontColor : '#656565';
        let footerFirstChild = {
            backgroundColor: menuItemsBackground
        };

        if (this.renderMessage()) {
            footerFirstChild.padding = '14px 6px 0 6px';
        }

        let conditionalStyles = {};

        if (isLowResTabletPortraitMode) {
            conditionalStyles.width = '100%';
            conditionalStyles.position = 'fixed';
            conditionalStyles.bottom = 0;
            conditionalStyles.zIndex = 3;

            if (!product.questions.length) {
                conditionalStyles = {};
            }
        }

        return (
            <div className='wizardFooter' style={{ ...footerMainStyles, ...conditionalStyles }}>
                <input className='chefsNotes'
                    // hidden={this.props.restaurant.posType.id === 7}
                    disabled={this.props.disableNotes}
                    name='notes'
                    type='text'
                    placeholder={placeholder}
                    maxLength={50}
                    style={isArabic ? { ...chefsNoteInputRTL, ...{ backgroundColor: chefNoteBackground, color: inputsFontColor, width: '100%' } } : { ...chefsNoteInput, ...{ backgroundColor: chefNoteBackground, color: inputsFontColor, width: '100%' } }}
                    value={instructions}
                    onChange={this.props.changeNotes}
                />
                <div style={footerFirstChild}>
                    <span
                        hidden={!product.questions.length}
                        className={blockBackBtn ? 'wizardBackBtn' : 'wizardBackBtn wizardFooterBackBtn'}
                        onClick={() => this.navigateBack()}
                        style={{ display: 'flex', alignItems: 'center', userSelect: 'none', cursor: !blockBackBtn ? 'pointer' : 'initial' }} >
                        <MaterialIcon icon="keyboard_arrow_left" color="#d3d3d3" size={35} />
                    </span>

                    <div style={isArabic ? incDecQtyContainerRTL : incDecQtyContainer}>
                        <span
                            className={productQty === 1 ? '' : 'qtyBtns'}
                            onClick={() => this.preventNegativeQty()}
                            style={{ display: 'flex', cursor: productQty === 1 ? 'initial' : 'pointer', border: '1px solid #dcdcdc', borderRadius: '100%', margin: 'auto 0', padding: 8 }}>
                            <MaterialIcon icon="remove" color={qtyBtnColor} size={18} />
                        </span>
                        <span style={{ fontSize: 16, padding: 8, color: primaryFontColor }}>{productQty}</span>
                        <span
                            className='qtyBtns'
                            onClick={() => this.props.incDecProductQty(1)}
                            style={incQtyIcon}>
                            <MaterialIcon icon="add" color={qtyBtnColor} size={18} />
                        </span>
                    </div>

                    {this.renderNextBtn()}
                </div>
                {this.renderMessage() && <p style={isArabic ? { ...redStarMsgRTL, backgroundColor: menuItemsBackground, color: primaryFontColor, paddingBottom: isIosAppMsg ? 10 : 0 } : { ...redStarMsg, backgroundColor: menuItemsBackground, color: primaryFontColor, paddingBottom: isIosAppMsg ? 10 : 0 }}><span className="redStar">*</span>{this.renderMessage()}</p>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        restaurant: state.selectedRestaurant,
        language: state.storeLocalStorage.language,
        pwaAppRunning: state.pwaAppRunning,
    }
}

export default connect(mapStateToProps)(Footer);

let styles = {
    footerMainStyles: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        maxHeight: 111,
        minHeight: 111,
        zIndex: 10
    },
    incDecQtyContainer: {
        display: 'flex',
        justifyContent: 'center',
        userSelect: 'none',
        width: '40%',
        paddingRight: '5%'
    },
    incDecQtyContainerRTL: {
        display: 'flex',
        justifyContent: 'center',
        userSelect: 'none',
        width: '40%',
        paddingLeft: '5%'
    },
    chefsNoteInput: {
        display: 'flex',
        height: 38,
        border: '1px solid #ced4da',
        borderBottom: '1px solid #e2e2e2',
        backgroundColor: '#f5f5f5',
        borderRadius: 0,
        padding: '0 25px 0 35px',
        fontSize: 16,
        overflow: 'visible'
    },
    chefsNoteInputRTL: {
        display: 'flex',
        height: 38,
        border: '1px solid #ced4da',
        borderBottom: '1px solid #e2e2e2',
        backgroundColor: '#f5f5f5',
        borderRadius: 0,
        padding: '0 35px 0 25px',
        fontSize: 16,
        overflow: 'visible'
    },
    incQtyIcon: {
        display: 'flex',
        cursor: 'pointer',
        border: '1px solid #dcdcdc',
        borderRadius: '100%',
        margin: 'auto 0',
        padding: 8
    },
    redStarMsg: {
        textAlign: 'right',
        padding: '0 21px 0 0',
        margin: 0,
        fontSize: 14
    },
    redStarMsgRTL: {
        textAlign: 'left',
        padding: '0 0 0 21px',
        margin: 0,
        fontSize: 14
    }
}
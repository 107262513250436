import {
    GET_ORDER_TYPES_SUCCESS,
    SELECT_ORDER_TYPE,
    CLEAR_ORDER_WIZARD,
    CLEAR_STATE,
    CLEAR_APP_STATE,
    SAVE_IN_STORE_BRAND_IMGS
} from '../../../actions/types';

const INITIAL_STATE = {
    orderTypes: [],
    selectedOrderType: {
        value: '',
        id: null
    },
    bannerImagePath: '',
    logoImagePath: ''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ORDER_TYPES_SUCCESS:
            return {
                ...state,
                orderTypes: action.payload.orderTypes,
                bannerImagePath: action.payload.bannerImagePath,
                logoImagePath: action.payload.logoImagePath
            };
        case SELECT_ORDER_TYPE:
            return {
                ...state,
                selectedOrderType: action.payload
            };
        case SAVE_IN_STORE_BRAND_IMGS:
            return {
                ...state,
                orderTypes: [action.payload.selectedOrderType],
                bannerImagePath: action.payload.bannerImagePath,
                logoImagePath: action.payload.logoImagePath
            }
        case CLEAR_ORDER_WIZARD:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }

}

import React, { Component, Fragment } from 'react';
import { Button, Col, Input } from 'reactstrap';
import i18n from '../../../i18next'
import { connect } from 'react-redux';

import { saveNavigationType, changeLanguage } from '../../../actions/index';

import { FRONT_END_URL } from '../../../utils/Constants';
import { parentLocalStorage } from '../../../utils/CrossOriginUtils';
import ModalStep from '../../common/ModalStep';
import MenuuLogo from '../../../pwa-app/components/common/svgComponents/MenuuLogoSVG';
import InStoreSVG from '../../../pwa-app/components/common/svgComponents/InStoreSVG';

class TableSelectionScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: null,
            label: null,
            value: null,
            typeId: null,
            defaultLanguage: 'en'
        }
        this.changeValue = this.changeValue.bind(this);
    }

    componentDidMount() {
        if (this.props.isMenuScreen) {
            const { title, label, value, typeId } = this.props.restaurant.orderInStoreInfo;
            this.setState({
                ...this.state,
                title,
                label,
                value,
                typeId
            });
        } else {
            if (this.props.selectedRestaurant.type) {
                const { title, label, id } = this.props.selectedRestaurant.type;
                this.setState({
                    ...this.state,
                    title,
                    label,
                    typeId: id
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isMenuScreen) {
            if ((prevProps.selectedRestaurant.type !== this.props.selectedRestaurant.type) && this.props.selectedRestaurant.type) {
                const { title, label, id } = this.props.selectedRestaurant.type;
                this.setState({
                    ...this.state,
                    title,
                    label,
                    typeId: id
                });
            }
        }
        if (this.valueInput) {
            this.valueInput.focus()
        }
    }

    toggleLanguageButton(language) {

        this.props.changeLanguage(language)
    }

    changeValue({ target }) {
        this.setState({
            ...this.state,
            [target.name]: target.value
        });
    }

    renderSwitchLanguageButtons() {

        const { selectedLanguage, isDefaultLanguage } = this.props;
        const { defaultLanguage } = this.state;
        const { fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const isMobileView = window.innerWidth <= 865;
        const defaultLanguageCountryCode = `${defaultLanguage === 'en' ? 'EN' : defaultLanguage.toUpperCase()}`
        const selectedLanguageCountryCode = selectedLanguage ? selectedLanguage.toUpperCase() : null;

        const languageBtnTextStyle = {
            fontSize: '15px',
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <div style={isMobileView ? { width: '100px', display: 'flex', justifyContent: 'start', position: 'absolute', paddingLeft: '10px' } : { width: '100px', display: 'flex', justifyContent: 'center', position: 'absolute' }}>
                <div>
                    <Button
                        color="outline-success"
                        style={{ height: '30px', display: 'flex', alignItems: 'center' }}
                        onClick={() => this.toggleLanguageButton(isDefaultLanguage ? selectedLanguage : defaultLanguage)}>
                        <span style={languageBtnTextStyle}>{isDefaultLanguage ? selectedLanguageCountryCode : defaultLanguageCountryCode}</span>
                    </Button>
                </div>
            </div>
        )
    }

    renderModalBody() {
        const { primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase, primaryColor, menuuLogoColor, chefNoteBackground, inputsFontColor } = this.props.customerThemes.selectedTheme;
        const { restaurantNameLabelStyle } = styles;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const titleStyle = {
            margin: 'auto',
            textAlign: 'center',
            fontSize: 22,
            color: primaryFontColor,
            fontFamily: fontFamilyType,
            fontWeight,
            fontStyle: fontItalic,
            textTransform: uppercase,
            display: 'flex',
            marginBottom: 10
        };

        const buttonNextStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold,
            fontStyle: fontItalic,
            textTransform: uppercase,
            maxWidth: window.innerWidth <= 768 ? window.innerWidth <= 400 ? '82%' : '65%' : '34%'
        };

        const fontStyles = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        };

        const inputsStyle = {
            maxWidth: window.innerWidth <= 768 ? window.innerWidth <= 400 ? '82%' : '65%' : '34%',
            height: 55,
            backgroundColor: chefNoteBackground,
            padding: '0 20px',
            borderRadius: 0,
            color: inputsFontColor
        }

        let nextButtonStyle = '';
        if (this.state.title && this.state.value) {
            nextButtonStyle = 'nextButton';
        } else {
            nextButtonStyle = 'disabledNextButton';
        }

        let inStoreAvailable = this.props.loading.errorResponse || this.props.selectedRestaurant ? this.props.selectedRestaurant.restaurantPaused : this.props.restaurant.restaurantPaused;
        let brandId = !this.props.isMenuScreen ? this.props.brand.id : this.props.restaurant.restaurantId;
        const isMobileView = window.innerWidth <= 865;
        const isDinePayScreen = window.location.href.includes('/dine-pay');

        return (
            <Fragment>
                {
                    !inStoreAvailable && brandId ?
                        <Col sm={12} style={{ paddingTop: 30 }}>
                            {this.props.selectedLanguage !== 'en' && isDinePayScreen ? this.renderSwitchLanguageButtons() : null}
                            {
                                this.props.logoImagePath ?
                                    <img src={`${FRONT_END_URL}/${this.props.logoImagePath}`} style={{ maxWidth: 160 }} /> :
                                    <h4 className='orderDetailsRestaurantName'>
                                        <span style={{ ...fontStyles, color: primaryFontColor }}>{!this.props.isMenuScreen ? this.props.brand.name : this.props.brandName}</span>
                                        <span style={{ ...restaurantNameLabelStyle, ...fontStyles }}>{!this.props.isMenuScreen ? this.props.selectedRestaurant.name : this.props.restaurant.name}</span>
                                    </h4>
                            }
                        </Col> :
                        <Col sm={12} style={{ paddingTop: 30 }}>
                            {this.props.selectedLanguage !== 'en' && isDinePayScreen ? this.renderSwitchLanguageButtons() : null}
                            <h4 className='orderDetailsRestaurantName' style={{ marginTop: '0px' }}>
                                {/* <InStoreSVG width={32} height={32} fill={primaryColor} style={{ margin: '0 12px 5px 0' }} /> */}
                                <span style={{ ...fontStyles, color: primaryFontColor }}>{i18n.t('screens:tableSelectionScreen.dinePay')}</span>
                            </h4>
                        </Col>
                }

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: inStoreAvailable ? 'initial' : 'center', flex: '1 1 100%', paddingTop: inStoreAvailable ? 100 : 40 }}>
                    {
                        !inStoreAvailable && brandId ?
                            <Col sm={12} className='modal-custom-body-title' style={{ height: 57, maxHeight: 57, minHeight: 57, maxWidth: window.innerWidth <= 768 ? window.innerWidth <= 400 ? '95%' : '75%' : '55%', margin: '0 auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p className='title-style' style={titleStyle}>
                                    {/* <InStoreSVG width={32} height={32} fill={primaryColor} style={{ marginRight: 12 }} /> */}
                                    <div style={{ display: 'flex', paddingTop: 3 }}>{this.state.title}</div>
                                </p>
                            </Col> : null
                    }
                    {
                        !inStoreAvailable && brandId ?
                            <Col sm={12} style={{ display: 'flex', justifyContent: 'center', paddingBottom: 60 }}>
                                <Input
                                    innerRef={(input) => { this.valueInput = input }}
                                    className='addressInputs'
                                    name='value'
                                    type='text'
                                    placeholder={this.state.title ? `${i18n.t('screens:tableSelectionScreen.labelPlaceholder')} ${this.state.label}` : ''}
                                    value={this.state.value}
                                    disabled={!this.state.title}
                                    onChange={this.changeValue}
                                    maxLength={48}
                                    style={inputsStyle} />
                            </Col> :
                            <h4 className='orderDetailsRestaurantName' style={{ color: primaryFontColor, margin: '0 0 30px 0', fontSize: 27 }}>{i18n.t('screens:tableSelectionScreen.serviceUnavailable')}</h4>
                    }
                </div>
                {
                    <Fragment>
                        <Col sm={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', marginLeft: 0, marginBottom: 0 }}>
                            <Button hidden={inStoreAvailable || !brandId} className={nextButtonStyle} style={buttonNextStyle} onClick={() => this.goForward()}>
                                <span style={{ margin: '0 auto', textAlign: 'center' }}>{!this.props.isMenuScreen ? i18n.t('common:buttons.nextBtn') : `${i18n.t('screens:tableSelectionScreen.changeLabel')} ${this.state.label}`}</span>
                            </Button>
                        </Col>
                        {
                            this.props.pwaAppRunning || isMobileView || this.props.estimateOrderTime.showMenuuLogo ?
                                <Col sm={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', marginLeft: 0, marginBottom: 30 }}>
                                    <MenuuLogo width={120} height={60} fill={menuuLogoColor} viewBoxThirdValue={520} />
                                </Col> : null
                        }

                    </Fragment>
                }

            </Fragment>
        );
    }

    renderTypeOptions(types) {
        if (types) {
            return types.map(type => {
                return <option value={type.title}>{type.title}</option>
            })
        }
    }

    goForward() {
        const { defaultLanguage, ...restOfTheState } = this.state;

        this.props.saveInStoreInfo(restOfTheState);

        if (this.props.isMenuScreen) {
            this.props.toggle();
        } else {
            if (this.props.selectedRestaurant.type.futureOrderEnabled) {
                this.props.toggleOrderTimeScreen(this.state.typeId);
            } else {
                this.props.history.push('/menu-screen');
                this.props.saveNavigationType(1);
                // parentLocalStorage('set', 'navigationType', 1);
            }
        }
    }

    render() {
        return (
            <div className="start-order" style={{ background: '#fff' }}>
                <ModalStep
                    onToggle={this.props.toggle}
                    renderBody={this.renderModalBody()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </div>
        );
    }
}

export default connect(null, { saveNavigationType, changeLanguage })(TableSelectionScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    modalStyle: {
        border: 'none',
    },
    restaurantNameLabelStyle: {
        marginLeft: 5,
        color: '#a7a7a7'
    },
};
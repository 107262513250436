import React from 'react';
import { Col, Badge } from 'reactstrap';

import { FRONT_END_URL } from '../../../utils/Constants';

const ProductCardImgContainer = props => {

    const { appOrMobile, product, selectedTheme, styles, productFoundInCart, productQuantityInCart } = props;
    const { webImgContainer, imgStyles, appImgContainer, badgeContainer } = constantStyles;

    if (!appOrMobile) {
        return (
            <div style={webImgContainer}>
                <img
                    className='img-thumbnail'
                    src={`${FRONT_END_URL}/${product.thumbnailPath}`}
                    style={{
                        ...imgStyles,
                        backgroundColor: selectedTheme.menuItemsBackground
                    }} />
            </div>
        );
    }

    return (
        <Col style={appImgContainer}>
            <img
                className='img-thumbnail-mobile'
                src={`${FRONT_END_URL}/${product.thumbnailPath}`}
                style={{
                    ...imgStyles,
                    backgroundColor: selectedTheme.menuItemsBackground
                }} />
            <Col
                hidden={productFoundInCart}
                style={{
                    ...styles.badgeColMobile,
                    ...badgeContainer
                }} >
                <Badge style={{
                    ...styles.badge,
                    background: selectedTheme.primaryColor
                }}>{productQuantityInCart}x</Badge>
            </Col>
        </Col>
    );
}

export default ProductCardImgContainer;

const constantStyles = {
    webImgContainer: {
        paddingRight: 0,
        paddingLeft: 0,
        minWidth: 125
    },
    imgStyles: {
        height: '100%',
        objectFit: 'cover'
    },
    appImgContainer: {
        textAlign: 'right',
        paddingRight: 0,
        paddingLeft: 0,
        marginBottom: -10
    },
    badgeContainer: {
        position: 'absolute',
        top: 12,
        paddingRight: 12
    }
};
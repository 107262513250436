import {
    TOGGLE_SEARCH_PRODUCT_FOCUS,
    TOGGLE_PROMOTIONS_MODAL,
    TOGGLE_SHOPPING_CART_MODAL,
    CLEAR_CART_AND_PROMO_MODALS
} from '../actions/types';

const INITIAL_STATE = {
    isSearchProductOnFocus: false,
    isPromotionsModalOpen: false,
    isShoppingCartModalOpen: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_SEARCH_PRODUCT_FOCUS:
            return { ...state, isSearchProductOnFocus: action.payload }
        case TOGGLE_PROMOTIONS_MODAL:
            return {
                ...state,
                isPromotionsModalOpen: !state.isPromotionsModalOpen
            }
        case TOGGLE_SHOPPING_CART_MODAL:
            return {
                ...state,
                isShoppingCartModalOpen: !state.isShoppingCartModalOpen
            }
        case CLEAR_CART_AND_PROMO_MODALS:
            return {
                ...state,
                isPromotionsModalOpen: false,
                isShoppingCartModalOpen: false
            }
        default:
            return state;
    }
};
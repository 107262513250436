import {
    GET_UPSELL_PRODUCTS_SUCCESS,
    CLEAR_STATE,
    CLEAR_APP_STATE
} from '../../actions/types';

const INITIAL_STATE = {
    upsellMessage: '',
    upsellProducts: []
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_UPSELL_PRODUCTS_SUCCESS:
            return {
                ...state,
                upsellMessage: payload.upsellMessage,
                upsellProducts: payload.upsellProducts
            };
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
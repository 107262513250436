import React from 'react';
import { DELIVERY_ID, COLLECTION_ID, CURBSIDE_ID } from '../../../../utils/Constants';
import i18n from '../../../../i18next';

const SelectedOrderTypeInfo = (props) => {
    let locationChange, timeChange;

    if (props.selectedOrderType.id === DELIVERY_ID) {
        locationChange = {
            label: i18n.t('screens:savedCustomerAddressesScreen.deliverTo'),
            infoLabel: props.selectedDeliveryAddress.fullAddress,
            onClick: props.toggle,
            style: props.changeDeliveryInformation,
            text: i18n.t('screens:savedCustomerAddressesScreen.changeAddress')
        }
        timeChange = {
            label: props.orderTimeLabel,
            infoLabel: props.orderTime,
            onClick: props.changeTime,
            style: props.changeDeliveryInformation,
            text: i18n.t('screens:savedCustomerAddressesScreen.changeTime')
        }
    } else if (props.selectedOrderType.id === COLLECTION_ID) {
        locationChange = {
            label: i18n.t('screens:savedCustomerAddressesScreen.collectFrom'),
            infoLabel: props.restaurantFullAddress,
            onClick: props.toggle,
            style: props.changeLinkStyle,
            text: i18n.t('screens:savedCustomerAddressesScreen.changeLocation')
        }
        timeChange = {
            label: props.orderTimeLabel,
            infoLabel: props.orderTime,
            onClick: props.toggle,
            style: props.changeLinkStyle,
            text: i18n.t('screens:savedCustomerAddressesScreen.changeTime')
        }
    } else if (props.selectedOrderType.id === CURBSIDE_ID) {
        locationChange = {
            label: i18n.t('screens:savedCustomerAddressesScreen.curbsideFrom'),
            infoLabel: props.restaurantFullAddress,
            onClick: props.toggle,
            style: props.changeLinkStyle,
            text: i18n.t('screens:savedCustomerAddressesScreen.changeLocation')
        }
        timeChange = {
            label: props.orderTimeLabel,
            infoLabel: props.orderTime,
            onClick: props.toggle,
            style: props.changeLinkStyle,
            text: i18n.t('screens:savedCustomerAddressesScreen.changeTime')
        }
    }

    const renderButtons = [locationChange, timeChange];
    return (
        <React.Fragment>
            {
                renderButtons.map((button, i) => {
                    return (
                        <div key={`button${i}`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: props.selectedTheme.primaryFontColor}}>
                            <div className="long-text" style={button.text === 'Change Time' ? {maxWidth: '70%'} : { maxWidth: '60%' }}>
                                <span className="deliveryInfoLabel">{button.infoLabel}</span>
                            </div>
                            <div disabled={props.isDisabled}
                                onClick={() => button.onClick(i)} // i is allways = to the second object - change time/ indexed as 1
                                style={button.style}>
                                {button.text}
                            </div>
                        </div>
                    );
                })
            }
        </React.Fragment>

    );

}
export default SelectedOrderTypeInfo;
import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import i18n from '../../../../i18next';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

const UserNotVerified = props => {

        const { 
            type,
            secondaryColor,
            buttonsFont,
            fontFamilyType,
            fontBold,
            fontItalic,
            uppercase,
            checkoutScreenNames,
            primaryFontColor 
        } = props.customerThemes.selectedTheme;

        return (
            <Fragment>
                { props.loading.transparentLoader ? null :
                    < div >
                        <Col style={{
                            maxWidth: '450px',
                            color: type === "LIGHT" ? checkoutScreenNames : secondaryColor,
                            fontSize: 18,
                            fontFamily: fontFamilyType,
                            fontWeight: fontBold === 'bold' ? fontBold : 300,
                            fontStyle: fontItalic,
                            textTransform: uppercase,
                            marginBottom: 30,
                            textAlign: 'center'
                        }}>
                            <p style={{paddingBottom: '25px' }}>{i18n.t('screens:como.finalizeRegistration')}</p>
                        </Col>
                        <Col style={{ width: '100%', display: 'flex', textAlign: 'center', alignItems: 'center' }}>
                            <div style={props.isArabic ? { margin: '0 auto', backgroundColor: primaryFontColor, width: '50%', marginRight: '2px' } : { margin: '0 auto', backgroundColor: primaryFontColor, width: '50%', marginLeft: '2px' }}>
                                <div onClick={ props.redirectNotVerifiedUser } style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', height:'35px', position: 'absolute', left: '25%', bottom: '0px', marginBottom: '5px', color: buttonsFont, backgroundColor: primaryFontColor, fontFamily: fontFamilyType, fontStyle: fontItalic, fontBold: fontBold, uppercase: uppercase }}>
                                    {i18n.t('screens:shoppingCart.checkOutNow')}
                                </div>
                            </div>
                        </Col>
                    </div>
                }
            </Fragment>
        )
}

export default (UserNotVerified);
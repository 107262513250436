import React from 'react';

const CardsSVG = ({ width, height, fill, style }) => {
	const viewBoxValues = "0 0 120 95.2";

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style} >
			<g>
				<g>
					<path fill={fill} d="M89.3,2.4c-0.7-1.9-2.8-2.9-4.7-2.2L2.4,29.6c-1.9,0.7-2.9,2.8-2.2,4.7L18,84c0.7,1.9,2.8,2.9,4.7,2.2
						l0.8-0.3l0.1-37.4l0,0.1L7.4,54.3l-3.1-8.7l19.2-6.9l0-2.1c0-2.1,1.7-3.8,3.8-3.8l72.9,0.1L89.3,2.4z M39.9,32.9l53.4-19.1
						l3.1,8.7L67.4,32.9L39.9,32.9z"/>
				</g>
				<g>
					<path fill={fill} d="M116.3,35H29.1c-2,0-3.7,1.6-3.7,3.7v52.8c0,2,1.6,3.7,3.7,3.7h87.3c2,0,3.7-1.6,3.7-3.7V38.7
					C120,36.7,118.4,35,116.3,35z M35.6,70.7h32.8c0.5,0,1,0.3,1,0.7c0,0.4-0.4,0.7-1,0.7H35.6c-0.5,0-1-0.3-1-0.7
					C34.7,71,35.1,70.7,35.6,70.7z M46.4,82.2H34.5V79h11.9V82.2z M62.5,82.2H50.6V79h11.9V82.2z M78.6,82.2H66.8V79h11.9V82.2z
					 M94.8,82.2H82.9V79h11.9V82.2z M96.3,51.6v-7.3c0-0.9,0.8-1.7,1.7-1.7h10.8c0.9,0,1.7,0.8,1.7,1.7v7.3c0,0.9-0.8,1.7-1.7,1.7H98
					C97.1,53.3,96.3,52.6,96.3,51.6z M110.9,82.2H99V79h11.9V82.2z"/>
				</g>
			</g>
		</svg>
	);
}

export default CardsSVG;
import React from "react";
import { connect } from 'react-redux';
import i18n from "../../i18next";

import { unsubscribeCustomerToChain } from "../../actions/index";
import { getAllUrlParams } from "../../utils/CommonUtils";

class UnsubscribeScreen extends React.Component {

    state = {
        isUnsubscribed: false
    }

    componentDidMount = () => {

        const params = getAllUrlParams(window.location.href);
        const customerId = params.customerId;
        const brandId = params.brandId;

        this.props.unsubscribeCustomerToChain(customerId, brandId, () => { this.setState({ isUnsubscribed: true }) });
    }

    renderUnsubscribeMessage = () => {

        const { outer, container, heading, information } = styles;

        return (
            <div style={outer}>
                <div style={container}>
                    <h3 style={heading}>{i18n.t('screens:unsubscribeScreen.unsubscribeSuccess')}</h3>
                    <div style={information}>{i18n.t('screens:unsubscribeScreen.unsubscribeAdditionalMessage')}</div>
                </div>
            </div>

        );
    }

    render() {
        return this.state.isUnsubscribed ? this.renderUnsubscribeMessage() : null;
    }
}

export default connect(null, { unsubscribeCustomerToChain })(UnsubscribeScreen);

const styles = {
    outer: {
        paddingTop: '75px'
    },
    container: {
        backgroundColor: '#f2f2f2',
        border: '1px solid #d4d9dd',
        borderRadius: '15px',
        margin: 'auto',
        width: '50%',
        padding: '30px',
    },
    heading: {
        fontSize: '25px',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontWeight: 'bold'
    },
    information: {
        marginTop: '20px'
    }
};
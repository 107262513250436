import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../i18next';

import ApplicationStep from '../../common/ApplicationStep';
import TipScreen from '../../../../components/order/shoppingCart/TipScreen';
import { transformProductPrice, getCurrencySymbol } from '../../../../utils/CommonUtils';
import { applyGiftCard, increaseOrDecreaseTip } from '../../../../actions';

class TipScreenPwa extends Component {

    renderContent() {
        const appliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards : null;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        
        return (
            <TipScreen
                tip={transformProductPrice(this.props.shoppingCart.tip)}
                currency={getCurrencySymbol(this.props.selectedRestaurant.currency)}
                saveTip={this.props.increaseOrDecreaseTip}
                isPwa={this.props.pwaAppRunning}
                history={this.props.history}
                applyGiftCard={this.props.applyGiftCard}
                appliedGiftCards={appliedGiftCards}
                isAppliedGiftCards={isAppliedGiftCards}
            />
        )
    }

    goBack() {
        this.props.history.push('/brand/' + this.props.brandId + '/order/menu');
    }

    render() {
        return (
            <ApplicationStep
                header={true}
                hideBackButton={false}
                transparentHeader={false}
                content={this.renderContent()}
                goBack={this.goBack.bind(this)}
                text={i18n.t('screens:shoppingCart.tip')}
                isHamburgerShadowed={false}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        shoppingCart: state.shoppingCart,
        selectedRestaurant: state.selectedRestaurant,
        pwaAppRunning: state.pwaAppRunning,
        brandId: state.brand.id,
        giftCards: state.giftCards
    }
}

export default connect(mapStateToProps, {increaseOrDecreaseTip, applyGiftCard})(TipScreenPwa);
import {CLEAR_APP_STATE, SET_CONNECTION_STATUS} from '../actions/types';

const INITIAL_STATE = true;

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_CONNECTION_STATUS:
            return action.payload;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
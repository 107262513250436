import React from 'react';
import { Label, Input, } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import {isMobile} from "mobile-device-detect";

const MultiAnswersListItem = ({
    iOSDetected,
    answer,
    selectedAnswerCopy,
    toppingPrice,
    primaryColor,
    primaryFontColor,
    type,
    answerPricePriceWithCurrency,
    mainLabelStyles,
    isSelected,
    selectItem,
    changeAnswerQty,
    hideWhenNoMultiIngr,
    hideAddButton,
    areAllItemsDefault,
    isArabic }) => {
    const { multiListItem, isSelectedAnsName, isNotSelectedAnsName, addIconStyle, addIconStyleRTL, removeIconStyle, removeIconStyleRTL, secondLabel, secondDiv } = styles;
    const answerName = areAllItemsDefault ? `No ${selectedAnswerCopy.name}` : selectedAnswerCopy.name;
    let qtyBtnColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? primaryFontColor : '#656565';
    let mainLabelStylesCopy = { ...mainLabelStyles, ... { cursor: 'pointer' } };

    if ((hideWhenNoMultiIngr || (isSelected && !hideWhenNoMultiIngr)) && (toppingPrice === 0 || !toppingPrice) && !areAllItemsDefault) {
        mainLabelStylesCopy.width = '80%';
    } else if (!hideWhenNoMultiIngr && toppingPrice && iOSDetected) {
        mainLabelStylesCopy.width = '65%';
    } else if (!hideWhenNoMultiIngr && toppingPrice && !iOSDetected) {
        // Android or Win systems
        if (window.innerWidth <= 320 && toppingPrice < 10) {
            mainLabelStylesCopy.width = '52%';
        } else if (window.innerWidth <= 320 && toppingPrice >= 10) {
            mainLabelStylesCopy.width = '50%';
        } else if (window.innerWidth <= 360) {
            mainLabelStylesCopy.width = '56%';
        }
    }

    return (
        <li style={{ ...multiListItem, ...{ color: primaryFontColor } }}>
            <Label className='ingredients-checkbox' style={mainLabelStylesCopy}>
                <Input id={answer.name} name={answer.name} style={isArabic ? { marginRight: 0, display: 'none' } : { marginLeft: 0, display: 'none' }}
                    type='checkbox' className='styled-checkbox'
                    value={answer.name}
                    checked={isSelected}
                    onChange={(event) => selectItem(event, answer)}
                />
                <Label for={answer.name} style={secondLabel}>
                    <p style={!isSelected ? isNotSelectedAnsName : isSelectedAnsName}>{answerName}</p>
                </Label>
            </Label>
            {
                !isSelected || areAllItemsDefault ? null :

                    <div style={secondDiv}>
                        {
                            hideWhenNoMultiIngr ? null :
                                <div style={isArabic ? { display: 'flex', width: 50, marginLeft: 22, marginTop: '-0.19rem' } : { display: 'flex', width: 50, marginRight: 22, marginTop: '-0.19rem' }} hidden={hideWhenNoMultiIngr}>
                                    <span
                                        className='addOrRemoveIcon' style={isArabic ? removeIconStyleRTL : removeIconStyle}
                                        onClick={() => changeAnswerQty(selectedAnswerCopy, -1)}
                                    >
                                        <MaterialIcon icon='remove' color={qtyBtnColor} size={20} />
                                    </span>
                                    <span style={{ position: 'relative', padding: 2, color: primaryColor }}>
                                        {(selectedAnswerCopy && selectedAnswerCopy.count) ? selectedAnswerCopy.count : null}
                                    </span>
                                    <span
                                        className='addOrRemoveIcon' style={isArabic ? addIconStyleRTL : addIconStyle}
                                        hidden={hideAddButton}
                                        onClick={() => changeAnswerQty(selectedAnswerCopy, 1)}
                                    >
                                        <MaterialIcon icon='add' color={qtyBtnColor} size={20} />
                                    </span>
                                </div>
                        }

                        {
                            toppingPrice === 0 || !toppingPrice ? null :

                                <span style={{ color: primaryColor }} //hidden={toppingPrice === 0 || !toppingPrice}
                                >
                                    +{answerPricePriceWithCurrency}
                                </span>
                        }

                    </div >
            }
        </li >
    );
};

export default MultiAnswersListItem;

let styles = {
    multiListItem: {
        display: 'flex',
        width: '100%',
        lineHeight: '1.3',
        padding: '5px 10px'
    },
    isSelectedAnsName: {
        width: 'calc(100% - 30px)',
        margin: 0,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    isNotSelectedAnsName: {
        width: 'calc(100% - 30px)',
        margin: 0
    },
    removeIconStyle: {
        cursor: 'pointer',
        paddingRight: 4,
        zIndex: 3
    },
    removeIconStyleRTL: {
        cursor: 'pointer',
        paddingLeft: 4,
        zIndex: 3
    },
    addIconStyle: {
        cursor: 'pointer',
        paddingLeft: 4,
        zIndex: 3
    },
    addIconStyleRTL: {
        cursor: 'pointer',
        paddingRight: 4,
        zIndex: 3
    },
    secondLabel: {
        display: 'flex',
        marginBottom: 0,
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        pointerEvents: 'none',
        width: '100%'
    },
    secondDiv: {
        display: 'flex',
        justifyContent: 'flex-end',
        flex: 1,
        // alignItems: 'center',
        width: '50%'
    }
}
// Dark Theme Icons
import menuuLogoThemeDark from '../images/icons/dark_theme_icons/dark-menuu-logo.png';
import deliveryIconDark from '../images/icons/dark_theme_icons/dark-icon-delivery.png';
import collectionIconDark from '../images/icons/dark_theme_icons/dark-icon-cart.png';
import commentsIconDark from '../images/icons/dark_theme_icons/dark-icon-comments.png';
import locationIconDark from '../images/icons/dark_theme_icons/dark-icon-location.png';
import userIconDark from '../images/icons/dark_theme_icons/dark-icon-user.png';
import clockIconDark from '../images/icons/dark_theme_icons/dark-icon-clock.png';
import cardIconDark from '../images/icons/dark_theme_icons/dark-icon-pay.png';
import checkIconDark from '../images/icons/dark_theme_icons/dark-icon-tick.png';
import homeIconDark from '../images/icons/dark_theme_icons/dark-icon-home.png';
import primaryIconDark from '../images/icons/dark_theme_icons/primary-dark-icon-location.png';
import messagesIconDark from '../images/icons/dark_theme_icons/dark-icon-messages.png';
import heartIconDark from '../images/icons/dark_theme_icons/dark-icon-heart.png';
import checkboxIconDark from '../images/icons/dark_theme_icons/dark-icon-checkbox.png';
import successIconDark from '../images/icons/dark_theme_icons/dark-icon-success.png';
import sendingIconDark from '../images/icons/dark_theme_icons/dark-icon-sending-order.png';
import mainMarkerIconDark from '../images/icons/dark_theme_icons/pwa-main-screen-marker-dark.png';
import orderSuccesIconDark from '../images/icons/dark_theme_icons/icon-success-dark.png';
import orderSendingIconDark from '../images/icons/dark_theme_icons/order-sending-icon-dark.png';

// Light Theme Icons
import menuuLogoThemeLight from '../images/icons/light_theme_icons/light-menuu-logo.png';
import deliveryIconLight from '../images/icons/light_theme_icons/light-icon-delivery.png';
import collectionIconLight from '../images/icons/light_theme_icons/light-icon-cart.png';
import commentsIconLight from '../images/icons/light_theme_icons/light-icon-comments.png';
import locationIconLight from '../images/icons/light_theme_icons/light-icon-location.png';
import userIconLight from '../images/icons/light_theme_icons/light-icon-user.png';
import clockIconLight from '../images/icons/light_theme_icons/light-icon-clock.png';
import cardIconLight from '../images/icons/light_theme_icons/light-icon-pay.png';
import checkIconLight from '../images/icons/light_theme_icons/light-icon-tick.png';
import homeIconLight from '../images/icons/light_theme_icons/light-icon-home.png';
import primaryIconLight from '../images/icons/light_theme_icons/primary-light-icon-location.png';
import collectionSecondaryIconLight from '../images/icons/light_theme_icons/secondary-light-icon-cart-grey.png';
import messagesIconLight from '../images/icons/light_theme_icons/light-icon-messages.png';
import heartIconLight from '../images/icons/light_theme_icons/light-icon-heart.png';
import checkboxIconLight from '../images/icons/light_theme_icons/light-icon-checkbox.png';
import successIconLight from '../images/icons/light_theme_icons/light-icon-success.png';
import sendingIconLight from '../images/icons/light_theme_icons/light-icon-sending-order.png';
import mainMarkerIconLight from '../images/icons/light_theme_icons/pwa-main-screen-marker-light.png';
// import orderSuccesIconLight from '../images/icons/light_theme_icons/icon-success-light.png';
// import orderSendingIconLight from '../images/icons/light_theme_icons/order-sending-icon-light.png';


// Common Icons
import closeIconGray from '../images/icons/icon-close-grey.png';
import collectionIconWhite from '../images/icons/dark_theme_icons/dark-icon-cart-white.png';
import deliveryIconWhite from '../images/icons/delivery-icon-white.png';

export const matchThemes = (serverTheme, stateThemes, themePreviewMode) => {
    let selectedTheme;

    if (!themePreviewMode) {
        if (serverTheme.type === "DARK") {
            selectedTheme = stateThemes.themeDark;
        } else if (serverTheme.type === "LIGHT") {
            selectedTheme = stateThemes.themeLight;
        } else {
            selectedTheme = serverTheme;
        }
    } else {
        if (themePreviewMode === "DARK") {
            selectedTheme = stateThemes.themeDark;
        } else if (themePreviewMode === 'LIGHT') {
            selectedTheme = stateThemes.themeLight;
        } else {
            selectedTheme = serverTheme;
        }
    }

    return selectedTheme;
}

export const updateCSSVariables = (variables) => {
    if (variables) {
        variables.forEach(attribute => {
            document.documentElement.style.setProperty(attribute['key'], attribute['value']); // etc.. color: btnColor - prop: value
        });
    }
}

export const updateIconsAccordingToTheme = (selectedThemeType, iconType) => {

    if (selectedThemeType === 'DARK' || selectedThemeType === 'CUSTOM') {
        switch (iconType) {
            case 'menuu-logo':
                return menuuLogoThemeDark;
            case 'delivery':
                return deliveryIconDark;
            case 'delivery-pwa':
                return deliveryIconWhite;
            case 'collection':
            case 'collection-secondary':
                return collectionIconDark;
            case 'collection-pwa':
                return collectionIconWhite;
            case 'comments':
                return commentsIconDark;
            case 'card':
                return cardIconDark;
            case 'clock':
                return clockIconDark;
            case 'location':
                return locationIconDark;
            case 'user':
            case 'user-secondary':
                return userIconDark;
            case 'check':
                return checkIconDark;
            case 'home':
                return homeIconDark;
            case 'location-primary':
                return primaryIconDark;
            case 'messages':
                return messagesIconDark;
            case 'heart-icon':
                return heartIconDark;
            case 'close':
                return closeIconGray;
            case 'checkbox':
                return checkboxIconDark;
            case 'pwa-main-marker':
                return mainMarkerIconDark;
            case 'order-success':
                return orderSuccesIconDark;
            case 'order-sending':
                return orderSendingIconDark;
            case 'succeed':
                return successIconDark;
            case 'sending':
                return sendingIconDark;
        }
    } else {
        switch (iconType) {
            case 'menuu-logo':
                return menuuLogoThemeLight;
            case 'delivery':
                return deliveryIconLight;
            case 'delivery-pwa':
                return deliveryIconWhite;
            case 'collection':
                return collectionIconLight;
            case 'collection-pwa':
                return collectionIconWhite;
            case 'comments':
                return commentsIconLight;
            case 'card':
                return cardIconLight;
            case 'clock':
                return clockIconLight;
            case 'location':
                return locationIconLight;
            case 'user':
            case 'user-secondary':
                return userIconLight;
            case 'check':
                return checkIconLight;
            case 'home':
                return homeIconLight;
            case 'location-primary':
                return primaryIconLight;
            case 'collection-secondary':
                return collectionSecondaryIconLight;
            case 'messages':
                return messagesIconLight;
            case 'heart-icon':
                return heartIconLight;
            case 'close':
                return closeIconGray;
            case 'checkbox':
                return checkboxIconLight;
            case 'succeed':
                return successIconLight;
            case 'sending':
                return sendingIconLight;
            case 'pwa-main-marker':
                return mainMarkerIconLight;
        }
    }
}
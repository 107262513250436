import React from 'react';
import { Col, Row, Badge, } from 'reactstrap';
import { FRONT_END_URL } from '../../../utils/Constants';

import MenuProductCard from './MenuProductCard';
import MenuProductCardLargeImage from './MenuProductCardLargeImage';
import ProductSearchBar from '../ProductSearchBar';

const ProductsScreen = (props) => {
    const renderProducts = (category) => {
        const { cardOutlined, badgeCol, badge, badgeColRTL, largeImageCardOutlined, largeImageBadge, largeImageBadgeCol, largeImageBadgeColRTL } = props.styles;
        const { products } = props;
        const { menuItemsBackground } = props.customerThemes.selectedTheme;
        const enableLargeImageDesign = (props.selectedRestaurant ? props.selectedRestaurant.enableLargeImageDesign : false) || (props.estimateOrderTime ? props.estimateOrderTime.enableLargeImageDesign : false);
        const badgeColStyle = enableLargeImageDesign && props.isArabic ? largeImageBadgeColRTL : enableLargeImageDesign && !props.isArabic ? largeImageBadgeCol : props.isArabic ? badgeColRTL : badgeCol;
        const productColStyle = enableLargeImageDesign ? { maxWidth: '400px', height: '400px', padding: 0 } : props.isArabic ? { paddingLeft: 5 } : { paddingRight: 5 };
        if (products !== undefined && products !== null && products.length > 0) {
            return products.map((product, i) => {

                if (product.categoryId == category.id) {

                    const productNotFoundInCart = checkIfProductIsInCart(product.id, product.productSizesIds);
                    const productQuantityInCart = checkProductQuantityInCart(product.name);

                    return (
                        <Col
                            key={`productCol${i}`}
                            xs={enableLargeImageDesign ? null : 12} 
                            sm={enableLargeImageDesign ? null : 12} 
                            md={enableLargeImageDesign ? null : 12} 
                            lg={enableLargeImageDesign ? null : 6} 
                            xl={enableLargeImageDesign ? null : 4} 
                            hidden={!props.isProductMatchingSearch(product.id) && props.hiddenStickyMenu}
                            style={productColStyle}
                            onClick={() => props.selectProduct(product)}>
                            <Col style={badgeColStyle} hidden={productNotFoundInCart}>
                                <Badge style={enableLargeImageDesign ? largeImageBadge : badge}>{productQuantityInCart}x</Badge>
                            </Col>
                            {
                                enableLargeImageDesign ?
                                    <MenuProductCardLargeImage
                                        productIndex={i}
                                        product={product}
                                        currency={props.currency}
                                        selectedTheme={props.customerThemes.selectedTheme}
                                        cardStyle={{ ...largeImageCardOutlined }}
                                        isArabic={props.isArabic}
                                    /> :
                                    <MenuProductCard
                                        productIndex={i}
                                        product={product}
                                        currency={props.currency}
                                        selectedTheme={props.customerThemes.selectedTheme}
                                        cardStyle={{ ...cardOutlined }}
                                        isArabic={props.isArabic}
                                    />
                            }
                        </Col>
                    );
                }
            });
        }
    }

    const checkIfProductIsInCart = (productId, productSizesIds) => {
        const { orderProducts } = props.shoppingCart;
        let productNotFoundInCart = true;

        if (orderProducts.length > 0) {
            orderProducts.map(productProp => {
                if (productProp.id === productId || productSizesIds.includes(productProp.id)) {
                    productNotFoundInCart = false;
                }
            });
        }
        return productNotFoundInCart;
    }

    const checkProductQuantityInCart = (productName) => {
        const { orderProducts } = props.shoppingCart;
        let productQuantity;
        let quantityToAdd = 0;
        let productNameFoundInCart = 0;

        if (orderProducts.length > 0) {
            orderProducts.map(productProp => {
                if (productProp.name === productName) {
                    productNameFoundInCart++;
                    if (productProp.quantity > 1) {
                        quantityToAdd += (productProp.quantity - 1);
                    }
                }
            });
            productQuantity = productNameFoundInCart + quantityToAdd;
        }
        return productQuantity;
    }

    const renderCategories = () => {
        const { categoryNameStyle, categoryNameStyleRTL, categoryDescriptionDesktop, categoryDescriptionDesktopRTL } = props.styles;
        const enableLargeImageDesign = (props.selectedRestaurant ? props.selectedRestaurant.enableLargeImageDesign : false) || (props.estimateOrderTime ? props.estimateOrderTime.enableLargeImageDesign : false);

        return props.selectedCategories.map((category, i) => {

            return (
                <section
                    key={`container${i}`}
                    hidden={props.hiddenStickyMenu && props.noSearchedProducts(category)}
                    style={{ width: '100%', marginBottom: '20px' }}
                    id={category.name}
                    className="productCategoryName menuCategoryMainContainer">
                    {/* input fields for auto scroll are removed for desktop */}
                    {/* {renderFirstCategoryHiddenId(this.props.menus, category, false, i, false)} */}
                    <Row id={category.name} style={{ marginTop: 10 }}>
                        <div style={{width: '100%'}}>
                            <h4 style={props.isArabic ? categoryNameStyleRTL : categoryNameStyle}>{category.name}</h4>
                            <span style={props.isArabic ? categoryDescriptionDesktopRTL : categoryDescriptionDesktop}>{category.description}</span>
                        </div>
                        {category.imageUrl && !enableLargeImageDesign ?
                            <div className='categoryImageContainer' style={{ width: '98%', maxHeight: '350px', margin: '0 20px 0 15px' }}>
                                <img src={`${FRONT_END_URL}/${category.imageUrl}`} style={{ width: '100%', height: '100%' }} alt='category image' />
                            </div> : null
                        }
                    </Row>
                    <Row className={enableLargeImageDesign ? 'largeImageProductsRow' : null} style={!enableLargeImageDesign && props.isArabic ? { paddingLeft: '15px' } : !enableLargeImageDesign && !props.isArabic ? { paddingRight: '15px' } : null }>
                        {renderProducts(category)}
                    </Row>
                </section>
            );
        })
    }

    const renderSearchBar = () => {
		return (
			<div style={{maxWidth: '1600px', margin: '0 auto'}}>
				<ProductSearchBar
						sendDataToParent={props.sendDataToParent}
						customerThemes={props.customerThemes}
						products={props.products}
						toggleSearchProductFocus={props.toggleSearchProductFocus} 
						isArabic={props.isArabic}
						enableLargeImageDesign={props.enableLargeImageDesign}
				/>
			</div>
		)
	}

    const renderProductsScreenContainer = () => {
        const isGeneralNoteSet = props.menuDescription.generalNote
        let { generalNote } = props.styles;
        generalNote = { ...generalNote, marginTop: 0, paddingTop: 20 }
        const screenWidth = window.outerWidth <= 925;
        const menuProductsPadding = (screenWidth) ? 0 : 15;
        const isLandscapeView = (window.orientation == 90 || window.orientation == -90) ? true : false;
        const productColWidth = (isLandscapeView) ? '60%' : null;

        return (
            <Col sm={12} md={12} style={{ padding: menuProductsPadding, width: productColWidth, paddingTop: !props.hiddenStickyMenu && isGeneralNoteSet ? 20 : 0 }} className="productCol">
                {renderSearchBar()}
                <Row style={screenWidth ? { margin: 0 } : { margin: 0, padding: props.isArabic ? '0px 12px 0px 20px' : '0px 20px 0px 12px' }}>
                    <div id='generalNote' style={generalNote}></div>
                    {renderCategories()}
                </Row>
            </Col>
        )
    }

    return renderProductsScreenContainer();
}

export default ProductsScreen;
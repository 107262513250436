import React, { Component } from 'react';
import Axios from 'axios';
import i18n from '../../../../i18next';
import { Col } from 'reactstrap';

import { SERVER_URL, FRONT_END_URL } from '../../../../utils/Constants';
import { getQueryInfoFromUrl, getAllUrlParams } from '../../../../utils/CommonUtils';

class BamboraForm extends Component {

    componentDidMount = () => {
        this.props.validateCardInput(true);
        this.completeOrder();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.payment.isCardValid !== this.props.payment.isCardValid) {
            this.props.validateCardInput(true);
        }

        // need to save bambora data first and after that to redirect to bambora url
        if ((prevProps.paymentProvider.bamboraData !== this.props.paymentProvider.bamboraData)
            && this.props.paymentProvider.bamboraData) {
            setTimeout(() => this.bamboraRedirect(this.props.paymentProvider.bamboraData.hostedPaymentPageUrl), 2000);
        }
    }

    componentWillUnmount = () => {
        this.props.validateCardInput(false);
    };

    doAuthorize = async (event) => {
        event.preventDefault();
        this.props.processingPayment();

        const { customer, selectedRestaurant, shoppingCart } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        const redirectUrls = this.getMenuuRedirectUrl();

        let paymentIntentRequest = {
            customerId: customer.id,
            restaurantId: selectedRestaurant.restaurantId,
            amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
            currency: selectedRestaurant.currency,
            bamboraNaAuthorizeRequest: {
                redirectUrl: encodeURIComponent(redirectUrls.successUrl),
                failRedirectUrl: encodeURIComponent(redirectUrls.failUrl)
            }
        }

        const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };

        await Axios.post(`${SERVER_URL}/payment/authorize`, paymentIntentRequest, header)
            .then(response => {
                this.props.saveBamboraData(response.data.bamboraNaResponse);
            })
            .catch(error => {
                this.props.errorProcessingPayment(error, 'Authorize Failure', this.props.paymentProvider.providerType.id);
                this.props.sendEmailReport(selectedRestaurant.restaurantId, error, 'Bambora authorize error');
            });
    }

    bamboraRedirect = (redirectUrl) => {
        if (!this.props.pwaAppRunning) {
            window.location.href = redirectUrl;
        } else {
            let browserRef = window.cordova.InAppBrowser.open(redirectUrl, '_blank', 'location=no,toolbar=no'); // iOS only works with target = _blank
            browserRef.addEventListener('loadstart', (event) => this.completeOrder(event, browserRef));
        }
    }

    getMenuuRedirectUrl = () => {
        const { pwaAppRunning } = this.props;
        const { navigationType, brandId } = this.props.storeLocalStorage;
        const isIntegration = getQueryInfoFromUrl().brandId;
        let redirectUrls = {
            successUrl: '',
            failUrl: ''
        }; 

        if (isIntegration) {
            redirectUrls.successUrl = `${FRONT_END_URL}/?brandId=${brandId}&navigationType=${navigationType}&status=SUCCESS#/checkout/`;
            redirectUrls.failUrl = `${FRONT_END_URL}/?brandId=${brandId}&navigationType=${navigationType}&status=FAIL#/checkout/`;
        } else if (pwaAppRunning) {
            redirectUrls.successUrl = 'http://localhost:8100/?status=SUCCESS';   // this is just fake url bcs bambora want some redirect url
            redirectUrls.failUrl = 'http://localhost:8100/?status=FAIL';   // this is just fake url bcs bambora want some redirect url
        } else {
            redirectUrls.successUrl = `${FRONT_END_URL}/?status=SUCCESS#/checkout/`;
            redirectUrls.failUrl = `${FRONT_END_URL}/?status=FAIL#/checkout/`;
        }

        return redirectUrls;
    }

    completeOrder = (event, browserRef) => {
        if (new URLSearchParams(window.location.search).get('status')) {    // desktop and mobile view flow
            const isSuccess = new URLSearchParams(window.location.search).get('status');

            if (isSuccess === 'SUCCESS') {
                const providerData = {
                    transactionId: new URLSearchParams(window.location.hash).get('trnId'),
                    amount: new URLSearchParams(window.location.hash).get('trnAmount')
                }

                this.props.placeOrder(false, providerData);
                this.props.clearBamboraData();
            } else {
                this.props.errorProcessingPayment({}, 'Something went wrong.', this.props.paymentProvider.providerType.id);
                this.props.clearBamboraData();
            }
        } else if (event) {                                                    // apps flow
            if ((event.url).indexOf('status=') !== -1) {
                browserRef.close();
                browserRef.removeEventListener('loadstart', this.completeOrder);
                let allUrlParams = getAllUrlParams(event.url);
                const isSuccess = allUrlParams.status;

                if (isSuccess === 'SUCCESS') {
                    const providerData = {
                        transactionId: allUrlParams.trnId,
                        amount: allUrlParams.trnAmount
                    }

                    this.props.placeOrder(false, providerData);
                    this.props.clearBamboraData();
                } else {
                    this.props.errorProcessingPayment({}, 'Something went wrong.', this.props.paymentProvider.providerType.id);
                    this.props.clearBamboraData();
                }
            }
        } else if (this.props.paymentProvider.bamboraData) {   // handle error when click back button or cancel transaction
            this.props.errorProcessingPayment({}, i18n.t(`screens:shoppingCartActions.cancelledByUser`), this.props.paymentProvider.providerType.id);
            this.props.clearBamboraData();
        }
    }

    render = () => {
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const { cardsInfoMessage } = this.props.styles;
        const isMobileView = window.innerWidth <= 865;

        return (
            <Col>
            <form
                id='bamboraForm'
                onSubmit={this.doAuthorize}
                hidden={true}
            />
            <p
                hidden={this.props.payment.selectedPaymentType.id !== 1}
                style={!this.props.pwaAppRunning && isMobileView ? {...cardsInfoMessage, color: primaryFontColor, position: 'none', left: 'unset'} :
                {...cardsInfoMessage, color: primaryFontColor}}>*{i18n.t('common:alerts.paymentProvidersWarning')}</p>
            </Col>
        )
    }
}
export default BamboraForm;
import axios from '../../utils/AxiosUtils';

import {
    CLEAR_SERVICE_MESSAGES,
    GET_SERVICE_MESSAGES
} from '../types';

export const getServiceMessages = (restaurantId, orderTypeValue) => {
    return (dispatch, getState) => {

        const url = `/restaurant-settings/${restaurantId}/message?orderTypeValue=${orderTypeValue}`;

        axios(getState).get(url)
            .then(response => {
                dispatch({ type: GET_SERVICE_MESSAGES, payload: response.data })
            })
            .catch(_ => { });
    }
};

export const clearServiceMessages = () => {
    return (dispatch) => {
        
        dispatch({ type: CLEAR_SERVICE_MESSAGES, payload: false })
    }
};
import {
    SHOW_SUCCESS_ALERT_MESSAGE,
    CLEAR_SUCCESS_ALERT_MESSAGE,
    SHOW_ERROR_ALERT_MESSAGE,
    CLEAR_ERROR_ALERT_MESSAGE,
    RESET_ALERTS_STATE,
    CLEAR_APP_STATE
} from '../actions/types';

const INITIAL_STATE = {
    showSuccessAlert: false,
    showErrorAlert: false
};

export default (state = INITIAL_STATE, action) => {
    let updatedState = {};

    switch(action.type) {
        case SHOW_SUCCESS_ALERT_MESSAGE:
            updatedState = {...INITIAL_STATE};
            updatedState.showSuccessAlert = true;

            return updatedState;
        case CLEAR_SUCCESS_ALERT_MESSAGE:
            updatedState = {...INITIAL_STATE};
            updatedState.showSuccessAlert = false;

            return updatedState;
        case SHOW_ERROR_ALERT_MESSAGE:
            updatedState = {...INITIAL_STATE};
            updatedState.showErrorAlert = true;

            return updatedState;
        case CLEAR_ERROR_ALERT_MESSAGE:
            updatedState = {...INITIAL_STATE};
            updatedState.showErrorAlert = false;

            return updatedState;
        case RESET_ALERTS_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
import * as ComoUtils from './como/ComoUtils';
// TODO implement error handler here for missing params
export default new class PromotionPartnerUtils {

    getCurrentPartnerUtils = (promotionParnters) => {

        if (promotionParnters.promotionPartner && promotionParnters.promotionPartner.id === 2) {
            return ComoUtils;
        }
        // return promotionParnters.promotionParnter;
    }

    getAppliedPromotions = (promotionParnters, resData) => {

        const currentPartner = this.getCurrentPartnerUtils(promotionParnters);

        return currentPartner.getAppliedPromotions(resData);
    }

    calculateAllDiscounts = (promotionParnters, dataObject) => {

        const currentPartner = this.getCurrentPartnerUtils(promotionParnters);

        if (!currentPartner) return 0;

        return currentPartner.calculateAllDiscounts(dataObject);
    }

    transformDataIntoPartnerStructure = (promotionParnters, dataObject) => {

        const currentPartner = this.getCurrentPartnerUtils(promotionParnters);

        return currentPartner.transformDataStructure(dataObject);
    }

    filterPromotions = (promotionParnters, resData) => {// Might be only for Como (for now)

        const currentPartner = this.getCurrentPartnerUtils(promotionParnters);

        return currentPartner.filterPromotions(resData);
    }
}
import React, {Component} from "react";
import {connect} from 'react-redux';
import i18n from '../../../i18next';

import {Switch, Route} from 'react-router-dom';

import DetailsScreen from "../../../components/profile/DetailsScreen";
import AddressesScreen from "../../../components/profile/AddressesScreen";
import SavedCardsScreen from "../../../components/profile/SavedCardsScreen";
import MarketingPreferencesScreen from "../../../components/profile/MarketingPreferencesScreen";
import OrderHistoryScreen from "../../../components/profile/OrderHistoryScreen";

import '../../styles/ProfileScreenOverrides.css';
import LoyaltyScreen from '../../../components/profile/LoyaltyScreen';
import {
    getCustomerPaymentCards,
    loadCustomerCollectionAddresses,
    loadCustomerDeliveryAddresses,
    loadMarketingPreferences
} from "../../../actions/index";
import {showMessageAlert, clearMessageAlert} from "../../../actions";
import {updateCSSVariables} from '../../../utils/ThemesSelector';
import ApplicationStep from "../common/ApplicationStep";

class ProfileScreenPWA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPath: '',
            isSideMenuOpen: true
        }

        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("backbutton", this.onBackKeyDown, false);

    }

    componentWillUnmount() {
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        this.goBack();
    }

    goBack() {
        this.props.history.push(this.props.lastOpenedPage);
    }

    componentWillMount() {
        if (this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }

        const currentPath = this.props.history.location.pathname;

        this.setState({
            currentPath: currentPath
        })

        this.props.getCustomerPaymentCards();
        this.props.loadCustomerDeliveryAddresses();
        this.props.loadCustomerCollectionAddresses();
        this.props.loadMarketingPreferences();
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }
    }


    applyTheme() {
        const {colorsCss, primaryColor, secondaryColor, mainTextColor, menuItemsBackground} = this.props.customerThemes.selectedTheme;
        updateCSSVariables(colorsCss);
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        customizedStyles.activeNavLinkStyle.color = secondaryColor;
        customizedStyles.saveButtonStyle.backgroundColor = primaryColor;
        customizedStyles.checkboxLabelStyle.color = mainTextColor;
        customizedStyles.mainContainer.backgroundColor = menuItemsBackground;
        styles = customizedStyles;
        return styles;
    }

    render() {
        const brandId = this.props.match.params.id;

        return (


            <Switch>
                <Route path={`/brand/${brandId}/profile/details`}
                       render={(props) => (
                           <ApplicationStep
                               hideBackButton={false}
                               transparentHeader={false}
                               text={i18n.t('screens:profileScreen.profileDetails')}
                               goBack={this.goBack.bind(this)}
                               content={
                                   <div className='profileScreenPWA'>
                                       <DetailsScreen {...props} styles={styles}
                                                      customer={this.props.customer}/>
                                   </div>

                               }
                           />
                       )}/>

                <Route path={`/brand/${brandId}/profile/addresses`}
                       render={(props) => (
                           <ApplicationStep
                               hideBackButton={false}
                               transparentHeader={false}
                               text={i18n.t('screens:profileScreen.addresses')}
                               goBack={this.goBack.bind(this)}
                               content={
                                   <div className='profileScreenPWA'>
                                       <AddressesScreen {...props} styles={styles}/>
                                   </div>

                               }
                           />
                       )}/>
                <Route path={`/brand/${brandId}/profile/cards`}
                       render={(props) => (
                           <ApplicationStep
                               hideBackButton={false}
                               transparentHeader={false}
                               text={i18n.t('screens:profileScreen.savedCards')}
                               goBack={this.goBack.bind(this)}
                               content={
                                   <div className='profileScreenPWA'>
                                       <SavedCardsScreen {...props} styles={styles}/>
                                   </div>
                               }
                           />
                       )}/>
                <Route path={`/brand/${brandId}/profile/order-history`}
                       render={(props) => (
                           <ApplicationStep
                               hideBackButton={false}
                               transparentHeader={false}
                               text={i18n.t('screens:profileScreen.orderHistory')}
                               goBack={this.goBack.bind(this)}
                               content={
                                   <div className='profileScreenPWA' style={this.props.orderHistory.length === 0 ? {display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', maxHeight: 'calc(70vh - 143px)'}: {}}>
                                       <OrderHistoryScreen {...props} styles={styles}/>
                                   </div>
                               }
                           />
                       )}/>
                <Route path={`/brand/${brandId}/profile/loyalty`}
                       render={(props) => (
                           <ApplicationStep
                               hideBackButton={false}
                               transparentHeader={false}
                               text={i18n.t('screens:profileScreen.loyalty')}
                               goBack={this.goBack.bind(this)}
                               content={
                                   <div className='profileScreenPWA'>
                                       <LoyaltyScreen {...props} styles={styles}/>
                                   </div>
                               }
                           />
                       )}/>
                <Route path={`/brand/${brandId}/profile/marketing`}
                       render={(props) => (
                           <ApplicationStep
                               hideBackButton={false}
                               transparentHeader={false}
                               text={i18n.t('screens:profileScreen.marketingPrefrences')}
                               goBack={this.goBack.bind(this)}
                               content={
                                   <div className='profileScreenPWA'>
                                       <MarketingPreferencesScreen {...props} styles={styles}/>
                                   </div>
                               }
                           />
                       )}/>
            </Switch>


        );
    }
}

const mapStateToProps = state => {
    return {
        alertMessage: state.alertMessage,
        customer: state.customer,
        customerThemes: state.customerThemes,
        lastOpenedPage: state.temporaryOrderTime.currentPagePath,
        orderHistory: state.orderHistory
    };
};

export default connect(mapStateToProps, {
    getCustomerPaymentCards,
    loadCustomerDeliveryAddresses,
    loadCustomerCollectionAddresses,
    loadMarketingPreferences,
    showMessageAlert,
    clearMessageAlert
}, null, {pure: false})(ProfileScreenPWA);

let styles = {
    navItemsContainer: {marginTop: 60, marginLeft: 40},
    navItemsStyle: {borderBottom: '1px solid lightgray'},
    navIconStyle: {
        padding: 7,
        marginBottom: -7,
        float: 'left'
    },
    activeNavLinkStyle: {
        color: '#62b400'
    },
    navBarProfile: {marginTop: 40},
    formStyle: {
        padding: '20px 10px'
    },
    mainContainer: {
        backgroundColor: '#fff',
        margin: 'auto 5%  20px',
        marginBottom: 20
    },
    saveButtonStyle: {
        backgroundColor: "#62b400",
        color: '#fff',
        border: 'none',
        borderRadius: 0,
        width: '100%',
        height: 46
    },
    formFieldsStyle: {
        border: '1px solid lightgray',
        borderRadius: 0,
        height: 46
    },
    formLabelStyle: {
        color: 'grey'
    },
    checkboxLabelStyle: {fontSize: 14, color: 'grey', padding: 5, margin: 0},
    checkboxInput: {marginLeft: 10},
    centredText: {
        textAlign: 'center'
    }
};
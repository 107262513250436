import {CLEAR_APP_STATE, CLEAR_STATE, SAVE_RESTAURANT_PRODUCTS} from "../../actions/types";

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_RESTAURANT_PRODUCTS:
            return action.payload;
        case CLEAR_STATE:
            // if (action.fromPwa) {
            //     return state;
            // } else {
                return INITIAL_STATE;
            // }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}

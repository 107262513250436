import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Button, Input, Col, Row, Container } from 'reactstrap';
import { GridLoader } from 'react-spinners';
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import i18n from "../../../i18next";

import { saveCustomerDeliveryAddress, restaurantChainService, activateLoader, stopLoader } from '../../../actions/index';
import { ARABIC, COLLECTION_ID, DELIVERY_ID, GOOGLE_API_KEY } from '../../../utils/Constants';
import ModalStep from '../../common/ModalStep';

import { getGoogleAddressParts, getLoggedInUserFirstName } from "../../../utils/CommonUtils";
import { MAIN_GREEN_COLOR } from "../../../utils/StylesConstants";
import UserIconSVG from '../../../pwa-app/components/common/svgComponents/UserIconSVG';
import DeliverySVG from '../../../pwa-app/components/common/svgComponents/DeliverySVG';
import ShoppingCartSVG from '../../../pwa-app/components/common/svgComponents/ShoppingCartSVG';

class UserAddressScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userAddress: '',
            streetName: null,
            streetNumber: null,
            city: null,
            country: null,
            zipCode: null,
            apartment: null,
            placeId: null,
            userCoordinates: {
                lat: null,
                lng: null
            },
            markersOnMap: [],
            showMap: false,
            disabledNext: true
        };

        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        this.applyTheme();
        this.loadGoogleLibrary();

        // If there is data for the address in the reducer load it
        if (this.props.userAddress) {
            this.setState({
                ...this.state,
                userAddress: this.props.userAddress.fullAddress,
                streetName: this.props.userAddress.street,
                streetNumber: this.props.userAddress.streetNumber,
                city: this.props.userAddress.city,
                country: this.props.userAddress.country,
                apartment: this.props.userAddress.apartment ? this.props.userAddress.apartment : null,
                zipCode: this.props.userAddress.zipCode ? this.props.userAddress.zipCode : null,
                placeId: this.props.userAddress.placeId ? this.props.userAddress.placeId : null,
                userCoordinates: {
                    lat: this.props.userAddress.latitude,
                    lng: this.props.userAddress.longitude
                },
            }, () => {
                if (this.validateData()) {
                    this.setState({ ...this.state, disableNext: false });
                }
            });
        }
    }

    loadGoogleLibrary() {

        if (!window.google) {
            let s = document.createElement('script');
            s.type = 'text/javascript';
            s.defer = 'defer';
            s.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places,geometry&language=en`;
            let x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            // Below is important.
            //We cannot access google.maps until it's finished loading
            s.addEventListener('load', e => {
                this.initAutoCompleteService()
            })
        } else {
            this.initAutoCompleteService()
        }

    }

    initAutoCompleteService() {
        let searchField = document.getElementById('googleAutoComplete');

        // Limit search results to Brand Country
        let brandCountryCode = this.props.brand.countryCode;
        const googleAutoComplete = new window.google.maps.places.Autocomplete(searchField, { types: [], componentRestrictions: { country: [brandCountryCode] } });
        // setFields is VERY IMPORTANT because saves money - the following four fields are all part of the basic PLACES API plan
        // Removing these will result in UNNECESSARY BILLING
        googleAutoComplete.setFields(['address_components', 'formatted_address', 'types', 'geometry', 'place_id']);

        // Listen for when the place is selected from autocomplete dropdown
        googleAutoComplete.addListener('place_changed', () => {

            let place = googleAutoComplete.getPlace();
            let addressSelectedFromPicker = place.formatted_address;
            // Guard againts the invalid addresses
            if (!addressSelectedFromPicker) {
                return;
            }

            let addressParts = getGoogleAddressParts(place.address_components);
            let coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };

            if (!coordinates.lat || !coordinates.lng) {
                coordinates = this.getCoordinatesFromUserAddress(addressSelectedFromPicker);
            }

            // Check if the address is a place or a street address
            let placeName = '';
            place.types.map((type) => {
                if (type === 'point_of_interest') {
                    placeName = place.name + ', ';
                }
            });

            let placeSelectedFromPickerAddress = placeName.concat(addressSelectedFromPicker);

            this.setState({
                ...this.state,
                userAddress: (placeName !== '') ? placeSelectedFromPickerAddress : addressSelectedFromPicker,
                streetNumber: addressParts.streetNumber,
                streetName: addressParts.streetName,
                city: addressParts.city,
                country: addressParts.country,
                zipCode: addressParts.zipCode,
                placeId: place.place_id,
                disabledNext: false,
                userCoordinates: coordinates
            });
        });
    }

    getCoordinatesFromUserAddress(address) {

        new window.google.maps.Geocoder().geocode({ 'address': address }, (results, status) => {

            if (status === 'OK') {
                let latitude = results[0].geometry.location.lat();
                let longitude = results[0].geometry.location.lng();

                let updatedUserCords = {
                    lat: latitude,
                    lng: longitude
                };

                // Update the state -> get address from coordinates to update address in redux store and update map
                // this.setState({ ...this.state, userCoordinates: updatedUserCords }, () => this.getAddressFromUserCords(latitude, longitude));
                return updatedUserCords;
                // this.setState({ ...this.state, userCoordinates: updatedUserCords });
            } else {
                alert(i18n.t('screens:userAddressScreen.geocodeFailure') + ': ' + status);
                return null;
            }
        });
    }

    initMapService() {
        // Default fallback coordinates in the center of Europe when there is no user Address
        let Lat;
        let Lng;

        const brandCity = this.props.brand.city;
        const userAddress = this.props.userAddress.fullAddress;
        const geocoder = new window.google.maps.Geocoder();

        let requestOptions = {
            address: (userAddress) ? userAddress : brandCity,
            componentRestrictions: {
                country: (this.props.brand.countryCode) ? this.props.brand.countryCode : ''
            }
        };

        let map;
        let mapLoaded = false;

        geocoder.geocode(requestOptions, (results, status) => {

            if (status === window.google.maps.GeocoderStatus.OK) {
                Lat = results[0].geometry.location.lat();
                Lng = results[0].geometry.location.lng();

                map = new window.google.maps.Map(document.getElementById('map'), {
                    center: new window.google.maps.LatLng(Lat, Lng),
                    zoom: (userAddress) ? 15 : (!userAddress && brandCity) ? 12 : 4
                });

                mapLoaded = true;
            }
            
            if (!results) {
                let requestOptions = {
                    address: brandCity,
                    componentRestrictions: {
                        country: (this.props.brand.countryCode) ? this.props.brand.countryCode : ''
                    }
                };

                geocoder.geocode(requestOptions, (results, status) => {
                    if (status === window.google.maps.GeocoderStatus.OK) {
                        Lat = results[0].geometry.location.lat();
                        Lng = results[0].geometry.location.lng();
                    }

                    // Default fallback coordinates in the center of Europe when there is no user Address
                    if (results.length === 0) {
                        Lat = 47.577915;
                        Lng = 16.329508;
                    }

                    map = new window.google.maps.Map(document.getElementById('map'), {
                        center: new window.google.maps.LatLng(Lat, Lng),
                        zoom: (userAddress) ? 15 : (!userAddress && brandCity) ? 12 : 4
                    });

                    mapLoaded = true;
                });
            }

            // Check if the map has received data for user/restaurant coordinates and it is loaded
            let mapLoadedInterval = setInterval(() => {
                if (mapLoaded) {
                    clearInterval(mapLoadedInterval);

                    // Listen for when the user clicks on the map to choose his address
                    window.google.maps.event.addListener(map, 'click', (event) => {
                        // Clear old marker
                        this.clearMarkersFromMap();

                        let myLatlng = new window.google.maps.LatLng(event.latLng.lat(), event.latLng.lng());

                        let marker = new window.google.maps.Marker({
                            position: myLatlng,
                            map: map,
                        });

                        let updatedMarkersList = [...this.state.markersOnMap, marker];

                        let coordinatesOfMarker = {
                            lat: event.latLng.lat(),
                            lng: event.latLng.lng()
                        };

                        this.setState({
                            ...this.state,
                            markersOnMap: updatedMarkersList,
                            userCoordinates: coordinatesOfMarker
                        }, () => {
                            let lat = this.state.userCoordinates.lat;
                            let lng = this.state.userCoordinates.lng;

                            this.getAddressFromUserCords(lat, lng);
                        });

                    });
                }
            }, 100);

        });
    }

    clearMarkersFromMap() {
        this.state.markersOnMap.map((marker) => {
            marker.setMap(null);
        });
    }

    getUserLocation() {
        // Check if the user has disabled geolocation service on the device
        navigator.geolocation.watchPosition((position) => { }, (error) => {
            // if (error.code == error.PERMISSION_DENIED) {
            if (error) {
                window.alert(i18n.t('screens:userAddressScreen.locationPromptMessage'));
                this.props.stopLoader();
            }
        }
        );

        if (!navigator.geolocation) {
            return;
        }

        this.props.activateLoader();

        const success = (position) => {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            this.getAddressFromUserCords(latitude, longitude);

            let updatedUserCoordinates = {
                lat: latitude,
                lng: longitude
            };

            this.props.stopLoader();

            this.setState({ ...this.state, userCoordinates: updatedUserCoordinates, showMap: false });
        };

        const error = (err) => {
            this.props.stopLoader();
        };

        navigator.geolocation.getCurrentPosition(success, error);

        // Stop the geolocation after 10sec have passed to allow the user to type the address or choose it from map
        setTimeout(() => {
            this.props.stopLoader();
        }, 10000);
    }

    getAddressFromUserCords(lat, lng) {
        const geocoder = new window.google.maps.Geocoder();
        let latlng = new window.google.maps.LatLng(lat, lng);

        // Update the user address in store
        const updateAddressInStore = (addressFromCoordinates, addressParts, placeId) => {
            let newAddress = {
                fullAddress: addressFromCoordinates,
                street: addressParts.streetName,
                streetNumber: addressParts.streetNumber,
                floor: null,
                apartment: null,
                city: addressParts.city,
                country: addressParts.country,
                longitude: lng,
                latitude: lat,
                postCode: addressParts.zipCode,
                zipCode: addressParts.zipCode,
                placeId,
                details: null
            };

            this.props.saveCustomerDeliveryAddress(newAddress);
        };

        geocoder.geocode({ 'latLng': latlng }, (results, status) => {

            if (status === window.google.maps.GeocoderStatus.OK) {

                let addressFromCoordinates = results[0].formatted_address;
                // let addressParts = this.getAddressParts(results[0]);
                let addressParts = getGoogleAddressParts(results[0].address_components);

                this.setState({
                    ...this.state,
                    userAddress: addressFromCoordinates,
                    streetNumber: addressParts.streetNumber,
                    streetName: addressParts.streetName,
                    city: addressParts.city,
                    country: addressParts.country,
                    zipCode: addressParts.zipCode,
                    placeId: results[0].place_id,
                    disabledNext: false
                }, () => updateAddressInStore(addressFromCoordinates, addressParts, results[0].place_id));
            }
        });
    }

    toggleMap() {
        // Stop the loader
        this.props.stopLoader();

        this.setState({ ...this.state, showMap: !this.state.showMap }, () => {
            if (this.state.showMap) {
                this.initMapService();
            }
        });
    }

    handleAddressChange({ target }) {
        // If the user starts deleting from address clear the state
        if (this.state.userAddress != undefined || this.state.userAddress != null) {

            if (this.state.userAddress.length < target.value.length) {

                let updatedUserCoordinates = { ...this.state.userCoordinates };
                updatedUserCoordinates.lat = null;
                updatedUserCoordinates.lng = null;

                this.setState({
                    ...this.state,
                    userAddress: target.value,
                    streetName: '',
                    streetNumber: '',
                    city: '',
                    country: '',
                    userCoordinates: updatedUserCoordinates,
                    showMap: false,
                    disabledNext: true
                });

                return;
            }
        }

        this.setState({ ...this.state, userAddress: target.value, showMap: false, disabledNext: true });
    }

    handleAddressFocus() {
        // It needs setTimeout to work on ios and android devices otherwise it does not select the text
        setTimeout(() => {
            // Select the entire address entered by the user when we focus on the input
            let addressInput = document.getElementById('googleAutoComplete');
            addressInput.setSelectionRange(0, 10000000000);
        }, 1);
    }

    renderModalHeader() {
        const customerLoggedIn = this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined
            && this.props.customer.id !== null && this.props.customer.id !== -1 && this.props.customer.email !== undefined
            && this.props.customer.email !== null && this.props.customer.email !== "null" && this.props.customer.email !== 'undefined';
       
        return (
            <Fragment>
                <div className='start-order-header-container' style={{ zIndex: 10 }}>
                    <Button className="start-order-custom-back-button" onClick={() => this.goBack()}>
                        <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    {(customerLoggedIn) ? this.renderCustomer() : ''}
                    <div style={{ width: 90, textAlign: 'right', visibility: 'hidden' }} >
                        <Button className='start-order-custom-close-button' onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderCustomer() {
        const { imageStyle, imageStyleRTL, profileNameStyle, centredItem } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass += 'customer-long-text-back-button';
        }

        return (
            <div style={{ margin: '0 auto', fontSize: 20, textAlign: 'center', display: 'flex', alignItems: 'center' }} className='customer-name-mobile-style'>
                <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                <span style={profileNameStyle} className={customerNameClass}>{userFirstName}</span>
            </div>
        );
    }

    validateData() {
        let validData = true;

        if (!this.state.userAddress) {
            validData = false;
            this.state.disabledNext = true;
            return validData;
        }
        if (this.state.userAddress.streetName === '') {
            this.state.disabledNext = true;
            return validData = false;
        }

        validData = true;
        this.state.disabledNext = false;

        return validData;
    }

    goForward() {
        const { userAddress, streetName, streetNumber,
            city, country, userCoordinates, zipCode, apartment, placeId } = this.state;

        let newAddress = {
            fullAddress: userAddress,
            street: streetName,
            streetNumber: streetNumber,
            // floor: null,
            apartment: apartment,
            city: city,
            country: country,
            longitude: userCoordinates.lng,
            latitude: userCoordinates.lat,
            zipCode: zipCode,
            placeId: placeId
            // details: null
        };

        this.props.saveCustomerDeliveryAddress(newAddress);

        let validData = this.validateData();

        if (validData) {
            if (this.props.orderTypes.selectedOrderType.id == COLLECTION_ID) { //case of Collection
                this.props.history.push('/start-order/restaurant-selection');
            } else {
                this.props.history.push('/start-order/confirm-address');
            }
        }
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={10} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderModalBody() {
        const { orderTypes } = this.props;
        const { type, primaryColor, secondaryColor, menuItemsBackground, primaryFontColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;

        const {
            searchContainer,
            autoSearchField,
            autoSearchFieldRTL,
            gpsLocationIcon,
            gpsLocationIconRTL,
            addressNotListed,
            mapInfoMessage,
            mapContainer,
            mapAndInfoWindowContainer,
            orderTypeIconStyle,
            orderTypeIconStyleRTL,
            showMapContainerRow
        } = styles;

        let orderTypeIcon = null;
        let addressNotListedText = null;
        const isArabic = this.props.language === ARABIC;


        if (orderTypes.selectedOrderType.id == DELIVERY_ID) { // in case of Delivery
            orderTypeIcon = <DeliverySVG width={38} height={38} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? Object.assign({},  orderTypeIconStyleRTL, { margin: '10px 3px 7px 7px', transform: 'scaleX(-1)'}) : Object.assign({},  orderTypeIconStyle, { margin: '10px 7px 7px 3px' })} />;

            addressNotListedText = i18n.t('screens:userAddressScreen.addressNotListed');
        } else if (orderTypes.selectedOrderType.id == COLLECTION_ID) { // in case of Collection
            orderTypeIcon = <ShoppingCartSVG width={32} height={32} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? Object.assign({},  orderTypeIconStyleRTL, { margin: '10px 3px 7px 7px' }) : Object.assign({},  orderTypeIconStyle, { margin: '10px 7px 7px 3px' })} />;
            addressNotListedText = i18n.t('screens:userAddressScreen.areaNotListed');
        }

        let nextButtonStyle = '';
        if (this.state.disabledNext) {
            nextButtonStyle = 'disabledNextButton';
        } else {
            nextButtonStyle = 'nextButton';
        }

        const isMobileView = window.outerWidth <= 800;

        let showMapRowContainer = { backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, width: '98%', margin: '0 1%' };
        let mapAndInfoContainerStyle = mapAndInfoWindowContainer;
        let mapContainerStyle = mapContainer;

        if (isMobileView) {

            mapAndInfoContainerStyle = {
                height: 240
            };

            mapContainerStyle = {
                height: '86%',
                overflow: 'hidden'
            };

            if (this.state.showMap) {
                showMapRowContainer = showMapContainerRow;
            }
        }

        let modalContentStyle = '';
        let headerTitleStyle = 'modal-custom-body-footer';
        if (this.state.showMap) {
            headerTitleStyle += 'modal-custom-body-footer show-map';
            modalContentStyle += 'user-address-show-map';
        }

        return (
            <React.Fragment>
                {this.renderModalHeader()}
                <div className='modal-body-custom'>
                    <Row style={{ marginLeft: 0, marginRight: 0 }} className={modalContentStyle}>
                        <Col sm={12} className='modal-custom-body-title' style={{ height: 57, maxHeight: 57, minHeight: 57, alignItems: (this.state.showMap) ? 'flex-end' : 'center' }}>
                            <p className='title-style'
                                style={{
                                    margin: 'auto',
                                    textAlign: 'center',
                                    fontSize: 22,
                                    color: primaryFontColor,
                                    fontFamily: fontFamilyType,
                                    fontWeight,
                                    fontStyle: fontItalic,
                                    textTransform: uppercase
                                }}>{i18n.t('screens:userAddressScreen.heading')}?</p>
                        </Col>
                        <Col xs={12} className='modal-custom-body-inner-content user-address-screen' style={{ minHeight: (this.state.showMap) ? 55 : 175 }}>
                            <Col xs={12} md={{ size: 10, offset: 1 }} style={searchContainer}>
                                {orderTypeIcon}
                                <Input id="googleAutoComplete" placeholder={i18n.t('screens:userAddressScreen.enterAddressInfo')}
                                    className='addressInputs'
                                    style={isArabic ? autoSearchFieldRTL : autoSearchField}
                                    value={this.state.userAddress}
                                    onChange={this.handleAddressChange.bind(this)}
                                    onFocus={this.handleAddressFocus.bind(this)}
                                />
                                <span id="gps-icon" style={isArabic ? gpsLocationIconRTL : gpsLocationIcon} onClick={this.getUserLocation.bind(this)}>
                                    <MaterialIcon icon="gps_fixed" size={20} />
                                </span>
                            </Col>
                            <Row style={showMapRowContainer}>
                                <Col xs={12} className="text-center" style={{ backgroundColor: menuItemsBackground }}>
                                    <span style={addressNotListed} onClick={this.toggleMap.bind(this)} hidden={this.state.showMap}>{addressNotListedText}</span>
                                </Col>
                                {this.state.showMap ?
                                    <div id="mapContain" style={mapAndInfoContainerStyle} >
                                        <h5 style={mapInfoMessage}>{i18n.t('screens:userAddressScreen.clickOnMapMsg')}</h5>
                                        <div id="map" style={mapContainerStyle} className="mapAndInfoContainer"></div>
                                    </div> : null}
                            </Row>
                        </Col>
                    </Row>
                    <div className={headerTitleStyle} style={{ position: 'relative', top: (this.state.showMap) ? 100 : 42, backgroundColor: menuItemsBackground }}>
                        <Container>
                            <Col xs={12} className="text-center" style={{ display: 'flex', justifyContent: 'space-around', marginTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                                <Button className={nextButtonStyle} onClick={this.goForward.bind(this)}>
                                    <span
                                        style={{
                                            fontFamily: fontFamilyType,
                                            fontWeight,
                                            fontStyle: fontItalic,
                                            textTransform: uppercase
                                        }}>{i18n.t('common:buttons.nextBtn')}</span>
                                    <span className="btnNextIcon">
                                        <MaterialIcon icon="navigate_next" color={buttonsFont} size={35} />
                                    </span>
                                </Button>
                            </Col>
                        </Container>
                        {this.renderTransparentBackgroundLoaderContainer()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderModalFooter() {
        return (null);
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, fontWeight, startOrderFontSize, chefNoteBackground, primaryFontColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;
        customizedStyles.screenMessage.fontSize = startOrderFontSize;
        customizedStyles.addressNotListed.color = primaryColor;
        customizedStyles.addressNotListed.fontWeight = fontWeight;
        customizedStyles.autoSearchField.backgroundColor = chefNoteBackground;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.mapAndInfoWindowContainer.backgroundColor = menuItemsBackground;
        styles = customizedStyles;

        return styles;
    }

    render() {
        return (
            <AvForm fluid className="start-order avForm">
                <ModalStep
                    isOpen={this.props.isOpen} onToggle={this.props.toggle}
                    // renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        brand: state.brand,
        customer: state.customer,
        userAddress: state.customer.selectedDeliveryAddress,
        selectedOrderType: state.orderTypes.selectedOrderType,
        orderTypes: state.orderTypes,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { saveCustomerDeliveryAddress, restaurantChainService, activateLoader, stopLoader })(UserAddressScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '420px',
        maxHeight: '640px',
        backgroundColor: '#ffffff'
    },
    showMapContainerRow: {
        backgroundColor: '#ffffff',
        display: 'inline-block',
        height: '330px',
        width: '98%',
        margin: '0 1%'
    },
    addressNotListed: {
        fontSize: 14,
        color: MAIN_GREEN_COLOR,
        fontWeight: 300,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    mapContainer: {
        height: 195
    },
    searchContainer: {
        position: 'relative',
        margin: ' 0 auto'
    },
    gpsLocationIcon: {
        position: 'absolute',
        top: 18,
        right: 25,
        cursor: 'pointer'
    },
    gpsLocationIconRTL: {
        position: 'absolute',
        top: 18,
        left: 25,
        cursor: 'pointer'
    },
    autoSearchField: {
        height: 55,
        paddingLeft: 60,
        paddingRight: 45,
        backgroundColor: '#F0F0F0',
        fontWeight: 300
    },
    autoSearchFieldRTL: {
        height: 55,
        paddingLeft: 45,
        paddingRight: 60,
        backgroundColor: '#F0F0F0',
        fontWeight: 300
    },
    mapInfoMessage: {
        fontSize: 13,
        color: '#5a5a5a',
        textAlign: 'center',
        marginTop: 3
    },
    mapAndInfoWindowContainer: {
        width: '100%',
        height: 170,
        margin: 'auto'
    },
    screenMessage: {
        textAlign: 'center'
    },
    navigateNextIcon: {
        position: 'relative',
        top: -3,
    },
    orderTypeIconStyle: {
        position: 'absolute',
        left: 20,
        zIndex: 1,
        margin: 7
    },
    orderTypeIconStyleRTL: {
        position: 'absolute',
        right: 20,
        zIndex: 1,
        margin: 7
    },
    disabledNextButton: {
        backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none'
    },
    nextButton: {
        display: 'flex',
        justifyContent: 'center'
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72
    },
    login: {
        fontSize: 22,
        marginLeft: 12,
        marginRight: 12
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    imageStyle: {
        marginRight: 16
    },
    imageStyleRTL: {
        marginLeft: 5
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center',
        paddingRight: 55
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    }
};
import { parentLocalStorage } from '../utils/CrossOriginUtils';

export const saveState = (state) => {
    try {
        let serializedState = JSON.stringify(state);
        parentLocalStorage('set', 'menuuReduxStore', serializedState);
    }
    catch (err) {
    }
}

export const savePreviewState = (state) => {
    try {
        let serializedState = JSON.stringify(state);
        parentLocalStorage('set', 'menuuReduxStorePreviewMode', serializedState);
    }
    catch (err) {
    }
}

import React from 'react';
import { Col, Badge } from 'reactstrap';

const ItemCardImage = props => {

    const { appOrMobile, customerThemes, styles, productFoundInCart, productQuantityInCart } = props;
    const { webImgContainer, imgStyles, appImgContainer, badgeContainer } = constantStyles;

    if (!appOrMobile) {

        return (
            <div style={webImgContainer}>
                <img
                    className='img-thumbnail'
                    src={props.imagePath}
                    style={{
                        ...imgStyles,
                        backgroundColor: customerThemes.selectedTheme.menuItemsBackground
                    }}
                    alt='product image' />
            </div>
        );
    }

    return (
        <Col style={appImgContainer}>
            <img
                className='img-thumbnail'
                src={props.imagePath}
                style={{
                    ...imgStyles,
                    backgroundColor: customerThemes.selectedTheme.menuItemsBackground
                }} />

            {
                props.isBadgeAllowed &&
                // TODO change all the specific things to general to allow real reusability
                <Col
                    hidden={productFoundInCart}
                    style={
                        {
                            ...styles.badgeColMobile,
                            ...badgeContainer
                        }
                    }>


                    <Badge style={
                        {
                            ...styles.badge,
                            background: customerThemes.selectedTheme.primaryColor
                        }
                    }>{productQuantityInCart}x</Badge>

                </Col>
            }

        </Col>
    );
}

export default ItemCardImage;

const constantStyles = {
    webImgContainer: {
        paddingRight: 0,
        paddingLeft: 0,
        minWidth: 125,
        maxWidth: 125
    },
    imgStyles: {
        height: '100%',
        objectFit: 'cover'
    },
    appImgContainer: {
        textAlign: 'right',
        paddingRight: 0,
        paddingLeft: 0,
        marginBottom: -10
    },
    badgeContainer: {
        position: 'absolute',
        top: 12,
        paddingRight: 12
    }
};
import { getQueryInfoFromUrl } from './CommonUtils';

export const sendMessageToParentSite = (param) => {
    window.parent.postMessage(
        param,
        '*');
}

export const parentLocalStorage = async (method, key, value) => {
    let isIntegration = getQueryInfoFromUrl().brandId;

    if (isIntegration) {
        if (method === 'set') {
            window.parent.postMessage({ method, value: { key, value } }, '*');
        } else if (method === 'remove') {
            window.parent.postMessage({ method, key }, '*');
        } else if (method === 'get') {
            window.parent.postMessage({ method, key }, '*');
            return await new Promise((resolve, reject) => {
                window.addEventListener("message", (e) => {
                    resolve(e.data)
                })
            });
        } else if (method === 'clear') {
            window.parent.postMessage({ method }, '*');
        }
    } else {
        if (method === 'set') {
            localStorage.setItem(key, value);
        } else if (method === 'remove') {
            localStorage.removeItem(key);
        } else if (method === 'get') {
            if (typeof key === 'object') {
                let data = [];
                key.map(e => {
                    data.push(localStorage.getItem(e));
                })
                return await new Promise((resolve, reject) => {
                    resolve(data)
                });
            } else {
                return await new Promise((resolve, reject) => {
                    resolve(localStorage.getItem(key))
                });
            }
        } else if (method === 'clear') {
            localStorage.clear();
        }
    }
}
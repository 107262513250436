import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Col, Collapse,
    Nav, Navbar, NavbarToggler,
    NavItem,
    NavLink
} from "reactstrap";
import { Switch, Route, Link } from 'react-router-dom';
import MaterialIcon from "material-icons-react";
import i18n from '../../i18next';

import DetailsScreen from "./DetailsScreen";
import AddressesScreen from "./AddressesScreen";
import SavedCardsScreen from "./SavedCardsScreen";
import MarketingPreferencesScreen from "./MarketingPreferencesScreen";
import {
    getCustomerPaymentCards,
    loadCustomerCollectionAddresses,
    loadCustomerDeliveryAddresses,
    loadMarketingPreferences,
    logoutCustomer
} from '../../actions/index';
import { showMessageAlert, clearMessageAlert } from '../../actions';
import { updateCSSVariables } from '../../utils/ThemesSelector';
import OrderHistoryScreen from "./OrderHistoryScreen";
import UserIconSecondarySVG from '../../pwa-app/components/common/svgComponents/UserIconSecondarySVG';
import CreditCardSVG from '../../pwa-app/components/common/svgComponents/CreditCardSVG';
import ClockSVG from '../../pwa-app/components/common/svgComponents/ClockSVG';
import LocationIconSVG from '../../pwa-app/components/common/svgComponents/LocationIconSVG';
import TrendingUpSVG from '../../pwa-app/components/common/svgComponents/TrendingUpSVG';
import LogoutSVG from "../../pwa-app/components/common/svgComponents/LogoutSVG";
import LoyaltyIconSvg from "../../pwa-app/components/common/svgComponents/LoyaltyIconSVG";
import LoyaltyScreen from "./LoyaltyScreen";
import { ARABIC, IN_STORE_ID } from "../../utils/Constants";
import { getQueryParams } from '../../utils/CommonUtils';

class ProfileScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPath: '',
            isSideMenuOpen: true
        }

        this.toggleSideMenu = this.toggleSideMenu.bind(this);
    }

    componentWillMount() {
        if (this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }

        const currentPath = this.props.history.location.pathname;

        this.setState({
            currentPath: currentPath
        })

        this.props.getCustomerPaymentCards();
        this.props.loadCustomerDeliveryAddresses();
        this.props.loadCustomerCollectionAddresses();
        this.props.loadMarketingPreferences();
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }
    }

    toggleSideMenu() {
        this.setState({
            isSideMenuOpen: !this.state.isSideMenuOpen
        });
    }

    selectPath(path) {
        this.setState({
            currentPath: path
        });
    }

    logoutCustomer() {
        if (this.props.orderTypes.selectedOrderType.id === IN_STORE_ID) {
            window.history.replaceState({}, document.title, `${getQueryParams()}#/dine-pay`);
            this.props.logoutCustomer(false, true);
        } else {
            this.props.logoutCustomer();
        }
    }

    renderSideMenuIcon(icon) {
        const { navIconStyle, navIconStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const isArabic = this.props.language === ARABIC;

        switch (icon) {
            case 'person':
                return <UserIconSecondarySVG width={25} height={25} fill={svgColor} style={isArabic ? navIconStyleRTL : navIconStyle} />;
            case 'history':
                return <ClockSVG width={23} height={23} fill={svgColor} style={isArabic ? navIconStyleRTL : navIconStyle} />;
            case 'location_on':
                return <LocationIconSVG width={24} height={24} fill={svgColor} style={isArabic ? navIconStyleRTL : navIconStyle} />;
            case 'credit_card':
                return <CreditCardSVG width={24} height={24} fill={svgColor} style={isArabic ? navIconStyleRTL : navIconStyle} />;
            case 'trending_up':
                return <TrendingUpSVG width={24} height={24} fill={svgColor} style={isArabic ? Object.assign({}, navIconStyleRTL, {transform: 'scaleX(-1)'})  : navIconStyle} />;
            case 'exit_to_app':
                return <LogoutSVG width={24} height={24} fill={svgColor} style={isArabic ? Object.assign({}, navIconStyleRTL, {transform: 'scaleX(-1)'})  : navIconStyle} />;
            case 'loyalty':
                return <LoyaltyIconSvg width={24} height={24} fill={svgColor} style={isArabic ? Object.assign({}, navIconStyleRTL, {transform: 'scaleX(-1)'})  : navIconStyle} />;
            default:
                return;

        }
    }

    renderSideMenu() {
        const { navItemsContainer, navItemsContainerRTL, navItemsStyle, activeNavLinkStyle } = styles;
        const isArabic = this.props.language === ARABIC;
        const { isRedirectFromComo } = this.props.como;

        return (
            <Navbar light expand="md">
                <Nav navbar style={isArabic ? Object.assign({}, navItemsContainerRTL, {paddingRight: 0}) : navItemsContainer} className="sideMenuProfile"
                    style={{ flexDirection: 'column', width: '100%' }}>
                    <NavItem style={navItemsStyle} onClick={() => this.selectPath('/profile/details')}>
                        {this.renderSideMenuIcon("person", "/profile/details")}
                        <NavLink tag={Link} to={{ pathname: "/profile/details", state: this.props.history.location.state }}
                            style={(this.state.currentPath === '/profile/details') ? activeNavLinkStyle : {}}>
                            {i18n.t('screens:profileScreen.profileDetails')}</NavLink>
                    </NavItem>
                    <NavItem style={navItemsStyle} onClick={() => this.selectPath('/profile/order-history')}>
                        {this.renderSideMenuIcon("history", "/profile/order-history")}
                        <NavLink tag={Link} to={{ pathname: "/profile/order-history", state: this.props.history.location.state }}
                            style={(this.state.currentPath == '/profile/order-history') ? activeNavLinkStyle : {}}>{i18n.t('screens:profileScreen.orderHistory')}</NavLink>
                    </NavItem>
                    <NavItem style={navItemsStyle} onClick={() => this.selectPath('/profile/addresses')}>
                        {this.renderSideMenuIcon("location_on", "/profile/addresses")}
                        <NavLink tag={Link} to={{ pathname: "/profile/addresses", state: this.props.history.location.state }}
                            style={(this.state.currentPath === '/profile/addresses') ? activeNavLinkStyle : {}}>{i18n.t('screens:profileScreen.addresses')}</NavLink>
                    </NavItem>
                    <NavItem style={navItemsStyle} onClick={() => this.selectPath('/profile/cards')}>
                        {this.renderSideMenuIcon("credit_card", "/profile/cards")}
                        <NavLink tag={Link} to={{ pathname: "/profile/cards", state: this.props.history.location.state }}
                            style={(this.state.currentPath === '/profile/cards') ? activeNavLinkStyle : {}}>{i18n.t('screens:profileScreen.savedCards')}</NavLink>
                    </NavItem>
                    {
                        this.props.loyalty.loyalty.id ?
                            <NavItem style={navItemsStyle} onClick={() => this.selectPath('/profile/loyalty')}>
                                {this.renderSideMenuIcon("loyalty", "/profile/loyalty")}
                                <NavLink tag={Link} to={{ pathname: '/profile/loyalty', state: this.props.history.location.state }}
                                    style={(this.state.currentPath === '/profile/loyalty') ? activeNavLinkStyle : {}}>
                                    {i18n.t('screens:profileScreen.loyalty')}
                                </NavLink>
                            </NavItem> : null
                    }
                    <NavItem style={navItemsStyle} onClick={() => this.selectPath('/profile/marketing')}>
                        {this.renderSideMenuIcon("trending_up", "/profile/marketing")}
                        <NavLink tag={Link} to={{ pathname: "/profile/marketing", state: this.props.history.location.state }}
                            style={(this.state.currentPath === '/profile/marketing') ? activeNavLinkStyle : {}}>{i18n.t('screens:profileScreen.marketingPrefrences')}</NavLink>
                    </NavItem>
                    { !isRedirectFromComo ?
                    <li style={navItemsStyle} onClick={() => this.logoutCustomer()}>
                        {this.renderSideMenuIcon("exit_to_app", "/logout")}
                        <span style={{ float: isArabic ? 'right' : 'left', paddingTop: 8, cursor: 'pointer'}}>{i18n.t('screens:profileScreen.logout')}</span>
                    </li> : null
                    }
                </Nav>
            </Navbar>
        );
    }

    applyTheme() {
        const { type, colorsCss, primaryColor, secondaryColor, mainTextColor, menuItemsBackground, chefNoteBackground, primaryFontColor, buttonsFont } = this.props.customerThemes.selectedTheme;
        updateCSSVariables(colorsCss);
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        customizedStyles.activeNavLinkStyle.color = type === 'DARK' ? secondaryColor : primaryColor;
        customizedStyles.saveButtonStyle.backgroundColor = primaryColor;
        customizedStyles.checkboxLabelStyle.color = mainTextColor;
        customizedStyles.mainContainer.backgroundColor = menuItemsBackground;
        customizedStyles.formFieldsStyle.backgroundColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? chefNoteBackground : '';
        customizedStyles.navItemsStyle.color = primaryFontColor;
        customizedStyles.saveButtonStyle.color = buttonsFont;
        styles = customizedStyles;
        return styles;
    }

    render() {

        const isMobileView = (window.outerWidth >= 768) ? false : true;
        const displayStyle = (isMobileView) ? 'block' : 'flex';
        const isArabic = this.props.language === ARABIC;

        return (
            <div style={{ display: displayStyle, alignSelf: 'flex-start' }}>
                <Col xs={12} sm={12} md={3} style={isArabic ? { paddingRight: 30, marginTop: 40, float: 'right' } : { paddingLeft: 30, marginTop: 40, float: 'left' }}
                    id="navBarProfileColumn">
                    {this.renderSideMenu()}
                </Col>
                <Col xs={12} sm={12} md={9} style={{ float: isArabic ? 'right' : 'left' }} className="sideMenuProfileContent">
                    <div>
                        <Switch>
                            <Route path='/profile/details'
                                render={(props) => (
                                    <DetailsScreen {...props} styles={styles} customer={this.props.customer} />
                                )} />
                            <Route path='/profile/addresses'
                                render={(props) => (
                                    <AddressesScreen {...props} styles={styles} />
                                )} />
                            <Route path='/profile/cards'
                                render={(props) => (
                                    <SavedCardsScreen {...props} styles={styles} />
                                )} />
                            <Route path='/profile/order-history'
                                render={(props) => (
                                    <OrderHistoryScreen {...props} styles={styles} />
                                )} />
                            <Route path='/profile/marketing'
                                render={(props) => (
                                    <MarketingPreferencesScreen {...props} styles={styles} />
                                )} />
                            <Route path='/profile/loyalty'
                                render={(props) => (
                                    <LoyaltyScreen {...props} styles={styles} />
                                )} />
                        </Switch>
                    </div>
                </Col>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        alertMessage: state.alertMessage,
        customer: state.customer,
        customerThemes: state.customerThemes,
        loyalty: state.loyalty,
        orderTypes: state.orderTypes,
        como: state.como,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, {
    getCustomerPaymentCards,
    loadCustomerDeliveryAddresses,
    loadCustomerCollectionAddresses,
    loadMarketingPreferences,
    showMessageAlert,
    clearMessageAlert,
    logoutCustomer
}, null, { pure: false })(ProfileScreen);

let styles = {
    navItemsContainer: {
        marginTop: 60,
        marginLeft: 40 
    },
    navItemsContainerRTL: {
        marginTop: 60,
        marginRight: 40, 
    },
    navItemsStyle: {
        borderBottom: '1px solid lightgray',
        minHeight: 40 
    },
    navIconStyle: {
        margin: '8px 6px 0 6px',
        float: 'left'
    },
    navIconStyleRTL: {
        margin: '8px 6px 0 6px',
        float: 'right'
    },
    activeNavLinkStyle: {
        color: '#62b400'
    },
    navBarProfile: {
        marginTop: 40 
    },
    formStyle: {
        padding: '20px 10px'
    },
    mainContainer: {
        backgroundColor: '#fff',
        border: '1px solid lightgray',
        margin: 'auto 5%  20px',
        marginBottom: 20
    },
    saveButtonStyle: {
        backgroundColor: "#62b400",
        color: '#fff',
        border: 'none',
        borderRadius: 0,
        width: '100%',
        height: 46
    },
    formFieldsStyle: {
        border: '1px solid lightgray',
        borderRadius: 0,
        height: 46
    },
    formLabelStyle: {
        color: 'grey'
    },
    checkboxLabelStyle: {
        width: '100%',
        fontSize: 14, 
        color: 'grey', 
        padding: 5, 
        margin: 0 
    },
    checkboxInput: {
        marginLeft: 10 
    },
    checkboxInputRTL: {
        marginRight: 10,
    },
    centredText: {
        textAlign: 'center'
    }
};
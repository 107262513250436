import moment from 'moment';

import {
    CLEAR_ORDER_WIZARD, CLEAR_STATE,
    SAVE_RESTAURANT_WORKING_TIME,
    SAVE_SELECTED_RESTAURANT, SAVE_SELECTED_RESTAURANT_PREVIEW_MODE,
    SAVE_RESTAURANT_SERVICE_FEE_PREVIEW_MODE,
    SAVE_SELECTED_RESTAURANT_TOPPING_SUBSTITUTION_FLAG_PREVIEW_MODE,
    CLEAR_APP_STATE,
    SAVE_RESTAURANT_DATA_FOR_IN_STORE,
    SAVE_IN_STORE_INFO
} from '../../../actions/types';

const INITIAL_STATE = {
    name: null,
    distance: null,
    currency: '',
    paymentPlatformAccountId: '',
    simpleBilling: null,
    serviceFees: [],
    restaurantId: null,
    restaurantWorkingTime: '',
    displayAddress: '',
    address: {
        id: null,
        street: null,
        streetNumber: null,
        floor: null,
        apartment: null,
        city: null,
        country: null,
        longitude: null,
        latitude: null,
        details: null
    },
    workingDay: {
        date: null,
        openingHour: {
            id: null,
            day: null,
            openHour: null,
            openMinute: null,
            closeHour: null,
            closeMinute: null
        }
    },
    workingDayIndex: null,
    futureOrdersDisabled: null,
    deliveryCharge: null,
    deliveryChargeInPercentage: null,
    deliveryTipEnabled: null,
    deliveryTipInPercentage: null,
    maximumOrderAmount: null,
    minimumDeliveryTip: null,
    minimumOrderAmount: null,
    allowToppingSubstitution: null,
    orderInStoreInfo: null,
    posType: null,
    restaurantTime: null,
    restaurantTimeOffset: 0,
    enableLargeImageDesign: null,
    giftCardProviderId: null
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_SELECTED_RESTAURANT:
            return {
                ...state,
                name: payload.name,
                distance: payload.distance,
                currency: payload.currency,
                paymentPlatformAccountId: payload.paymentPlatformAccountId,
                simpleBilling: payload.simpleBilling,
                serviceFees: payload.serviceFees,
                restaurantId: payload.restaurantId,
                address: payload.address,
                displayAddress: payload.displayAddress,
                workingDay: payload.workingDay,
                maximumOrderAmount: payload.maximumOrderAmount,
                minimumOrderAmount: payload.minimumOrderAmount,
                minimumDeliveryTip: payload.minimumDeliveryTip,
                deliveryZoneId: payload.deliveryZoneId,
                deliveryCharge: payload.deliveryCharge,
                deliveryChargeInPercentage: payload.deliveryChargeInPercentage,
                deliveryTipEnabled: payload.deliveryTipEnabled,
                deliveryTipInPercentage: payload.deliveryTipInPercentage,
                allowToppingSubstitution: payload.allowToppingSubstitution,
                posType: payload.posType,
                restaurantTime: payload.restaurantTime,
                restaurantTimeOffset: moment(payload.restaurantTime, 'DD-MM-YYYY HH:mm:ss').diff(moment()),
                enableLargeImageDesign: payload.enableLargeImageDesign,
                giftCardProviderId: payload.giftCardProviderId
            };
        case SAVE_SELECTED_RESTAURANT_PREVIEW_MODE:
            return {
                ...state,
                name: payload.name,
                currency: payload.currency,
                distance: payload.distance,
                restaurantId: payload.restaurantId,
                address: payload.address,
                displayAddress: payload.displayAddress,
                posType: payload.posType,
                restaurantTime: payload.restaurantTime,
                restaurantTimeOffset: moment(payload.restaurantTime, 'DD-MM-YYYY HH:mm:ss').diff(moment()),
                enableLargeImageDesign: payload.enableLargeImageDesign
            };
        case SAVE_SELECTED_RESTAURANT_TOPPING_SUBSTITUTION_FLAG_PREVIEW_MODE:
            return {
                ...state,
                allowToppingSubstitution: payload,
            };

        case SAVE_RESTAURANT_WORKING_TIME:
            return { ...state, restaurantWorkingTime: payload };

        case SAVE_RESTAURANT_SERVICE_FEE_PREVIEW_MODE:
            return { ...state, serviceFee: payload };
        case SAVE_RESTAURANT_DATA_FOR_IN_STORE: // TODO if more props are needed use the first case
            return {
                name: payload.restaurantName,
                currency: payload.currency,
                paymentPlatformAccountId: payload.paymentPlatformAccountId,
                serviceFees: payload.serviceFees ? payload.serviceFees : [],
                restaurantId: payload.restaurantId,
                address: payload.address,
                displayAddress: payload.displayAddress,
                workingDay: payload.workingDay,
                maximumOrderAmount: payload.maxOrderAmount,
                minimumOrderAmount: payload.minOrderAmount,
                allowToppingSubstitution: payload.allowToppingSubstitution,
                deliveryTipEnabled: payload.deliveryTipEnabled, // check when deployed,
                deliveryTipInPercentage: payload.deliveryTipInPercentage, // same as above
                minimumDeliveryTip: payload.minTip,
                type: payload.type,
                posType: payload.posType,
                restaurantPaused: payload.restaurantPaused,
                restaurantTime: payload.restaurantTime,
                restaurantTimeOffset: moment(payload.restaurantTime, 'DD-MM-YYYY HH:mm:ss').diff(moment()),
                enableLargeImageDesign: payload.enableLargeImageDesign,
                deliveryCharge: payload.deliveryCharge,
                giftCardProviderId: payload.giftCardProviderId
            };
        case SAVE_IN_STORE_INFO:
            return {
                ...state,
                orderInStoreInfo: payload
            }
        case CLEAR_ORDER_WIZARD:
        if (action.fromPwa) {
            return state;
        } else {
            return INITIAL_STATE;
        }
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18next';
import MaterialIcon from "material-icons-react";

import { displayProductInformation } from '../../../utils/ShoppingCartUtils';
import { getAllergensAndDietaryInfo, mealDealCookTimeCalculations } from '../../../utils/CommonUtils';
import NutritionsList from './nutrition/NutritionsList';
import { ARABIC } from '../../../utils/Constants';

class Header extends Component {
    componentDidMount() {
        if (document.getElementById('productDescription')) {
            document.getElementById('productDescription').innerHTML = this.props.description;
        }
    }

    renderHeaderNavigation(question, questionIndex) {
        const { primaryColor, primaryFontColor } = this.props.selectedTheme;
        const { headerListItem, headerListItemRTL } = styles;
        let productDetails = ' ';
        const isArabic = this.props.language === ARABIC;

        if (question.selectedProduct && question.selectedProduct.id) {
            let productDetailsData = {
                noItems: null,
                extraItems: null
            };

            productDetailsData = displayProductInformation(productDetailsData, question.selectedProduct);

            if (productDetailsData.noItems.length) {
                productDetails += `${i18n.t('screens:shoppingCart.noItems')} ${productDetailsData.noItems.join()} `;
            }

            if (productDetailsData.extraItems.length) {
                productDetails += `${i18n.t('screens:shoppingCart.extraItems')} ${productDetailsData.extraItems.join()}`;
            }
        }
        // Below logic applies only to MealDeals
        if (!question.selectedProduct) return null;

        return (
            <li key={question.id} style={isArabic ? headerListItemRTL : headerListItem}>
                <div style={{ display: 'flex', flexDirection: 'row', }}>
                    <div style={isArabic ? { display: 'inline-block', maxWidth: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: 5, color: primaryFontColor } : { display: 'inline-block', maxWidth: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 5, color: primaryFontColor }}>
                        {question.selectedProduct ? question.selectedProduct.name : ''}
                    </div>
                    <div style={{ display: 'inline-block', maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: primaryFontColor }}>{productDetails}</div>
                    <div
                        onClick={() => this.props.directStepNavigation(questionIndex)}
                        style={isArabic ? { fontSize: 13, color: primaryColor, cursor: 'pointer', marginRight: 0, fontWeight: 'normal', textDecoration: 'underline', paddingRight: 8 } : { fontSize: 13, color: primaryColor, cursor: 'pointer', marginLeft: 0, fontWeight: 'normal', textDecoration: 'underline', paddingLeft: 8 }}>
                        {i18n.t('common:buttons.editBtn')}
                    </div>
                </div>
            </li>
        );
    }

    renderCookTime(product) {
        const { mainTextColor } = this.props.selectedTheme;
        const { cookTimeStyle } = styles;
        const isDinePay = this.props.orderTypes ? this.props.orderTypes[0] ? this.props.orderTypes[0].value === 'In Store' : false : false;
        const isArabic = this.props.language === ARABIC;

        if (product.mealDeal) {
            const mealDealLongestCookTime = mealDealCookTimeCalculations(product);

            return (
                mealDealLongestCookTime ?
                    <p hidden={isDinePay ? true : false} style={{ ...cookTimeStyle, ...{ color: mainTextColor } }}>
                        <span style={isArabic ? { marginLeft: 5 } : { marginRight: 5 }}><MaterialIcon icon='schedule' color={mainTextColor} size={18} /></span>
                        <span style={isArabic ? { paddingLeft: '3px' } : { paddingRight: '3px' }}>{i18n.t('screens:shoppingCart.prepTime')}</span>
                        <span>{`+${mealDealLongestCookTime}`}</span>
                        <span>{i18n.t('common:wizard.minutes')}</span>
                    </p> : null
            )
        } else if (product.cookTime && !product.mealDeal) {
            return (
                <p hidden={isDinePay ? true : false} style={{ ...cookTimeStyle, ...{ color: mainTextColor } }}>
                    <span style={isArabic ? { marginLeft: 5 } : { marginRight: 5 }}><MaterialIcon icon='schedule' color={mainTextColor} size={18} /></span>
                    <span style={isArabic ? { paddingLeft: '3px' } : { paddingRight: '3px' }}>{i18n.t('screens:shoppingCart.prepTime')}</span>
                    <span>{`+${product.cookTime}`}</span>
                    <span>{i18n.t('common:wizard.minutes')}</span>
                </p>
            )
        }
    }

    getAllergensAndDietary() {
        const { allergens, dietaryInfo } = this.props.product;
        let allergensAndDietary = null;
        allergensAndDietary = getAllergensAndDietaryInfo(allergens, dietaryInfo);

        if (allergensAndDietary === '* ') {
            allergensAndDietary = null;
        }

        return allergensAndDietary;
    }

    render() {
        const { iOSDetected, product, name, description, pwaAppRunning } = this.props;
        const { allergensAndDietary, descriptionStyle } = styles;
        const { menuItemsBackground, primaryFontColor, mainTextColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        const fontStyles = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        let headerStyles = pwaAppRunning && isArabic ? {
            width: '100%',
            backgroundColor: menuItemsBackground,
            padding: '15px 45px 0px'
        } : {
            width: '100%',
            backgroundColor: menuItemsBackground
        }

        if (iOSDetected || !product.mealDeal) {
            headerStyles.maxHeight = 'max-content';
        }

        let hasNutritions = false;
        if (product.mealDeal) {
            /**check if any MD selectedProduct has nutrients */
            product.questions.map(mdQuestion => {
                if (mdQuestion.selectedProduct && mdQuestion.selectedProduct.nutrients && mdQuestion.selectedProduct.nutrients.length) {
                    hasNutritions = true;
                }

                return hasNutritions;
            });

        } else {
            hasNutritions = product.nutrients && product.nutrients.length;
        }

        return (
            <div className='wizardHeader' id="wizardHeader" style={headerStyles}>
                <h5 style={{ ...fontStyles, fontSize: 26, marginBottom: 0, color: primaryFontColor }}>{name}</h5>
                {description && <p id='productDescription' style={{ ...descriptionStyle, ...{ color: mainTextColor } }}></p>}
                {
                    this.getAllergensAndDietary() && !product.mealDeal && <p style={{ ...allergensAndDietary, color: mainTextColor }}>{this.getAllergensAndDietary()}</p>
                }
                {
                    this.renderCookTime(product)
                }
                <ul style={{ margin: '10px 0px', padding: 0 }}>
                    {
                        product.questions.map((question, questionIndex) => {
                            return this.renderHeaderNavigation(question, questionIndex);
                        })
                    }
                </ul>
                {/** Nutritional values if any */}
                {
                    hasNutritions ? <NutritionsList {...this.props} calledFrom={'productWizard'} /> : null
                }
            </div>
        );
    }
}

const mapStateToProp = state => {
    return {
        language: state.storeLocalStorage.language,
        pwaAppRunning: state.pwaAppRunning
    }
}

export default connect(mapStateToProp)(Header);

let styles = {
    allergensAndDietary: {
        fontSize: 14,
        lineHeight: 1.3,
        marginTop: 4,
        marginBottom: 1,
        fontStyle: 'italic',
        color: '#828282'
    },
    descriptionStyle: {
        fontSize: 14,
        color: 'rgb(130, 130, 130)',
        lineHeight: 1.3,
        marginTop: 6,
        marginBottom: 0
    },
    headerListItem: {
        display: 'inline-block',
        width: '47%',
        marginRight: '3%',
        listStyleSype: 'none',
        fontSize: 14,
        lineHeight: 1.8
    },
    headerListItemRTL: {
        display: 'inline-block',
        width: '47%',
        marginLeft: '3%',
        listStyleSype: 'none',
        fontSize: 14,
        lineHeight: 1.8
    },
    cookTimeStyle: {
        display: 'flex',
        fontSize: 14,
        color: 'rgb(130, 130, 130)',
        lineHeight: 1.3,
        marginTop: 6,
        marginBottom: 0
    }
}
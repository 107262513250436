import React, { Fragment } from 'react';
import { Col } from 'reactstrap';

const CheckoutSectionContainer = props => {

    const { sectionContainer, sectionContainerRTL, iconCol } = styles;
    const isPwa = window.location.href.includes('brand');
    const borderStyle = isPwa ? 'none' : '1px solid rgba(167, 167, 167, 0.5)';
    let isPwaStyle = isPwa ? 'center' : 'flex-start';

    const { primaryFontColor } = props.customerThemes.selectedTheme;
    let classList = '';
    let appStyles = {};
    let iconColApps = {}

    if (isPwa) {
        classList += 'col-12 col-sm-12 col-md-8';
        appStyles = {
            margin: 0,
            maxWidth: '100%'
        };

        iconColApps = {
            marginLeft: props.isPromotionsScreen ? null : 15,
            paddingLeft: props.isPromotionsScreen ? null : 15
        };
    }

    return (
        <div
            className={classList}
            hidden={props.isHidden}
            style={props.isArabic ?
                {
                    ...sectionContainerRTL,
                    borderTop: borderStyle,
                    color: primaryFontColor,
                    ...appStyles,
                    padding: props.isPromotionsScreen ? props.rejectedRegistration ? 0 : '15px 0' : '15px 0 15px 15px'
                } :
                {
                    ...sectionContainer,
                    borderTop: borderStyle,
                    color: primaryFontColor,
                    ...appStyles,
                    padding: props.isPromotionsScreen ? props.rejectedRegistration ? 0 : '15px 0' : '15px 15px 15px 0'
                }
            }
        >
            { props.isPromotionsScreen ?
                <Fragment>
                    <Col xs={12} sm={12} style={{ ...iconColApps, justifyContent: isPwaStyle }}>
                        <span style={{ position: 'absolute' }}>
                            {props.iconImage}
                        </span>
                        {props.children}
                    </Col>
                </Fragment> :
                <Fragment>
                    <Col xs={1} sm={1} style={{ ...iconCol, ...iconColApps, justifyContent: isPwaStyle }}>
                        <span>
                            {props.iconImage}
                        </span>
                    </Col>
                    <Col xs={11} sm={11}>
                        {props.children}
                    </Col>
                </Fragment>
            }
        </div>
    );
}

export default CheckoutSectionContainer;

const styles = {
    sectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 15px',
        padding: '15px 15px 15px 0'
    },
    sectionContainerRTL: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 15px',
        padding: '15px 0px 15px 15px'
    },
    iconCol: {
        padding: 0,
        display: 'flex',
        alignItems: 'flex-start'
    }
};
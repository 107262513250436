import React from 'react';

const MenuuLogoSVG = ({ width, height, fill, style, onClick, viewBoxThirdValue }) => {
    const viewBoxValues = `0 0 ${viewBoxThirdValue ? viewBoxThirdValue : 500.000000} 207.000000`;

    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width={width} height={height} viewBox={viewBoxValues} style={style}
            preserveAspectRatio="xMidYMid meet" onClick={onClick}>
            <g fill={fill} stroke="none">
                <path d="M410.46,494.23c20.93,0,34.92-12.67,34.92-37.07V413.39a4.72,4.72,0,1,0-9.45,0v44.48c0,18.3-9.8,27.62-25.23,
                    27.62-15.91,0-25.59-10.17-25.59-28.22V413.39a4.72,4.72,0,1,0-9.45,0v44.48c0,23.68,14.11,36.35,34.8,36.35Zm-146.81-5.38a4.6,
                    4.6,0,1,0,9.21,0V424l51.9,66.36c1.43,1.67,2.87,2.87,4.9,2.87h.48a4.24,4.24,0,0,0,4.3-4.42V413.28a4.6,4.6,0,1,0-9.2,0v63.14L274.41,
                    411.6c-1.31-1.67-2.63-2.75-4.78-2.75h-1.32a4.75,4.75,0,0,0-4.66,4.66v75.33ZM42,488.85a4.6,4.6,0,1,0,9.21,0V426.43l26.67,
                    39.22A4.82,4.82,0,0,0,81.94,468a4.63,4.63,0,0,0,3.95-2.39l26.79-39.34v62.42a4.87,4.87,0,0,0,4.78,4.78,4.77,4.77,0,0,0,
                    4.66-4.78V413.51a4.75,4.75,0,0,0-4.66-4.66h-1.08a5.07,5.07,0,0,0-4.42,2.51l-29.89,45-29.89-45a5.18,5.18,0,0,0-4.42-2.51H46.66A4.75,
                    4.75,0,0,0,42,413.51v75.33Zm179.34.12H169a4.32,4.32,0,0,1-4.31-4.31h0a4.32,4.32,0,0,1,4.31-4.3h52.37a4.32,4.32,0,0,1,4.31,
                    4.3h0a4.32,4.32,0,0,1-4.31,4.31Zm0-32.89H169a4.32,4.32,0,0,1-4.31-4.3h0a4.32,4.32,0,0,1,4.31-4.3h52.37a4.32,4.32,0,0,1,4.31,
                    4.3h0a4.32,4.32,0,0,1-4.31,4.3Zm0-33.6H169a4.32,4.32,0,0,1-4.31-4.31h0a4.32,4.32,0,0,1,4.31-4.3h52.37a4.32,4.32,0,0,1,4.31,
                    4.3h0a4.32,4.32,0,0,1-4.31,4.31Zm298.75,71.75c20.92,0,34.91-12.67,34.91-37.07V413.39a4.72,4.72,0,1,0-9.44,0v44.48c0,18.3-9.81,
                    27.62-25.23,27.62-15.9,0-25.59-10.17-25.59-28.22V413.39a4.72,4.72,0,1,0-9.45,0v44.48c0,23.68,14.11,36.35,34.8,36.35Z"
                    transform="translate(-42 -333.89)" />
            </g>
            <g fill={fill} stroke="none">
                <path d="M182.13,368.21V333.89h14.3q5.38,0,8.16,3.31a9.69,9.69,0,0,1,2.3,6.53q0,4.8-2.78,7.44t-8.3,2.64h-9.36v14.4Zm4.32-30.58v12.67h9a7.59,
            7.59,0,0,0,5.35-1.68,6.12,6.12,0,0,0,1.8-4.7,6,6,0,0,0-1.8-4.68,7.42,7.42,0,0,0-5.11-1.61Z" transform="translate(-42 -333.89)" />
                <path d="M229.07,365.14a12,12,0,0,1-17.4,0,14.83,14.83,0,0,1,0-18.62,12,12,0,0,1,17.4,0,14.91,14.91,0,0,1,0,18.62Zm-14.62-2.28a7.72,
            7.72,0,0,0,11.83,0,13,13,0,0,0,0-14.11,7.67,7.67,0,0,0-11.81,0,12.93,12.93,0,0,0,0,14.09Z" transform="translate(-42 -333.89)" />
                <path d="M241.17,368.21l-7.87-24.82h4.18l4.37,15.31,1.34,5.33h.1q.72-3.26,1.25-5.23l4.18-15.41h4.08l4,15.41L258,364h.1q.77-3.26,
            1.39-5.33l4.61-15.31h4L260,368.21h-4l-4.13-15-1.2-5.18h-.1q-.67,3.12-1.25,5.18l-4.18,15Z" transform="translate(-42 -333.89)" />
                <path d="M281.1,368.88a11,11,0,0,1-8.76-3.72,14.91,14.91,0,0,1,0-18.62,10.73,10.73,0,0,1,8.47-3.77,10.09,10.09,0,0,1,8.45,3.82q3,
            3.82,3,10.15h-19.2a10.63,10.63,0,0,0,2.33,6.41,7.05,7.05,0,0,0,5.69,2.47q5.57,0,6.86-5.09h3.79a10.3,10.3,0,0,1-3.65,6.17A11,11,0,0,1,
            281.1,368.88ZM286,348a6.57,6.57,0,0,0-5.18-2.11,6.65,6.65,0,0,0-5.26,2.21,10.64,10.64,0,0,0-2.42,5.76h14.93A9.27,9.27,0,0,0,286,348Z"
                    transform="translate(-42 -333.89)" />
                <path d="M300,343.39v4.13h.1a9.75,9.75,0,0,1,3.12-3.19,7.77,7.77,0,0,1,4.27-1.22,4.24,4.24,0,0,1,1.58.29v3.74h-.14a5.52,5.52,0,0,0-1.73-.24,
            7,7,0,0,0-5.09,2.09,7.32,7.32,0,0,0-2.11,5.45v13.78h-3.89V343.39Z"
                    transform="translate(-42 -333.89)" />
                <path d="M322.19,368.88a11,11,0,0,1-8.76-3.72,14.91,14.91,0,0,1,0-18.62,10.73,10.73,0,0,1,8.47-3.77,10.09,10.09,0,0,1,8.45,3.82q3,
            3.82,3,10.15h-19.2a10.63,10.63,0,0,0,2.33,6.41,7.05,7.05,0,0,0,5.69,2.47q5.57,0,6.86-5.09h3.79a10.3,10.3,0,0,1-3.65,6.17A11,11,0,0,1,
            322.19,368.88ZM327.13,348a6.57,6.57,0,0,0-5.18-2.11,6.65,6.65,0,0,0-5.26,2.21,10.64,10.64,0,0,0-2.42,5.76H329.2A9.27,9.27,0,0,0,327.13,348Z"
                    transform="translate(-42 -333.89)" />
                <path d="M346.76,368.93a9.93,9.93,0,0,1-8-3.53q-3-3.53-3-9.53t3.07-9.55a9.94,9.94,0,0,1,7.82-3.5,8.82,8.82,0,0,1,7.78,4.08h.1v-13h3.89v34.32h-3.89v-3h-.1A9.28,
            9.28,0,0,1,346.76,368.93Zm.14-3.26a6.88,6.88,0,0,0,5.93-2.64,11.72,11.72,0,0,0,1.94-7.1,11.27,11.27,0,0,0-2.09-7.1,7,7,0,0,0-5.83-2.69,6,6,0,0,
            0-5.26,2.71,12.34,12.34,0,0,0-1.85,7.08A12.22,12.22,0,0,0,341.6,363,6,6,0,0,0,346.91,365.67Z"
                    transform="translate(-42 -333.89)" />
                <path d="M383.15,368.93a9.15,9.15,0,0,1-7.82-4h-.1v3.26h-3.89V333.89h3.89v13.06h.1A10,10,0,0,1,391,346.3q2.88,3.48,2.88,9.58T391,365.4A9.55,
            9.55,0,0,1,383.15,368.93Zm-.29-3.26a5.88,5.88,0,0,0,5.23-2.66,12.58,12.58,0,0,0,1.78-7.08,12.73,12.73,0,0,0-1.78-7.08,5.81,5.81,0,0,0-5.18-2.71,
            6.85,6.85,0,0,0-5.9,2.76,11.62,11.62,0,0,0-2,7,11.46,11.46,0,0,0,2,7.06A6.87,6.87,0,0,0,382.86,365.67Z"
                    transform="translate(-42 -333.89)" />
                <path d="M400.43,376.42a9.49,9.49,0,0,1-3.5-.53v-3.46h.19a5.51,5.51,0,0,0,2.64.53,3.5,3.5,0,0,0,2.16-.65,5.34,5.34,0,0,0,
            1.54-2.38l1-2.45-9.7-24.1h4.22l5.86,15.46,1.68,4.85h.1q.86-2.78,1.63-4.85l5.66-15.46H418l-10.61,26.83a12.41,12.41,0,0,1-3,
            4.92A6,6,0,0,1,400.43,376.42Z"
                    transform="translate(-42 -333.89)" />
            </g>
        </svg>
    )
}

export default MenuuLogoSVG;
import {CLEAR_APP_STATE, CLEAR_STATE, CLOSE_START_ORDER_SCREEN, OPEN_START_ORDER_SCREEN} from "../../../actions/types";

const INITIAL_STATE = {
    startOrderScreenOpened: false,
    currentPagePath: '/start-order'
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case OPEN_START_ORDER_SCREEN:
            return {
                ...state,
                startOrderScreenOpened: true,
                currentPagePath: action.payload
            };
        case CLOSE_START_ORDER_SCREEN:
            return {
                ...state,
                startOrderScreenOpened: false,
                currentPagePath: action.payload
            };
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
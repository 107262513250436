import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody } from 'reactstrap';
import i18n from '../../i18next';

class ConfirmationModal extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    toggle() {
        this.props.onToggle();
    }

    onConfirm() {
        this.props.onConfirm();

        if (this.props.dontCloseOnSuccess) return;
        
        this.toggle();
    }

    onCancel() {

        if (this.props.onCancel) {

            this.props.onCancel();
        }

        this.toggle();
    }

    renderConfirmButton() {
        return (
            <Button
                style={{ marginRight: 5, borderRadius: 0 }}
                outline color="secondary"
                onClick={this.onConfirm}>{i18n.t('common:buttons.delete')}</Button>
        );
    }

    renderCancelButton() {
        return (
            <Button
                hidden={this.props.hideCancelBtn}
                style={{ marginRight: 5, borderRadius: 0 }}
                outline color="danger"
                onClick={this.onCancel}>{i18n.t('common:buttons.cancel')}</Button>
        );
    }

    render() {
        const { menuItemsBackground } = this.props.customerThemes.selectedTheme;

        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.toggle}>
                    <ModalBody style={{ backgroundColor: menuItemsBackground }}>
                        {this.props.body()}
                        <Col xs={12} style={{display: 'flex', justifyContent: 'space-between'}}>
                            {this.renderCancelButton()}
                            {this.renderConfirmButton()}
                        </Col>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ConfirmationModal;



import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Input, Button } from 'reactstrap';
import i18n from '../../../i18next';

import { getPriceWithCurrencySymbol, transformProductPrice } from '../../../utils/CommonUtils';
import { ARABIC, DELIVERY_ID } from '../../../utils/Constants';

class TipScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            minTip: null,
            tip: null,
            tipError: false
        }

        this.onClickConfirm = this.onClickConfirm.bind(this);
        this.onClickConfirmPwa = this.onClickConfirmPwa.bind(this);
    }

    componentDidMount() {
        this.applyTheme();

        let minTip = 0;
        let { subtotal, discount, freeDelivery } = this.props.shoppingCart;
        let { minimumDeliveryTip, deliveryCharge } = this.props.selectedRestaurant;
        const orderTypeId = this.props.orderTypes.selectedOrderType.id;

        let haveDiscount = this.props.shoppingCart.appliedPromotions.length || discount > 0;
        if (this.props.selectedRestaurant.deliveryTipInPercentage) {
            if (freeDelivery && orderTypeId === DELIVERY_ID) {
                minTip = transformProductPrice(Number((subtotal - (haveDiscount ? discount - deliveryCharge : 0)) * Number(minimumDeliveryTip === null ? 0 : minimumDeliveryTip / 100)));
            } else {
                minTip = transformProductPrice(Number((subtotal - (haveDiscount ? discount : 0)) * Number(minimumDeliveryTip === null ? 0 : minimumDeliveryTip / 100)));
            }

            if (+minTip < 0) {   // if discount is greater than subtotal
                minTip = 0;
            }
        } else {
            minTip = minimumDeliveryTip === null ? 0 : minimumDeliveryTip;
        }

        this.setState({
            minTip: Number(Number(minTip).toFixed(2)),
            tip: this.props.tip
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tip !== this.props.tip) {
            this.setState({
                tip: this.props.tip
            });
        }

        if (this.props.shoppingCart !== prevProps.shoppingCart) {
            let minTip = 0;
            let { subtotal, discount } = this.props.shoppingCart;
            let { minimumDeliveryTip } = this.props.selectedRestaurant;
            let haveDiscount = this.props.shoppingCart.appliedPromotions.length || discount > 0;
            if (this.props.selectedRestaurant.deliveryTipInPercentage) {
                minTip = transformProductPrice(Number((subtotal - (haveDiscount ? discount : 0)) * Number(minimumDeliveryTip === null ? 0 : minimumDeliveryTip / 100)));

                if (+minTip < 0) {   // if discount is greater than subtotal
                    minTip = 0;
                }
            } else {
                minTip = minimumDeliveryTip === null ? 0 : minimumDeliveryTip;
            }

            this.setState({
                minTip: Number(Number(minTip).toFixed(2)),
                tip: this.props.tip
            });
        }
    }

    renderModalHeader() {

        const isArabic = this.props.language === ARABIC;

        return (
            <div style={styles.headerStyle}>
                <h3 style={{ fontSize: 24 }}>{i18n.t('screens:tipScreen.addTip')}</h3>
                {!this.props.isPwa ? <div
                    id="customCloseBtn"
                    className='available-discounts'
                    style={isArabic ? { marginTop: 5, position: 'absolute', top: 0, left: 5 } : { marginTop: 5, position: 'absolute', top: 0, right: 5 }} onClick={() => this.props.onToggle()}></div> : null}
            </div>
        )
    }

    onClickConfirm() {
        this.props.onToggle();
        this.props.saveTip(Number(transformProductPrice(Number(this.state.tip) - Number(this.state.minTip))));
    }

    onClickConfirmPwa() {

        if(this.props.isAppliedGiftCards) {
            this.props.saveTip(this.state.tip - this.state.minTip, () => {
                this.props.applyGiftCard(this.props.appliedGiftCards, null)
            });
        } else {
            this.props.saveTip(this.state.tip - this.state.minTip);
        }
        
        this.props.history.push('/brand/' + this.props.brandId + '/order/checkout')
    }

    onChangeTip({ target }) {
        let value = Number(target.value);
        let minTip = Number(this.state.minTip);

        if (value < 0 || value < minTip) {
            this.setState({ ...this.state, tip: target.value, tipError: true })
        } else {
            this.setState({ ...this.state, tip: target.value, tipError: false })
        }
    }

    onClickPercentages = ({ target }) => {
        let { subtotal, discount, freeDelivery } = this.props.shoppingCart;
        let { deliveryCharge } = this.props.selectedRestaurant;
        const orderTypeId = this.props.orderTypes.selectedOrderType.id;
        let haveDiscount = this.props.shoppingCart.appliedPromotions.length || discount > 0;
        let totalTip;
        if (freeDelivery && orderTypeId === DELIVERY_ID) {
            totalTip = Number((subtotal - (haveDiscount ? discount - deliveryCharge : 0)) * Number(target.value / 100));
        } else {
            totalTip = Number((subtotal - (haveDiscount ? discount : 0)) * Number(target.value / 100));
        }
        if (+totalTip < 0) {   // if discount is greater than subtotal
            totalTip = 0;
        }

        this.setState({ ...this.state, tip: totalTip === 0 ? totalTip.toFixed(2) : Number(Number(Math.round(totalTip + 'e2') + 'e-2').toFixed(2)), tipError: false }, () => {
            document.getElementById('tipInput').value = this.state.tip;
        })

    }

    renderModalBody() {
        const rightCurrencySymbol = this.props.currency !== 'лв' ? false : true;
        const { tipError } = this.state;
        const { subtotal } = this.props.shoppingCart;
        const { minimumDeliveryTip } = this.props.selectedRestaurant;
        const tipInPercentage = (Number(minimumDeliveryTip) / Number(subtotal)) * 100;
        const isArabic = this.props.language === ARABIC;

        const firstPercent = this.getPercentage(this.props.selectedRestaurant.deliveryTipInPercentage ? minimumDeliveryTip : tipInPercentage.toFixed(2));
        let percentages = [firstPercent];
        for (let i = 0; i < 3; i++) {
            let newPercent = percentages[i] + 5;
            percentages.push(newPercent);
        }

        let inputStyle, inputWrapperStyle;
        if (tipError) {
            inputStyle = styles.invalidInputStyle;
            inputWrapperStyle = isArabic ? styles.invalidInputWrapperStyleRTL : styles.invalidInputWrapperStyle;
        } else {
            inputStyle = styles.inputStyle;
            inputWrapperStyle = isArabic ? styles.inputWrapperStyleRTL : styles.inputWrapperStyle;
        }
        return (
            <Fragment>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={inputWrapperStyle}>
                        <span hidden={rightCurrencySymbol}>{this.props.currency}</span>
                        <Input
                            id='tipInput'
                            className='tipInput'
                            type='number'
                            style={inputStyle}
                            step={0.1}
                            min={this.state.minTip}
                            value={this.state.tip}
                            onChange={(e) => this.onChangeTip(e)}
                        // onKeyDown={(e) => { e.preventDefault() }}
                        />
                        <div hidden={!rightCurrencySymbol}>{this.props.currency}</div>
                    </div>
                </div>
                <p hidden={!this.state.minTip > 0} style={styles.infoLabel}>{`*${i18n.t('screens:tipScreen.minTip')}: ${getPriceWithCurrencySymbol(this.state.minTip, this.props.selectedRestaurant.currency, isArabic)}`}</p>
                <ul style={{ display: 'flex', justifyContent: 'space-evenly', padding: 60 }}>
                    {
                        percentages.map(percent => {
                            if (percent <= 100) {
                                return (
                                    <li style={isArabic ? styles.tipPercentagesRTL : styles.tipPercentages} value={percent} onClick={this.onClickPercentages}>{`${percent}%`}</li>
                                )
                            }
                        })
                    }
                </ul>
            </Fragment>
        )
    }

    renderModalFooter() {
        const { isPwa } = this.props;
        const { buttonsMainContainer, buttonsMainContainerPwa, buttonsContainer, buttonsContainerPwa, buttonsStylePwa, confirmButtonPwa, cancelButtonPwa, buttonsStyle, confirmButton, cancelButton, mobileButtons } = styles;
        const { isMobile } = this.props;

        const buttonsStyles = isMobile ? mobileButtons : buttonsStyle;

        return (
            <div style={isPwa ? buttonsMainContainerPwa : buttonsMainContainer}>
                <div style={isPwa ? buttonsContainerPwa : buttonsContainer}>
                    <Button
                        className='tipButtons'
                        style={isPwa ? { ...buttonsStylePwa, ...cancelButtonPwa } : { ...buttonsStyles, ...cancelButton }}
                        onClick={isPwa ? () => this.props.history.push('/brand/' + this.props.brandId + '/order/checkout') : () => this.props.onToggle()}>
                        {i18n.t('screens:tipScreen.cancel')}
                    </Button>
                    <Button
                        disabled={this.state.tipError}
                        style={isPwa ? { ...buttonsStylePwa, ...confirmButtonPwa } : { ...buttonsStyles, ...confirmButton }}
                        onClick={isPwa ? () => this.onClickConfirmPwa() : () => this.onClickConfirm()}>
                        {i18n.t('screens:tipScreen.confirm')}
                    </Button>
                </div>
            </div>
        )
    }

    getPercentage(number) {
        let percentage = Number(number)
        if (percentage <= 10) return 10;
        else if (percentage > 10 && percentage <= 15) return 15;
        else if (percentage > 15 && percentage <= 20) return 20;
        else if (percentage > 20 && percentage <= 25) return 25;
        else if (percentage > 25 && percentage <= 30) return 30;
        else if (percentage > 30 && percentage <= 35) return 35;
        else if (percentage > 35 && percentage <= 40) return 40;
        else if (percentage > 40 && percentage <= 45) return 45;
        else if (percentage > 45 && percentage <= 50) return 50;
        else if (percentage > 50 && percentage <= 55) return 55;
        else if (percentage > 55 && percentage <= 60) return 60;
        else if (percentage > 60 && percentage <= 65) return 65;
        else if (percentage > 65 && percentage <= 70) return 70;
        else if (percentage > 70 && percentage <= 75) return 75;
        else if (percentage > 75 && percentage <= 80) return 80;
        else if (percentage > 80 && percentage <= 85) return 85;
        else if (percentage > 85 && percentage <= 90) return 90;
        else if (percentage > 90 && percentage <= 95) return 95;
        else if (percentage > 95 && percentage <= 100) return 100;
        else return 101;

    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const {
            primaryColor,
            mainTextColor,
            chefNoteBackground,
            primaryFontColor,
            menuItemsBackground,
            buttonsFont,
            inputsFontColor,
            inactiveColor,
            fontFamilyType,
            fontBold,
            fontItalic,
            uppercase
        } = this.props.customerThemes.selectedTheme;

        customizedStyles.mainContainer.backgroundColor = menuItemsBackground;
        customizedStyles.headerStyle.color = primaryFontColor;
        customizedStyles.tipPercentages.color = primaryFontColor;
        customizedStyles.tipPercentagesRTL.color = primaryFontColor;
        customizedStyles.tipPercentages.borderBottom = `1px solid ${primaryFontColor}`;
        customizedStyles.tipPercentagesRTL.borderBottom = `1px solid ${primaryFontColor}`;
        customizedStyles.infoLabel.color = mainTextColor;
        customizedStyles.buttonsStyle.color = buttonsFont;
        customizedStyles.inputStyle.backgroundColor = chefNoteBackground;
        customizedStyles.inputStyle.color = inputsFontColor;
        customizedStyles.invalidInputStyle.color = inputsFontColor;
        customizedStyles.inputWrapperStyle.backgroundColor = chefNoteBackground;
        customizedStyles.inputWrapperStyleRTL.backgroundColor = chefNoteBackground;
        customizedStyles.inputWrapperStyle.color = inputsFontColor;
        customizedStyles.inputWrapperStyleRTL.color = inputsFontColor;
        customizedStyles.invalidInputWrapperStyle.color = inputsFontColor;
        customizedStyles.invalidInputWrapperStyleRTL.color = inputsFontColor;
        customizedStyles.confirmButton.backgroundColor = primaryColor;
        customizedStyles.cancelButton.backgroundColor = inactiveColor;
        customizedStyles.confirmButtonPwa.backgroundColor = primaryColor;
        customizedStyles.cancelButtonPwa.backgroundColor = inactiveColor;
        customizedStyles.buttonsStylePwa.color = buttonsFont;
        customizedStyles.buttonsStyle.fontFamily = fontFamilyType;
        customizedStyles.buttonsStyle.fontWeight = fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300;
        customizedStyles.buttonsStyle.fontStyle = fontItalic;
        customizedStyles.buttonsStyle.textTransform = uppercase;
        customizedStyles.buttonsStylePwa.fontFamily = fontFamilyType;
        customizedStyles.buttonsStylePwa.fontWeight = fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300;
        customizedStyles.buttonsStylePwa.fontStyle = fontItalic;
        customizedStyles.buttonsStylePwa.textTransform = uppercase;

        styles = customizedStyles;
        return styles;
    }
    render() {
        return (
            <div style={styles.mainContainer}>
                {this.renderModalHeader()}
                {this.renderModalBody()}
                {this.renderModalFooter()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        customerThemes: state.customerThemes,
        selectedRestaurant: state.selectedRestaurant,
        shoppingCart: state.shoppingCart,
        brandId: state.brand.id,
        language: state.storeLocalStorage.language,
        orderTypes: state.orderTypes
    }
}

export default connect(mapStateToProps)(TipScreen);

let styles = {
    mainContainer: {

    },

    headerStyle: {
        display: 'flex',
        padding: 20,
        justifyContent: 'center'
    },

    inputWrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        marginTop: 30,
        fontSize: 30,
        fontWeight: 'bold',
        padding: 10,
        border: '1px solid #e9ecef'
    },

    inputWrapperStyleRTL: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        marginTop: 30,
        fontSize: 30,
        fontWeight: 'normal',
        padding: 10,
        border: '1px solid #e9ecef'
    },

    inputStyle: {
        borderRadius: 0,
        border: 'none',
        height: 50,
        width: 130,
        textAlign: 'center'
    },

    invalidInputStyle: {
        borderRadius: 0,
        border: 'none',
        height: 50,
        width: 130,
        textAlign: 'center',
        backgroundColor: '#F2D8D9'
    },

    tipPercentages: {
        borderBottom: '1px solid black',
        padding: 3,
        lineHeight: 0.6,
        cursor: 'pointer',
        fontWeight: 'bold',
        fontSize: 20,
        margin: 0,
        listStyleType: 'none'
    },

    tipPercentagesRTL: {
        borderBottom: '1px solid black',
        padding: 3,
        lineHeight: 0.6,
        cursor: 'pointer',
        fontWeight: 'normal',
        fontSize: 20,
        margin: 0,
        listStyleType: 'none'
    },

    buttonsStyle: {
        borderRadius: 0,
        border: 'none',
        minWidth: 170,
        paddingTop: 10,
        paddingBottom: 10
    },

    mobileButtons: {
        borderRadius: 0,
        border: 'none',
        minWidth: '47%',
        paddingTop: 10,
        paddingBottom: 10
    },

    invalidInputWrapperStyle: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        marginTop: 30,
        fontSize: 30,
        fontWeight: 'bold',
        padding: 10,
        border: '1px solid #e9ecef',
        backgroundColor: '#F2D8D9'
    },

    invalidInputWrapperStyleRTL: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        marginTop: 30,
        fontSize: 30,
        fontWeight: 'normal',
        padding: 10,
        border: '1px solid #e9ecef',
        backgroundColor: '#F2D8D9'
    },

    infoLabel: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 12,
        color: 'rgb(110, 118, 118)'
    },

    buttonsMainContainer: {
        width: '100%'
    },

    buttonsMainContainerPwa: {
        width: '100%',
        position: 'fixed',
        bottom: 0
    },

    buttonsContainer: {
        display: 'flex',
        padding: 20,
        textAlign: 'center'
    },

    buttonsContainerPwa: {
        display: 'flex',
        textAlign: 'center'
    },

    buttonsStylePwa: {
        borderRadius: 0,
        border: 'none',
        minWidth: '50%',
        height: 60,
        fontSize: 20
    },

    cancelButton: {},

    confirmButton: {
        marginLeft: 20,
    },

    cancelButtonPwa: {},

    confirmButtonPwa: {}
}
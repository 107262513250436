import React, { Component } from 'react';

import { PaymentRequestButtonElement, injectStripe } from "react-stripe-elements";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { selectPaymentType, placeOrder } from "../../../../actions/index";
import { getQueryInfoFromUrl } from '../../../../utils/CommonUtils';
import { sendMessageToParentSite } from '../../../../utils/CrossOriginUtils';

class StripeAppleGooglePayForm extends Component {
    constructor() {
        super();

        this.state = {
            canMakePayment: false,
            paymentRequest: {},
            stripeAlreadyCalled: false
        };
    }

    componentDidUpdate(prevProps) {
        const { stripe, shoppingCart, brand, selectedRestaurant, pwaAppRunning, shoppingCartTotalWithGiftCard } = this.props;

        let isIntegration = getQueryInfoFromUrl().brandId;

        const amount = Number((shoppingCart.total * 100).toFixed(0));
        const countryCode = brand.countryCode;
        const restaurantCurrency = selectedRestaurant.currency;
        const orderData = {
            country: countryCode,
            currency: restaurantCurrency,
            total: {
                label: 'Total',
                amount: shoppingCartTotalWithGiftCard ? Number((shoppingCartTotalWithGiftCard * 100).toFixed(0)) : amount
            },
        };

        if (stripe && !this.state.stripeAlreadyCalled && !pwaAppRunning) {
            const paymentRequest = stripe.paymentRequest(orderData);

            paymentRequest.on('paymentmethod', (ev) => this.props.handleSubmit(ev));

            paymentRequest.canMakePayment().then((result) => {
                this.setState({ canMakePayment: !!result });
            });
            this.setState({ ...this.state, stripeAlreadyCalled: true, paymentRequest })
        }

        if (prevProps.shoppingCart.total !== shoppingCart.total && !pwaAppRunning) {
            const amount = Number((shoppingCart.total * 100).toFixed(0));
            if (isIntegration && this.props.stripeClientSideAlreadyCalled) {
                let applePayObject = {
                    method: 'appleGooglePay',
                    key: {
                        apiKey: this.props.paymentProvider.stripePremenuData.publicKey,
                        orderData: orderData,
                        totalAmountUpdate: amount
                    }
                };
                sendMessageToParentSite(applePayObject);
            } else if (!isIntegration) {
                this.state.paymentRequest.update({
                    total: {
                        label: 'Total',
                        amount: amount
                    }
                })
            }
        }

        if (this.props.paymentProvider.isAppleGooglePayClicked !== prevProps.paymentProvider.isAppleGooglePayClicked) {
            let applePayObject = {
                method: 'appleGooglePay',
                key: {
                    apiKey: this.props.paymentProvider.stripePremenuData.publicKey,
                    orderData: orderData,
                    buttonClicked: true
                }
            };

            (this.props.pwaAppRunning) ? this.props.handlePayment() : isIntegration ? sendMessageToParentSite(applePayObject) : this.state.paymentRequest.show();
        }
    }

    render() {
        return this.state.canMakePayment && this.props.isAppleGooglePaySelected ? (
            <React.Fragment>
                <Col xs={12} sm={12} md={12} style={{ display: 'none' }}>
                    <PaymentRequestButtonElement
                        paymentRequest={this.state.paymentRequest}
                        className="PaymentRequestButton ApplePaymentButton"
                        style={{
                            // For more details on how to style the Payment Request Button, see:
                            // https://stripe.com/docs/elements/payment-request-button#styling-the-element
                            paymentRequestButton: {
                                theme: 'light-outline',
                                height: '50px',
                            },
                        }}
                    />
                </Col>
            </React.Fragment>
        ) : null;
    }
}

const mapStateToProps = state => {
    return {
        brand: state.brand,
        customer: state.customer,
        shoppingCart: state.shoppingCart,
        selectedRestaurant: state.selectedRestaurant
    };
};

export default connect(mapStateToProps, { selectPaymentType, placeOrder })(injectStripe(StripeAppleGooglePayForm));
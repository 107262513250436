import React, { Component, Fragment } from 'react';
import { Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap';
import i18n from '../../../../i18next';
import {isAndroid, isIOS, isMacOs, isMobile} from 'mobile-device-detect';

import { updateIconsAccordingToTheme } from '../../../../utils/ThemesSelector';
import {
    isCardAlreadyExpired,
    transformProductPrice,
    getPriceWithCurrencySymbol
} from '../../../../utils/CommonUtils';

import CheckoutRow from './CheckoutRow';
import TickSVG from '../../common/svgComponents/TickSVG';
import CardsSVG from '../../common/svgComponents/CardsSVG';
import ApplePaySVG from "../../common/svgComponents/ApplePaySVG";
import GooglePaySVG from "../../common/svgComponents/GooglePaySVG";
import CashSVG from "../../common/svgComponents/CashSVG";
import MaterialIcon from "material-icons-react";

export default class CheckoutPaymentInformation extends Component {

    renderPaymentType() {
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <CardsSVG width={21} height={21} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />
        const isGiftCardsTotalZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 ? true : false : false;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        const sectionTitleText = i18n.t('screens:checkoutScreen.paymentType');
        const isApplePaySelected = (this.props.selectedPaymentId == 3) ? true : false;
        const isPaidByVoucher = (this.props.shoppingCart.total === 0 && this.props.shoppingCart.discount > 0) || (isGiftCardsTotalZero && isAppliedGiftCards);
        
        return (
            <CheckoutRow
                pwaMode={this.props.isPWA}
                component={'paymentType'}
                savedCards={this.renderCustomerSavedPaymentCards(isApplePaySelected)}
                selectedPaymentId={this.props.selectedPaymentId}
                hideTickInitially={true}
                removeSeparatorLine={true}
                isAppleGooglePayAvailable={this.props.isAppleGooglePayAvailable}
                aboveSpansElement={isMobile && this.props.isAppleGooglePayAvailable && !isPaidByVoucher
                    ? this.renderPaymentOptions()
                    : this.props.payment.paymentTypes[0] && this.renderPaymentButtons()}
                sectionTitleText={sectionTitleText}
                isDisabled={null}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                styles={this.props.styles}
                isApplePaySelected={isApplePaySelected}
                isAppliedGiftCards={isAppliedGiftCards}
                isArabic={this.props.isArabic}
            />
        );
    }

    renderPaymentButtons() {
        const { radioBtnOne, radioBtnOneRTL, paymentTypeButtons } = this.props.styles;
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const { minimumOrderAmount, currency } = this.props.selectedRestaurant;
        const isMacLike = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;
        const isIOS = navigator.platform.match(/(iPhone|iPod|iPad)/i) ? true : false;
        const isGiftCardsTotalZero = this.props.giftCards.giftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 ? this.props.giftCards.giftCards.total === 0 ? true : false : false;
        
        let isAppleGooglePayHidden = true;
        const isPaidByVoucher = (this.props.shoppingCart.total === 0 && this.props.shoppingCart.discount > 0) || (isGiftCardsTotalZero);
        
        if (this.props.paymentProvider.providerType) isAppleGooglePayHidden = ((isMacLike || isIOS) || isAndroid) && this.props.paymentProvider.providerType.id === 1 && this.props.isAppleGooglePayAvailable ? false : true;
        const appleGooglePayHiddenStyle = (isAppleGooglePayHidden || isPaidByVoucher) ? { display: 'none' } : {};
        let radioButtonName;
        if (isMacLike || isIOS) radioButtonName = i18n.t(`screens:checkoutScreen.applePay`);
        else if (isAndroid) radioButtonName = i18n.t(`screens:checkoutScreen.googlePay`);

        const isCashExist = this.props.payment.paymentTypes.some(e => e.id === 2);
        const haveDiscount = this.props.shoppingCart.discount > 0;
        const minimumOrderAmountPrice = transformProductPrice(minimumOrderAmount);
        const minOrderAmountPriceWithCurrency = getPriceWithCurrencySymbol(minimumOrderAmountPrice, currency, this.props.isArabic);
        const minimumOrderAmountWithPromotion = getPriceWithCurrencySymbol(transformProductPrice(1), currency, this.props.isArabic);

        let currentMinimumOrderAmount = this.props.shoppingCart.appliedPromotions.length ? 1 : minimumOrderAmount;

        if ((this.props.shoppingCart.total > 0 && this.props.shoppingCart.total < currentMinimumOrderAmount && !isGiftCardsTotalZero) || (this.props.shoppingCart.total === 0 && this.props.shoppingCart.discount === 0 && !isGiftCardsTotalZero)) {  // when total is less than minimum order amount
            return (
                <p style={{ color: primaryFontColor }}>{`${i18n.t(`screens:checkoutScreen.requiredMinOrderAmount`)} ${this.props.shoppingCart.appliedPromotions.length ? minimumOrderAmountWithPromotion : minOrderAmountPriceWithCurrency}`}</p>
            )
        } else if(isGiftCardsTotalZero) {
            return (
                <Label check className="custom-radio-btn" style={this.props.isArabic ? { ...radioBtnOneRTL, color: primaryFontColor } : { ...radioBtnOne, color: primaryFontColor }}>
                    {i18n.t(`screens:checkoutScreen.paidBy`)}
                    <Input type="radio" name="paymentTypeCash"
                        checked={true}
                    />
                    <span className="checkmark"></span>
                </Label>
            )
        } else {
            return (
                <Form>
                    <FormGroup check style={paymentTypeButtons}>

                        {
                            !isCashExist && haveDiscount && this.props.shoppingCart.total === 0 ?
                                <Label check className="custom-radio-btn" style={this.props.isArabic ? { ...radioBtnOneRTL, color: primaryFontColor } : { ...radioBtnOne, color: primaryFontColor }}>
                                    {i18n.t(`screens:checkoutScreen.paidBy`)}
                                    <Input type="radio" name="paymentTypeCash"
                                        id={2}
                                        value={'Cash'}
                                        checked={(this.props.selectedPaymentId == 2) ? true : false}
                                        onChange={this.props.changeState}
                                    />
                                    <span className="checkmark"></span>
                                </Label>
                                : null
                        }
                        {
                            this.props.payment.paymentTypes.map((payment, i) => {
                                
                                const paymentButtonText = payment.value.toLowerCase().replace(' ', '');
                                const isTotalLessThenOne = this.props.shoppingCart.total < 1 && payment.id === 1;

                                if (isTotalLessThenOne) {
                                    return null;
                                }

                                return (
                                    <Label key={i} check className="custom-radio-btn" style={this.props.isArabic ? { ...radioBtnOneRTL, color: primaryFontColor } : { ...radioBtnOne, color: primaryFontColor }}>
                                        {payment.id === 2 && haveDiscount && this.props.shoppingCart.total === 0 ? i18n.t(`screens:checkoutScreen.paidBy`) : i18n.t(`screens:checkoutScreen.${paymentButtonText}`)}
                                        <Input type="radio" name="paymentTypeCash"
                                            id={payment.id}
                                            value={payment.value}
                                            checked={(this.props.selectedPaymentId == payment.id) ? true : false}
                                            onChange={this.props.changeState}
                                        />
                                        <span className="checkmark"></span>
                                    </Label>)
                            })
                        }
                        <Label check className="custom-radio-btn" style={Object.assign({}, this.props.isArabic ? radioBtnOneRTL : radioBtnOne, appleGooglePayHiddenStyle, { color: primaryFontColor })} hidden={isAppleGooglePayHidden}>
                        {radioButtonName}
                        <Input type="radio" name="paymentTypeCash"
                            id={3}
                            value="Credit card"
                            checked={(this.props.selectedPaymentId == 3 && (isMacLike || isIOS || isAndroid)) ? true : false}
                            onChange={this.props.changeState}
                        />
                        <span className="checkmark"></span>
                    </Label>

                    </FormGroup>
                </Form>
            );
        }
    }

    renderOptions = (dropDownOptions) => {
        return this.props.payment.paymentTypes.map((payment, i) => {
            const paymentButtonText = payment.value.toLowerCase().replace(' ', '');
            const isTotalLessThenOne = this.props.shoppingCart.total < 1 && payment.id === 1;

            if (isTotalLessThenOne) {
                return null;
            }

            return <option style={dropDownOptions} key={i} value={payment.id}>{i18n.t(`screens:checkoutScreen.${paymentButtonText}`)}</option>
        })
    }

    renderPaymentOptions() {
        const { isPWA } = this.props;
        const { paymentTypes, selectedPaymentType } = this.props.payment;
        const { primaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const rowBtnsStyle = isPWA? {width: '100%', padding: "3px 10px"} : {width: '100%'};
        const appleGoolePayPaymentId = 3;
        const paymentIconImage = <TickSVG width={32} height={32} fill={primaryColor} />

        let btnContent;

        if (isIOS || isMacOs) {
            btnContent =
            <ApplePaySVG width={50} height={20} fill={primaryFontColor} />
        } else if (isAndroid) {
            btnContent = 'Google Pay'
            // <GooglePaySVG width={50} height={20} fill={"#000"} />
        }

        const isAppleGPaySelectedByDefault = isMobile && this.props.isAppleGooglePayAvailable ? true : false;

        return <React.Fragment>
            {
                paymentTypes.map((payment, i) => {
                    const paymentButtonText = payment.value.toLowerCase().replace(' ', '');
                    let paymentBtnContent = payment.id == 2 ?
                        <CashSVG width={50} height={20} fill={primaryFontColor} /> :
                        <CardsSVG width={20} height={20} fill={primaryFontColor} />


                    return <Col style={{width: '100%', display: 'flex', justifyContent: 'space-between', padding: 0}}>
                                <Row style={rowBtnsStyle}>
                                    <ListGroup id={payment.id} onClick={this.props.changePaymentStateMobile} className="paymentTypeList">
                                        {paymentBtnContent}
                                        <span style={this.props.isArabic ? {marginRight: 5} : {marginLeft: 5}} >{i18n.t(`screens:checkoutScreen.${paymentButtonText}`)}</span>
                                    </ListGroup>
                                </Row>
                                <Row id={"tick_" + payment.id} className="paymentTypeTickDisable">
                                    <span>{paymentIconImage}</span>
                                </Row>
                            </Col>
                })
            }

            <Col style={{width: '100%', display: 'flex', justifyContent: 'space-between', padding: 0}} >
                <Row style={rowBtnsStyle}>
                    <ListGroup  id={appleGoolePayPaymentId} onClick={this.props.changePaymentStateMobile} className="paymentTypeList">
                        <span>{btnContent}</span>
                    </ListGroup>
                </Row>
                <Row id={"tick_" + appleGoolePayPaymentId} className={isAppleGPaySelectedByDefault ? "paymentTypeTick" : "paymentTypeTickDisable"}>
                    <span>{paymentIconImage}</span>
                </Row>
            </Col>
        </React.Fragment>
    }

    renderCustomerSavedPaymentCards(isApplePay) {

        const { listGroupStyle, listGroupActiveStyle, expiredCard, availableCard, alignRight, cardBrand, cardNumber } = this.props.styles;
        const { customer, payment, customerThemes } = this.props;
        const { type, secondaryColor } = customerThemes.selectedTheme;
        const iconImage = updateIconsAccordingToTheme(type, 'check');

        const isPayCashSelected = (this.props.paymentType == '' || this.props.paymentType == 'Cash') ? true : false;
        const listItemStyle = (this.props.activeClass) ? listGroupActiveStyle : listGroupStyle;
        const isLandscapeMode = window.outerHeight <= 414;
        const mobileStars = (window.outerWidth <= 575) ? ' **** ' : (isLandscapeMode) ? ' **** ' : ' **** **** **** ';

        let currentMinimumOrderAmount = this.props.shoppingCart.appliedPromotions.length ? 1 : this.props.selectedRestaurant.minimumOrderAmount;
        const tbkPaymentProvider = this.props.paymentProvider.providerType && this.props.paymentProvider.providerType.id === 9 ? true : false;

        if (!isApplePay && this.props.customer.savedPaymentCards !== undefined && this.props.customer.savedPaymentCards !== null
            && this.props.customer.savedPaymentCards.length > 0 && this.props.payment.selectedPaymentType.value === 'Credit card'
            && this.props.shoppingCart.total >= currentMinimumOrderAmount) {
            return (
                <Col sm={12} hidden={isPayCashSelected} className="userSavedCardsList" style={{ padding: 0 }}>
                    <ListGroup flush style={{ width: '100%' }}>
                        {
                            customer.savedPaymentCards.map((card, i) => {
                                const currentCardActive = (card.paymentCardId == payment.selectedPaymentCard.paymentCardId) ? listItemStyle : null;
                                const isCardExpired = isCardAlreadyExpired(card);
                                const listItemIcon = (isCardExpired) ? <span style={expiredCard} className='useThisCard'>{i18n.t(`screens:checkoutScreen.expired`)}</span>
                                    : (this.props.activeClass && card.paymentCardId == payment.selectedPaymentCard.paymentCardId) ? <span style={{ float: 'right' }}><img src={iconImage} width={26} style={alignRight} /></span>
                                        : <span style={availableCard} className='useThisCard' onClick={tbkPaymentProvider ? () => this.props.toggleDeleteCardDialog(card) : null}>{!tbkPaymentProvider ? i18n.t(`screens:checkoutScreen.useThisCard`) : <MaterialIcon icon="delete" color={secondaryColor} />}</span>;

                                return (
                                    <ListGroupItem key={`card${i}`} style={Object.assign({}, {...listGroupStyle, ...currentCardActive, cursor: tbkPaymentProvider ? 'default' : 'pointer'})} onClick={() => !tbkPaymentProvider ? this.props.selectCard(card) : null} className={(isCardExpired) ? 'cardExpired' : ''} disabled={isCardExpired}>
                                        <span className="creditCardNameCheckout" style={cardBrand}>{card.brand}</span>
                                        <span style={cardNumber}>{mobileStars + card.lastDigits + ' '}</span>
                                        {listItemIcon}
                                    </ListGroupItem>
                                );
                            })
                        } 
                    </ListGroup>
                </Col>
            );
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderPaymentType()}
            </Fragment>
        )
    }
}
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import moment from "moment/moment";
import i18n from "../../../i18next";
import _ from "lodash";
import ReactCountryFlag from 'react-country-flag';

import '../../styles/MainPwaScreen.css';
import { updateCSSVariables } from "../../../utils/ThemesSelector";
import {
    getOrderTypes,
    loadCustomerFromLocalStorage,
    loadOrderTypeData,
    saveCustomerDeliveryAddress,
    saveStartOrderTime,
    loadCustomerSavedDeliveryAddresses,
    setApplicationType,
    openChangeOrderTimeScreen,
    saveMapZoom,
    resetSendingOrderModal,
    registerAppDevice,
    changeAppleGooglePayAvailability,
    changeLanguage
} from "../../../actions";
import { initiateAppDevice } from "../../utils/FirebaseUtils";
import {
    setLastOpenedPagePWA,
    formatCountryCodeToCountry,
    formatLanguageCode
} from "../../../utils/CommonUtils";
import {
    COLLECTION_ID,
    DELIVERY_ID,
    PWA_APP,
    IN_STORE_ID,
    CURBSIDE_ID,
    ARABIC
} from '../../../utils/Constants';
import ApplicationStep from '../common/ApplicationStep';
import Map from './map/Map';
import OfflineMap from './map/OfflineMap';
import MapMarker from './map/MapMarker';
import DeliverySVG from '../common/svgComponents/DeliverySVG';
import ShoppingCartSVG from '../common/svgComponents/ShoppingCartSVG';
import CurbsideSVG from '../common/svgComponents/CurbsideSVG';

let lastTimeBackPress = 0;
let timePeriodToExit = 2000;

let getOrderTypesServiceLoaded = false;

class MainScreenPwa extends Component {

    constructor(props) {
        super(props);

        const userAgent = window.navigator.userAgent;
        let userOnMobileIos = userAgent.match(/iPhone; CPU iPhone OS/) || userAgent.match(/CPU iPhone/) || userAgent.match(/iPad;/);

        this.state = {
            selectedAddress: this.props.userAddress.fullAddress,
            // Added formattedSelectedAddress in userAddress reducer to be able to load it even if reverse geolocation of address is not succesfull
            formattedSelectedAddress: this.props.userAddress.formattedSelectedAddress,
            userAddress: {
                fullAddress: this.props.userAddress.fullAddress,
                street: this.props.userAddress.street,
                streetNumber: this.props.userAddress.streetNumber,
                floor: this.props.userAddress.floor,
                apartment: this.props.userAddress.apartment,
                city: this.props.userAddress.city,
                country: this.props.userAddress.country,
                longitude: this.props.userAddress.longitude,
                latitude: this.props.userAddress.latitude,
                postCode: this.props.userAddress.postCode,
                zipCode: this.props.userAddress.zipCode,
                details: this.props.userAddress.details
            },
            geolocationRunning: false,
            iOSDetected: userOnMobileIos,
            userPositionCoordinates: null,
            useGeocoder: 'DEFAULT',
            makeGeocodingApiCall: false,
            defaultLanguage: 'en'
        };

        this.onBackKeyDown = this.onBackKeyDown.bind(this);
        this.handleAddressData = this.handleAddressData.bind(this);
        this.handleMapChange = this.handleMapChange.bind(this);
        this.getGeolocationOnUserInteraction = this.getGeolocationOnUserInteraction.bind(this);
        this.centerMapOnUserPosition = this.centerMapOnUserPosition.bind(this);
    }

    componentWillMount() {
        // Get the brandId from the browser url
        let urlHash, urlHashItems, brandId;

        urlHash = window.location.hash;
        urlHashItems = urlHash.split('/');

        for (let i = 0; i <= urlHashItems.length; i++) {
            if (urlHashItems[i] === 'brand') {
                brandId = urlHashItems[i + 1];
            }
        }

        this.props.getOrderTypes(brandId, () => {
            getOrderTypesServiceLoaded = true;

            // Run geolocation for the first time when we load the application -> if we have redirectFromSplash property from props history
            if (this.props.history.location && this.props.history.location.state &&
                this.props.history.location.state.redirectFromSplash) {

                this.setState({
                    ...this.state,
                    geolocationRunning: true
                });

                this.navigatorGeolocation();
            } else {
                this.setState({
                    ...this.state,
                    userPositionCoordinates: {
                        lat: this.props.customer.selectedDeliveryAddress.latitude,
                        lng: this.props.customer.selectedDeliveryAddress.longitude
                    }
                });
            }
        });

        this.props.loadCustomerFromLocalStorage();

        const currentTime = moment();
        this.props.saveStartOrderTime(currentTime);

        // If we have logged in customer load his saved addresses
        // if (this.props.customer.id && this.props.customer.id !== -1) {
        //     this.props.loadCustomerSavedDeliveryAddresses();
        // }
    }

    componentDidMount() {
        const totalWithGiftCardIsZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 : false;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        this.props.resetSendingOrderModal();

        //clear apple/google pay state
        if (!totalWithGiftCardIsZero && !isAppliedGiftCards){
            this.props.changeAppleGooglePayAvailability(null);
        } 

        if (this.state.iOSDetected) {
            const pwaMainScreenContainer = document.getElementById('pwaMainScreenContainer');
            pwaMainScreenContainer.ontouchmove = (e) => { e.preventDefault() };
        }
        this.props.setApplicationType(PWA_APP);

        this.applyTheme();

        setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);

        document.addEventListener("backbutton", this.onBackKeyDown, false);

        initiateAppDevice(this.props.match.params.id, (brandId, token, uuid, platform) => {
            this.props.registerAppDevice(brandId, token, uuid, platform);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const markerContainerElement = document.getElementById('markerContainer');

        if (this.state.iOSDetected) {
            this.throttledMainScreenMapFunction();
        }

        // Reset the component when network connection is changed
        if (prevProps.userOnline !== this.props.userOnline) {
            this.componentWillMount();
            this.componentDidMount();
        }

        // Handle location animation
        if (prevState.geolocationRunning !== this.state.geolocationRunning) {
            (this.state.geolocationRunning) ? markerContainerElement.classList.add('geolocationAnimation') : markerContainerElement.classList.remove('geolocationAnimation');
        }

        // If we have logged in customer load his saved addresses
        if ((this.props.customer.token !== prevProps.customer.token) && this.props.customer.token) {
            this.props.loadCustomerSavedDeliveryAddresses();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        if (new Date().getTime() - lastTimeBackPress < timePeriodToExit) {
            navigator.app.exitApp();
        } else {
            window.plugins.toast.showWithOptions(
                {
                    message: "Press again to exit.",
                    duration: "short", // which is 2000 ms. "long" is 4000. Or specify the nr of ms yourself.
                    position: "bottom",
                    addPixelsY: -40  // added a negative value to move it up a bit (default 0)
                }
            );

            lastTimeBackPress = new Date().getTime();
        }
    }

    toggleLanguageButton(language) {
        this.props.changeLanguage(language)
    }

    goToConfirmAddress() {
        let brandId = Number(this.props.brandId);
        this.props.history.push('/brand/' + brandId + '/start-order/confirm-address');
    }

    throttledMainScreenMapFunction = _.throttle(() => {
        const pwaMainScreenMap = document.getElementById('pwaMainScreenMap');
        if (pwaMainScreenMap) {
            pwaMainScreenMap.style.cssText = 'position: relative; overflow: visible!important;';
        }
    }, 1000);

    navigatorGeolocation() {

        const success = (position) => {
            let makeGeocodingApiCall = false;
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;

            const userPositionCoordinates = {
                lat: latitude,
                lng: longitude
            };

            if (position.coords.latitude !== this.props.userAddress.latitude
                || position.coords.longitude !== this.props.userAddress.longitude) {
                makeGeocodingApiCall = true;
            }

            this.props.saveMapZoom(17);

            this.setState({
                ...this.state,
                geolocationRunning: false,
                userPositionCoordinates,
                useGeocoder: 'ALLOWED',
                makeGeocodingApiCall
            });
        };

        const error = (err) => {
            // Show permission Denied message or Location not found message
            if (err.code == err.PERMISSION_DENIED) {
                window.alert(i18n.t('screens:userAddressScreen.locationPromptMessage'));
                this.setState({
                    ...this.state,
                    useGeocoder: 'DECLINED'
                });
            } else {
                window.alert(i18n.t('screens:userAddressScreen.locationNotFoundMessage'));
                this.setState({
                    ...this.state,
                    useGeocoder: 'DECLINED'
                });
            }
        };

        navigator.geolocation.getCurrentPosition(success, error, { timeout: 30000, enableHighAccuracy: true });
    }

    handleMapChange(map) {

        let markerCoordinates = this.getCenterMapAndGetMarkerPosition(map);
        // The method below uses the 'geometry' library form the Google MAPS API
        let deliveryAddressInProximity = this.getDeliveryAddressesInProximityToCurrentAddressLocation(markerCoordinates);

        if (this.props.customerSavedAddresses && deliveryAddressInProximity.length) {
            // If any delivery address is in proximity to the current address -> load the map on the previously saved delivery address
            // Sort the deliveryAddresses by distance in ascending order
            deliveryAddressInProximity.sort((a, b) => {
                return a.distance - b.distance
            });
            // Get the closest address
            let closestAddressInProximity = deliveryAddressInProximity[0];

            let closestPositionCoordinates = {
                lat: closestAddressInProximity.latitude,
                lng: closestAddressInProximity.longitude
            };
            // Load the map on previously saved closest delivery address
            markerCoordinates = closestPositionCoordinates;

            map.setCenter(markerCoordinates);
        }

        this.getAddressFromUserCords(markerCoordinates);
    }

    getCenterMapAndGetMarkerPosition(map) {
        // Get the center of the map to get the address the user is pointing to
        let centeredMap = map.getCenter();
        // Get the current map zoom and save it in the map zoom reducer
        let mapZoomLevel = map.getZoom();
        this.props.saveMapZoom(mapZoomLevel);

        return {
            lat: centeredMap.lat(),
            lng: centeredMap.lng()
        };
    }

    getDeliveryAddressesInProximityToCurrentAddressLocation = (markerCoordinates) => {
        let deliveryAddressInProximity = [];
        if (!this.props.customerSavedAddresses.length) return deliveryAddressInProximity;

        this.props.customerSavedAddresses.map((address) => {
            let markerPosition = new window.google.maps.LatLng(markerCoordinates.lat, markerCoordinates.lng);
            let savedAddressPosition = new window.google.maps.LatLng(address.latitude, address.longitude);

            // Calculates distance between two points in meters
            const calcDistanceToSavedAddress = (markerPosition, savedAddressPosition) => {
                return (window.google.maps.geometry.spherical.computeDistanceBetween(markerPosition, savedAddressPosition) / 1).toFixed(2);
            };

            let distance = calcDistanceToSavedAddress(markerPosition, savedAddressPosition);

            if (distance < 30) {
                let addressData = {
                    latitude: address.latitude,
                    longitude: address.longitude,
                    distance: distance
                };

                deliveryAddressInProximity.push(addressData)
            }

        });

        return deliveryAddressInProximity;
    };

    getAddressFromUserCords(markerPositionCords) {

        const geocoder = new window.google.maps.Geocoder();

        let latlng = new window.google.maps.LatLng(markerPositionCords.lat, markerPositionCords.lng);

        geocoder.geocode({ 'latLng': latlng }, (results, status) => {

            if (status === window.google.maps.GeocoderStatus.OK) {

                this.handleAddressData(results, markerPositionCords);
            }
        });
    }

    handleAddressData(googleApiResponseData, markerPositionCords) {

        let responseAddress = googleApiResponseData[0].formatted_address;
        // Get Address data to save it in reducer if no addressParts DO NOT save address to reducer
        let addressParts = this.getAddressParts(googleApiResponseData[0], markerPositionCords);

        if (!addressParts) {
            return;
        }

        let address = this.getAddressData(responseAddress, addressParts, markerPositionCords);

        this.props.saveCustomerDeliveryAddress(address);

        this.setState({
            ...this.state,
            selectedAddress: responseAddress,
            formattedSelectedAddress: addressParts.formattedSelectedAddress,
            userAddress: address,
            geolocationRunning: false
        });
    }

    getAddressParts(place, markerPositionCords) {

        let addressParts = {
            streetNumber: null,
            streetName: null,
            city: null,
            country: null,
            postCode: null,
            formattedSelectedAddress: null,
        };

        place.address_components.map((component) => {

            component.types.map((type) => {
                if (type === "street_number") {
                    addressParts.streetNumber = component.long_name
                }
                if (type === "route") {
                    addressParts.streetName = component.long_name
                }
                if (type === "locality" || type === 'sublocality') {
                    addressParts.city = component.long_name
                }
                if (type === "country") {
                    addressParts.country = component.long_name
                }
                if (type === "postal_code") {
                    addressParts.postCode = component.long_name
                }

                if (!addressParts.city && type === 'postal_town') {
                    addressParts.city = component.long_name;
                }
            })

        });

        // If no match for address street name search for neighborhood
        if (!addressParts.streetName) {
            place.address_components.map((component) => {

                if (!component.types.length) return;

                component.types.map((type) => {
                    if (type === "neighborhood") {
                        addressParts.streetName = component.long_name
                    }
                })

            });
        }

        // TODO VT if problems with address the problem most probably is with the code below to return null statement
        // Address geolocation returns bad result containing no address_components that can be used try to get new result for coordinates near by the current coordinates
        const reverseGeolocationBadResultForCurrentMarkerPosition = !addressParts.streetName && !addressParts.streetNumber && !addressParts.city;
        if (reverseGeolocationBadResultForCurrentMarkerPosition) {
            let nearbyAddressCoordinates = {
                lat: markerPositionCords.lat + 0.0000010,
                lng: markerPositionCords.lng + 0.0000010
            };

            this.getAddressFromUserCords(nearbyAddressCoordinates);

            return null;
        }

        // Set the formattedSelectedAddress so we can access it from props even if reverse geolocation is not being succesfull
        let streetNumber = (addressParts.streetNumber) ? addressParts.streetNumber : '';
        let streetName = (addressParts.streetName) ? addressParts.streetName : '';
        addressParts.formattedSelectedAddress = streetNumber + ' ' + streetName;

        return addressParts;
    }

    getAddressData(fullAddress, addressParts, addressCords) {
        let newAddress = {
            fullAddress: fullAddress,
            formattedSelectedAddress: addressParts.formattedSelectedAddress,
            streetNumber: addressParts.streetNumber,
            street: addressParts.streetName,
            floor: null,
            apartment: null,
            city: addressParts.city,
            country: addressParts.country,
            longitude: addressCords.lng,
            latitude: addressCords.lat,
            postCode: addressParts.postCode,
            zipCode: addressParts.postCode,
            details: null
        };

        return newAddress;
    }

    getGeolocationOnUserInteraction(map) {
        let newCoords = null;

        const success = (position) => {
            newCoords = { lat: position.coords.latitude, lng: position.coords.longitude };
            this.centerMapOnUserPosition(map, newCoords);
        }

        const error = () => {
            this.geocodeBrandCityOrCountry(map)
        };

        navigator.geolocation.getCurrentPosition(success, error, { timeout: 30000, enableHighAccuracy: true });
    }

    centerMapOnUserPosition(map, userPositionCoordinates) {
        // Set the map center on user position and get the name of the address from the cords
        // Do reverse geocoding 
        this.getAddressFromUserCords(userPositionCoordinates);
        map.setCenter(userPositionCoordinates);
        map.setZoom(17);
        this.props.saveMapZoom(17);
    }

    geocodeBrandCityOrCountry(map) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ 'address': this.props.brandCity ? this.props.brandCity : this.props.brandCountry }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {

                const positionFromGoogleApi = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                };

                this.handleAddressData(results, positionFromGoogleApi);

                map.setCenter(positionFromGoogleApi);
            }
        });
    }

    renderSwitchLanguageButtons() {

        const { selectedLanguage, isDefaultLanguage } = this.props;
        const { defaultLanguage } = this.state;
        const { fontFamilyType, fontBold, fontItalic, uppercase, buttonsFont, primaryColor } = this.props.customerThemes.selectedTheme;
        const defaultLanguageCountryCode = `${defaultLanguage === 'en' ? 'EN' : defaultLanguage.toUpperCase()}`

        const languageBtnTextStyle = {
            fontSize: '15px',
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase,
            color: buttonsFont
        }

        return (
            <div style={{ position: 'absolute', top: '15px', left: '20px' }}>
                <Button
                    style={{ height: '30px', display: 'flex', alignItems: 'center', backgroundColor: primaryColor, border: 'none' }}
                    onClick={() => this.toggleLanguageButton(isDefaultLanguage ? selectedLanguage : defaultLanguage)}>
                    <span style={languageBtnTextStyle}>{isDefaultLanguage ? selectedLanguage.toUpperCase() : defaultLanguageCountryCode}</span>
                </Button>
            </div>
        )
    }

    renderContent() {
        const { customerThemes, userOnline, pwaMapZoomLevel, customerSavedAddresses } = this.props;

        return (
            <React.Fragment>
                <div id="pwaMainScreenContainer">
                    <MapMarker
                        selectedTheme={customerThemes.selectedTheme}
                        formattedSelectedAddress={this.state.formattedSelectedAddress} />
                    {   // If we are Online render the map if NOT render image of map
                        userOnline
                            && (this.state.userPositionCoordinates || this.state.useGeocoder !== 'DEFAULT') ?
                            <Map
                                id='pwaMainScreenMap'
                                options={{
                                    center: this.state.userPositionCoordinates,
                                    zoom: pwaMapZoomLevel,
                                    draggable: true,
                                    disableDefaultUI: true,
                                    clickableIcons: false,
                                    gestureHandling: 'greedy'
                                }}
                                useGeocoder={this.state.useGeocoder}
                                brandCity={this.props.brandCity}
                                brandCountry={this.props.brandCountry}
                                handleAddressData={this.handleAddressData}
                                handleMapChange={this.handleMapChange}
                                centerMapOnUserPosition={this.getGeolocationOnUserInteraction}
                                customerSavedAddresses={customerSavedAddresses}
                                hasFullAddress={this.props.customer.selectedDeliveryAddress.fullAddress && !this.state.makeGeocodingApiCall}
                                pwaAppRunning={this.props.pwaAppRunning}
                            /> : null
                    }
                    <OfflineMap userOnline={userOnline} />
                    {this.renderDeliveryOptionButtons()}
                </div>
            </React.Fragment>
        );
    }

    renderDeliveryOptionButtons() {
        const { primaryColor, secondaryColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;

        // Fix for the problem with delivery buttons not being loaded with bg color -> when the styles for MainScreenPwa.css are loaded we do not have data from
        // getOrderTypes service and the variables for background color in the styles are not set the background color here when we have data from getOrderTypes
        let orderBtnsAdditionalStyle = { backgroundColor: 'transparent' };
        if (secondaryColor && getOrderTypesServiceLoaded) orderBtnsAdditionalStyle = { backgroundColor: primaryColor };

        const orderTypesBtnStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase,
            color: buttonsFont
        }

        return (
            <div id="deliveryOptionButtonsContainer">
                {this.props.orderTypes.map((type) => {
                    const orderTypeNotSupported = type.value === "Dine In";
                    const isDisabled = orderTypeNotSupported || !this.props.userOnline;

                    const calledFromPwa = {
                        brandId: Number(this.props.brandId),
                        calledFrom: 'MainScreen'
                    };

                    if (type.id === IN_STORE_ID) {
                        return null;
                    }

                    return (
                        <Button key={`orderTypeBtns${type.value}`} className='orderTypeBtns' style={orderBtnsAdditionalStyle}
                            onClick={() => this.props.loadOrderTypeData(type, calledFromPwa)} disabled={isDisabled}>
                            {this.renderButtonIcon(type)}
                            <span
                                style={orderTypesBtnStyle}>{i18n.t(`screens:selectOrderTypeScreen.${type.value.toLowerCase().replace(' ', '')}`)}</span>
                        </Button>
                    );
                })}
            </div>
        );
    }

    renderButtonIcon(orderType) {
        const { buttonsFont } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        switch (orderType.id) {
            case DELIVERY_ID:
                return (
                    <DeliverySVG
                        width={37}
                        height={37}
                        fill={buttonsFont}
                        style={isArabic ? { paddingBottom: 8, transform: 'scaleX(-1)' } : { paddingBottom: 8 }} />
                );
            case COLLECTION_ID:
                return (
                    <ShoppingCartSVG
                        width={27}
                        height={27}
                        fill={buttonsFont}
                        style={{ marginBottom: 2 }} />
                );
            case CURBSIDE_ID:
                return (
                    <CurbsideSVG
                        width={37}
                        height={37}
                        viewBoxNewValues={'0 0 130.7 97.2'}
                        fill={buttonsFont} />
                );
            default:
                return;
        }
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    render() {
        const { selectedLanguage } = this.props;

        return (
            <React.Fragment>
                <ApplicationStep
                    header={true}
                    hideBackButton={true}
                    transparentHeader={true}
                    content={this.renderContent()}
                    drawerColor={'#363636'}
                    isHamburgerShadowed={false}
                    isMainScreen={true}
                    switchLanguageButtons={selectedLanguage !== 'en' ? this.renderSwitchLanguageButtons() : null}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderTypes: state.orderTypes.orderTypes,
        userOnline: state.userOnline,
        customerSavedAddresses: state.customerSavedAddresses,
        lastOpenedPage: state.temporaryOrderTime.currentPagePath,
        brandId: state.brand.id,
        pwaMapZoomLevel: state.pwaMapZoomLevel,
        brandCity: state.brand.city,
        brandCountry: formatCountryCodeToCountry(state.brand.countryCode),
        userAddress: state.customer.selectedDeliveryAddress,
        customer: state.customer,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language,
        selectedLanguage: state.storeLocalStorage.selectedLanguage,
        isDefaultLanguage: state.storeLocalStorage.isDefaultLanguage,
        giftCards: state.giftCards,
        pwaAppRunning: state.pwaAppRunning
    };
};

export default connect(mapStateToProps, {
    getOrderTypes,
    loadOrderTypeData,
    loadCustomerFromLocalStorage,
    saveCustomerDeliveryAddress,
    saveStartOrderTime,
    loadCustomerSavedDeliveryAddresses,
    setApplicationType,
    openChangeOrderTimeScreen,
    saveMapZoom,
    resetSendingOrderModal,
    registerAppDevice,
    changeAppleGooglePayAvailability,
    changeLanguage
})(MainScreenPwa);
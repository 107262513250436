import React from 'react';

const DeliverySVG = ({ width, height, fill, style }) => {
	const viewBoxValues = "0 0 133.9 80.2";
	
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style}>
			<g>
				<g>
					<path fill={fill} d="M47,2.7l5.8-2.6c0.7-0.3,1.5,0,1.8,0.7c0.3,0.7,0,1.5-0.7,1.8l-5.7,2.6C47.7,4.4,47.2,3.4,47,2.7z"/>
				</g>
				<path fill={fill} d="M33.2,15.8c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2l0.5-7.4c0-0.2-0.1-0.3-0.3-0.3l-0.2,0
					c-1.6,0-2.9,1.7-3.1,3.8C30.4,13.8,31.6,15.7,33.2,15.8C33.2,15.8,33.2,15.8,33.2,15.8z"/>
				<path fill={fill} d="M128.3,45.5c1.5,0,3.1,0.3,3.9,1.7c1.2,2.1,2.6,4.7,1,5.8c-0.9,0.6-1.9,1.2-2.6,1.5c-1.1-3-2.6-6.2-4.7-8.7
					C126.4,45.6,127.3,45.5,128.3,45.5z"/>
				<path fill={fill} d="M88.8,62.5c0,0.1,31.8,0,31.8,0c-0.1-0.8-2.3-12-10.8-16.8c-4.4-2.5-11-2.8-16.1-0.8c-3.3,1.3-5.2,3.5-6.1,6.1
					C86.7,54,86.8,59.9,88.8,62.5z M96.7,59h14.7v0.7H96.7V59z M96.7,56.2h14.7v0.7H96.7V56.2z M96.7,53.4h14.7v0.7H96.7V53.4z"/>
				<path fill={fill} d="M23.8,41.6c0.9-1.4,12.4-18.2,15-23.3c0.9-1.8-1.3-1.7-3.2-2.5c0-0.1,0.1-0.2,0.1-0.2l0.5-7.4
					c0-0.1,0-0.3-0.1-0.4C37.5,7,39.3,6.4,40.7,5c0.6-0.6,1-2.1,2-2.4c2.2-0.5,2.5,0.8,2.9,1.8c1.2,2.6,2.6,4.2,4.1,5
					c4.9,2.7-0.3,2.7-1.8,5.5c-1.3,2.3-1.6,2.8-2.3,5.8c-0.7,3-1.4,5.5-2.7,12.5c-1.3,7.1,3.1,20.2,7.1,22.7c2.6,1.6,4.1,3.4,10.9,3.4
					c12.6,0,17.1-3.3,17.1-11c0-7.7-13.5-16.1-13.5-16.1l51.8,2.4c0,0-0.6,4.1,5.6,9.4c0.5,0.4,1,0.9,1.4,1.4c0.1,0.1,0.1,0.2,0.2,0.2
					c0.1,0.1,0.1,0.2,0.2,0.3c2.1,2.5,3.6,5.8,4.7,8.7c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3c1.4,4,2,7.3,2,7.3h-7.4
					c0,0,0,0,0,0c0-0.1-2.3-13.5-12.3-19.2c-5.1-2.9-12.5-3.3-18.4-1c-3.9,1.5-6.6,4.1-7.5,7.2c-1.1,3.4-1.1,8.6,0.8,13
					c0.5,1.1,0.5,1.1,0.5,1.1l-42.5,0c0-1.4-0.3-3-0.6-4.4c-2.2-9.4-10.2-16.8-19.3-17.5C23.8,41.7,23.8,41.6,23.8,41.6z"/>
				<g>
					<path fill={fill} d="M0,63.6C0,54.4,6.3,47,15.7,44.8C26.6,42.3,37.4,49.1,40,60c0.4,1.5,0.5,2.1,0.5,3.6H0z"/>
				</g>
				<g>
					<g>
						<path fill={fill} d="M96.9,64.6c-0.2,1.2-0.2,2.4,0.1,3.6c1.1,4.7,5.8,7.6,10.4,6.5c4.6-1.1,7.4-5.6,6.6-10.1h5.4
							c0.8,7-3.7,13.6-10.7,15.3c-7.5,1.8-15-2.9-16.8-10.5c-0.4-1.6-0.4-3.2-0.3-4.8H96.9z"/>
					</g>
					<g>
						<path fill={fill} d="M109.1,64.6c0.1,0.2,0.2,0.5,0.3,0.8c0.5,2.1-0.8,4.2-2.9,4.7c-2.1,0.5-4.2-0.8-4.7-2.9
						c-0.2-0.9-0.1-1.8,0.3-2.5H109.1z"/>
					</g>
				</g>
				<g>
					<path fill={fill} d="M53.5,12.9l6.1,2.6c0.8,0.3,1.2,1.3,0.8,2.1c-0.3,0.8-1.3,1.2-2.1,0.8l-7.6-3.2C51.7,14,53,13.2,53.5,12.9z" />
				</g>
				<path fill={fill} d="M118.4,32.7c0.1,0,0.3-0.1,0.3-0.2c0.1-0.3,1.6-8.2-2.3-9.5c-7-2.2-17.8-2.8-25.6-2.8
					c-12.1-0.1-23.7,1-25.3,1.7c-2.8,1.2-2.5,7.5-2.5,7.8c0,0.2,0.1,0.3,0.3,0.3C63.3,29.9,118.3,32.7,118.4,32.7z"/>
				<path fill={fill} d="M17,65.9c0,0.4,0,0.8,0.1,1.2c0.5,2.1,2.6,3.4,4.7,2.9c1.9-0.4,3.2-2.2,3-4.1H17z"/>
				<path fill={fill} d="M29.6,65.9c0.1,4.1-2.6,7.8-6.7,8.8c-4.7,1.1-9.4-1.8-10.4-6.5c-0.2-0.8-0.2-1.5-0.2-2.3H7
					c0,1.1,0,2.3,0.3,3.5c1.8,7.5,9.3,12.2,16.8,10.5c6.6-1.5,10.9-7.5,10.8-13.9H29.6z"/>
			</g>
		</svg>
	);
}

export default DeliverySVG;
import React, { Component } from 'react'
import ApplicationStep from "../../common/ApplicationStep";
import UserCredentialsCommon from "./UserCredentialsCommon";
import i18n from "../../../../i18next";

export default class UserCredentialsPWA extends Component {
    componentWillMount() {
        this.applyTheme();
        this.props.loadCaptcha();
    }

    componentWillUnmount() {
        this.props.resetErrMsg()
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, textDecoration, startOrderFontSize, mainTextColor, chefNoteBackground } = this.props.customerThemes.selectedTheme;

        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.submitBtn.backgroundColor = primaryColor;
        customizedStyles.privacyPolicy.color = primaryColor;
        customizedStyles.privacyPolicy.textDecoration = textDecoration;
        customizedStyles.subHeading.color = mainTextColor;
        customizedStyles.input.backgroundColor = chefNoteBackground;
        customizedStyles.termsAndConditions.color = primaryColor;
        customizedStyles.termsAndConditions.textDecoration = textDecoration;
        styles = customizedStyles;
        return styles;
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        
        return (
            <ApplicationStep
                hideBackButton={false}
                transparentHeader={false}
                text={i18n.t('screens:phoneNumberScreen.verifySignUp')}
                goBack={this.goBack.bind(this)}
                content={<UserCredentialsCommon
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                    customer={this.props.customer}
                    registerCustomer={this.props.registerCustomer}
                    isPWA={true}
                    resetErrMsg={this.props.resetErrMsg}
                    brand={this.props.brand}
                    resetErrMsg={this.props.resetErrMsg}
                    loadCaptcha={this.props.loadCaptcha}
                />}
            />
        );
    }
}

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72
    },
    login: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    loginTextStyle: {
        marginLeft: -10,
        verticalAlign: 'middle'
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 14,
        marginBottom: 0,
        color: '#939393',
        paddingBottom: 12,
    },
    submitBtn: {
        display: 'flex',
        justifyContent: 'center',
        height: 50,
        borderRadius: 0,
        padding: 12,
        width: '100%',
        marginBottom: 38,
        border: 'none',
        backgroundColor: '#61B522'
    },
    submitBtnDisabled: {
        display: 'flex',
        justifyContent: 'center',
        height: 50,
        borderRadius: 0,
        padding: 12,
        width: '100%',
        marginBottom: 38,
        border: 'none',
        backgroundColor: '#a8a8a8'
    },
    marginAuto: {
        margin: 'auto',
        position: 'relative',
        bottom: 20,
        top: 0
    },
    inputGroup: {
        margin: 'auto',
        height: 50,
        marginBottom: 10
    },
    input: {
        height: 50,
        backgroundColor: "#e9ecef",
        textAlign: 'center'
    },
    inputGroupTickbox: {
        margin: 'auto',
        marginBottom: 12,
        backgroundColor: '#e9ecef',
        borderRadius: 4
    },
    inputGroupText: {
        alignSelf: 'flex-start',
        padding: '18px 8px 6px 16px',
        border: 'none'
    },
    label: {
        padding: '12px 16px 5px 0',
        textAlign: 'left',
        whiteSpace: 'normal',
        fontSize: 14
    },
    privacyPolicy: {
        color: '#61B522',
        fontWeight: 700,
        cursor: 'pointer'
    },
    imageStyle: {
        paddingRight: 18,
        marginTop: 3
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 22,
        textAlign: 'center'
    },
    modalCustomSubmitBody: {
        display: 'flex',
        justifyContent: 'center',
        margin: '50px 0 0 0'
    },
    phoneIconStyle: {
        position: 'absolute',
        padding: '8px 5px',
        marginLeft: 75,
        zIndex: 1
    },
    phoneIconStyleRTL: {
        position: 'absolute',
        padding: '8px 5px',
        marginRight: 70,
        zIndex: 1
    },
    checkIconStyle: {
        position: 'absolute',
        padding: 4,
        marginLeft: -30,
        zIndex: 1,
        marginTop: 10
    },
    checkIconStyleRTL: {
        position: 'absolute',
        padding: 4,
        marginRight: -30,
        zIndex: 1,
        marginTop: 10
    },
    subMsg: {
        fontSize: 14,
        color: '#9a9a9a',
        marginBottom: 5
    },
    termsAndConditions: {
        color: "#61B522",
        cursor: 'pointer'
    }
}
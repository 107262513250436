import {
    CLEAR_APP_STATE,
    CLEAR_ORDER_WIZARD,
    CLEAR_STATE,
    SAVE_RESTAURANT_MENUS,
    SELECT_RESTAURANT_MENU_ID,
    SELECTED_MENU_ARRAY_INDEX
} from "../../actions/types";

const INITIAL_STATE = {
    selectedMenuId: -1,
    menus: [],
    selectedMenuArrayIndex: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_RESTAURANT_MENUS:
            return { ...state, menus: action.payload };
        case SELECT_RESTAURANT_MENU_ID:
            return { ...state, selectedMenuId: action.payload };
        case CLEAR_ORDER_WIZARD:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_STATE:
            // if (action.fromPwa) {
            //     return state;
            // } else {
                return INITIAL_STATE;
            // }
        case SELECTED_MENU_ARRAY_INDEX:
            return {...state, selectedMenuArrayIndex: action.payload};
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}

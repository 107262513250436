import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../../i18next';

import OrderConfirmationScreen from '../../../../components/order/OrderConfirmationScreen';
import ApplicationStep from '../../common/ApplicationStep';

class OrderConfirmationScreenPwa extends Component {

    constructor(props) {
        super(props);

    }

    renderContent() {

        return (
            <React.Fragment>
                <div>
                    <OrderConfirmationScreen renderInPwa={true} history={this.props.history} />
                </div>
            </React.Fragment>
        );
    }

    render() {

        return (
            <React.Fragment>
                <ApplicationStep
                    header={true}
                    hideBackButton={true}
                    transparentHeader={false}
                    content={this.renderContent()}
                    text={i18n.t('screens:orderConfirmationScreen.orderConfirmation')}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        brandId: state.brand.id,
    };
};

export default connect(mapStateToProps, {})(OrderConfirmationScreenPwa);
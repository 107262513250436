import React, { Component } from "react";
import { connect } from 'react-redux';
import { Input, Col, Row, Container } from 'reactstrap';
import i18n from "../../../i18next";

import { INPUT_FIELDS_BG_COLOR } from '../../../utils/StylesConstants';
import TickSVG from '../../../pwa-app/components/common/svgComponents/TickSVG';
import { ARABIC } from "../../../utils/Constants";

class CarDetailsContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            makeBy: '',
            model: '',
            plate: '',
            color: '',
        };

    }

    componentWillMount() {
        this.applyTheme()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.state !== this.props.state) {
            this.setState({
                ...this.state,
                makeBy: this.props.state.makeBy,
                model: this.props.state.model,
                plate: this.props.state.plate,
                color: this.props.state.color,
            })
        }
    }

    handleInputChange({ target }) {
        this.setState({ ...this.state, [target.name]: target.value }, () => this.props.updateCarDetailsInParent(this.state));
    }

    renderCarDetailsForm() {
        const { inputFieldStyle, invalidInputFieldStyle, inputFieldContainer, greenTickIconStyle, greenTickIconStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        // Check for invalid fields
        const makeByInvalid = this.state.makeBy.length === 0 || this.state.makeBy === '' || this.state.makeBy === null;
        const modelInvalid = this.state.model.length === 0 || this.state.model === '' || this.state.model === null;
        const plateInvalid = this.state.plate.length === 0 || this.state.plate === '' || this.state.plate === null;
        const colorInvalid = this.state.color.length === 0 || this.state.color === '' || this.state.color === null;

        // Set input field styles
        let makeByFieldStyle, modelFieldStyle, plateFieldStyle, colorFieldStyle;

        (makeByInvalid) ? makeByFieldStyle = invalidInputFieldStyle : makeByFieldStyle = inputFieldStyle;
        (modelInvalid) ? modelFieldStyle = invalidInputFieldStyle : modelFieldStyle = inputFieldStyle;
        (plateInvalid) ? plateFieldStyle = invalidInputFieldStyle : plateFieldStyle = inputFieldStyle;
        (colorInvalid) ? colorFieldStyle = invalidInputFieldStyle : colorFieldStyle = inputFieldStyle;

        return (
            <React.Fragment>
                <Container style={{ padding: 0, marginTop: 16 }}>
                    <Row className="confirmAddressContainer" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col xs={12} sm={12} md={10} style={inputFieldContainer}>
                            <Input placeholder={i18n.t('screens:carDetailsScreen.makeBy')}
                                name="makeBy"
                                className='addressInputs'
                                style={makeByFieldStyle}
                                value={this.state.makeBy}
                                maxLength={24}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            {this.state.makeBy ?
                                this.state.makeBy.length ?
                                    <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle} /> : null
                                : null
                            }
                        </Col>
                        <Col xs={12} sm={12} md={10}  >
                            <Input placeholder={i18n.t('screens:carDetailsScreen.model')}
                                name="model"
                                className='addressInputs'
                                style={modelFieldStyle}
                                value={this.state.model}
                                maxLength={24}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            {this.state.model ?
                                this.state.model.length ?
                                    <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle} /> : null
                                : null
                            }
                        </Col>
                        <Col xs={12} sm={12} md={5}>
                            <Input placeholder={i18n.t('screens:carDetailsScreen.plate')}
                                name="plate"
                                className='addressInputs'
                                style={plateFieldStyle}
                                value={this.state.plate}
                                maxLength={24}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            {this.state.plate ?
                                this.state.plate.length ?
                                    <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle} /> : null
                                : null
                            }
                        </Col>
                        <Col xs={12} sm={12} md={5}>
                            <Input placeholder={i18n.t('screens:carDetailsScreen.color')}
                                name="color"
                                className='addressInputs'
                                style={colorFieldStyle}
                                value={this.state.color}
                                maxLength={24}
                                onChange={this.handleInputChange.bind(this)}
                            />
                            {this.state.color ?
                                this.state.color.length ?
                                    <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle} /> : null
                                : null
                            }
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { chefNoteBackground } = this.props.customerThemes.selectedTheme;
        customizedStyles.inputFieldStyle.backgroundColor = chefNoteBackground;
        customizedStyles.greenTickIconStyle.backgroundColor = chefNoteBackground;
        customizedStyles.greenTickIconStyleRTL.backgroundColor = chefNoteBackground;
        styles = customizedStyles;
        return styles;
    }

    render() {
        return (
            <React.Fragment>
                {this.renderCarDetailsForm()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    }
}

export default connect(mapStateToProps)(CarDetailsContent);

let styles = {
    inputFieldStyle: {
        height: 55,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: INPUT_FIELDS_BG_COLOR,
        marginBottom: 10,
        fontWeight: 300
    },

    invalidInputFieldStyle: {
        height: 55,
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 10,
        backgroundColor: '#F2D8D9',
        fontWeight: 300
    },

    inputFieldContainer: {
        position: 'relative',
        fontWeight: 300
    },

    greenTickIconStyle: {
        width: 30,
        height: 'auto',
        position: 'absolute',
        top: 13,
        right: 23,
        backgroundColor: '#f0f0f0',
        padding: 5
    },
    greenTickIconStyleRTL: {
        width: 30,
        height: 'auto',
        position: 'absolute',
        top: 13,
        left: 23,
        backgroundColor: '#f0f0f0',
        padding: 5
    },
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18next';

import ApplicationStep from './ApplicationStep';

class OfflineScreen extends Component {
    constructor(props) {
        super(props);
        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        // console.log('OfflineScreen DidMount');
        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }

    componentWillUnmount() {
        // console.log('OfflineScreen WillUnmount');
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        // console.log('OfflineScreen Backbutton');
        this.goBack();
    }

    goBack() {
        const path = this.props.temporaryOrderTime.currentPagePath;
        this.props.history.push(path);
    }

    renderContent() {
        const contentStyle = { 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.25em',
            height: '100%',
            maxHeight: 'calc(70vh - 85px)',
            width: '86%',
            margin: 'auto',
            textAlign: 'center'
        }

        return (
            <div style={contentStyle}>
                {i18n.t('screens:offlineScreen.offlineMsg')}</div>
        );
    }

    render() {
        const textProp = i18n.t('screens:offlineScreen.offline');

        return (
            <ApplicationStep
                hideBackButton={false}
                transparentHeader={false}
                goBack={this.goBack.bind(this)}
                text={textProp}
                content={this.renderContent()}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brand: state.brand,
        temporaryOrderTime: state.temporaryOrderTime
    }
}

export default connect(mapStateToProps, {})(OfflineScreen);
import React from 'react';

const GooglePaySVG = ({ width, height, fill, style, viewBoxNewValues }) => {
    const viewBoxValues = viewBoxNewValues ? viewBoxNewValues : "0 0 512 210.2";

    return (
        // <svg width={width} height={height} style={style} viewBox={viewBoxValues} role="presentation" fill="#A1A1A1" class="main-header__logo-image" viewBox="0 0 435.97 173.13">
        //     <path d="M206.2,84.58v50.75H190.1V10h42.7a38.61,38.61,0,0,1,27.65,10.85A34.88,34.88,0,0,1,272,47.3a34.72,34.72,0,0,1-11.55,
        //     26.6q-11.2,10.68-27.65,10.67H206.2Zm0-59.15V69.18h27a21.28,21.28,0,0,0,15.93-6.48,21.36,21.36,0,0,0,0-30.63,21,21,0,0,
        //     0-15.93-6.65h-27Z" fill={fill}></path>
        //     <path d="M309.1,46.78q17.85,0,28.18,9.54T347.6,82.48v52.85H332.2v-11.9h-.7q-10,14.7-26.6,14.7-14.17,0-23.71-8.4a26.82,26.82,0,0,
        //     1-9.54-21q0-13.31,10.06-21.17t26.86-7.88q14.34,0,23.62,5.25V81.25A18.33,18.33,0,0,0,325.54,67,22.8,22.8,0,0,0,310,61.13q-13.49,
        //     0-21.35,11.38l-14.18-8.93Q286.17,46.78,309.1,46.78Zm-20.83,62.3a12.86,12.86,0,0,0,5.34,10.5,19.64,19.64,0,0,0,12.51,4.2,25.67
        //     ,25.67,0,0,0,18.11-7.52q8-7.53,8-17.67-7.53-6-21-6-9.81,0-16.36
        //     ,4.73C290.46,100.52,288.27,104.41,288.27,109.08Z" fill={fill}></path>
        //     <path d="M436,49.58,382.24,173.13H365.62l19.95-43.23L350.22,49.58h17.5l25.55,61.6h.35l24.85-61.6Z" fill={fill}></path>
        //     <path d="M141.14,73.64A85.79,85.79,0,0,0,139.9,59H72V86.73h38.89a33.33,33.33,0,0,1-14.38,21.88v18h23.21C133.31,114.08,141.14,
        //     95.55,141.14,73.64Z" fill="#4285f4"></path>
        //     <path d="M72,144c19.43,0,35.79-6.38,47.72-17.38l-23.21-18C90.05,113,81.73,115.5,
        //     72,115.5c-18.78,0-34.72-12.66-40.42-29.72H7.67v18.55A72,72,0,0,0,72,144Z" fill="#34a853"></path>
        //     <path d="M31.58,85.78a43.14,43.14,0,0,1,0-27.56V39.67H7.67a72,72,0,0,0,0,64.66Z" fill="#fbbc04"></path>
        //     <path d="M72,28.5A39.09,39.09,0,0,1,99.62,39.3h0l20.55-20.55A69.18,69.18,0,0,0,72,0,72,72,0,0,0,7.67,39.67L31.58,58.22C37.28,41.16,
        //     53.22,28.5,72,28.5Z" fill="#ea4335"></path>
        // </svg>

        <svg width={width} height={height} style={style} role="presentation" class="main-header__logo-image">
            <path d="M1.96475 15.0006V3.54461h4.288c0.61866 0 1.184 0.13333 1.696 0.4 0.52266 0.26667 
            0.93333 0.62933 1.232 1.088 0.30933 0.45867 0.464 0.976 0.464 1.552 0 0.58667-0.14934 1.088-0.448 1.504-0.288 0.416-0.656 
            0.73067-1.104 0.944v0.08c0.576 0.20267 1.04533 0.53867 1.408 1.00799 0.36266 0.4587 0.54395 1.0133 0.54395 1.664 0 0.64-0.16529 
            1.2053-0.49595 1.696-0.32 0.48-0.75734 0.8533-1.312 1.12-0.544 0.2667-1.14134 0.4-1.792 0.4h-4.48zm4.272-9.83999h-2.512v3.2h2.448c0.55466 
            0 0.98666-0.16533 1.296-0.496 0.30933-0.34133 0.464-0.71467 0.464-1.12 0-0.39467-0.14934-0.75733-0.448-1.088-0.29867-0.33067-0.71467-0.496-1.248-0.496zm-2.512 8.20799h2.72c0.576 0 1.024-0.1707 1.344-0.512 0.33066-0.352 0.496-0.752 0.496-1.2 
            0-0.448-0.16534-0.848-0.496-1.2-0.33067-0.352-0.8-0.52799-1.408-0.52799h-2.656v3.43999zm11.07765 1.888c-1.0027 0-1.76-0.2987-2.272-0.896-0.5014-0.608-0.752-1.4293-0.752-2.464V6.84061h1.728v4.81599c0 0.6933 0.16 1.2053 0.48 1.536 0.3306 0.32 0.7413 0.48 1.232 0.48 0.4373 0 0.816-0.112 1.136-0.336 0.3306-0.2347 0.5813-0.5333 0.752-0.896 0.1813-0.3733 0.272-0.7787 
            0.272-1.216V6.84061h1.712v8.15999h-1.616v-1.104h-0.096c-0.2347 0.384-0.5867 0.7093-1.056 0.976-0.4587 0.256-0.9654 0.384-1.52 0.384zm9.2552 2.8c-0.032 0.0853-0.0693 0.1653-0.112 0.24-0.032 0.0747-0.0533 0.128-0.064 0.16h-1.84c0.0534-0.1173 
            0.128-0.288 0.224-0.512 0.1067-0.2133 0.208-0.4213 0.304-0.624s0.1974-0.432 0.304-0.688c0.1174-0.256 0.2347-0.5067 0.352-0.752l0.608-1.328-3.408-7.71199h1.92l2.336 5.56799h0.08l2.256-5.56799h1.904l-4.224 9.72799c-0.1173 0.2773-0.2453 0.5653-0.384 0.864-0.128 0.3093-0.2133 0.5173-0.256 0.624zm11.7593-3.056l-2.608-8.15999h1.872l1.68 5.87199h0.064l1.84-5.87199h1.776l1.84 5.87199h0.064l1.68-5.87199h1.84l-2.624 
            8.15999h-1.808l-1.888-5.90399h-0.064l-1.872 5.90399h-1.792zm12.305-9.35999c-0.32 0-0.5974-0.112-0.832-0.336-0.224-0.23467-0.336-0.512-0.336-0.832 0-0.32 0.112-0.592 0.336-0.816 0.2346-0.224 0.512-0.336 0.832-0.336 0.32 0 0.592 0.112 0.816 0.336 0.2346 0.224 0.352 0.496 0.352 0.816 0 0.32-0.1174 0.59733-0.352 0.832-0.224 0.224-0.496 
            0.336-0.816 0.336zm-0.864 9.35999V6.84061h1.728v8.15999h-1.728zm4.8078-2.368V8.34461h-1.424v-1.504h1.424v-2.304h1.728v2.304h2v1.504h-2v3.91999c0 
            0.4053 0.08 0.7147 0.24 0.928 0.1707 0.2133 0.4534 0.32 0.848 0.32 0.2027 0 0.3734-0.0267 0.512-0.08 0.1494-0.0533 0.2987-0.128 0.448-0.224v1.68c-0.1813 0.0747-0.3733 0.1333-0.576 0.176-0.2026 0.0427-0.4426 0.064-0.72 0.064-0.7573 0-1.36-0.2187-1.808-0.656-0.448-0.448-0.672-1.0613-0.672-1.84zm5.6142 2.368V3.54461h1.728v3.136l-0.096 1.28h0.096c0.224-0.39467 0.5653-0.72 1.024-0.976 0.4693-0.26667 0.9866-0.4 1.552-0.4 1.0133 0 1.776 0.304 2.288 0.912 0.512 0.59733 0.768 1.38133 
            0.768 2.352v5.15199h-1.712v-4.928c0-0.66132-0.176-1.14132-0.528-1.43999-0.3414-0.30933-0.7627-0.464-1.264-0.464-0.416 0-0.784 0.11733-1.104 0.352-0.32 0.23467-0.5707 0.544-0.752 0.928-0.1814 0.37333-0.272 0.77329-0.272 1.19999v4.352h-1.728z" fill="#ffffff"></path>
            <path d="M94.3563 3.42339v4.73836h2.9221c0.6961 0 1.2717-0.23394 1.7271-0.70182 0.4673-0.4668 0.7015-1.02349 0.7015-1.6679 0-0.63142-0.2342-1.18162-0.7015-1.64949-0.4554-0.4798-1.031-0.72024-1.7271-0.72024h-2.9221v0.00109zm0 
            6.40627v5.49654h-1.7456V1.75549h4.6296c1.1765 0 2.1751 0.39207 2.9977 1.17512 0.835 0.78304 1.253 1.73721 1.253 2.86142 0 1.15021-0.418 2.11088-1.253 2.88093-0.8097 0.77113-1.8094 1.15562-2.9977 1.15562h-2.884v0.00108zM103.255 
            12.4831c0 0.4549 0.193 0.8339 0.579 1.1372 0.385 0.3033 0.838 0.4549 1.356 0.4549 0.734 0 1.388-0.272 1.963-0.8145 0.576-0.5437 0.863-1.1816 0.863-1.9137-0.544-0.43-1.303-0.6444-2.276-0.6444-0.709 0-1.3 0.1711-1.774 0.5122-0.474 
            0.3411-0.711 0.7625-0.711 1.2683m2.258-6.74741c1.29 0 2.308 0.34441 3.055 1.03323 0.746 0.68882 1.119 1.63325 1.119 2.83327v5.72391h-1.669v-1.2888h-0.076c-0.721 1.0613-1.683 1.5921-2.884 1.5921-1.025 0-1.883-0.3033-2.571-0.9098-0.69-0.6065-1.034-1.3646-1.034-2.2744 
            0-0.9607 0.363-1.7243 1.09-2.2928 0.728-0.56862 1.698-0.85347 2.913-0.85347 1.036 0 1.89 0.18954 2.56 0.56861V9.46897c0-0.60651-0.24-1.12096-0.72-1.54443-0.482-0.42348-1.044-0.63467-1.689-0.63467-0.974 0-1.745 0.41048-2.314 1.23251l-1.538-0.96716c0.848-1.21302 2.1-1.81953 
            3.758-1.81953M119.269 6.03894l-5.826 13.38116h-1.802l2.163-4.6821-3.833-8.69906h1.898l2.77 6.67166h0.038l2.694-6.67166h1.898z" fill="#ffffff" fillType="evenOdd"></path>
            <path d="M87.3028 8.64473c0-0.5491-0.0466-1.07872-0.1344-1.58559h-7.3619v3.00336h4.2165c-0.181 0.9789-0.7286 1.8119-1.5591 2.3697v1.9506h2.5165c1.4735-1.3582 2.3224-3.3651 2.3224-5.73807" fill="#4285f4" fillType="evenOdd"></path>
            <path d="M79.8065 16.2651c2.1066 0 3.8804-0.691 5.1739-1.8823l-2.5165-1.9506c-0.7004 0.4711-1.6025 0.7462-2.6574 0.7462-2.0362 0-3.7645-1.3711-4.3825-3.21884h-2.5924v2.00904c1.2848 2.5474 3.9249 4.2965 6.9749 4.2965" fill="#34a853" fillType="evenOdd"></path>
            <path d="M75.424 9.95957c-0.1583-0.47113-0.245-0.97367-0.245-1.49245s0.0867-1.02132 0.245-1.49245V4.96561h-2.5924C72.3003 6.01833 72 7.20753 72 8.46712c0 1.25959 0.3003 2.44888 0.8316 3.50148l2.5924-2.00903z" fill="#fabb05" fillType="evenOdd"></path>
            <path d="M79.8065 3.75583c1.1503 0 2.1814 0.39532 2.9946 1.1697v0.00108l2.2281-2.22567c-1.3531-1.25851-3.1171-2.031812-5.2227-2.031812-3.05 0-5.6901 1.749132-6.9749 4.296472l2.5924 2.00907c0.618-1.84769 2.3463-3.21884 4.3825-3.21884" fill="#e94235" fillType="evenOdd"></path>
        </svg>
    );
}

export default GooglePaySVG;
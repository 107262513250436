import React from 'react';
import i18n from '../../../i18next';

const Discounts = props => {
    
    const { promotionPartner, promoPartnerAppliedPromos, promoPartnerDiscountTotal } = props.promotionPartners;
    const { orderProducts, appliedPromotions } = props.shoppingCart;
    const { mainTextColor } = props.customerThemes.selectedTheme;

    let currentPromotions = appliedPromotions;

    let discountsText = '';
    const discountName = currentPromotions.length > 0 ? currentPromotions[0].name : null; // when we can have more than one promotion
    let hideDiscounts = true;
    if (promotionPartner && promotionPartner.id === 1) {
        discountsText = `${i18n.t('screens:shoppingCart.discount')} (${discountName})`;
        hideDiscounts = orderProducts.length === 0 || currentPromotions.length === 0;
    } else if (promotionPartner && promotionPartner.id === 2) {
        discountsText = `${i18n.t('screens:como.discount')}`;
        currentPromotions = promoPartnerAppliedPromos;
        hideDiscounts = !promoPartnerDiscountTotal || orderProducts.length === 0;
    }

// TODO make it render from an array if como balance needs to be shown on a different level
    return (
        <p
            style={
                {
                    color: mainTextColor,
                    marginBottom: 2,
                    fontSize: 15
                }
            }
            hidden={hideDiscounts}
            className="long-text">
            {discountsText}
        </p>
    );
}

export default Discounts;
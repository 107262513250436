import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

class ModalStep extends Component {
    constructor(props) {
        super(props);

    }

    toggle() {
        this.props.onToggle();
    }

    render() {
        let { menuItemsBackground } = this.props.customerThemes.selectedTheme;

        return (
            <div className="modalStepWrapper">
                <ModalHeader toggle={this.toggle.bind(this)} style={this.props.styles.modalStyle}>{this.props.renderHeader}</ModalHeader>
                <ModalBody style={{...this.props.styles.modalBody, backgroundColor: menuItemsBackground}}>{this.props.renderBody}</ModalBody>
                <ModalFooter style={this.props.styles.modalFooter}>{this.props.renderFooter}</ModalFooter>
            </div>
        );
    }
}

export default ModalStep;
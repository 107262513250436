import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Col, ListGroup, ListGroupItem, Button } from "reactstrap";

import ModalStep from "../common/ModalStep";
import { AvForm } from "availity-reactstrap-validation/dist/availityreactstrapvalidation";
import i18n from "../../i18next";
import MaterialIcon from "material-icons-react";
import { getLoggedInUserFirstName } from "../../utils/CommonUtils";
import { updateIconsAccordingToTheme } from "../../utils/ThemesSelector";

class UnavailableProductsScreen extends Component {
    constructor(props) {
        super(props);

        this.goBack = this.goBack.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    toggle(isClickYes) {
        this.props.isMenuScreen && this.props.clearTemporaryOrderTimeData();
        this.props.toggle(isClickYes);
    }

    removeAllOrderHistoryItems() {
        if (this.props.onCancel !== undefined && this.props.onCancel !== null) {
            this.props.onCancel();
        } else {
            this.toggle();
        }
    }

    onConfirm() {
        this.props.onConfirm();
        this.toggle(true);
    }

    renderModalHeader() {
        const { centredItem, headerColumn } = styles;

        if (this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined
            && this.props.customer.id !== undefined && this.props.customer.id !== null && this.props.customer.id !== -1) {

            const isHidden = (this.props.isOrderHistoryScreen) ? true : false;

            return (
                <Col xs={12} sm={12} style={Object.assign({}, centredItem, { paddingTop: 3, textAlign: 'center', margin: 'auto', })}>
                    {/* <Col xs={2} sm={2} style={headerColumn} hidden={isHidden}>
                        <Button className="backButton" onClick={() => this.goBack()}>
                            <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                            <span className="backButtonText" style={{fontWeight: 300}}>{i18n.t('common:buttons.backBtn')}</span>
                        </Button>
                    </Col> */}
                    {this.renderCustomer()}
                </Col>
            )
        }

    }

    renderCustomer() {
        const { imageStyle, profileNameStyle, centredItem } = styles;
        const { type, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const iconImage = updateIconsAccordingToTheme(type, 'user-secondary');

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass = 'customer-long-name-no-back-button';
        }

        return (
            <div style={Object.assign({color: primaryFontColor}, centredItem)} className='customer-name-mobile-style'>
                <img src={iconImage} width="40" height="auto" style={imageStyle} />
                <span className={customerNameClass} style={profileNameStyle}>{userFirstName}</span>
            </div>
        )
    }

    renderModalBody() {

        const { unavailableProducts } = styles;
        const areButtonsHidden = (this.props.allProductsUnavailable) ? true : false;
        const unAvailableOrderedProducts = this.props.temporaryOrderTime.unAvailableOrderedProducts;
        const { primaryFontColor, mainTextColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;

        const infoMessage = (this.props.allProductsUnavailable) ? i18n.t('screens:unavailableProductsScreen.allProductsUnavailable') : i18n.t('screens:unavailableProductsScreen.someProductsUnavailable');

        return (
            <Fragment>
                <p style={{ textAlign: 'center', fontSize: 18, color: primaryFontColor }}>{infoMessage}</p>
                <Col sm={12} style={{ marginBottom: 10, textAlign: 'center', maxHeight: 200, overflowY: 'auto' }} >
                    <ListGroup flush style={{ marginBottom: 20, color: mainTextColor, cursor: 'default' }}>
                        {
                            unAvailableOrderedProducts.map((product) => {
                                const isMealDeal = product.mealDeal ? 'products/toppings' : 'toppings';
                                const text = product.questions.length ? `${product.name} ${i18n.t('screens:unavailableProductsScreen.textPartOne')} ${isMealDeal} ${i18n.t('screens:unavailableProductsScreen.textPartTwo')}.` : `${product.name} ${i18n.t('screens:unavailableProductsScreen.singleProductText')}`;
                                return <ListGroupItem style={{...unavailableProducts, backgroundColor: menuItemsBackground }} id={product.answerId}>{text}</ListGroupItem>
                            })
                        }
                    </ListGroup>
                </Col>
                <p style={{ textAlign: 'center', fontSize: 18 }} hidden={areButtonsHidden} >{i18n.t('screens:unavailableProductsScreen.confirmation')}</p>
                <Col style={{ marginBottom: 20, paddingBottom: 10 }} hidden={areButtonsHidden}>
                    <Button style={{ float: 'right', borderRadius: 0 }} outline color="danger" onClick={() => this.removeAllOrderHistoryItems()}>{i18n.t('common:buttons.no')}</Button>
                    <Button style={{ float: 'right', borderRadius: 0, marginRight: 10 }} onClick={() => this.onConfirm()} outline color="secondary" >{i18n.t('common:buttons.yes')}</Button>
                </Col>
            </Fragment>
        );
    }


    renderModalFooter() {
        return (
            <Fragment />
        );
    }

    render() {
        return (
            <AvForm className="unavailableProductsScreen">
                <ModalStep
                    onToggle={this.props.isMenuScreen ? this.props.onExit : this.props.toggle}
                    onConfirm={this.props.onConfirm}
                    renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        )
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        temporaryOrderTime: state.temporaryOrderTime,
        customerThemes: state.customerThemes
    };
};

export default connect(mapStateToProps, {})(UnavailableProductsScreen);

const styles = {
    unavailableProducts: {
        borderTop: 'none',
        borderBottom: '1px solid lightgray'
    },
    imageStyle: {
        paddingRight: 18,
        marginTop: -5
    },
    profileNameStyle: {
        marginLeft: -5,
        verticalAlign: 'middle'
    },
    centredItem: {
        margin: 'auto'
    },
    headerColumn: {
        paddingLeft: 0,
        marginRight: 35,
        marginLeft: -25,
        float: 'left'
    }
}
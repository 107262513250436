import React, { Component } from "react";
import { connect } from 'react-redux';

import ApplicationStep from '../common/ApplicationStep';
import OrderTimeScreen from '../../../components/order/start/OrderTimeScreen';

import '../../styles/OrderTimePwa.css';
import '../../styles/Common.css';
import { updateCSSVariables } from "../../../utils/ThemesSelector";

import { openChangeOrderTimeScreen } from "../../../actions";
import { setLastOpenedPagePWA } from "../../../utils/CommonUtils";
import { COLLECTION_ID, CURBSIDE_ID, DELIVERY_ID } from "../../../utils/Constants";
import i18n from "../../../i18next";

class OrderTimePwa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isBackButtonInactive: this.props.location.state ? (this.props.location.state.includes('menu') || this.props.location.state.includes('checkout')) : false
        };

        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        this.applyTheme();

        // Get OrderTimeScreen Next Button and change the text inside it and style it differently
        let orderTimeScreenButton = document.querySelectorAll('#contentContainer .modal-custom-body-footer .nextButton')[0];
        const { fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        if (orderTimeScreenButton) {
            orderTimeScreenButton.textContent = i18n.t('screens:orderTimeScreen.goToMenu');
            orderTimeScreenButton.style.justifyContent = 'space-evenly';
            orderTimeScreenButton.style.textAlign = 'center';
            orderTimeScreenButton.style.fontFamily = fontFamilyType;
            orderTimeScreenButton.style.setProperty('font-weight', fontBold === 'bold' ? fontBold : 300, 'important');
            orderTimeScreenButton.style.fontStyle = fontItalic;
            orderTimeScreenButton.style.textTransform = uppercase;
        }

        setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);

        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }


    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!this.state.isBackButtonInactive) {
            this.goBack();
        }
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    goBack() {
        if (this.props.orderTypes.selectedOrderType.value === 'Collection' || this.props.orderTypes.selectedOrderType.value === 'Curbside') {
            this.props.history.push('/brand/' + this.props.brandId + '/start-order/restaurant-selection');
        } else if (this.props.orderTypes.selectedOrderType.id == DELIVERY_ID && Array.isArray(this.props.deliveryData)) {
            this.props.history.push({
                pathname: '/brand/' + this.props.brandId + '/start-order/restaurant-selection',
                state: { isRedirectedFromDelivery: true }
            });
        } else {
            this.props.history.push('/brand/' + this.props.brandId + '/start-order/confirm-address');
        }
    }

    renderContent() {

        return (
            <React.Fragment>
                <div className='startOrderContentWrapper'>
                    <OrderTimeScreen renderInPwa={true} history={this.props.history} />
                </div>
            </React.Fragment>
        );
    }

    render() {
        const { selectedOrderType } = this.props.orderTypes;

        let navbarText = '';

        switch (selectedOrderType.id) {
            case DELIVERY_ID:
                navbarText = i18n.t('screens:orderTimePwa.selectDeliveryTime');
                break;
            case COLLECTION_ID:
                navbarText = i18n.t('screens:orderTimePwa.selectPickupTime');
                break;
            case CURBSIDE_ID:
                navbarText = i18n.t('screens:orderTimePwa.selectCurbsideTime');
                break;
            default:
                navbarText = '';
        }

        return (
            <React.Fragment>
                <ApplicationStep
                    header={true}
                    hideBackButton={this.state.isBackButtonInactive}
                    transparentHeader={false}
                    content={this.renderContent()}
                    goBack={this.goBack.bind(this)}
                    text={navbarText}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        brandId: state.brand.id,
        customerThemes: state.customerThemes,
        orderTypes: state.orderTypes,
        restaurantsInZone: state.restaurantsInZone
    };
};

export default connect(mapStateToProps, { openChangeOrderTimeScreen })(OrderTimePwa);
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import {
    Col,
    Row,
    Button,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Input,
    Label
} from 'reactstrap';
import moment from "moment/moment";
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import { GridLoader } from 'react-spinners';

import i18n from '../../../i18next';

import ModalStep from '../../common/ModalStep';
import { getEstimateOrderTime, saveOrderTimes, changeOrderTimes, getTemporaryEstimateOrderTime, activateLoader, stopLoader, getPaymentProvider, saveNavigationType } from '../../../actions/index';
import { getLoggedInUserFirstName, getRestaurantStatus, isArabic } from '../../../utils/CommonUtils';
import { DELIVERY_ID, CURBSIDE_ID, COLLECTION_ID, ARABIC, IN_STORE_ID } from '../../../utils/Constants';
import UserIconSVG from '../../../pwa-app/components/common/svgComponents/UserIconSVG';
import DeliverySVG from '../../../pwa-app/components/common/svgComponents/DeliverySVG';
import ShoppingCartSVG from '../../../pwa-app/components/common/svgComponents/ShoppingCartSVG';
import CurbsideSVG from '../../../pwa-app/components/common/svgComponents/CurbsideSVG';

import IconChevronDown from '../../../images/icons/icon-chev-down.png';
import { isMobileOnly, isTablet } from 'mobile-device-detect';

class OrderTimeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            radioOne: true,
            radioTwo: false,
            date: null,
            time: null,
            btnOneIsHidden: false,
            selectedOptionIndex: -1
        }

        this.goForward = this.goForward.bind(this);
        this.goBack = this.goBack.bind(this);
        this.changeRadioBtnState = this.changeRadioBtnState.bind(this);
        this.changeState = this.changeState.bind(this);
    }


    componentDidMount() {
        this.applyTheme();

        const { restaurantId } = this.props.selectedRestaurant;
        const { selectedOrderType } = this.props.orderTypes;

        if (restaurantId !== undefined && restaurantId !== null) {
            const selectedOrderTypeValue = selectedOrderType.value;

            let deliveryZoneId = deliveryZoneId;

            if (deliveryZoneId == undefined || deliveryZoneId == null) {
                deliveryZoneId = this.props.selectedRestaurant.deliveryZoneId;
            }

            if (this.props.isCheckoutChangeTime) {
                this.props.getTemporaryEstimateOrderTime(restaurantId, selectedOrderTypeValue, deliveryZoneId, this.props.dinePayTypeId);
            } else {
                this.props.activateLoader();
                this.props.getEstimateOrderTime(restaurantId, selectedOrderTypeValue, deliveryZoneId, this.props.stopLoader, this.props.dinePayTypeId);
            }
        }

        // Calls payment Provider information TODO do not call when changing time
        this.props.getPaymentProvider(restaurantId);
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.estimateOrderTime.workingHoursByDate !== this.props.estimateOrderTime.workingHoursByDate) {
            if (this.props.estimateOrderTime.workingHoursByDate !== null && !this.props.timeSlotBased) {

                let workingHoursCopy = [...this.props.estimateOrderTime.workingHoursByDate];
                const today = moment(moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY'), 'DD-MM-YYYY');
                const firstDayFromWorkingHours = moment(this.props.estimateOrderTime.workingHoursByDate[0].date, 'DD-MM-YYYY');

                if (firstDayFromWorkingHours.isBefore(today) && workingHoursCopy[0].openingHour === null) {
                    workingHoursCopy.shift();
                }

                const firstDate = (workingHoursCopy[0] !== undefined) ? workingHoursCopy[0].date : '';
                //const firstTime = `${this.props.estimateOrderTime.workingHoursByDate[0].openingHour.openHour}:${this.props.estimateOrderTime.workingHoursByDate[0].openingHour.openMinute}`
                const nullifyDate = workingHoursCopy[0] !== undefined && workingHoursCopy[0].openingHour === null ? true : false;

                if (nullifyDate) {
                    this.setState({
                        date: ''
                    });
                } else {
                    this.setState({
                        date: firstDate,
                        // time: firstTime
                    });
                }
            }
        }

        if (prevState.date !== this.state.date && !this.props.timeSlotBased) { // On date change sets time to the first possible time for the newly selected date
            this.transformTimeData(this.props.estimateOrderTime.workingHoursByDate).map(date => {
                if (date[0] !== null) {
                    const selectedDate = date[0].split('_')[1];
                    const openTime = this.formatTime(date[0].split('_')[0]);

                    if (selectedDate === this.state.date && openTime !== this.state.time) {

                        this.setState({
                            time: openTime
                        });
                    }
                }
            });
        }

        if (prevProps.restaurantsInZone === this.props.restaurantsInZone && !this.props.timeSlotBased) { // When ASAP has to be hidden due to futureOrdersDisabled === true when restaurant is closed;

            this.props.restaurantsInZone.map(restaurant => {
                const isRestaurantOpenStatus = getRestaurantStatus(restaurant);
                if (restaurant.restaurantId === this.props.selectedRestaurant.restaurantId && !isRestaurantOpenStatus
                    && !restaurant.futureOrdersDisabled && !this.state.btnOneIsHidden) {

                    this.setState({
                        radioOne: false,
                        radioTwo: true,
                        btnOneIsHidden: true
                    });
                }
            });
        }

        if (prevProps.estimateOrderTime !== this.props.estimateOrderTime && !this.props.timeSlotBased) {
            this.setState({
                radioOne: true,
                radioTwo: false
            });
        }

        if (prevProps.timeSlotsByDate !== this.props.timeSlotsByDate && this.props.timeSlotsByDate && this.props.timeSlotBased && !this.state.date) {
            this.setState({
                ...this.state,
                date: this.props.timeSlotsByDate[0].date,
                time: this.props.timeSlotsByDate[0].timeSlots ? this.props.timeSlotsByDate[0].timeSlots[0] : null
            });
        }

        if (this.props.isChangeTime && !this.state.date && this.props.timeSlotBased) {
            this.setState({
                ...this.state,
                date: this.props.realEstimateOrderTime.time.date,
                time: this.props.realEstimateOrderTime.time
            });
        }
    }

    goBack() {
        if (this.props.orderTypes.selectedOrderType.id == DELIVERY_ID && this.props.restaurantsInZone.length > 0) {

            this.props.history.replace({ pathname: '/start-order/restaurant-selection', state: { isRedirectedFromDelivery: true } });

            this.props.history.goBack();

        } else if (this.props.orderTypes.selectedOrderType.id == IN_STORE_ID) {
            this.props.toggleOrderTimeScreen();
        } else {
            this.props.history.goBack();
        }
    }

    goForward() {
        // Added check to solve the problem with empty time in OrderTime selection field
        const noDataForDateOrTimeFromProps = this.state.date === null || this.state.time === null;

        // Guard the error dump opening empty menu screen and the application crash in the pwa
        if (this.props.renderInPwa && noDataForDateOrTimeFromProps && !this.state.radioOne) {
            return;
        }

        let wantedTime = null;

        if (this.state.radioTwo && !this.props.timeSlotBased) {
            const dateString = this.state.date;
            const timeString = this.state.time;
            const concatinatedTime = timeString.concat(':', '00');
            wantedTime = dateString.concat(' ', concatinatedTime); // This prop is needed for the final JSON object that is going to be send to server
        } else if (this.state.radioTwo && this.props.timeSlotBased) {
            const fromHourMin = moment(`${this.state.time.fromHour}:${this.state.time.fromMinute}`, 'HH:mm').format('HH:mm');
            const toHourMin = moment(`${this.state.time.toHour}:${this.state.time.toMinute}`, 'HH:mm').format('HH:mm');
            const concatinatedTime = `${this.state.time.date} ${fromHourMin} - ${toHourMin}`;
            wantedTime = concatinatedTime;
        }

        // Next 30 lines correct the date if user tries to order after midnight
        let arraysByDate = this.transformTimeData();
        let isDateToBeChanged = false;
        let parsedDate = { ...this.state.date };

        arraysByDate.map(arrayByDate => {
            arrayByDate.map((dateItem, i) => {
                let dateItemSplit = dateItem.split('_');
                let newDate = dateItemSplit[1];
                let timeToBeDisplayed = dateItemSplit[0];

                if (this.state.date == newDate && this.state.selectedOptionIndex == i) {

                    let timeArray = timeToBeDisplayed.split(':');
                    let hh = parseInt(timeArray[0]);
                    let mm = parseInt(timeArray[1]);

                    if (hh >= 24) {
                        isDateToBeChanged = true;
                    }
                }
            });
        });

        if (isDateToBeChanged && !this.props.timeSlotBased) {
            parsedDate = moment(this.state.date, 'DD-MM-YYYY').add(1, 'days').format('DD-MM-YYYY');
        }

        const orderTimesDate = {
            asap: this.state.radioOne ? 'asap' : '',
            selectTime: this.state.radioTwo ? 'selectTime' : '',
            estimateOrderTime: this.props.estimateOrderTime.estimateOrderTime,
            date: this.state.radioOne ? null : parsedDate,
            time: this.state.radioOne ? null : this.state.time,
            futureOrder: this.state.radioOne ? false : true,
            wantedTime: wantedTime,
            workingHoursByDate: !this.props.timeSlotBased ? this.props.estimateOrderTime.workingHoursByDate : this.props.timeSlotsByDate,
            taxIncludeInPrice: this.props.estimateOrderTime.taxIncludeInPrice,
            timeSlotBased: this.props.timeSlotBased,
            timeSlotsByDate: this.props.timeSlotsByDate,
            additionalMoneyCookTimes: this.props.estimateOrderTime.additionalMoneyCookTimes,
            showMenuuLogo: this.props.estimateOrderTime.showMenuuLogo
        };

        if (this.state.date === '' && !this.state.radioOne) {
            return;
        }

        const isMenuScreen = this.props.isMenuScreen;
        const isCheckoutScreen = this.props.isCheckoutScreen;

        if (this.props.isCheckoutChangeTime) {
            const restaurantId = this.props.selectedRestaurant.restaurantId;
            this.props.changeOrderTimes(orderTimesDate, restaurantId, false, false, null, isMenuScreen, false, false, isCheckoutScreen);
            this.props.toggle();
        } else {
            this.props.saveOrderTimes(orderTimesDate);

            // IF component is not rendered in pwa
            if (!this.props.renderInPwa) {
                this.props.toggle(true);

                this.props.history.push('/menu-screen');
                this.props.saveNavigationType(1);
                // parentLocalStorage('set', 'navigationType', 1);
            } else {
                this.props.history.push('/brand/' + this.props.brand.id + '/order/menu');
            }
        }

    }

    changeRadioBtnState({ target }) {
        if (target.name === 'radioOne') {
            this.setState({
                radioOne: !this.state.radioOne,
                radioTwo: !this.state.radioTwo
            });
        } else {
            this.setState({
                radioOne: !this.state.radioOne,
                radioTwo: !this.state.radioTwo
            });
        }

    }

    changeState({ target }) {
        if (target.name === 'time') {
            let targetValue = target.value;
            if (this.props.timeSlotBased) {
                let currSlot = this.props.timeSlotsByDate.filter(date => this.state.date === date.date)[0];
                currSlot = currSlot.timeSlots[target.options.selectedIndex];
                targetValue = currSlot;
            }
            // console.log('CHANGE STATE', target.options[target.selectedIndex].dataset);
            this.setState({
                ...this.state,
                [target.name]: targetValue,
                selectedOptionIndex: target.options.selectedIndex
            });
        } else if (target.name === 'date' && this.props.timeSlotBased) {
            let currSlot = this.props.timeSlotsByDate.filter(date => target.value === date.date)[0];
            if (!currSlot.timeSlots) {
                this.setState({
                    [target.name]: target.value,
                    time: null
                });
            } else {
                this.setState({
                    [target.name]: target.value,
                    time: currSlot.timeSlots[0]
                });
            }
        } else {
            this.setState({
                [target.name]: target.value
            });
        }

    }

    renderModalHeader() {

        const isHidden = (this.props.isCheckoutChangeTime) ? true : false;
        const customerLoggedIn = this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined
            && this.props.customer.id !== undefined && this.props.customer.id !== null && this.props.customer.id !== -1 && this.props.customer.email !== undefined
            && this.props.customer.email !== null && this.props.customer.email !== "null" && this.props.customer.email !== 'undefined';

        // Show close button on orderTimeScreen only when coming form menu page
        let showCloseModalButton = '';
        const orderTimeModalOpenedFromMenuScreen = this.props.temporaryOrderTime.currentPagePath.includes('/menu-screen/order-time');
        if (orderTimeModalOpenedFromMenuScreen) showCloseModalButton = 'start-order-custom-close-button-show-close-button';

        return (
            <Fragment>
                <div className='start-order-header-container' style={{ position: 'relative', top: 2 }}>
                    <Button className="start-order-custom-back-button" onClick={() => this.goBack()} style={{ opacity: (isHidden) ? 0 : 1, cursor: (isHidden) ? 'default' : 'pointer' }}>
                        <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    {(customerLoggedIn) ? this.renderCustomer() : ''}
                    <div style={{ width: 90, textAlign: 'right', position: 'relative', bottom: 2 }}>
                        <Button className={`start-order-custom-close-button ${showCloseModalButton}`} onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderCustomer() {
        const { profileNameStyle, profileNameStyleRTL, imageStyle, imageStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass += 'customer-long-text-back-button';
        }

        return (
            <div className='customer-name-mobile-style'>
                <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                <span style={isArabic ? profileNameStyleRTL : profileNameStyle} className={customerNameClass}>{userFirstName}</span>
            </div>
        )
    }

    renderOrderTypeString(selectedOrderType) {
        switch (selectedOrderType) {
            case 'Collection':
                return i18n.t('screens:selectOrderTypeScreen.collection');
            case 'Delivery':
                return i18n.t('screens:selectOrderTypeScreen.delivery');
            case 'Curbside':
                return i18n.t('screens:selectOrderTypeScreen.curbside')
            case 'In Store':
                return i18n.t('screens:selectOrderTypeScreen.dinePay')
            default:
                return
        }
    }

    renderIconSource(orderTypeId) {
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        switch (orderTypeId) {
            case COLLECTION_ID:
                return <ShoppingCartSVG width={28} height={28} fill={type === 'DARK' ? secondaryColor : primaryColor} />;
            case DELIVERY_ID:
                return <DeliverySVG style={isArabic ? { transform: 'scaleX(-1)' } : null} width={40} height={40} fill={type === 'DARK' ? secondaryColor : primaryColor} />;
            case CURBSIDE_ID:
                return <CurbsideSVG style={isArabic ? { marginTop: '6px' } : null} width={60} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} />;
            default:
                return
        }
    }

    renderModalBody() {
        const { heading, headingRTL } = styles;
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;

        const noContentOrShowContent = this.props.orderTypes.selectedOrderType.id == DELIVERY_ID && this.props.deliveryData === ''
            && Object.keys(this.props.deliveryData).length === 0 && this.props.estimateOrderTime.estimateOrderTime === null;

        const iconSource = this.renderIconSource(this.props.orderTypes.selectedOrderType.id);
        const isArabic = this.props.language === ARABIC;
        const isCurbside = iconSource ? iconSource.type.name === 'CurbsideSVG' ? true : false : false;

        if (!noContentOrShowContent && this.props.selectedRestaurant.name) {
            return (
                <React.Fragment>
                    {!this.props.renderInPwa ? this.renderModalHeader() : null}
                    <div className='modal-body-custom'>
                        <Col sm={12} className='modal-custom-body-title' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {iconSource}
                            <p className="text-center start-order-body-header-title" style={isArabic ? isCurbside ? { ...headingRTL, color: primaryFontColor, paddingRight: '0px' } : { ...headingRTL, color: primaryFontColor } : { ...heading, color: primaryFontColor }}>{this.renderOrderTypeString(this.props.orderTypes.selectedOrderType.value)}</p>
                        </Col>
                        <Col xs={12} className='modal-custom-body-inner-content' style={{ flexDirection: 'column', justifyContent: 'center', flex: 1 }}>
                            {this.renderOrderTypeInformation()}
                        </Col>
                        {this.renderModalFooter()}
                    </div>
                </React.Fragment>
            );
        } else if (noContentOrShowContent || !this.props.selectedRestaurant.name) {
            return (
                <React.Fragment>
                    {!this.props.renderInPwa ? this.renderModalHeader() : null}
                    <div className='modal-body-custom'>
                        <Col sm={12} className='modal-custom-body-title' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            {iconSource}
                            <p className="text-center start-order-body-header-title" style={isArabic ? { ...headingRTL, color: primaryFontColor } : { ...heading, color: primaryFontColor }}>{this.props.orderTypes.selectedOrderType.value}</p>
                        </Col>
                        <Col xs={12} className='modal-custom-body-inner-content'>
                            {this.renderNoContentMsg()}
                        </Col>
                        {this.renderModalFooter()}
                    </div>
                </React.Fragment>
            );
        }
    }

    renderOrderTypeInformation() {
        const { flexCenter, radioBtnOne, radioBtnOneRTL, radioBtnTwo, paddingFive, bodyText, changeLocation, orderType, orderTypeRTL, asap,
            chevronDownImg, chevronDownImgRTL, chevronDownImgDisabled, chevronDownImgDisabledRTL, hiddenRadioBtnTwo, option, orderFromContent, orderFromContentRTL } = styles;
        const { selectedOrderType } = this.props.orderTypes;

        let isHidden = this.state.radioOne ? true : false;
        const orderTypeInformation = this.props.orderTypes.selectedOrderType.id === DELIVERY_ID ? i18n.t('screens:orderTimeScreen.deliverFrom')
            : this.props.orderTypes.selectedOrderType.id === CURBSIDE_ID ? i18n.t('screens:savedCustomerAddressesScreen.curbsideFrom') : i18n.t('screens:savedCustomerAddressesScreen.collectFrom');
        const isDisabled = this.state.date === '' ? true : false;
        const isArabic = this.props.language === ARABIC;
        const dynamicStyleChange = this.state.date === '' ? isArabic ? chevronDownImgDisabledRTL : chevronDownImgDisabled : isArabic ? chevronDownImgRTL : chevronDownImg;
        const hideRadioTwo = this.props.estimateOrderTime.workingHoursByDate === null;
        const isRestaurantOpen = getRestaurantStatus(this.props.selectedRestaurant);
        let hideRadioOne = false;
        const innerWidthMobile = window.innerWidth <= 768;
        this.props.restaurantsInZone.map(restaurant => {
            const isRestaurantOpenStatus = getRestaurantStatus(restaurant);

            if (restaurant.restaurantId === this.props.selectedRestaurant.restaurantId && !isRestaurantOpenStatus && !restaurant.futureOrdersDisabled) {
                hideRadioOne = true;
                this.state.radioTwo = true;
                this.state.radioOne = false;
            }
        });

        if (!isRestaurantOpen && !this.props.selectedRestaurant.futureOrdersDisabled && !this.props.estimateOrderTime.estimateOrderTime) {
            hideRadioOne = true;
            isHidden = false;
            this.state.radioTwo = true;
            this.state.radioOne = false;
        } else if (isRestaurantOpen && !this.props.selectedRestaurant.futureOrdersDisabled && !this.props.estimateOrderTime.estimateOrderTime) {
            isHidden = false
            hideRadioOne = true;
            this.state.radioTwo = true;
            this.state.radioOne = false;
        } else if (!this.props.estimateOrderTime.estimateOrderTime) { // If estimateOrderTime comes as null hide asap option
            hideRadioOne = true;
            isHidden = false;
            this.state.radioTwo = false;
            this.state.radioOne = true;
        } else if (!isRestaurantOpen) {
            if (!this.props.selectedRestaurant.futureOrdersDisabled) {
                isHidden = false
            }
            hideRadioOne = true;
            this.state.radioTwo = true;
            this.state.radioOne = false;
        } else if (this.props.selectedRestaurant.workingDay && !this.props.estimateOrderTime.timeSlotBased) {
            const today = this.props.selectedRestaurant.workingDay;
            const todaysOpenHours = moment(`${today.openingHour.openHour}:${today.openingHour.openMinute}`, 'HH:mm');
            const todaysCloseHours = moment(`${today.openingHour.closeHour}:${today.openingHour.closeMinute}`, 'HH:mm');
            const todayDate = moment(moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY'), 'DD-MM-YYYY');
            const restaurantOpenDate = moment(this.props.selectedRestaurant.workingDay.date, 'DD-MM-YYYY');

            if (todaysOpenHours.isSameOrAfter(todaysCloseHours)) {
                todaysCloseHours.add(1, 'day');
            }

            if (todayDate.isAfter(restaurantOpenDate)) {
                todaysOpenHours.subtract(1, 'day')
            }

            const hh = moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('HH');
            const mm = moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds').format('mm');
            const isAtBreak = this.checkIfrestAtBreak(today, hh, mm);
            const isBetween = moment(`${hh}:${mm}`, 'HH:mm').isBetween(todaysOpenHours, todaysCloseHours, 'minutes', '[]');

            if (isBetween && isAtBreak || !isBetween) {
                hideRadioOne = true;
                this.state.radioTwo = true;
                this.state.radioOne = false;
            }
        }

        const preselectRadioBtnOneStyle = hideRadioOne === true ? { display: 'none' } : isArabic ? radioBtnOneRTL : radioBtnOne;
        let removePaddingFromLabel = isArabic ? (hideRadioOne || hideRadioTwo) ? { paddingRight: 0, cursor: 'auto' } : null : (hideRadioOne || hideRadioTwo) ? { paddingLeft: 0, cursor: 'auto' } : null;
        const isChangeLocationHidden = (this.props.isCheckoutChangeTime) ? true : false;
        const colWidth = (this.props.isCheckoutChangeTime) ? 12 : 8;
        const pwaMode = this.props.pwaAppRunning ? `/brand/${this.props.brand.id}/start-order/main-screen` : selectedOrderType.id === CURBSIDE_ID ? '/start-order/order-type' : '/start-order/user-address';

        const { primaryFontColor } = this.props.customerThemes.selectedTheme;

        let filteredWorkingHoursByDate;

        if (this.props.timeSlotsByDate || (this.props.estimateOrderTime && this.props.estimateOrderTime.workingHoursByDate)) {
            filteredWorkingHoursByDate = (this.props.timeSlotBased ? this.props.timeSlotsByDate : this.props.estimateOrderTime.workingHoursByDate).filter(day => {
                const isSameDay = moment(day.date.split('-').reverse().join('-')).isSame(moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds'), 'day');
                if (day.openingHour || day.timeSlots || isSameDay) {
                    return day;
                }
            })
        }

        return (
            <React.Fragment>
                <Col>
                    <FormGroup check style={flexCenter}>
                        <Label check className="custom-radio-btn" style={Object.assign({}, preselectRadioBtnOneStyle, removePaddingFromLabel, { color: primaryFontColor })} hidden={hideRadioOne}>{i18n.t('screens:orderTimeScreen.asap')}
                            <Input type="radio" name="radioOne" checked={this.state.radioOne} hidden={hideRadioTwo} onChange={this.changeRadioBtnState} />
                            <span className="checkmark" hidden={hideRadioOne || hideRadioTwo}></span>
                        </Label>
                        <Label check className="custom-radio-btn" style={Object.assign({}, hideRadioTwo ? { display: 'none' } : (hideRadioOne) ? { display: 'none' } : radioBtnTwo, removePaddingFromLabel, { color: primaryFontColor })} hidden={hideRadioTwo}>{i18n.t('screens:orderTimeScreen.selectTime')}
                            <Input type="radio" name="radioTwo" checked={this.state.radioTwo} hidden={hideRadioOne} onChange={this.changeRadioBtnState} />
                            <span className="checkmark" hidden={hideRadioTwo || hideRadioOne}></span>
                        </Label>
                    </FormGroup>
                </Col>
                <Row style={flexCenter} className="selectTimeRow">
                    <Col xs={12} sm={10} md={6} style={paddingFive}>
                        <InputGroup className="custom-input" hidden={isHidden}>
                            <InputGroupAddon addonType="prepend" style={{ zIndex: 3 }}>{i18n.t('screens:orderTimeScreen.date')}</InputGroupAddon>
                            <Input
                                className="custom-input" type="select" name="date" style={option} tabIndex={-1}
                                value={this.state.date} onChange={this.changeState}>
                                {
                                    this.props.timeSlotBased ?
                                        this.renderTimeSlotDates(filteredWorkingHoursByDate)
                                        : this.renderDateOptions(filteredWorkingHoursByDate)
                                }
                            </Input>
                            <img src={IconChevronDown} width="auto" height="24" style={isArabic ? chevronDownImgRTL : chevronDownImg} />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={10} md={6} style={paddingFive}>
                        <InputGroup className="custom-input" hidden={isHidden}>
                            <InputGroupAddon className={(isDisabled || (this.props.timeSlotBased && !this.state.time)) ? 'addonPrependDisabled' : 'addonPrepend'} disabled={isDisabled || (this.props.timeSlotBased && !this.state.time)} addonType="prepend" style={{ zIndex: 3 }}>{i18n.t('screens:orderTimeScreen.time')}</InputGroupAddon>
                            <Input disabled={isDisabled || (this.props.timeSlotBased && !this.state.time)} className={(isDisabled || (this.props.timeSlotBased && !this.state.time)) ? 'custom-input disabledInput' : 'custom-input'}
                                type="select" name="time" style={option} tabIndex={-1}
                                value={this.props.timeSlotBased && this.state.time ? `From ${moment(`${this.state.time.fromHour}:${this.state.time.fromMinute}`, 'HH:mm').format('HH:mm')} - To ${moment(`${this.state.time.toHour}:${this.state.time.toMinute}`, 'HH:mm').format('HH:mm')}` : this.state.time} onChange={this.changeState}>
                                {
                                    this.props.timeSlotBased ?
                                        this.renderTimeSlotsTimes(filteredWorkingHoursByDate)
                                        : this.renderTimeOptions(filteredWorkingHoursByDate)
                                }
                            </Input>
                            <img src={IconChevronDown} width="auto" height="24" style={dynamicStyleChange} />
                        </InputGroup>
                    </Col>
                    {isArabic ?
                        <Col xs={12} sm={10} hidden={!isHidden || hideRadioOne} style={asap}>
                            <p>{`${i18n.t('screens:orderTimeScreen.mins')} ${this.props.estimateOrderTime.estimateOrderTime} :${i18n.t('screens:orderTimeScreen.estimatedTime')}`}</p>
                        </Col> :
                        <Col xs={12} sm={10} hidden={!isHidden || hideRadioOne} style={asap}>
                            <p>{`${i18n.t('screens:orderTimeScreen.estimatedTime')}: ${this.props.estimateOrderTime.estimateOrderTime} ${i18n.t('screens:orderTimeScreen.mins')}`}</p>
                        </Col>
                    }
                </Row>
                <Row style={{ marginBottom: 16, marginTop: 0 }}>
                    <Col xs={8} style={Object.assign({}, isArabic ? { margin: 'auto', paddingLeft: 30 } : { margin: 'auto', paddingRight: 30 }, innerWidthMobile ? { marginRight: 'auto' } : { marginRight: isArabic ? '170px' : '120px' })} className="orderFrom orderFormContainer">
                        <Col xs={colWidth} style={isArabic ? orderFromContentRTL : orderFromContent} className="orderFrom">
                            <span style={isArabic ? orderTypeRTL : orderType}>{orderTypeInformation}</span>
                            {
                                isArabic ?
                                    <span style={{ color: primaryFontColor }}>{this.props.selectedRestaurant.name}, {this.props.brand.name}
                                    </span> :
                                    <span style={{ color: primaryFontColor }}>{this.props.brand.name}, {this.props.selectedRestaurant.name}
                                    </span>
                            }
                        </Col>
                        <Col xs={4} style={isArabic ? { float: 'left', paddingRight: '10px', marginRight: 0, textAlign: 'right' } : { float: 'right', paddingLeft: '10px', marginLeft: 0, textAlign: 'left' }} className="orderFrom orderFromChangeLocationTxt">
                            <NavLink hidden={isChangeLocationHidden} tag={Link} to={pwaMode} style={changeLocation}>
                                {
                                    selectedOrderType.id === CURBSIDE_ID ?
                                        i18n.t('screens:orderTimeScreen.restartOrder') :
                                        i18n.t('screens:orderTimeScreen.changeLocation')
                                }
                            </NavLink>
                        </Col>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderNoContentMsg() {
        const { noContentCol, noContentMessage } = styles;

        const { primaryFontColor } = this.props.customerThemes.selectedTheme;

        return (
            <Col xs={12} sm={12} style={noContentCol}>
                <p style={{ ...noContentMessage, color: primaryFontColor }}>{i18n.t('screens:orderTimeScreen.noDelivery')}</p>
            </Col>
        );
    }

    renderDateOptions(filteredWorkingHoursByDate) {
        if (filteredWorkingHoursByDate !== undefined && filteredWorkingHoursByDate !== null
            && filteredWorkingHoursByDate.length > 0) {

            return filteredWorkingHoursByDate.map((date, i) => {
                const day = date.openingHour === null ? null : date.openingHour.day;
                let formatedDate = this.formatDate(date.date, day);

                return (
                    <option className="custom-option" key={'date' + i} value={day === null ? '' : date.date}>{formatedDate}</option>
                );
            });
        }
    }

    formatDate(date, day) {
        let today, displayDate, lastNum, currentDay, unavailableText;
        today = moment().add(this.props.selectedRestaurant.restaurantTimeOffset, 'milliseconds');
        // let transformedToday = moment(today, 'DD-MM-YYYY').format('DD-MM-YYYY');
        // let isToday = transformedToday === date;
        const currentDate = date.split('-');
        const dd = currentDate[0];
        const mm = currentDate[1];
        displayDate = dd;

        if (dd > 0 && dd < 10) {                            // Removes zeros from dates between 1st and 9th
            const removeZeroFromDate = dd.split('0');
            displayDate = Number(removeZeroFromDate[1]);
        }

        lastNum = Number(dd.charAt(dd.length - 1));
        currentDay = '';

        switch (lastNum) {
            case 1:
            case 2:
            case 3:
                currentDay = `${displayDate}${i18n.t(`common:suffices.suffix_${lastNum}`)}`;
                break;
            default:
                currentDay = `${displayDate}${i18n.t('common:suffices.suffix')}`;
                break;
        }

        unavailableText = window.outerWidth < 786 ? i18n.t('screens:orderTimeScreen.unavailableMobile') : i18n.t(`screens:orderTimeScreen.unavailable`);

        if (day === null) return `${unavailableText} ${currentDay} ${i18n.t(`common:months.month_${mm}`)}`;

        const returnDate = parseInt(today.format('DD')) === parseInt(dd) ? i18n.t('screens:orderTimeScreen.today') : `${i18n.t(`common:days.weekday_${day}`)} ${currentDay} ${i18n.t(`common:months.month_${mm}`)}`;

        return returnDate;
    }

    renderTimeSlotDates(filteredWorkingHoursByDate) {
        if (filteredWorkingHoursByDate) {

            return filteredWorkingHoursByDate.map(timeSlot => {
                let day = moment(timeSlot.date, 'DD-MM-YYYY').format('d');
                // moment works with day values from 0 to 6 while backend sends 1 to 7
                day++;
                if (!timeSlot.timeSlots) {
                    day = null;
                }

                return <option
                    className='custom-option'
                    key={`date${timeSlot.date}`}
                    value={timeSlot.date}>
                    {this.formatDate(timeSlot.date, day)}
                </option>
            });
        }
    }

    renderTimeSlotsTimes(filteredWorkingHoursByDate) {
        if (filteredWorkingHoursByDate) {

            return filteredWorkingHoursByDate.map(timeSlot => {

                if (timeSlot.date === this.state.date) {
                    if (timeSlot.timeSlots) {

                        return timeSlot.timeSlots.map((currDateTimeSlot, i) => {
                            const { fromHour, fromMinute, toHour, toMinute } = currDateTimeSlot;

                            return <option
                                className='custom-option' key={'time' + i} data-itemindex={i}>
                                {`From ${moment(`${fromHour}:${fromMinute}`, 'HH:mm').format('HH:mm')} - To ${moment(`${toHour}:${toMinute}`, 'HH:mm').format('HH:mm')}`}
                            </option>
                        });
                    }
                }
            })
        }
    }

    renderTimeOptions(filteredWorkingHoursByDate) {
        if (filteredWorkingHoursByDate !== undefined && filteredWorkingHoursByDate !== null
            && filteredWorkingHoursByDate.length > 0) {
            let arraysByDate = this.transformTimeData(filteredWorkingHoursByDate);

            return arraysByDate.map(arrayByDate => {
                return arrayByDate.map((dateItem, i) => {
                    let dateItemSplit = dateItem.split('_');
                    let newDate = dateItemSplit[1];
                    let timeToBeDisplayed = dateItemSplit[0];

                    if (newDate === this.state.date) {
                        return (
                            <option className="custom-option" key={'time' + i} data-itemindex={i} value={this.formatTime(timeToBeDisplayed)}>{this.formatTime(timeToBeDisplayed)}</option>
                        );
                    }
                });
            });
        }
    }

    transformTimeData(filteredWorkingHoursByDate) {
        let arraysByDate = [];

        if (filteredWorkingHoursByDate) {
            filteredWorkingHoursByDate.map(time => {
                if (time.openingHour !== null) {
                    let openHour = time.openingHour.openHour;
                    let openMinute = time.openingHour.openMinute;
                    let closeHour = time.openingHour.closeHour;
                    let closeMinute = time.openingHour.closeMinute;
                    let iterationNumber;

                    const firstCondition = openMinute > closeMinute;
                    const secondCondition = openMinute < closeMinute;
                    let workingTime = closeHour - openHour;

                    if (closeHour < openHour) { // Work time after midnight
                        workingTime = 24 - openHour + closeHour;
                    } else if (closeHour === 0 && openHour === 0 && closeMinute === 0 && openMinute === 0) {
                        workingTime = 24;
                    }

                    if (workingTime === 24 && openMinute === 0 && closeMinute === 0) {
                        iterationNumber = workingTime * 4;// - 1; // TODO For some reason -1 was added as a case - will leave it for now
                    } else if (firstCondition) {
                        iterationNumber = (workingTime) * 4 - ((openMinute - closeMinute) / 15);
                    } else if (secondCondition) {
                        iterationNumber = (workingTime) * 4 + ((closeMinute - openMinute) / 15);
                    } else {
                        iterationNumber = (workingTime) * 4;
                    }
                    const timePeriods = [];

                    let mins = openMinute;
                    let hours = openHour;

                    for (let i = 0; i <= iterationNumber; i++) {
                        let item, isRestaurantAtBreak = false;
                        if (i === 0) {
                            isRestaurantAtBreak = this.checkIfrestAtBreak(time, hours, mins);

                            if (!isRestaurantAtBreak) {
                                item = `${hours}:${mins}_${time.date}`;
                            }
                        } else {
                            mins += 15;
                            if (mins >= 60) {
                                hours++;
                                mins -= 60;
                            }

                            isRestaurantAtBreak = this.checkIfrestAtBreak(time, hours, mins);

                            if (!isRestaurantAtBreak) {
                                item = `${hours}:${mins}_${time.date}`;
                            }
                        }
                        if (item) {
                            timePeriods.push(item);
                        }
                    }
                    arraysByDate.push(timePeriods);
                }
            });
        }

        return arraysByDate;
    }

    checkIfrestAtBreak(time, hours, mins) {
        let isRestaurantAtBreak = false;

        time.openingHour.breakTimes.map((breakTime, i) => {
            let breakTimePeriodStartValue = `${breakTime.startHour}:${breakTime.startMinute}`;
            let breakTimePeriodStart = moment(breakTimePeriodStartValue, 'HH:mm');
            let breakTimePeriodValue = `${breakTime.endHour}:${breakTime.endMinute}`;
            let breakTimePeriodEnd = moment(breakTimePeriodValue, 'HH:mm');
            let currentTime = moment(`${hours}:${mins}`, 'HH:mm');

            if (currentTime.isBetween(breakTimePeriodStart, breakTimePeriodEnd, breakTimePeriodStartValue, breakTimePeriodValue) && (!currentTime.isSame(breakTimePeriodStart) && !currentTime.isSame(breakTimePeriodEnd))) {
                isRestaurantAtBreak = true;
            }
        });

        return isRestaurantAtBreak;
    }

    formatTime(timeToBeDisplayed) { // Added to ease the change from 24 hour format to 12 with am/pm
        let timeToBeDisplayedSplit = timeToBeDisplayed.split(':');
        let hours = timeToBeDisplayedSplit[0];                  // Cast is NOT here because '.length' needs string
        let minutes = timeToBeDisplayedSplit[1];

        if (Number(hours) >= 24) {
            hours -= 24;
        }

        if (minutes.length === 1) {
            minutes = '00';
        }

        if (hours >= 0 && hours < 10) {
            return `0${hours}:${minutes}`;
        } else {
            return `${hours}:${minutes}`;
        }
    }

    renderModalFooter() {
        const { btnColumn } = styles;
        const isHidden = this.props.orderTypes.selectedOrderType.id === DELIVERY_ID && this.props.deliveryData === ''
            && Object.keys(this.props.deliveryData).length === 0 && this.props.estimateOrderTime.estimateOrderTime === null;
        const { buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;

        let nextButtonStyle = '';

        if ((this.state.date === '' && !this.state.radioOne) || (this.props.timeSlotBased && !this.state.time)) {
            nextButtonStyle = 'disabledNextButton';
        } else {
            nextButtonStyle = 'nextButton';
        }

        const buttonNextStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold,
            fontStyle: fontItalic,
            textTransform: uppercase,
        }

        let isPwa = this.state.pwaAppRunning;
        let columnWidth = isPwa ? { size: 10 } : isMobileOnly ? { size: 10, offset: 1 } : isTablet && window.screen.width < 768 ? { size: 10, offset: 1 } : { size: 10 };

        if (this.props.isCheckoutChangeTime || this.props.isChangeTime) {
            return (
                <Row className='modal-custom-body-footer order-times-screen' style={{ padding: '0px 12px' }}>
                    <Col sm={columnWidth} md={12} style={btnColumn} hidden={(isHidden || !this.props.selectedRestaurant.name)}>
                        <Button className={nextButtonStyle} style={buttonNextStyle} onClick={() => this.goForward()}>
                            <span style={{ margin: '0 auto', textAlign: 'center' }}>{i18n.t('common:buttons.changeOrderTime')}</span>
                        </Button>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className='modal-custom-body-footer order-times-screen' style={{ padding: '0px 12px' }}>
                    <Col sm={{ size: 10, offset: 1 }} md={{ size: 12, offset: 0 }} style={btnColumn} hidden={(isHidden || !this.props.selectedRestaurant.name)}>
                        <Button className={nextButtonStyle} onClick={this.goForward}>
                            <span style={buttonNextStyle}>{i18n.t('common:buttons.nextBtn')}</span>
                            <span className="btnNextIcon">
                                <MaterialIcon icon="navigate_next" color={buttonsFont} size={35} />
                            </span>
                        </Button>
                    </Col>
                </Row>
            );
        }
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;
        const topPosition = this.props.pwaAppRunning ? 60 : 0;
        const containerPosition = this.props.pwaAppRunning ? 'fixed' : 'absolute';

        if (this.props.loading.transparentLoader) {
            return (
                <div style={Object.assign({}, loadingContainerTransparent, { top: topPosition, position: containerPosition })}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={10} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderLoaderContainer() {
        const { loadingContainer, loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;

        if (this.props.loading.backgroundLoader) {
            return (
                <div style={loadingContainer}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={10} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, fontWeight, textDecoration, startOrderFontSize, mainTextColor, chefNoteBackground, menuItemsBackground, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase, inactiveColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.headingRTL.fontSize = startOrderFontSize;
        customizedStyles.changeLocation.color = primaryColor;
        customizedStyles.changeLocation.fontWeight = fontWeight;
        customizedStyles.changeLocation.textDecoration = textDecoration;
        customizedStyles.option.fontWeight = fontWeight;
        customizedStyles.orderType.color = mainTextColor;
        customizedStyles.orderTypeRTL.color = mainTextColor;
        customizedStyles.chevronDownImg.backgroundColor = menuItemsBackground;
        customizedStyles.chevronDownImgRTL.backgroundColor = menuItemsBackground;
        customizedStyles.loadingContainerTransparent.background = menuItemsBackground;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.profileNameStyleRTL.color = primaryFontColor;
        customizedStyles.asap.color = primaryFontColor;
        customizedStyles.heading.fontFamily = fontFamilyType;
        customizedStyles.headingRTL.fontFamily = fontFamilyType;
        customizedStyles.heading.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.headingRTL.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.heading.fontStyle = fontItalic;
        customizedStyles.headingRTL.fontStyle = fontItalic;
        customizedStyles.heading.textTransform = uppercase;
        customizedStyles.headingRTL.textTransform = uppercase;
        customizedStyles.chevronDownImgDisabled.backgroundColor = inactiveColor;
        customizedStyles.chevronDownImgDisabledRTL.backgroundColor = inactiveColor;
        customizedStyles.option.color = primaryFontColor;
        styles = customizedStyles;
        return styles;
    }

    render() {

        return (
            <Fragment>
                {this.renderLoaderContainer()}
                {this.renderTransparentBackgroundLoaderContainer()}
                <AvForm className="start-order">
                    <ModalStep
                        onToggle={this.props.toggle}
                        renderBody={this.renderModalBody()}
                        styles={styles}
                        customerThemes={this.props.customerThemes}
                    />
                </AvForm>
            </Fragment>

        );
    }
}

const mapStateToProps = (state, props) => {

    const estimateOrderTime = (props.isCheckoutChangeTime !== undefined && props.isCheckoutChangeTime !== null && props.isCheckoutChangeTime) ? state.temporaryOrderTime : state.estimateOrderTime;

    return {
        loading: state.loading,
        brand: state.brand,
        customer: state.customer,
        orderTypes: state.orderTypes,
        estimateOrderTime: estimateOrderTime,
        selectedRestaurant: state.selectedRestaurant,
        deliveryData: state.deliveryData,
        restaurantsInZone: state.restaurantsInZone,
        temporaryOrderTime: state.temporaryOrderTime,
        customerThemes: state.customerThemes,
        pwaAppRunning: state.pwaAppRunning,
        timeSlotBased: state.estimateOrderTime.timeSlotBased,
        timeSlotsByDate: state.estimateOrderTime.timeSlotsByDate,
        realEstimateOrderTime: state.estimateOrderTime,
        userAddress: state.customer.selectedDeliveryAddress,
        language: state.storeLocalStorage.language
    }
}
export default connect(mapStateToProps, {
    getEstimateOrderTime,
    getTemporaryEstimateOrderTime,
    saveOrderTimes,
    changeOrderTimes,
    activateLoader,
    stopLoader,
    getPaymentProvider,
    saveNavigationType
})(OrderTimeScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    bodyHeading: {
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        textAlign: 'center'
    },
    btnColumn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        // marginLeft: 0
    },
    submitBtn: {
        display: 'flex',
        justifyContent: 'center',
        height: 46,
        borderRadius: 8,
        width: '100%',
        margin: '0 5px 26px 5px',
        border: 'none',
        backgroundColor: '#61B522'
    },
    submitBtnDisabled: {
        display: 'flex',
        justifyContent: 'center',
        height: 46,
        borderRadius: 8,
        width: '100%',
        margin: '0 5px 38px 5px',
        border: 'none',
        backgroundColor: '#a8a8a8',
        pointerEvents: 'none'
    },
    btnText: {
        paddingTop: 1,
        position: 'relative',
        left: 4
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 15px',
        margin: 'auto'
    },
    radioBtnOne: {
        fontSize: 16,
        marginRight: 20
    },
    radioBtnOneRTL: {
        fontSize: 16,
        marginLeft: 20
    },
    radioBtnTwo: {
        fontSize: 16,
    },
    paddingFive: {
        padding: 5
    },
    bodyText: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 12
    },
    changeLocation: {
        color: '#61B522',
        cursor: 'pointer'
    },
    orderType: {
        fontStyle: 'italic',
        color: '#D0D3D2',
        marginRight: 8
    },
    orderTypeRTL: {
        fontStyle: 'italic',
        color: '#D0D3D2',
        marginLeft: 8
    },
    chevronDownImg: {
        bottom: 14,
        position: 'absolute',
        zIndex: 1,
        backgroundColor: '#FFF',
        right: 4,
        paddingRight: 6,
    },
    chevronDownImgRTL: {
        bottom: 14,
        position: 'absolute',
        zIndex: 1,
        backgroundColor: '#FFF',
        left: 4,
        paddingLeft: 6,
    },
    asap: {
        height: 50
    },
    noContentCol: {
        display: 'flex',
        justifyContent: 'center'
    },
    noContentMessage: {
        display: 'flex',
        paddingBottom: 30
    },
    chevronDownImgDisabled: {
        bottom: 14,
        position: 'absolute',
        zIndex: 81000,
        backgroundColor: '#e9ecef',
        right: 7,
        cursor: 'not-allowed !important'
    },
    chevronDownImgDisabledRTL: {
        bottom: 14,
        position: 'absolute',
        zIndex: 81000,
        backgroundColor: '#e9ecef',
        left: 7,
        cursor: 'not-allowed !important'
    },
    hiddenRadioBtnTwo: {
        pointerEvents: 'none'
    },
    login: {
        fontSize: 22,
        marginLeft: 12,
        marginRight: 12
    },
    heading: {
        fontSize: 22,
        paddingLeft: 18,
        paddingTop: 20,
        width: 'auto',
        // margin: 'auto',
        // textAlign: 'center'
    },
    headingRTL: {
        fontSize: 22,
        paddingRight: 18,
        paddingTop: 20,
        width: 'auto',
    },
    imageStyle: {
        // paddingRight: 5,
        // marginTop: '-15px'
        marginRight: 16
    },
    imageStyleRTL: {
        // paddingRight: 5,
        // marginTop: '-15px'
        marginLeft: 16
    },
    centredItem: {
        margin: '0 auto',
        textAlign: 'center',
        fontSize: 20,
        paddingRight: 55
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72
    },
    option: {
        fontWeight: 300
    },
    loadingContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: '#fff',
        zIndex: 100000000
    },
    loadingIndicator: {
        width: '72px',
        height: '72px',
    },
    loadingContainerTransparent: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // color: '#ffffff',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: '#FFF',
        zIndex: 100000000
    },
    orderFromContent: {
        float: 'left',
        paddingRight: 0,
        paddingLeft: 5,
        marginRight: -18,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    orderFromContentRTL: {
        float: 'right',
        paddingLeft: 0,
        paddingRight: 5,
        marginLeft: -18,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}
import React, {Component} from 'react';
import {connect} from 'react-redux';
import i18n from '../../../../i18next';
import ApplicationStep from '../../common/ApplicationStep';

import {Col} from 'reactstrap';
import {setLastOpenedPagePWA} from "../../../../utils/CommonUtils";
import DiscountsContentPwa from './DiscountsContentPwa';

class DiscountsPwa extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoyalty: window.location.href.includes('loyalty')
        }

        this.onBackKeyDown = this.onBackKeyDown.bind(this);
    }

    componentDidMount() {
        setLastOpenedPagePWA(this.props.openChangeOrderTimeScreen);
        document.addEventListener("backbutton", this.onBackKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("backbutton", this.onBackKeyDown, false);
    }

    onBackKeyDown(e) {
        e.preventDefault();
        e.stopPropagation();
        this.goBack();
    }

    goBack() {
        this.props.history.push(`/brand/${this.props.brandId}/order/menu`);
    }

    renderDiscountsContent() {
        let filteredDiscounts, copyOfAvailableDiscounts, singleDiscount;
        copyOfAvailableDiscounts = this.props.availableDiscounts.slice(0);

        if (this.state.isLoyalty) {
            filteredDiscounts = copyOfAvailableDiscounts;
        } else {
            filteredDiscounts = copyOfAvailableDiscounts.filter(function (discount) {
                return discount.withPromoCodes === false;
            });
        }

        singleDiscount = filteredDiscounts.length === 1 ? 'discounts-container-single' : 'discounts-container';

        return (
            <DiscountsContentPwa
                renderColumn={this.renderColumn.bind(this)}
                singleDiscount={singleDiscount}
                filteredDiscounts={filteredDiscounts}
                styles={styles}
                selectedTheme={this.props.customerThemes.selectedTheme}
            />
        );
    }

    renderColumn() {
        const {columnStyle, subHeading} = styles;
        return (
            <Col sm={12} style={columnStyle}>
                <div style={subHeading}>*{i18n.t('screens:discountsScreen.subHeading')}</div>
            </Col>
        );
    }

    render() {

        const text = this.state.isLoyalty ? i18n.t('screens:discountsScreen.headingTwo') : i18n.t('screens:discountsScreen.discounts');

        return (
            <ApplicationStep
                hideBackButton={false}
                transparentHeader={false}
                text={text}
                content={this.renderDiscountsContent()}
                goBack={this.goBack.bind(this)}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        customerThemes: state.customerThemes
    }
}

export default connect(mapStateToProps, {})(DiscountsPwa);

let styles = {
    columnStyle: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    subHeading: {
        fontSize: 12
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { getOrderTypes, saveBrandIdToStore, setConnectionStatus, clearShoppingCart, allowTimeWarningPopup } from '../../../actions/index';
import { updateCSSVariables } from '../../../utils/ThemesSelector';
import MainScreenPwa from './MainScreenPwa';
import ConfirmAddressPwa from './ConfirmAddressPwa';
import OrderTimePwa from './OrderTimePwa';
import RestaurantSelectionPwa from './RestaurantSelectionPwa';
import CarDetailsScreenPwa from './CarDetailsScreenPwa';

class PwaStartContainer extends Component {
    componentDidMount() {
        this.props.allowTimeWarningPopup(false);
        let urlHash, urlHashItems, brandId;

        urlHash = window.location.hash;
        urlHashItems = urlHash.split('/');

        for (let i = 0; i <= urlHashItems.length; i++) {
            if (urlHashItems[i] === 'brand') {
                brandId = urlHashItems[i + 1];
            }
        }
        this.props.saveBrandIdToStore(brandId);
        this.props.clearShoppingCart();

        this.applyTheme();

        // Listen from online/offline mode for the app
        this.checkOnlineStatus();
        window.addEventListener('online', this.checkOnlineStatus.bind(this));
        window.addEventListener('offline', this.checkOnlineStatus.bind(this));
    }

    checkOnlineStatus() {
        let networkState = navigator.connection.type;
        let userIsOnline = false;

        if (networkState !== 'none') {
            userIsOnline = true;
            this.props.setConnectionStatus(userIsOnline, this.props.history);
        } else {
            userIsOnline = false;
            this.props.setConnectionStatus(userIsOnline, this.props.history);
        }
    }

    applyTheme() {
        updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
    }

    render() {
        return (
            <div>
                <Switch>
                    {/* Start order and login routes  */}
                    <Route path='/brand/:id/start-order/main-screen'
                        render={(props) => (
                            <MainScreenPwa {...props} />
                        )} />
                    <Route path='/brand/:id/start-order/confirm-address'
                        render={(props) => (
                            <ConfirmAddressPwa {...props} />
                        )} />
                    <Route path='/brand/:id/start-order/restaurant-selection'
                        render={(props) => (
                            <RestaurantSelectionPwa {...props} />
                        )} />
                    <Route path='/brand/:id/start-order/order-time'
                        render={(props) => (
                            <OrderTimePwa {...props} />
                        )} />
                    <Route path='/brand/:id/start-order/car-details'
                        render={(props) => (
                            <CarDetailsScreenPwa {...props} />
                        )} />
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        customerThemes: state.customerThemes
    };
};

export default connect(mapStateToProps, { getOrderTypes, saveBrandIdToStore, setConnectionStatus, clearShoppingCart, allowTimeWarningPopup })(PwaStartContainer);
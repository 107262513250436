import React from 'react';
import { Card, CardImg, CardTitle, CardText, CardBody } from 'reactstrap'; 

const PromotionCard = (props) => {
    const { promotionImage, promotion, styles } = props;

    if (!styles.promotionNameStyle) {
        const { fontFamilyType, fontBold, fontItalic, uppercase } = props.selectedTheme
        styles.promotionNameStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
    }

    return (
        <Card className="discount-card" style={{...styles.card, border: 'none'}}>
                <div className="discount-card-header">
                    <CardImg hidden={!promotionImage} top src={promotionImage} style={styles.discountImage}/>
                    <p hidden={promotionImage} style={styles.promotionNameStyle}>{promotion.name}</p>
                </div>
            <CardBody style={{backgroundColor: props.selectedTheme.restInfoBackground, color: props.selectedTheme.primaryFontColor}}>
                <CardTitle style={styles.promotionNameStyle}>{promotion.name}</CardTitle>
                <CardText style={styles.description}>{promotion.description}</CardText>
            </CardBody>
        </Card>
    );
}

export default PromotionCard;
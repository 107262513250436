import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PromotionPartnerAdapter from '../../../../components/order/promotions/PromotionPartnerAdapter';
import ShoppingCart from '../../../../components/order/shoppingCart/ShoppingCart';
import i18n from '../../../../i18next';
import ApplicationStep from '../../common/ApplicationStep';
import { resetShowComoError, togglePromotionsModal } from '../../../../actions/index';

class PromotionsScreenPwa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comoInformation: null,
            rejectedRegistration: false
        }

        this.sendInfoToShoppingCart = this.sendInfoToShoppingCart.bind(this);
        this.redirectNotVerifiedUser = this.redirectNotVerifiedUser.bind(this);
    }

    componentWillUnmount =() => {
        this.props.resetShowComoError();

        if(this.props.isPromotionsModalOpen) {
            this.props.togglePromotionsModal();
        }
    }

    rejectedRegistrationOnClick = () => {
        this.setState({
            rejectedRegistration: true
        })
    }

    goBack() {
        const brandId = this.props.brand.id;
        
        this.props.history.push(`/brand/${brandId}/order/menu`);
    }

    redirectNotVerifiedUser() {
        const { id } = this.props.brand;
        this.props.history.push(`/brand/${id}/order/checkout`);
    }

    sendInfoToShoppingCart(comoInformation) {
        this.setState({
            comoInformation
        })
    }

    renderPromotionsBody() {
        const { menuBackground } = this.props.customerThemes.selectedTheme;
        const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;
        const membershipStatus = this.props.como.membership ? this.props.como.membership.status : null;

        return (
            <div style={membershipStatus === 'Inactive' ? { width: '100%', height: '100%', overflowY: 'hidden', backgroundColor: menuBackground } : { width: '100%', backgroundColor: menuBackground }}>
                <PromotionPartnerAdapter
                    sendInfoToShoppingCart={this.sendInfoToShoppingCart}
                    rejectedRegistrationOnClick={this.rejectedRegistrationOnClick}
                    rejectedRegistration={this.state.rejectedRegistration}
                    redirectNotVerifiedUser={this.redirectNotVerifiedUser}
                />
                {this.state.rejectedRegistration || (isLoggedInPromotionPartner && membershipStatus === 'Active') ?
                    <ShoppingCart
                        history={this.props.history}
                        isCheckoutScreen={false}
                        comoInformation={this.state.comoInformation}
                    /> : null
                }
            </div>
        )
    }

    render() {
        return (
            <Fragment>
                <ApplicationStep
                    hideBackButton={false}
                    transparentHeader={false}
                    goBack={this.goBack.bind(this)}
                    text={i18n.t('screens:como.mainSectionHeader')}
                    content={this.renderPromotionsBody()}
                    isLoadedFromCheckout={false}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {

    return {
        brand: state.brand,
        promotionPartners: state.promotionPartners,
        customerThemes: state.customerThemes,
        shoppingCart: state.shoppingCart,
        payment: state.payment,
        customer: state.customer,
        como: state.como,
        isPromotionsModalOpen: state.commonReducer.isPromotionsModalOpen
    };
};

export default connect(mapStateToProps, { resetShowComoError, togglePromotionsModal })(PromotionsScreenPwa);
export const checkIfProviderScriptExists = (providerID) => {
    let selectedProviderFound = false;

    switch (providerID) {
        case 1:
            if (document.getElementById('stripe-js')) selectedProviderFound = true;
            return selectedProviderFound;
        case 2:
            if (document.getElementById('braintree') && document.getElementById('hostedfields')) selectedProviderFound = true;
            return selectedProviderFound;
        case 3:
            if (document.getElementById('mollie')) selectedProviderFound = true;
        default:
            return selectedProviderFound;
    }
}

export const addScriptToIndexHtml = (provider, callback) => {

    let head = document.getElementsByTagName('head')[0];
    let body = document.getElementsByTagName('body')[0];
    let script = document.createElement('script');

    if (provider.providerType.id === 1) {
        script.src = 'https://js.stripe.com/v3/';
        script.id = 'stripe-js';
        script.async = true;
    } else if (provider.providerType.id === 2) {
        let hostedFieldsScript = document.createElement('script');
        let threeDSecureScript = document.createElement('script');
        script.id = 'braintree'
        script.src = 'https://js.braintreegateway.com/web/3.57.0/js/client.min.js';
        hostedFieldsScript.id = 'hostedfields';
        hostedFieldsScript.src = 'https://js.braintreegateway.com/web/3.57.0/js/hosted-fields.min.js';
        threeDSecureScript.id = 'threeDsecure';
        threeDSecureScript.src = 'https://js.braintreegateway.com/web/3.57.0/js/three-d-secure.min.js'
        head.appendChild(hostedFieldsScript);
        head.appendChild(threeDSecureScript);
    } else if (provider.providerType.id === 3) {
        script.id = 'mollie';
        script.src = 'https://js.mollie.com/v1/mollie.js';
        script.async = true;
    }

    if (provider.providerType.id === 3) {
        body.appendChild(script);
    } else {
        head.appendChild(script);
    }

    if (callback) callback();
}

export const removeThirdPartyScripts = (providerID) => {
    let thirdPartyEl = null, secondThirdPartyEl = null;

    switch (providerID) {
        case 3:
            thirdPartyEl = document.getElementById('mollie');
            secondThirdPartyEl = document.getElementsByClassName('mollie-components-controller')[0];
            thirdPartyEl.parentNode.removeChild(thirdPartyEl);
            secondThirdPartyEl.parentNode.removeChild(secondThirdPartyEl);
            break;
        default:
            break;
    }
}
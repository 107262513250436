import {
    GET_PROMOTION_PARTNER,
    TOGGLE_PROMO_PARTNER_MODAL,
    USER_LOGGED_IN_PARTNER,
    SET_PARTNER_DISCOUNT_TOTAL,
    SET_APPLIED_PROMOTIONS,
    SET_USER_ANSWER,
    VOID_PURCHASE,
    CLEAR_APP_STATE,
    CLEAR_STATE
} from '../../actions/types';

const INITIAL_STATE = {
    promotionPartner: null,
    isPromoPartnerModalOpen: false,
    isUserLoggedInPartner: false,
    promoPartnerAppliedPromos: [],
    promoPartnerDiscountTotal: 0,
    userAnswer: null
};

export default (state = INITIAL_STATE, action) => {

    const { type, payload } = action;

    switch (type) {
        case GET_PROMOTION_PARTNER:

            return {
                ...state,
                promotionPartner: payload
            };
        case TOGGLE_PROMO_PARTNER_MODAL:

            return {
                ...state,
                isPromoPartnerModalOpen: !state.isPromoPartnerModalOpen
            };
        case USER_LOGGED_IN_PARTNER:

            return {
                ...state,
                isUserLoggedInPartner: payload
            };
        case SET_PARTNER_DISCOUNT_TOTAL:

            return {
                ...state,
                promoPartnerDiscountTotal: payload
            };
        case SET_APPLIED_PROMOTIONS:

            return {
                ...state,
                promoPartnerAppliedPromos: payload
            };
        case SET_USER_ANSWER:

            return {
                ...state,
                userAnswer: payload
            };
        case VOID_PURCHASE:

            return {
                ...state,
                promoPartnerAppliedPromos: [],
                promoPartnerDiscountTotal: 0
            };
        case CLEAR_STATE:

            return INITIAL_STATE;
        case CLEAR_APP_STATE:

            return INITIAL_STATE;
        default:
            
            return state;
    }
};
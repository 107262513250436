import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import i18n from '../../i18next';
import { Button, Col, Modal, NavLink, Row } from 'reactstrap';
import { isMobile, isMobileOnly } from 'mobile-device-detect';

import {
    loadPaymentTypes,
    selectPaymentType,
    getCustomerPaymentCards,
    selectCustomerPaymentCard,
    clearPaymentCard,
    saveGeneralInstructions,
    clearWizardData,
    editProduct, // New Wizard edit
    addProductToCart,
    closeStartOrderScreen,
    openChangeOrderTimeScreen,
    clearTemporaryOrderTimeData,
    saveNewOrderTime,
    clearState,
    getSelectedProduct,
    stopLoader,
    processingPayment,
    closeSendingOrderModal,
    saveCurrentPathToStore,
    allowTimeWarningPopup,
    getOldestActiveLoyalty,
    setMenuOrOrderHistoryScreen,
    toggleUnavailableProductsScreenOpen,
    changeAppleGooglePayAvailability,
    changeCodeManuallySet,
    updateProfileBackBtnPath,
    getRestaurantPauseStatus,
    toggleShoppingCartModal,
    voidPurchase,
    resetPromoCodeField,
    resetOldDiscountFlag,
    cancelComoPayment,
    applyGiftCard,
    clearGiftCardsErrorMessage,
    deleteCustomerCard,
    sendTbkToken
} from "../../actions/index";

import { getLoggedInUserFirstName, getQueryInfoFromUrl } from '../../utils/CommonUtils';
import { pushDataToGoogleTagManager } from '../../utils/AnalyticsUtils';
import { updateCSSVariables } from '../../utils/ThemesSelector';
import { sendMessageToParentSite, parentLocalStorage } from "../../utils/CrossOriginUtils";

import ConfirmationModal from '../common/ConfirmationModal';
import SendingOrderModal from './SendingOrderModal';
import OrderTimeScreen from './start/OrderTimeScreen';
import UnavailableProductsScreen from './UnavailableProductsScreen';
import CheckoutPaymentInformation from '../../pwa-app/components/order/checkout/CheckoutPaymentInformation';
import CheckoutInformation from '../../pwa-app/components/order/checkout/CheckoutInformation';
import ShoppingCart from './shoppingCart/ShoppingCart';
import PaymentAdapter from './providers/PaymentAdapter';
import MenuLoaderContainer from './menu/MenuLoaderContainer';
import { ARABIC, FRONT_END_URL } from '../../utils/Constants';
import SavedCardsModal from './SavedCardsModal';

class CheckoutScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            generalInstructions: '',
            activeClass: false,
            openConfirmationDialog: false,
            selectedPaymentId: null,
            selectedPaymentCard: {},
            paymentType: '',
            selectedProductWizardOpened: false,
            generalProductWizardOpened: false,
            isChangeTimeScreenOpened: false,
            stateAlreadyCopiedFromProps: false,
            isLoadedFromIntegration: false,
            fullscreenOffset: 200,
            isProductWizardOpen: false,
            isAppleGooglePayAvailable: false,
            openConfirmationModal: false,
            currentGiftCard: {
                cardId: '',
                amount: 0
            },
            isSavedCardModalOpen: false,
            selectedCardInfo: null,
            openDeleteCardDialog: false,
            selectedCard: ''
        };

        this.changeState = this.changeState.bind(this);
        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.toggleConfirmationDialog = this.toggleConfirmationDialog.bind(this);
        this.toggleSendingOrderModal = this.toggleSendingOrderModal.bind(this);
        this.confirmOrderReset = this.confirmOrderReset.bind(this);
        this.goBack = this.goBack.bind(this);
        this.toggleChangeTimeScreen = this.toggleChangeTimeScreen.bind(this);
        this.toggleSavedCardsModal = this.toggleSavedCardsModal.bind(this);

        this.iosTouchEvents = this.iosTouchEvents.bind(this);
        this.componentScrollEvents = this.componentScrollEvents.bind(this);
        this.selectCard = this.selectCard.bind(this);
        this.clearPaymentCard = this.clearPaymentCard.bind(this);
        this.selectPayment = this.selectPayment.bind(this);
        this.onBackKeyDown = this.onBackKeyDown.bind(this);
        this.removeGiftCard = this.removeGiftCard.bind(this);
        this.applyGiftCardInformation = this.applyGiftCardInformation.bind(this);
        this.deleteSavedCreditCard = this.deleteSavedCreditCard.bind(this);
        this.renderSavedCardModalBody = this.renderSavedCardModalBody.bind(this);
        this.toggleDeleteCardDialog = this.toggleDeleteCardDialog.bind(this);
        this.confirmDeleteCard = this.confirmDeleteCard.bind(this);
    }

    componentWillMount() {
        this.props.getRestaurantPauseStatus();

        if (!this.props.menuPreviewMode.previewMode) {
            this.props.loadPaymentTypes();
            this.props.getCustomerPaymentCards();
        }

        // Listen for route change and stop the loader from checkout
        this.props.history.listen((location, action) => {
            this.props.stopLoader();
        });

        if (window.performance) {
            if (performance.navigation.type == 0 || this.props.sendOrderModal.sendOrderModalOpened) {
                this.props.closeSendingOrderModal();
            }
        }
    }

    componentDidMount() {
        const { type, menuBackground } = this.props.customerThemes.selectedTheme;
        const totalWithGiftCardIsZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 : false;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;


        parentLocalStorage('get', 'integrationDetected')
            .then(localStorageData => {
                if (new URLSearchParams(window.location.search).get('brandId') || localStorageData === 'true') {
                    this.setState({
                        ...this.state,
                        isLoadedFromIntegration: true
                    })
                }
            });

        document.getElementById('root').style.backgroundColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? menuBackground : '#fff';
        document.getElementById('root').style.height = '100vh';
        document.getElementById('root').style.overflow = 'auto';

        pushDataToGoogleTagManager('event', 'checkout', this.props.pwaAppRunning, this.props.shoppingCart, this.props.restaurantProducts, this.props.restaurantCategories, this.props.selectedRestaurant.currency);

        this.props.allowTimeWarningPopup(true);

        document.getElementsByClassName('checkoutTitle')[0].parentNode.parentNode.scrollIntoView({ block: "start" });

        this.applyTheme();

        //Handle integration omnipresent NavBar hide on scroll

        if (this.state.isLoadedFromIntegration) {
            const userAgent = window.navigator.userAgent;
            let userOnMobileIos = userAgent.match(/iPhone; CPU iPhone OS/) || userAgent.match(/CPU iPhone/) || userAgent.match(/iPad;/);
            if (userOnMobileIos) {
                // console.log('iOS Detected');
                document.addEventListener('touchend', this.iosTouchEvents, false);
            } else {
                // console.log('Not iOS Detected');
                document.addEventListener('scroll', this.componentScrollEvents, false);
            }
        }

        // Gets an active loyalty code whenever user is logged in
        const { customer } = this.props;
        const appliedPromotions = this.props.shoppingCart ? this.props.shoppingCart.appliedPromotions : null;
        let isAppliedPromotionWithPromoCode = false;
        if(appliedPromotions && appliedPromotions.length) {
            appliedPromotions.map((promotion, index) => {
                if(promotion.promoCode) {
                    isAppliedPromotionWithPromoCode = true;
                }
            })
        }

        if (customer.token && !this.props.storeLocalStorage.codeManuallySet && customer.autoApplyLoyalty && !isAppliedPromotionWithPromoCode) {
            this.props.getOldestActiveLoyalty();
        }

        // This invocation handles selection on page refresh, if card has already been selected e.i.
        this.selectCard();

        if ((this.props.shoppingCart.discount > 0 && this.props.shoppingCart.total === 0) || (!totalWithGiftCardIsZero && isAppliedGiftCards && this.props.payment.selectedPaymentType.id !== 1)) { // if total after discount is 0 or less
            this.props.selectPaymentType({ id: 2, value: 'Cash' });
            this.clearPaymentCard();
        }

        if (this.props.storeLocalStorage.isAppleGooglePayAvailable && (!totalWithGiftCardIsZero && !isAppliedGiftCards)) {
            this.setState({
                ...this.state,
                isAppleGooglePayAvailable: this.props.storeLocalStorage.isAppleGooglePayAvailable
            })
        }

        this.props.updateProfileBackBtnPath('/checkout');

        const isAppleGooglePayAvailable = this.props.storeLocalStorage.isAppleGooglePayAvailable;

        if (isMobile && isAppleGooglePayAvailable && this.props.payment.selectedPaymentType) {
            this.styleActiveElements(this.props.payment.selectedPaymentType);
        }

        if (this.props.giftCards.errorMessage || !this.state.currentGiftCard.cardId) {
            this.props.clearGiftCardsErrorMessage();
        }

        if (new URLSearchParams(window.location.search).get('TBK_TOKEN')) {
            let token = new URLSearchParams(window.location.search).get('TBK_TOKEN');

            if (token.includes('/')) {
                token = token.replace(/\\|\//g, '');
            }
            const restaurantId = this.props.selectedRestaurant ? this.props.selectedRestaurant.restaurantId : null;
            this.props.stopLoader();
            this.props.sendTbkToken(token, null, null, restaurantId);
            
            const isIntegration = getQueryInfoFromUrl().brandId;

            if (isIntegration) {
                const { navigationType, brandId } = this.props.storeLocalStorage;

                const newURL =`${FRONT_END_URL}/?brandId=${brandId}&navigationType=${navigationType}#/checkout`

                window.history.replaceState({}, document.title, newURL);
            } 
        }
    }

    componentDidUpdate(prevProps) {
        const isCashExist = this.props.payment.paymentTypes.some(e => e.id === 2);
        this.applyTheme(prevProps);
        let paymentTypesCopy = this.props.payment.paymentTypes;
        paymentTypesCopy = this.props.payment.paymentTypes[0] && paymentTypesCopy.filter(type => {
            if (type.id === 2) {
                return type;
            }
        });
    

        this.setPaymentType(isCashExist, paymentTypesCopy, prevProps);
    }

    componentWillUnmount() {
        const totalWithGiftCardIsZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 : false;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        document.removeEventListener('touchend', this.iosTouchEvents, false);
        document.removeEventListener('scroll', this.componentScrollEvents, false);
        sendMessageToParentSite('else');

        // window.onpopstate = null;

        this.props.allowTimeWarningPopup(false);

        document.getElementById('root').removeAttribute('style');

        this.props.setMenuOrOrderHistoryScreen(false);

        if (!totalWithGiftCardIsZero && !isAppliedGiftCards){
            this.props.changeAppleGooglePayAvailability(null);
        }

        if (this.state.isSavedCardModalOpen) {
            this.setState({
                isSavedCardModalOpen: false
            })
        }

        // const isProfileScreen = window.location.href.includes('profile');
        // const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;

        // if (isLoggedInPromotionPartner && !isProfileScreen) {
        //     const onSuccess = () => {
        //         this.props.voidPurchase(() => this.props.resetOldDiscountFlag());
        //         this.props.resetPromoCodeField();
        //     };

        //     this.props.cancelComoPayment(onSuccess);
        // }
    }

    copyStateFromProps() {

        if (this.props.payment != null &&
            this.props.payment.selectedPaymentType != null
            && !this.state.stateAlreadyCopiedFromProps) {

            const isAppleGooglePayAvailable = this.props.storeLocalStorage.isAppleGooglePayAvailable;
            const totalWithGiftCardIsZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 : false;
            const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;

            if ((this.props.shoppingCart.discount > 0 && this.props.shoppingCart.total === 0) || (totalWithGiftCardIsZero && isAppliedGiftCards)) { // if total after discount is 0 or less
                this.setState({
                    ...this.state,
                    selectedPaymentId: 2,
                    paymentType: 'Cash',
                    stateAlreadyCopiedFromProps: true
                });
            } else {
                this.setState({
                    ...this.state,
                    selectedPaymentId: !isAppleGooglePayAvailable ? this.props.payment.selectedPaymentType.id : 3,
                    paymentType: !isAppleGooglePayAvailable ? (this.props.payment.selectedPaymentType.id == 1) ? 'Credit card' : 'Cash' : 'Credit card',
                    stateAlreadyCopiedFromProps: true
                });
            }

            if (isMobile && isAppleGooglePayAvailable && this.props.payment.selectedPaymentType) {
                this.styleActiveElements(this.props.payment.selectedPaymentType);
            }
        }
    }

    componentScrollEvents = _.debounce(() => {
        this.notInViewPort();
    }, 100);

    iosTouchEvents() {
        const compareBoundingDiv = document.getElementsByClassName('checkoutTitle')[0];

        this.notInViewPort();

        let currentBounding;
        let compareBounding;
        setTimeout(() => {
            currentBounding = compareBoundingDiv.getBoundingClientRect().top;
        }, 100);
        setTimeout(() => {
            compareBounding = compareBoundingDiv.getBoundingClientRect().top;
            if (compareBounding && (currentBounding !== compareBounding)) {
                this.iosTouchEvents();
            }
        }, 300);

    }

    notInViewPort() {

        const compareBoundingDiv = document.getElementsByClassName('checkoutTitle')[0];

        try {
            let positionOfElementAboveMenuNavigationContainer = compareBoundingDiv.getBoundingClientRect().top;

            const fixedContainer = document.getElementsByClassName('fixedContainer')[0];
            const isEnoughSpaceForFullscreen = window.innerHeight + this.state.fullscreenOffset < fixedContainer.offsetHeight;
            let notInViewPort = positionOfElementAboveMenuNavigationContainer < 0;
            if (isEnoughSpaceForFullscreen) {
                if (notInViewPort) {
                    this.setState({ ...this.state, fullscreenOffset: 0 });
                    sendMessageToParentSite('if');
                } else {
                    this.setState({ ...this.state, fullscreenOffset: 200 });
                    sendMessageToParentSite('else');
                }
                return notInViewPort;
            }
        } catch (e) {

        }
        return false;
    }

    setPaymentType(isCashExist, paymentTypesCopy, prevProps) {
        const { minimumOrderAmount } = this.props.selectedRestaurant;
        const { shoppingCart, shoppingCartProducts, giftCards } = this.props;
        let currentMinimumOrderAmount = shoppingCart.appliedPromotions.length ? 1 : minimumOrderAmount;
        let noneOfTheAboveCases = true;
        const giftCardsTotal = giftCards.giftCards ? giftCards.giftCards.total : null;
        const prevPropsGiftCardsTotal = prevProps.giftCards.giftCards ? prevProps.giftCards.giftCards.total : null;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        const totalWithGiftCardIsZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 : false;

        const isPrevTotalDifFromCurrTotal = prevProps.shoppingCart.total !== shoppingCart.total;
        const isPrevTotalFromGiftCardsDifFromCurr = prevPropsGiftCardsTotal !== giftCardsTotal;
        const isCurrDiscountBiggerThanZero = shoppingCart.discount > 0;
        const isCurrTotalBiggerThanZeroAndLessThanCurrMinOrderAmount = shoppingCart.total > 0 && shoppingCart.total < currentMinimumOrderAmount;
        const isProductLengthDifferent = prevProps.shoppingCartProducts.length !== shoppingCartProducts.length;
        const noProductsInCart = shoppingCartProducts.length === 0;
        const zeroTotalZeroDiscount = shoppingCart.total === 0 && shoppingCart.discount === 0;
        const totalBiggerOrEqualToCurrMinOrderAmount = shoppingCart.total >= currentMinimumOrderAmount;

        // Sets Payment to Cash when total < 1 - when there is a negative or big discount/loyalty
        if (shoppingCart.total < 1 && this.props.payment.selectedPaymentType.id === 1 && paymentTypesCopy.length) {
            noneOfTheAboveCases = false;
            this.setState({
                ...this.state,
                selectedPaymentId: 2,
                paymentType: 'Cash'
            }, this.selectPayment(2));
        }

        if ((isPrevTotalDifFromCurrTotal && shoppingCart.total === 0 && isCurrDiscountBiggerThanZero)
            || (isPrevTotalDifFromCurrTotal && zeroTotalZeroDiscount) || (isPrevTotalFromGiftCardsDifFromCurr && giftCardsTotal === 0 && isAppliedGiftCards)) {
            noneOfTheAboveCases = false;
            this.setState({
                ...this.state,
                selectedPaymentId: 2,
                paymentType: 'Cash'
            }, this.props.selectPaymentType({ id: 2, value: 'Cash' }))
        }

        if ((isPrevTotalDifFromCurrTotal && isCurrTotalBiggerThanZeroAndLessThanCurrMinOrderAmount && isCurrDiscountBiggerThanZero && !(totalWithGiftCardIsZero && isAppliedGiftCards))
            || (isPrevTotalDifFromCurrTotal && totalBiggerOrEqualToCurrMinOrderAmount && isCurrDiscountBiggerThanZero && this.state.selectedPaymentId == 2 && !isCashExist && !(totalWithGiftCardIsZero && isAppliedGiftCards))
            || (isProductLengthDifferent && noProductsInCart && zeroTotalZeroDiscount)
            || (isPrevTotalDifFromCurrTotal && prevProps.shoppingCart.total === 0 && totalBiggerOrEqualToCurrMinOrderAmount)) {
            noneOfTheAboveCases = false;
            this.setState({
                ...this.state,
                selectedPaymentId: null,
                paymentType: null
            }, this.props.selectPaymentType({ id: null, value: 'Cash' }))
        }

        if (noneOfTheAboveCases && !this.props.payment.selectedPaymentType.id && !isCurrTotalBiggerThanZeroAndLessThanCurrMinOrderAmount
            || (noneOfTheAboveCases && (prevProps.giftCards.giftCards !== giftCards.giftCards && (!totalWithGiftCardIsZero && !isAppliedGiftCards)))) {

            let hasCardPaymentOption = false;
            for (const paymentType of this.props.payment.paymentTypes) {
                if (paymentType.id === 1) {
                    hasCardPaymentOption = true;
                }
            }

            if (this.state.isAppleGooglePayAvailable) {
                this.setState({
                    ...this.state,
                    selectedPaymentId: 3,
                    paymentType: 'Credit card'
                });

                this.props.selectPaymentType({ id: 3, value: 'Credit card' });
            } else if (hasCardPaymentOption) {
                this.setState({
                    ...this.state,
                    selectedPaymentId: 1,
                    paymentType: 'Credit card'
                });

                this.props.selectPaymentType({ id: 1, value: 'Credit card' });
            } else if (this.props.payment.paymentTypes.length === 1) {

                this.setState({
                    ...this.state,
                    selectedPaymentId: this.props.payment.paymentTypes[0].id,
                    paymentType: this.props.payment.paymentTypes[0].value
                });

                this.props.selectPaymentType(this.props.payment.paymentTypes[0]);
            }
        }
    }

    toggleChangeTimeScreen(isClickYes) {
        // const path = '/checkout/order-time';
        // this.props.openChangeOrderTimeScreen(path);
        if (!isClickYes) {
            this.setState({ ...this.state, isChangeTimeScreenOpened: !this.state.isChangeTimeScreenOpened });
        }
    }

    toggleConfirmationDialog() {
        this.setState({ ...this.state, openConfirmationDialog: !this.state.openConfirmationDialog });
    }

    toggleConfirmationModal() {
        this.setState({ ...this.state, openConfirmationModal: !this.state.openConfirmationModal });
    }

    toggleDeleteCardDialog(selectedCard) {
        this.setState({
            openDeleteCardDialog: !this.state.openDeleteCardDialog,
            selectedCard: selectedCard
        });
    }

    confirmDeleteCard() {
        const paymentCardId = this.state.selectedCard.paymentCardId;

        this.props.deleteCustomerCard(paymentCardId, () => {
            this.clearSelectedCardState();
        });
    }

    clearSelectedCardState() {
        this.setState({
            selectedCard: ''
        })
    }

    toggleSendingOrderModal() {
        // console.log('toggleSendingOrderModal');
    }

    toggleSavedCardsModal() {
        if(this.state.isSavedCardModalOpen) {
            this.setState({ 
                ...this.state, 
                isSavedCardModalOpen: false,
                selectedCardInfo: null 
            });
        } else {
            this.setState({ 
                ...this.state, 
                isSavedCardModalOpen: true });
        }
    }

    confirmOrderReset() {
        this.props.clearWizardData();
        this.props.clearState();
        this.props.history.push('/start-order/order-type');
        this.props.changeCodeManuallySet(null);
    }

    goBack() {
        const { menuPreviewMode, promotionPartners } = this.props;

        if (menuPreviewMode.previewMode === true || menuPreviewMode.previewMode === 'true') {
            this.props.history.push(`/menu-screen?previewMode=${menuPreviewMode.previewMode}&restaurantId=${menuPreviewMode.restaurantId}`);
        } else {
            this.props.history.push(`/menu-screen`);
        }
    }

    deleteSavedCreditCard() {
        
        if(this.state.selectedCardInfo && this.state.selectedCardInfo.paymentCardId) {
            this.props.deleteCustomerCard(this.state.selectedCardInfo.paymentCardId, () => {
                this.setState({
                    isSavedCardModalOpen: false,
                    selectedCardInfo: null
                })
            });
        }
    }


    onBackKeyDown() {

        const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;
        const isOrderType = this.props.orderTypes.selectedOrderType.value !== '';

        if (isLoggedInPromotionPartner) {
            this.toggleConfirmationModal();
        } else if (isOrderType) {
            this.goBack()
        }
    }

    changeState({ target }) {
        if (target.name == 'generalInstructions') {
            this.setState({
                [target.name]: target.value
            }, this.props.saveGeneralInstructions(target.value));
        } else if (target.name == 'giftCardNumber') {
            this.setState({
                currentGiftCard: {
                    ...this.state.currentGiftCard,
                    cardId: target.value
                }
            });
            if (this.props.giftCards.errorMessage) {
                this.props.clearGiftCardsErrorMessage();
            }
        } else {
            this.setState({
                selectedPaymentId: target.id,
                paymentType: target.value
            }, this.selectPayment(target.id));
        }
    }

    changeNotes(value) {
        this.setState({
            ...this.state,
            generalInstructions: value
        }, () => {
            this.props.saveGeneralInstructions(value);
        });
    }

    mobileDropdownChangeState = ({ target }) => {
        this.setState({
            selectedPaymentId: target.value,
            paymentType: target[target.selectedIndex].text === 'Card' ? 'Credit card' : target[target.selectedIndex].text
        }, this.selectPayment(target.value));
    }

    changePaymentStateMobile = ({ currentTarget }) => {
        const paymentType = currentTarget.id == 1 ? 'Card' : 'Cash';

        this.setState({
            selectedPaymentId: currentTarget.id,
            paymentType: paymentType
        }, this.selectPayment(currentTarget.id))

        this.styleActiveElements(currentTarget);
    }

    styleActiveElements = (target) => {
        const targetId = Number(target.id);
        let activeTick = (target.id == 3) ? document.getElementById("tick_3") : document.getElementById("tick_" + target.id);

        if (activeTick !== null) {
            activeTick.classList.remove("paymentTypeTickDisable");
            activeTick.classList.add("paymentTypeTick");
        }

        if (targetId !== 3) {
            let unActiveTick = document.getElementById("tick_3");

            if (unActiveTick !== null && unActiveTick.classList.value.includes("paymentTypeTick")) {
                unActiveTick.classList.remove("paymentTypeTick");
                unActiveTick.classList.add("paymentTypeTickDisable");
            }
        }

        this.props.payment.paymentTypes.map((payment, i) => {
            if (payment.id !== targetId) {
                let unActiveTick = document.getElementById("tick_" + payment.id);

                if (unActiveTick !== null) {
                    unActiveTick.classList.add("paymentTypeTickDisable");
                }
            }
        });
    }

    changeAppleGooglePayAvailable = (isAvailable) => {
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        const totalWithGiftCardIsZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total === 0 : false; 

        if (isAvailable && (!isAppliedGiftCards && !totalWithGiftCardIsZero)) {
            this.setState({
                ...this.state,
                isAppleGooglePayAvailable: isAvailable,
                selectedPaymentId: 3
            })
        } else {
            this.setState({
                ...this.state,
                isAppleGooglePayAvailable: isAvailable
            })
        }
        this.props.changeAppleGooglePayAvailability(isAvailable)
    }

    selectCard(card) { // TODO - will possibly need refactoring to work with Braintree or other providers
        let cardInfo = null;
        const isMoneris = this.props.paymentProvider.providerType ? this.props.paymentProvider.providerType.id === 5 ? true : false : false;

        if (!card) {
            cardInfo = this.props.payment.selectedPaymentCard;
        } else {
            cardInfo = card;
        }

        if(cardInfo.brand && isMoneris && (!cardInfo.street || !cardInfo.streetNumber || !cardInfo.zipCode)) {

            this.setState({
                isSavedCardModalOpen: !this.state.isSavedCardModalOpen,
                selectedCardInfo: cardInfo
            })
        } else if (cardInfo.brand && isMoneris) {
            const selectedCard = {
                'brand': cardInfo.brand,
                'paymentCardId': cardInfo.paymentCardId,
                'lastDigits': cardInfo.lastDigits,
                'street': cardInfo.street,
                'streetNumber': cardInfo.streetNumber,
                'zipCode': cardInfo.zipCode
            }

            this.setState({
                activeClass: !this.state.activeClass,
                selectedPaymentCard: selectedCard
            }, (!this.state.activeClass) ? this.props.selectCustomerPaymentCard(selectedCard) : this.clearPaymentCard());
        } else if (cardInfo.brand) {
            const selectedCard = {
                'brand': cardInfo.brand,
                'paymentCardId': cardInfo.paymentCardId,
                'lastDigits': cardInfo.lastDigits
            }

            this.setState({
                activeClass: !this.state.activeClass,
                selectedPaymentCard: selectedCard
            }, (!this.state.activeClass) ? this.props.selectCustomerPaymentCard(selectedCard) : this.clearPaymentCard());
        }
    }

    clearPaymentCard() {
        this.props.clearPaymentCard();
        this.state.activeClass = false;
    }

    selectPayment(paymentTypeId) {
        this.props.payment.paymentTypes.map((payment, i) => {
            if (Number(paymentTypeId) === payment.id) {
                this.props.selectPaymentType(payment);
                if (payment.value === 'Cash') {
                    this.clearPaymentCard();
                }
            }
        });
    }

    applyGiftCardInformation(appliedGiftCards, currentGiftCard) {
        
        this.props.applyGiftCard(appliedGiftCards, currentGiftCard, () => {
            this.setState ({
                ...this.state,
                currentGiftCard: {
                    ...this.state.currentGiftCard,
                    cardId: ''
                }
            });
        })
    }

    removeGiftCard(cardToRemoveIndex, appliedGiftCards) {

        let copyOfAppliedGiftCards = appliedGiftCards;

        copyOfAppliedGiftCards.map((card, index) => {
            if(cardToRemoveIndex === index) {
                copyOfAppliedGiftCards.splice(index, 1)
                this.props.applyGiftCard(copyOfAppliedGiftCards, null)
            }
        })
    }

    renderSavedCardModalBody() {

        const { primaryColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;
        const { savedGiftCardContainer } = styles;
        const mobileStars = ' **** **** **** ';

        return (
            <Row>
                <Col xs={12} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}>
                    <div style={{...savedGiftCardContainer, backgroundColor: menuItemsBackground}}>
                        <span style={{textAlign: 'center', color: primaryColor, fontWeight: 'bold'}}>
                            {mobileStars + this.state.selectedCardInfo.lastDigits + ' '}
                        </span>
                    </div>
                    <div style={{maxWidth: '400px', margin: '0px auto 20px'}}>{i18n.t('screens:checkoutScreen.deleteSavedCardInfo')}</div>
                </Col>
            </Row>
        )
    }

    renderCheckoutTitle() {
        const { customer } = this.props;
        const { checkoutCustomerContainer, backButtonStyle, serviceNote } = styles;
        const userFirstName = getLoggedInUserFirstName(customer.name);
        let customerNameClass = 'profileName profileNameLinkCheckout';
        const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;

        if (window.outerWidth <= 500) {
            customerNameClass += ' customer-long-text-back-button';
        }

        return (
            <Fragment>
                <Col xs={12} sm={8}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '15px 0 10px 0' }}>
                    <Col xs={2} style={{ padding: 0 }}>
                        <Button className="backButton" style={backButtonStyle} onClick={() => isLoggedInPromotionPartner ? this.toggleConfirmationModal() : this.goBack()}>
                            <span className="backButtonIcon" style={{ fontWeight: 300 }}>
                                <MaterialIcon icon="navigate_before" color="#000" size={32} />
                            </span>
                            <span className="backButtonText">{i18n.t('common:buttons.backBtn')}</span>
                        </Button>
                    </Col>

                    <Col xs={10} style={checkoutCustomerContainer} className="checkoutTitle">
                        {i18n.t('screens:checkoutScreen.checkoutHeader')}
                        <NavLink tag={Link} to={{ pathname: "/profile/details", state: '/checkout' }}
                            className={customerNameClass}>
                            {userFirstName}
                        </NavLink>
                        . . . </Col>
                </Col>
            </Fragment>
        );
    }

    renderCheckoutPaymentInformation() {
        const isAppleGooglePayAvailable = this.props.storeLocalStorage.isAppleGooglePayAvailable;
        const isArabic = this.props.language === ARABIC;
        let paymentTypeStyle = {};

        if (isAppleGooglePayAvailable) {
            paymentTypeStyle = {
                display: 'flex',
                flexDirection: 'row',
                padding: '15px 12px 15px 0px',
                margin: '0 15px',
                borderTop: '1px solid rgba(167, 167, 167, 0.5)'
            };
        } else {
            paymentTypeStyle = isMobile ? {
                display: "flex",
                flexDirection: "row",
                padding: isArabic ? "15px 0px 15px 12px" : "15px 12px 15px 0px",
                margin: '0 15px',
                borderTop: '1px solid rgba(167, 167, 167, 0.5)'
            } : {
                    display: 'flex',
                    flexDirection: 'row',
                    padding: isArabic ? '15px 0px 15px 12px' : '15px 12px 15px 0px',
                    margin: '0 15px',
                    borderTop: '1px solid rgba(167, 167, 167, 0.5)'
                };
        }

        return (
            <div style={paymentTypeStyle}>
                <CheckoutPaymentInformation
                    // Store props
                    customerThemes={this.props.customerThemes}
                    customer={this.props.customer}
                    brand={this.props.brand}
                    payment={this.props.payment}
                    shoppingCart={this.props.shoppingCart}
                    // State props
                    changeState={this.changeState}
                    changePaymentStateMobile={this.changePaymentStateMobile}
                    selectedPaymentId={this.state.selectedPaymentId}
                    selectCard={this.selectCard}
                    activeClass={this.state.activeClass}
                    selectedPaymentCard={this.state.selectedPaymentCard}
                    // selectPayment={this.selectPayment}
                    selectedRestaurant={this.props.selectedRestaurant}
                    paymentProvider={this.props.paymentProvider}
                    isAppleGooglePayAvailable={this.state.isAppleGooglePayAvailable}
                    isPWA={this.props.pwaAppRunning}
                    styles={styles}
                    isArabic={isArabic}
                    giftCards={this.props.giftCards}
                    toggleDeleteCardDialog={this.toggleDeleteCardDialog}
                />
            </div>
        );
    }

    renderCheckoutInformation() {
        const isArabic = this.props.language === ARABIC;
        
        return (
            <CheckoutInformation
                // Store props
                history={this.props.history}
                customerThemes={this.props.customerThemes}
                customer={this.props.customer}
                orderTypes={this.props.orderTypes}
                menuPreviewMode={this.props.menuPreviewMode}
                brand={this.props.brand}
                selectedRestaurant={this.props.selectedRestaurant}
                estimateOrderTime={this.props.estimateOrderTime}
                // Action props
                clearWizardData={this.props.clearWizardData}
                clearState={this.props.clearState}
                clearTemporaryOrderTimeData={this.props.clearTemporaryOrderTimeData}
                openChangeOrderTimeScreen={this.props.openChangeOrderTimeScreen}
                // State props
                changeState={this.changeState}
                changeNotes={this.changeNotes.bind(this)}
                generalInstructions={this.state.generalInstructions}
                currentGiftCard={this.state.currentGiftCard}
                paymentType={this.state.paymentType}
                activeClass={this.state.activeClass}
                toggleChangeTimeScreen={this.toggleChangeTimeScreen}
                isArabic={isArabic}
                styles={styles} 
                giftCards={this.props.giftCards}
                giftCardsErrorMessage={this.props.giftCards.errorMessage}
                removeGiftCard={this.removeGiftCard}
                applyGiftCardInformation={this.applyGiftCardInformation}
                />
                
        );
    }

    confirmChangeTime() {
        this.props.saveNewOrderTime(true, true, true);
    }

    exitChangeTime() {
        this.props.toggleUnavailableProductsScreenOpen();
        this.props.clearTemporaryOrderTimeData();
    }

    applyTheme(prevProps) {
        if (prevProps && this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
        } else {
            updateCSSVariables(this.props.customerThemes.selectedTheme.colorsCss);
        }

        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { type, primaryColor, secondaryColor, checkoutScreenNames, fontWeight, textDecoration, chefNoteBackground, primaryFontColor, mainTextColor, menuItemsBackground, fontFamilyType, fontBold, fontItalic, uppercase, menuBackground } = this.props.customerThemes.selectedTheme;

        customizedStyles.updateInfoStyle.color = primaryColor;
        customizedStyles.updateInfoStyle.fontWeight = fontWeight;
        customizedStyles.updateInfoStyle.textDecoration = textDecoration;
        customizedStyles.checkoutCustomerContainer.fontWeight = fontWeight;
        customizedStyles.sectionTitle.color = (this.props.customerThemes.selectedTheme.type === "LIGHT") ? checkoutScreenNames : secondaryColor;
        customizedStyles.checkOutBtn.backgroundColor = primaryColor;
        customizedStyles.instructionsInput.backgroundColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? chefNoteBackground : '';
        customizedStyles.checkoutCustomerContainer.color = primaryFontColor;
        customizedStyles.backButtonStyle.color = primaryFontColor;
        customizedStyles.expiredCard.color = mainTextColor;
        customizedStyles.availableCard.color = primaryColor;
        customizedStyles.cardBrand.color = primaryFontColor;
        customizedStyles.cardNumber.color = primaryFontColor;
        customizedStyles.listGroupActiveStyle.background = menuItemsBackground;
        customizedStyles.listGroupActiveStyle.border = `1px solid ${primaryColor}`;
        customizedStyles.charactersInfo.color = mainTextColor;
        customizedStyles.sectionTitle.fontFamily = fontFamilyType;
        customizedStyles.sectionTitle.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.sectionTitle.fontStyle = fontItalic;
        customizedStyles.sectionTitle.textTransform = uppercase;
        customizedStyles.dropdownPayments.backgroundColor = menuBackground;
        customizedStyles.dropdownPayments.color = primaryFontColor;
        customizedStyles.separatorStyle.color = primaryFontColor;
        styles = customizedStyles;
        return styles;
    }

    render() {
        const { menuBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;
        let currentMinimumOrderAmount = this.props.shoppingCart.appliedPromotions.length ? 1 : this.props.selectedRestaurant.minimumOrderAmount;
        const textData = { 
            informationMessage: i18n.t(`screens:checkoutScreen.deleteInfoMsg`),
            confirmationMessage: i18n.t(`screens:checkoutScreen.continueQestion`)
        };

        const deleteCardTextData = {
            informationMessage: i18n.t('screens:profileScreen.deleteCardConfirmation'),
            confirmationMessage: null
        };

        const onBackTextData = {
            informationMessage: i18n.t('screens:checkoutScreen.informationMessage')
        };
        const isArabic = this.props.language === ARABIC;
        // const isGiftCardTotalBiggerThanZero = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards.length > 0 ? this.props.giftCards.giftCards.total > 0 : true : true;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        const isTotalWithGiftCardsBiggerThanOne = isAppliedGiftCards ? (shoppingCartTotalWithGiftCard > 1) ? true : false : true;
        const isTotalWithGiftCardsBiggerThanZeroAndLessThanOne = isAppliedGiftCards && ((shoppingCartTotalWithGiftCard < 1) && (shoppingCartTotalWithGiftCard > 0)) ? true : false;
        this.copyStateFromProps();
        
        return (
            <React.Fragment>
                <MenuLoaderContainer {...this.props} />
                <div className='fixedContainer' style={{ backgroundColor: menuBackground }}>
                    <Row style={{ margin: 0 }}>
                        {this.renderCheckoutTitle()}
                        <Col xs={12} sm={12} md={isMobileOnly ? 12 : 8} style={{ backgroundColor: menuBackground }}>
                            {/* {this.props.isPWA ? 12 : 8} TODO - find why props.isPWA was hardcoded */}
                            {this.renderCheckoutPaymentInformation()}
                            {
                                (this.props.paymentProvider.providerType && this.props.shoppingCart.total >= currentMinimumOrderAmount && isTotalWithGiftCardsBiggerThanOne) &&
                                <PaymentAdapter
                                    history={this.props.history}
                                    selectCard={this.selectCard}
                                    selectedPaymentId={Number(this.state.selectedPaymentId)}
                                    changeAppleGooglePayAvailable={this.changeAppleGooglePayAvailable}
                                    isAppleGooglePayAvailable={this.state.isAppleGooglePayAvailable}
                                    styles={styles}
                                />
                            }
                            {
                                isTotalWithGiftCardsBiggerThanZeroAndLessThanOne && (this.state.selectedPaymentId == 1) ?
                                <Col xs={12} md={12}> 
                                    <p style={{ color: primaryFontColor, paddingLeft: '10%' }}>{i18n.t(`screens:checkoutScreen.totalMustBeGreaterOrEqualTo1`)}</p>
                                </Col> : null
                            }
                            {this.renderCheckoutInformation()}
                        </Col>
                        <Col xs={12} sm={12} md={this.props.pwaAppRunning ? 12 : 4} style={this.props.pwaAppRunning ? {} : isMobileOnly ? { paddingLeft: 0, paddingRight: 0, backgroundColor: menuBackground } : isArabic ? { paddingLeft: 10, paddingRight: 0, backgroundColor: menuBackground } : { paddingLeft: 0, paddingRight: 10, backgroundColor: menuBackground }}>
                            <ShoppingCart
                                {...this.props}
                                isCardValid={this.props.payment.isCardValid}
                                isAppleGooglePaySelected={Number(this.state.selectedPaymentId) === 3}
                                selectedPaymentId={this.state.selectedPaymentId}
                                isCheckoutScreen={true}
                            />
                        </Col>
                    </Row>
                </div>

                {
                    this.state.openConfirmationDialog &&
                    <ConfirmationModal
                        isOpen={this.state.openConfirmationDialog}
                        onToggle={this.toggleConfirmationDialog.bind(this)}
                        onConfirm={this.confirmOrderReset.bind(this)}
                        message={textData}
                        customerThemes={this.props.customerThemes}
                    />
                }

                {
                    this.props.sendOrderModal.sendOrderModalOpened &&
                    <SendingOrderModal
                        isOpen={this.props.sendOrderModal.sendOrderModalOpened}
                        orderStatusMessage={this.props.sendOrderModal.orderStatusMessage}
                        customerInfoMessage={this.props.sendOrderModal.customerInfoMessage}
                        orderSucceed={this.props.sendOrderModal.orderSucceed}
                        onToggle={this.toggleSendingOrderModal}
                        biggerInfoMessage={this.props.sendOrderModal.biggerInfoMessage}
                    />
                }

                <Modal className="modalStep startOrder" isOpen={this.state.isChangeTimeScreenOpened} size='lg'>
                    <OrderTimeScreen isChangeTime={true}
                        isOpen={this.state.isChangeTimeScreenOpened}
                        toggle={() => this.toggleChangeTimeScreen()}
                        isCheckoutChangeTime={true}
                        isCheckoutScreen={true}
                        changeOrderTimeScreenOpened={this.props.changeOrderTimeScreenOpened} 
                        dinePayTypeId={this.props.selectedRestaurant.type ? this.props.selectedRestaurant.type.id : null}/>
                </Modal>

                {
                    this.props.isUnavailableProductsScreenOpen &&
                    <Modal className="modalStep" isOpen={this.props.isUnavailableProductsScreenOpen} size='lg'>
                        <UnavailableProductsScreen
                            {...this.props}
                            isOpen={this.props.isUnavailableProductsScreenOpen}
                            toggle={(isClickYes) => this.toggleChangeTimeScreen(isClickYes)}
                            onConfirm={() => this.confirmChangeTime()}
                            isCheckoutChangeTime={true}
                            isMenuScreen={true}
                            clearTemporaryOrderTimeData={() => this.props.clearTemporaryOrderTimeData()}
                            onExit={() => this.exitChangeTime()} />
                    </Modal>
                }
                {
                    this.state.openConfirmationModal &&
                    <ConfirmationModal
                        isOpen={this.state.openConfirmationModal}
                        onToggle={this.toggleConfirmationModal.bind(this)}
                        onConfirm={this.goBack.bind(this)}
                        message={onBackTextData}
                        customerThemes={this.props.customerThemes}
                    />
                }
                {
                    this.state.isSavedCardModalOpen &&
                    <SavedCardsModal
                        isOpen={this.state.isSavedCardModalOpen}
                        onToggle={this.toggleSavedCardsModal}
                        onConfirm={this.deleteSavedCreditCard}
                        customerThemes={this.props.customerThemes}
                        body={this.renderSavedCardModalBody}
                    />
                }
                {
                    this.state.openDeleteCardDialog &&
                    <ConfirmationModal
                    isOpen={this.state.openDeleteCardDialog}
                    onToggle={this.toggleDeleteCardDialog}
                    onConfirm={this.confirmDeleteCard}
                    message={deleteCardTextData}
                    customerThemes={this.props.customerThemes}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        customer: state.customer,
        sendOrderModal: state.sendOrderModal,
        isStartOrderScreenOpened: state.startOrder.startOrderScreenOpened,
        shoppingCartProducts: state.shoppingCart.orderProducts,
        shoppingCart: state.shoppingCart,
        payment: state.payment,
        brand: state.brand,
        selectedRestaurant: state.selectedRestaurant,
        selectedMenuId: state.restaurantMenus.selectedMenuId,
        selectedProduct: state.selectedProduct,
        orderTypes: state.orderTypes,
        estimateOrderTime: state.estimateOrderTime,
        temporaryOrderTime: state.temporaryOrderTime,
        changeOrderTimeScreenOpened: state.temporaryOrderTime.changeOrderTimeScreenOpened,
        menuPreviewMode: state.menuPreviewMode,
        customerThemes: state.customerThemes,
        loyalty: state.loyalty,
        pwaAppRunning: state.pwaAppRunning,
        serviceNote: state.menuDescription.serviceNote,
        paymentProvider: state.paymentProvider,
        restaurantMenus: state.restaurantMenus.menus,
        restaurantProducts: state.restaurantProducts,
        restaurantCategories: state.restaurantCategories,
        isUnavailableProductsScreenOpen: state.temporaryOrderTime.isUnavailableProductsScreenOpen,
        storeLocalStorage: state.storeLocalStorage,
        loading: state.loading,
        promotionPartners: state.promotionPartners,
        como: state.como,
        language: state.storeLocalStorage.language,
        giftCards: state.giftCards
    };
};

export default connect(mapStateToProps, {
    loadPaymentTypes,
    selectPaymentType,
    getCustomerPaymentCards,
    selectCustomerPaymentCard,
    clearPaymentCard,
    saveGeneralInstructions,
    clearWizardData,
    editProduct,
    addProductToCart,
    closeStartOrderScreen,
    openChangeOrderTimeScreen,
    clearTemporaryOrderTimeData,
    saveNewOrderTime,
    clearState,
    getSelectedProduct,
    stopLoader,
    processingPayment,
    closeSendingOrderModal,
    saveCurrentPathToStore,
    allowTimeWarningPopup,
    getOldestActiveLoyalty,
    setMenuOrOrderHistoryScreen,
    toggleUnavailableProductsScreenOpen,
    changeAppleGooglePayAvailability,
    changeCodeManuallySet,
    updateProfileBackBtnPath,
    getRestaurantPauseStatus,
    toggleShoppingCartModal,
    voidPurchase,
    resetPromoCodeField,
    resetOldDiscountFlag,
    cancelComoPayment,
    applyGiftCard,
    clearGiftCardsErrorMessage,
    deleteCustomerCard,
    sendTbkToken
})(CheckoutScreen);

let styles = {
    checkoutTitleContainer: {
        float: 'left',
        marginTop: 15,
        marginBottom: 0
    },
    cardBrand: {
        paddingRight: 10,
        float: 'left'
    },
    cardNumber: {
        paddingRight: 10,
        float: 'left'
    },
    checkoutCustomerContainer: { marginBottom: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    iconStyle: {
        float: 'left',
        padding: 0,
        marginRight: -6
    },
    charactersInfo: { float: 'right', fontSize: 12, color: '#a7a7a7' },
    sectionTitle: {
        color: '#a7a7a7', fontSize: 18, fontFamily: 'Roboto',
        fontWeight: 300,
        marginBottom: 4
    },
    updateInfoStyle: {
        color: '#62b400',
        cursor: 'pointer'
    },
    disabledLink: {
        color: 'grey',
        cursor: 'pointer',
        pointerEvents: 'none'
    },
    radioBtnOne: {
        fontSize: 16,
        marginRight: 20
    },
    radioBtnOneRTL: {
        fontSize: 16,
        marginLeft: 20
    },
    paymentTypeButtons: {
        display: 'flex',
        marginTop: 10,
        paddingLeft: 0
    },
    listGroupStyle: {
        cursor: 'pointer',
        background: '#e4e4e461',
        borderTop: 'none',
        borderRadius: 0,
        paddingRight: 15,
        paddingLeft: 15,
        marginBottom: 10
    },
    listGroupActiveStyle: {
        cursor: 'pointer',
        background: '#fff',
        border: '1px solid #63b400',
        borderRadius: 0,
        paddingRight: 15,
        paddingLeft: 15,
        marginBottom: 10
    },
    expiredCard: { color: 'grey', float: 'right' },
    availableCard: { color: '#62b400', float: 'right', cursor: 'pointer' },
    instructionsInput: {
        width: '100%',
        border: '1px solid #cfd5da',
        borderRadius: 0,
        height: 38,
        marginTop: 5,
        paddingLeft: 12,
        fontSize: 14,
        zIndex: 1
    },
    backButtonStyle: {
        marginRight: 25
    },
    serviceNote: {
        fontSize: 14,
        color: 'rgb(110, 118, 118)',
        padding: '0 33px 0 27px'
    },
    separatorStyle: { color: 'rgba(167, 167, 167, 0.5)', margin: '0px 6px 0px 6px' },
    orderTypeBtnsContainer: {
        float: 'left',
        // marginLeft: 58,
        marginTop: 25,
        marginBottom: -30
    },
    checkoutContainer: {
        marginLeft: -3,
        marginRight: 12
    },
    alignLeft: {
        float: 'left'
    },
    checkoutContainerCol: {},
    checkIconStyle: { marginTop: '30%', float: 'right', marginRight: 5 },
    infoRowStyle: {
        marginTop: 7,
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        zIndex: 2
    },
    checkOutBtn: {
        margin: 15,
        height: 50,
        backgroundColor: '#61B522',
        border: 0,
        borderRadius: 0,
        zIndex: 100,
        flexShrink: 0,
        fontSize: 18
    },
    checkOutBtnDisabled: {
        margin: 15,
        height: 50,
        backgroundColor: '#a8a8a8',
        border: 'none',
        borderRadius: 0,
        pointerEvents: 'none',
        flexShrink: 0,
        opacity: 1,
        zIndex: 5,
        fontSize: 18
    },
    dropdownPayments: {
        borderRadius: 0,
        borderColor: 'rgba(167, 167, 167, 0.5)',
        fontWeight: 300
    },
    dropDownOptions: {
        fontWeight: 300
    },
    cardsInfoMessage: {
        fontSize: 13,
        color: '#fff',
        marginBottom: 0,
        marginTop: 4,
        position: 'relative',
        left: '100px'
    },
    savedGiftCardContainer: {
        width: '300px',
        height: '140px',
        display: 'flex',
        borderRadius: '15px',
        boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
        margin: '10px auto',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
import {
    CLEAR_APP_STATE,
    CLEAR_STATE,
    SELECTED_PRODUCT,
    SELECTED_PRODUCT_EDIT
} from '../../actions/types';

const INITIAL_STATE = {};

export default (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case SELECTED_PRODUCT:
            return action.payload;
        case SELECTED_PRODUCT_EDIT:
            return action.payload;
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
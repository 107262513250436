import { SELECT_BRAND_ID_SUCCESS } from '../../types';

import { saveBrandIdToStoreLocalStorage } from '../../StoreLocalStorageActions';

export const saveBrandIdToStore = (brandId) => {
    return (dispatch) => {

        dispatch(saveBrandIdToStoreLocalStorage(brandId));

        dispatch({ type: SELECT_BRAND_ID_SUCCESS, payload: brandId });
    }
};
import {
    CLEAR_APP_STATE,
    LOADING,
    LOADING_NO_BACKGROUND,
    ERROR_RESPONSE,
    ERROR_RESPONSE_RESET
} from '../actions/types';

const INITIAL_STATE = {
    backgroundLoader: false,
    transparentLoader: false,
    errorResponse: false
};

export default (state = INITIAL_STATE, action) => {
    let updatedState = {};

    switch (action.type) {
        case LOADING:
            return {
                ...state,
                backgroundLoader: action.payload,
                transparentLoader: false
            }
        case LOADING_NO_BACKGROUND:
            updatedState = {
                ...state,
                backgroundLoader: false,
                transparentLoader: action.payload
            };

            return {
                ...state,
                backgroundLoader: false,
                transparentLoader: action.payload
            };
        case ERROR_RESPONSE:
            return {
                ...state,
                errorResponse: true
            }
        case ERROR_RESPONSE_RESET:
            return {
                ...state,
                errorResponse: false
            };
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, Table, Alert } from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import i18n from '../../i18next';

import ConfirmAddressScreen from "../order/start/ConfirmAddressScreen";
import ConfirmationModal from "../common/ConfirmationModal";
import { saveCustomerDeliveryAddress, deleteCustomerDeliveryAddress, deleteCustomerSavedRestaurant } from "../../actions/index";
import { clearMessageAlert, showMessageAlert, resetMessageAlert } from "../../actions";
import { COLLECTION_ID, DELIVERY_ID } from "../../utils/Constants";

class AddressesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isConfirmAddressScreenOpened: false,
            openConfirmationDialog: false,
            selectedAddressTypeId: null,
            selectedAddress: '',
            selectedRestaurant: ''
        };

        this.toggleChangeAddressScreen = this.toggleChangeAddressScreen.bind(this);
        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.confirmDeleteAddress = this.confirmDeleteAddress.bind(this);
        this.confirmDeleteRestaurantAddress = this.confirmDeleteRestaurantAddress.bind(this);
    }

    componentWillMount() {
        this.props.resetMessageAlert();
    }

    clearState() {
        this.state.isConfirmAddressScreenOpened = false;
        this.state.openConfirmationDialog = false;
        this.state.selectedAddressTypeId = null;
        this.state.selectedAddress = '';
        this.state.selectedRestaurant = '';
    }

    toggleModal() {
        this.setState({
            openConfirmationDialog: !this.state.openConfirmationDialog
        })
    }

    toggleChangeAddressScreen() {
        this.setState({
            isConfirmAddressScreenOpened: !this.state.isConfirmAddressScreenOpened
        });
    }

    toggleConfirmationModal(id, value) {

        if (id == DELIVERY_ID) {
            this.setState({
                openConfirmationDialog: !this.state.openConfirmationDialog,
                selectedAddress: value,
                selectedAddressTypeId: id
            });
        } else if (id == COLLECTION_ID) {
            this.setState({
                openConfirmationDialog: !this.state.openConfirmationDialog,
                selectedRestaurant: value,
                selectedAddressTypeId: id
            });
        }
    }

    confirmDeleteAddress() {
        const addressId = this.state.selectedAddress.id;
        this.props.deleteCustomerDeliveryAddress(addressId, () => {
            this.clearState();
        });
    }

    confirmDeleteRestaurantAddress() {
        const restaurantId = this.state.selectedRestaurant.restaurantId;
        this.props.deleteCustomerSavedRestaurant(restaurantId, () => {
            this.clearState();
        });
    }

    selectCustomerAddress(address) {
        this.props.saveCustomerDeliveryAddress(address);
        this.toggleChangeAddressScreen();
    }

    renderDeliveryAddresses() {
        const { centredText } = this.props.styles;
        const tabletOrDesctopView = window.innerWidth >= 940;
        const colWidth = tabletOrDesctopView ? 'col-8' : 'col-10';
        let { tableHeadersBackground, tableHeadersFont } = this.props.customerThemes.selectedTheme;

        return (
            <Table style={{ marginTop: 10 }}>
                <thead >
                    <tr className="d-table-row" style={{ background: tableHeadersBackground, marginTop: 10, padding: '0px 6px', color: tableHeadersFont }}>
                        <th className={colWidth} style={{ border: 'none' }}>{i18n.t('screens:profileScreen.deliveryAddresses')}</th>
                        {tabletOrDesctopView ? (
                            <React.Fragment>
                                <th className="col-1" style={Object.assign({}, centredText, { border: 'none' })}>{i18n.t('common:buttons.edit')}</th>
                                <th className="col-1" style={Object.assign({}, centredText, { border: 'none' })}>{i18n.t('common:buttons.delete')}</th>
                            </React.Fragment>) : null}
                    </tr>
                </thead>
                <tbody>
                    {this.renderDeliveryTableRows()}
                </tbody>
            </Table>
        );
    }

    renderDeliveryTableRows() {
        const { centredText } = this.props.styles;
        const { type, primaryColor, secondaryColor, menuItemsBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const tabletOrDesctopView = window.innerWidth >= 940;
        const colWidth = tabletOrDesctopView ? 'col-8' : 'col-10';

        if (this.props.customerSavedAddresses !== undefined && this.props.customerSavedAddresses !== null
            && this.props.customerSavedAddresses.length > 0) {
            return this.props.customerSavedAddresses.map((address) => {
                const deliveryAddress = (address.fullAddress !== null) ? address.fullAddress : address.street + ', ' + address.city + ', ' + address.country;

                return (
                    <tr className="d-table-row" style={{ backgroundColor: menuItemsBackground }}>
                        <td className={colWidth} style={{ fontSize: 14, color: primaryFontColor }}>{deliveryAddress}</td>
                        {tabletOrDesctopView ? (
                            <React.Fragment>
                                <td className="col-1" style={Object.assign({}, centredText, { cursor: 'pointer' })} onClick={() => this.selectCustomerAddress(address)}>
                                    <MaterialIcon icon="edit" color={type === 'DARK' ? secondaryColor : primaryColor} />
                                </td>
                                <td className="col-1" style={Object.assign({}, centredText, { cursor: 'pointer' })} onClick={() => this.toggleConfirmationModal(DELIVERY_ID, address)}>
                                    <MaterialIcon icon="delete" color={type === 'DARK' ? secondaryColor : primaryColor} />
                                </td>
                            </React.Fragment>) : null}
                    </tr>
                );
            });
        } else {
            return (
                <tr className="d-table-row">
                    <td className="col-10" style={{ fontSize: 14 }}>{i18n.t('screens:profileScreen.noPrevDeliveries')}</td>
                    {/* <td className="col-1" style={Object.assign({}, centredText, { cursor: 'pointer' })}>
                        <MaterialIcon icon="edit" color={secondaryColor} />
                    </td>
                    <td className="col-1" style={Object.assign({}, centredText, { cursor: 'pointer' })}>
                        <MaterialIcon icon="delete" color={secondaryColor} />
                    </td> */}
                </tr>
            );
        }
    }

    renderCollectionAddresses() {
        const { centredText } = this.props.styles;
        const tabletOrDesctopView = window.innerWidth >= 940;
        const colWidth = tabletOrDesctopView ? 'col-10' : 'col-12';
        let { menuItemsBackground, tableHeadersBackground, tableHeadersFont } = this.props.customerThemes.selectedTheme;

        return (
            <Table style={{ marginTop: 10 }}>
                <thead>
                    <tr className="d-table-row" style={{ background: tableHeadersBackground, marginTop: 10, padding: '0px 6px', color: tableHeadersFont }}>
                        <th className={colWidth} style={{ border: 'none' }}>{i18n.t('screens:profileScreen.collectionAddresses')}</th>
                        {tabletOrDesctopView ? <th className="col-2" style={Object.assign({}, centredText, { border: 'none' })}>{i18n.t('common:buttons.delete')}</th> : null}
                    </tr>
                </thead>
                <tbody style={{ backgroundColor: menuItemsBackground }}>
                    {this.renderCollectionTableRows()}
                </tbody>
            </Table>
        )
    }

    renderCollectionTableRows() {
        const { centredText } = this.props.styles;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const tabletOrDesctopView = window.innerWidth >= 940;
        const colWidth = tabletOrDesctopView ? 'col-10' : 'col-12';

        if (this.props.customerSavedRestaurants !== undefined && this.props.customerSavedRestaurants !== null
            && this.props.customerSavedRestaurants.length > 0) {
            return this.props.customerSavedRestaurants.map((restaurant) => {
                return (
                    <tr className="d-table-row">
                        <td className={colWidth} style={{ fontSize: 14, color: primaryFontColor }}>{restaurant.name}</td>
                        { tabletOrDesctopView ? (<td className="col-2" style={Object.assign({}, centredText, { cursor: 'pointer' })} onClick={() => this.toggleConfirmationModal(COLLECTION_ID, restaurant)}>
                            <MaterialIcon icon="delete" color={type === 'DARK' ? secondaryColor : primaryColor} />
                        </td>) : null}
                    </tr>
                );
            });
        } else {
            return (
                <tr className="d-table-row">
                    <td className="col-12" style={{ fontSize: 14 }}>{i18n.t('screens:profileScreen.noPrevCollections')}</td>
                    {/* <td className="col-2" style={Object.assign({}, centredText, { cursor: 'pointer' })}>
                        <MaterialIcon icon="delete" color={secondaryColor} />
                    </td> */}
                </tr>
            );
        }
    }

    renderNoSavedAddresses() {
        return (
            <div style={{ width: '100%', textAlign: 'center' }}>{i18n.t('screens:profileScreen.noSavedAddresses')}</div>
        );
    }

    renderSuccessAlert() {
        const successAlert = this.props.alertMessage.showSuccessAlert;

        if (successAlert) {
            setTimeout(() => this.props.clearMessageAlert('success'), 1500);
        }

        const dismiss = this.props.clearMessageAlert.bind(this, 'success');

        if (successAlert) {
            return (
                <div className='alertContainer'>
                    <Alert color='success' className='success-alert' toggle={dismiss} style={{ borderRadius: 0 }}>
                        {i18n.t('common:alerts.addressListUpdateSuccess')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    renderErrorAlert() {
        const errorAlert = this.props.alertMessage.showErrorAlert;

        // if (errorAlert) {
        //     setTimeout(() => this.props.clearMessageAlert('error'), 1500);
        // }

        const dismiss = this.props.clearMessageAlert.bind(this, 'error');

        if (errorAlert) {
            return (
                <div className='alertContainer'>
                    <Alert color='danger' className='error-alert' toggle={dismiss} style={{ borderRadius: 0 }}>
                        {i18n.t('common:alerts.addressListUpdateFailure')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { mainContainer } = this.props.styles;
        const textData = {
            informationMessage: i18n.t('screens:profileScreen.deleteAddress'),
            confirmationMessage: null
        };
        const isMobileView = window.innerWidth <= 768;

        return (
            <Fragment>
                <Col xs={12} sm={12} md={10} style={isMobileView ? { ...mainContainer, margin: '0, 5px, 20px' } : mainContainer} className="sideContent table">
                    <div className='text-center alert-container'>
                        {this.renderSuccessAlert()}
                        {this.renderErrorAlert()}
                    </div>
                    {
                        this.props.customerSavedAddresses.length > 0 ? this.renderDeliveryAddresses()
                            : this.props.customerSavedRestaurants.length === 0 ? this.renderNoSavedAddresses() : null}
                </Col>
                <Col xs={12} sm={12} md={10} style={isMobileView ? { ...mainContainer, margin: '0, 5px, 20px' } : mainContainer} className="sideContent table">
                    {this.props.customerSavedRestaurants.length > 0 ? this.renderCollectionAddresses() : null}
                </Col>
                <Modal className="modalStep startOrder" isOpen={this.state.isConfirmAddressScreenOpened} toggle={this.toggle} size='lg'>
                    <ConfirmAddressScreen {...this.props} toggle={this.toggleChangeAddressScreen} isProfileEdit={true} />
                </Modal>
                <ConfirmationModal
                    isOpen={this.state.openConfirmationDialog}
                    onToggle={this.toggleModal}
                    onConfirm={(this.state.selectedAddressTypeId == DELIVERY_ID) ? this.confirmDeleteAddress : (this.state.selectedAddressTypeId == COLLECTION_ID) ? this.confirmDeleteRestaurantAddress : null}
                    message={textData}
                    customerThemes={this.props.customerThemes}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        customer: state.customer,
        userAddress: state.customer.selectedDeliveryAddress,
        customerSavedAddresses: state.customerSavedAddresses,
        customerSavedRestaurants: state.customerSavedRestaurants,
        alertMessage: state.alertMessage,
        customerThemes: state.customerThemes
    };
};

export default connect(mapStateToProps, { saveCustomerDeliveryAddress, deleteCustomerDeliveryAddress, deleteCustomerSavedRestaurant, showMessageAlert, clearMessageAlert, resetMessageAlert })(AddressesScreen);
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { convertColorToRgba } from '../../../utils/CommonUtils';

import MenuProductCardContentLargeImage from './MenuProductCardContentLargeImage';
import ProductCardLargeImgContainer from './MenuProductCardLargeImgContainer';

const MenuProductCardLargeImage = props => {
    const { menuItemsBackground, primaryColor,  fontFamilyType, fontBold, fontItalic, uppercase, primaryFontColor, mainTextColor, tableHeadersBackground} = props.selectedTheme;
    const convertedColorToRgba = convertColorToRgba(menuItemsBackground, 0.8);

    const productName = {
        fontFamily: fontFamilyType,
        fontWeight: fontBold,
        fontStyle: fontItalic,
        textTransform: uppercase,
        color: tableHeadersBackground,
        fontSize: '25px',
        textAlign: 'center'
    };

    const renderThumbnailCardBody = () => {

        return (
            <CardBody style={{ display: 'flex', padding: 0, flexDirection: 'column', maxWidth: '400px', maxHeight: '400px' }}>

            <ProductCardLargeImgContainer {...props} appOrMobile={false} />

                <div style={{ padding: props.isArabic ? "12px 30px 20px 18px" : "12px 18px 20px 30px", width: '100%', backgroundColor: convertedColorToRgba, bottom: '0', height: '132px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <MenuProductCardContentLargeImage {...props} styles={styles} />
                </div>

            </CardBody>
        );
    };

    const renderNoThumbnailCardBody = () => {
        const { cardBody } = styles;

        return (
            <CardBody style={{ display: 'flex', flexDirection: 'column' , padding: 0, width: '100%', height: '100%' }}>

                <div style={{ backgroundColor: primaryColor, width: '100%', height: '267px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <p style={productName}>{props.product.name}</p>
                </div>

                <div style={{ padding: props.isArabic ? "12px 30px 20px 18px" : "12px 18px 20px 30px", width: '100%', backgroundColor: convertedColorToRgba, bottom: '0', height: '132px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <MenuProductCardContentLargeImage {...props} styles={styles} />
                </div>

            </CardBody>
        );
    };

    const hasRectImage = props.product.rectImagePath === null;

    return (
        <Card style={props.cardStyle} className='product-card' id={props.product.id}>
            {hasRectImage ? renderNoThumbnailCardBody() : renderThumbnailCardBody()}
        </Card>
    );
}

export default MenuProductCardLargeImage;

const styles = {
    productNameStyle: {
        marginBottom: 0,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1.125rem',
        padding: '0 5px 0 0'
    },
    productDescriptionStyle: {
        fontSize: '0.875rem',
        padding: 0,
        color: '#6e7676',
        marginBottom: 0
    },
    productPriceStyle: {
        fontSize: '0.875rem',
        padding: '5px 5px 5px 0',
        color: '#62b400',
        marginLeft: 0,
        marginBottom: 0
    },
    cardBody: {
        padding: "12px 30px 12px 30px",
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    }
}
import React, {Component} from 'react';
import {connect} from 'react-redux';
import i18n from '../../../i18next';

import '../../styles/PwaSplashScreen.css';
import {getBrandData, checkVersionControl, clearAppState, allowTimeWarningPopup, registerAppDevice} from "../../../actions";
import {PWA_VERSION} from "../../../utils/Constants";
import WarningSvg from "./svgComponents/WarningSVG";
import {allowIOSNotifications} from "../../utils/FirebaseUtils";

const appVersionResponse = {
    "UP_TO_DATE": "UP_TO_DATE",
    "RECOMMENDED_UPDATE": "RECOMMENDED_UPDATE",
    "URGENT_UPDATE": "URGENT_UPDATE"
};

class PwaSplashScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCheckVersionCompleted: false
        }
    }


    componentDidMount() {
        this.props.allowTimeWarningPopup(false);
        const brandId = this.props.match && this.props.match.params ? this.props.match.params.id : null;

        this.props.checkVersionControl(PWA_VERSION, brandId, () => this.setState({ isCheckVersionCompleted: true }));
        this.versionDifferenceClearState();

        const splashBackground = document.getElementById("splashBackground");
        splashBackground.onscroll = (e) => {e.preventDefault()};
        splashBackground.ontouchmove = (e) => {e.preventDefault()};

    }

    versionDifferenceClearState() {
        let currentAppVersion = PWA_VERSION.toString();
        let installedAppVersion = localStorage.getItem('installedAppVersion');

        if (currentAppVersion !== installedAppVersion) {

            this.props.clearAppState();
            localStorage.clear();

            localStorage.setItem('installedAppVersion', currentAppVersion);
        }
    }

    goToMainScreen() {
        this.props.history.push({
            pathname: `/brand/${this.props.match.params.id}/start-order/main-screen`,
            state: {redirectFromSplash: true}
        });
    }

    renderSplash(isUpToDate, isRedirectingToMainScreen) {

        if (isRedirectingToMainScreen && this.state.isCheckVersionCompleted) {
            let that = this;
            allowIOSNotifications(() => {
                setTimeout(function () {
                    that.goToMainScreen();
                }, 4000);
            });
        }

        let message;

        if (isUpToDate) {
            message = () => {
                return(
                <React.Fragment>
                {i18n.t('screens:splashScreen.outOfDate')} <br/> {i18n.t('screens:splashScreen.updateApp')}
            </React.Fragment>
            )};
        } else {
            message = () => {
                return (
                    <React.Fragment>
                        {i18n.t('screens:splashScreen.newVersion')}
                    </React.Fragment>
                );
            }
        }

        const userAgent = window.navigator.userAgent;
        let userOnMobileIos = userAgent.match(/iPhone; CPU iPhone OS/) || userAgent.match(/CPU iPhone/) || userAgent.match(/iPad;/);

        return (
            <div id="splashBackground" className="background">
                <div className="scanAnimation">
                </div>

                <img alt="splash" className={userOnMobileIos ? "appSplashImageIos" :"appSplashImage"} src="splash.jpg" />

                {
                    (!isRedirectingToMainScreen || !isUpToDate) && this.state.isCheckVersionCompleted ?
                        <div className="warningCriticalUpdate">
                            <WarningSvg width={50} height={50} fill={isUpToDate ? "red" : "#F0AE30"}/>
                            <div className="warningCriticalUpdateText">
                                {message()}
                            </div>
                        </div>
                        : null}
                {/*<img className="menuuLogo" src={menuLogoGreySrc} alt=""/>*/}
             </div>
        );
    }

    renderBody(appVersion) {

        switch (appVersion) {
            case appVersionResponse.UP_TO_DATE:
                return this.renderSplash(true, true);
            case appVersionResponse.RECOMMENDED_UPDATE:
                return this.renderSplash(false, true);
            case appVersionResponse.URGENT_UPDATE:
                return this.renderSplash(true, false);
            default:
                return this.renderSplash(true, true);
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderBody(this.props.checkVersion.status)}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        // brand: state.brand,
        checkVersion: state.checkVersion
    };
};

export default connect(mapStateToProps, {
    // getBrandData,
    clearAppState,
    checkVersionControl,
    allowTimeWarningPopup,
    registerAppDevice
})(PwaSplashScreen);
import {
    LOAD_LOYALTY_SUCCESS,
    CLEAR_STATE,
    CLEAR_APP_STATE,
    GET_ALL_LOYALTY_CODES_SUCCESS,
    GET_AUTO_APPLY_LOYALTY,
    GET_OLDEST_ACTIVE_LOYALTY
} from '../../../actions/types';

const INITIAL_STATE = {
    loyalty: {},
    loyaltyCodes: [],
    autoApplyLoyalty: true,
    oldestActiveCode: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_LOYALTY_SUCCESS:
            return {
                ...state,
                loyalty: action.payload
            };
        case GET_ALL_LOYALTY_CODES_SUCCESS:
            return {
                ...state,
                loyaltyCodes: action.payload
            }
        case GET_AUTO_APPLY_LOYALTY:
            return {
                ...state,
                autoApplyLoyalty: action.payload.autoApplyLoyalty
            }
        case GET_OLDEST_ACTIVE_LOYALTY:
            return {
                ...state,
                oldestActiveCode: action.payload
            }
        case CLEAR_STATE:
            if (action.fromPwa) {
                return state;
            } else {
                return INITIAL_STATE;
            }
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
import React from 'react';

const ShoppingCartSVG = ({ width, height, fill, style, isCartItemsButton }) => {
	const viewBoxValues = "0 0 76.7 97.2";

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style}>
			{
				isCartItemsButton ?
				<g>
				{/* These are the holes in the bag */}
				{/* <path fill={fill} d="M49,14.6C49,9,44.4,4.4,38.8,4.4c-5.7,0-10.3,4.6-10.3,10.3V22H49V14.6z"/> */}
				<path fill={fill} stroke={fill} d="M26.3,27.8c-3.4,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3c3.5,0,6.3-2.8,6.3-6.3S29.8,27.8,26.3,27.8z"/>
				<path fill={fill} stroke={fill} d="M51.2,27.8c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3c3.4,0,6.3-2.8,6.3-6.3S54.7,27.8,51.2,27.8z"/>
				<path fill={fill} stroke={fill} d="M70,24c-0.1-1.1-1-2-2.2-2H53.4v-7.4C53.4,6.6,46.8,0,38.8,0c-8.1,0-14.6,6.6-14.6,14.6V22H8.9
					c-1.1,0-2.1,0.9-2.2,2L0,94.8C0,95.4,0.2,96,0.6,96.5c0.4,0.5,1,0.7,1.6,0.7h72.3c0.6,0,1.2-0.3,1.6-0.7c0.4-0.5,0.6-1.1,0.6-1.7
					L70,24z M28.5,14.6c0-5.7,4.6-10.3,10.3-10.3C44.4,4.4,49,9,49,14.6V22H28.5V14.6z M26.3,40.3c-3.4,0-6.3-2.8-6.3-6.3
					s2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3S29.8,40.3,26.3,40.3z M51.2,40.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3
					c3.4,0,6.3,2.8,6.3,6.3S54.7,40.3,51.2,40.3z"/>
				</g> :
				<g>
				{/* These are the holes in the bag */}
				{/* <path fill={fill} d="M49,14.6C49,9,44.4,4.4,38.8,4.4c-5.7,0-10.3,4.6-10.3,10.3V22H49V14.6z"/> */}
				{/* <path fill={fill} d="M26.3,27.8c-3.4,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3c3.5,0,6.3-2.8,6.3-6.3S29.8,27.8,26.3,27.8z"/> */}
				{/* <path fill={fill} d="M51.2,27.8c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3c3.4,0,6.3-2.8,6.3-6.3S54.7,27.8,51.2,27.8z"/> */}
				<path fill={fill} d="M70,24c-0.1-1.1-1-2-2.2-2H53.4v-7.4C53.4,6.6,46.8,0,38.8,0c-8.1,0-14.6,6.6-14.6,14.6V22H8.9
					c-1.1,0-2.1,0.9-2.2,2L0,94.8C0,95.4,0.2,96,0.6,96.5c0.4,0.5,1,0.7,1.6,0.7h72.3c0.6,0,1.2-0.3,1.6-0.7c0.4-0.5,0.6-1.1,0.6-1.7
					L70,24z M28.5,14.6c0-5.7,4.6-10.3,10.3-10.3C44.4,4.4,49,9,49,14.6V22H28.5V14.6z M26.3,40.3c-3.4,0-6.3-2.8-6.3-6.3
					s2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3S29.8,40.3,26.3,40.3z M51.2,40.3c-3.5,0-6.3-2.8-6.3-6.3s2.8-6.3,6.3-6.3
					c3.4,0,6.3,2.8,6.3,6.3S54.7,40.3,51.2,40.3z"/>
				</g>
			}
		</svg>
	);
}

export default ShoppingCartSVG;
import React, { Component, Fragment } from 'react';
import {
    Card,
    CardBody,
    Col,
    Row,
    ListGroup,
    ListGroupItem,
    Badge
} from "reactstrap";

import MenuProductCardContent from '../../../../components/order/menu/MenuProductCardContent';
import MenuProductCardContentLargeImage from '../../../../components/order/menu/MenuProductCardContentLargeImage';
import ProductCardLargeImgContainer from '../../../../components/order/menu/MenuProductCardLargeImgContainer';
import ProductCardImgContainer from '../../../../components/order/menu/ProductCardImgContainer';
import ProductSearchBar from '../../../../components/order/ProductSearchBar';
import { convertColorToRgba } from '../../../../utils/CommonUtils';
import { FRONT_END_URL } from '../../../../utils/Constants';

export default class ProductsScreenPwa extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.applyTheme();
        if (document.getElementById('generalNoteMobile')) {
            document.getElementById('generalNoteMobile').innerHTML = this.props.generalNote;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.generalNote !== this.props.generalNote) {

            if (document.getElementById('generalNoteMobile')) {
                document.getElementById('generalNoteMobile').innerHTML = this.props.generalNote;
            }

        }
    }

    renderProductByCategory() {
        const { mainTextColor, imageBackground } = this.props.customerThemes.selectedTheme;
        const isGeneralNoteSet = this.props.generalNote
        let { menuContainer, generalNoteMobile, generalNoteMobileMultipleMenus } = this.props.styles;

        generalNoteMobileMultipleMenus = { ...generalNoteMobileMultipleMenus, backgroundColor: 'transparent', color: mainTextColor, paddingTop: 20 };
        generalNoteMobile = { ...generalNoteMobile, backgroundColor: 'transparent', color: mainTextColor, paddingTop: 20 };

        // Handle the problem with the scrolling not showing category name when we have more than one Menu in mobile view
        let multipleMenus = this.props.numberOfMenus > 1;
        let generalNoteMobileCopy = { ...generalNoteMobile };
        let menuContainerStyles = {
            ...menuContainer,
            display: 'block',
            padding: !this.props.hiddenStickyMenu && isGeneralNoteSet ? !multipleMenus ? 0 : '50px 0px 0px 0px' : 0
        }

        if (window.innerWidth <= 768) {
            generalNoteMobileCopy = {
                ...generalNoteMobileCopy,
                padding: '0px 16px 0px 12px',
                margin: '0px 0px -25px 0px'
            };
        }

        return (
            <div style={menuContainerStyles} className='menuContainer' >
                <Col xs={12} sm={12} md={12} lg={12} style={imageBackground ? styles.imageBackground : styles.colorBackground}>
                    {this.renderSearchBar()}
                    <Row style={{ margin: 0, padding: 0 }}>
                        <div id='generalNoteMobile' style={multipleMenus ? generalNoteMobileMultipleMenus : generalNoteMobileCopy}></div>
                        {this.renderCategories()}
                    </Row>
                </Col>
            </div>
        )
    }

    getSelectedCategories(selectedMenuId) {
        const { categories } = this.props;

        let selectedCategories = [];

        for (let i = 0; i < categories.length; i++) {
            if (categories[i].menuId == selectedMenuId) {
                selectedCategories.push(categories[i]);
            }
        }

        return selectedCategories;
    }

    renderCategories() {
        const { primaryFontColor, mainTextColor, fontFamilyType, fontBold, fontItalic, uppercase, imageBackground } = this.props.customerThemes.selectedTheme;
        let { categoryNameStyleMobile, firstCategoryName, categoryDescriptionMobile } = this.props.styles;
        categoryDescriptionMobile = { ...categoryDescriptionMobile, color: mainTextColor }

        const titleCategoryFontStyle = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        const firstCategoryStyle = this.props.isPWA ? firstCategoryName : categoryNameStyleMobile;

        return (
            <Fragment>
                {
                    this.props.selectedCategories.map((category, i) => {
                        return (
                            <Fragment key={`category${i}`}>
                                <section
                                    key={`container${i}`}
                                    id={category.name}
                                    className={`productCategoryName menuCategoryMainContainer`}
                                    hidden={this.props.hiddenStickyMenu && this.props.noSearchedProducts(category)}
                                    style={{ backgroundColor: 'transparent' }}>
                                    {/* {this.props.renderFirstCategoryHiddenId(this.props.menus, category, true, i, this.props.isPWA)} */}
                                    <Row id={category.name} style={{ margin: '10px 0px 0px 0px' }}>
                                        <h4 className={(i == 0 && this.props.isPWA) ? "firstCategory" : "categoryNameStyle"}
                                            style={Object.assign({}, firstCategoryStyle, {
                                                ...titleCategoryFontStyle,
                                                color: primaryFontColor,
                                                padding: '26px 12px 0'
                                            })}>
                                            {category.name}
                                        </h4>
                                        <span style={categoryDescriptionMobile}>{category.description}</span>
                                        {category.imageUrl && !this.props.enableLargeImageDesign ?
                                            <div style={{ width: '100%', maxHeight: '350px', padding: '0 0 15px' }}>
                                                <img src={`${FRONT_END_URL}/${category.imageUrl}`} style={{ width: '100%', height: '100%' }} alt='category image' />
                                            </div> : null
                                        }
                                    </Row>
                                    <Col sm={12} className="noPaddingElement" style={this.props.enableLargeImageDesign ? {display: 'flex', justifyContent: 'center', padding: 0 } : { padding: 0 }}>
                                        <ListGroup className="mobile-view-products" style={this.props.enableLargeImageDesign ? {maxWidth: '100%', width: '400px'} : null}>{this.renderProducts(category)}</ListGroup>
                                    </Col>
                                </section>
                            </Fragment>
                        );
                    })
                }
            </Fragment>
        )
    }

    renderProducts(category) {
        let { card, listItemNoThumbnail, largeImageCard } = this.props.styles;
        const { products } = this.props;
        listItemNoThumbnail = this.props.enableLargeImageDesign ? { ...listItemNoThumbnail, backgroundColor: 'transparent', border: 'none'} : { ...listItemNoThumbnail, backgroundColor: 'transparent' }

        if (products !== undefined && products !== null && products.length > 0) {

            return products.map((product, i) => {

                if (product.categoryId == category.id) {

                    const productFoundInCart = this.checkIfProductIsInCart(product.id, product.productSizesIds);
                    const productQuantityInCart = this.checkProductQuantityInCart(product.name);

                    const hasThumbnail = !this.props.enableLargeImageDesign ? product.thumbnailPath === null : false;
                    const hasRectImage = this.props.enableLargeImageDesign ? product.rectImagePath === null : false;

                    return (
                        <ListGroupItem key={`product${i}`} className="product-card-list" style={listItemNoThumbnail} onClick={() => this.props.selectProduct(product)} id={product.id}>

                            <Card style={this.props.enableLargeImageDesign ? largeImageCard : card} className={this.props.enableLargeImageDesign ? null : "product-card"}>
                                {
                                    hasThumbnail || hasRectImage ?
                                        this.renderNoThumbnailCardBodyMobile(product, productFoundInCart, productQuantityInCart, i) :
                                        this.renderThumbnailCardBodyMobile(product, productFoundInCart, productQuantityInCart, i)
                                }
                            </Card>


                        </ListGroupItem>
                    );
                }
            });
        }
    }

    checkIfProductIsInCart(productId, productSizesIds) {
        const { orderProducts } = this.props.shoppingCart;
        let productNotFoundInCart = true;

        if (orderProducts.length > 0) {
            orderProducts.map(productProp => {
                if (productProp.id === productId || productSizesIds.includes(productProp.id)) {
                    productNotFoundInCart = false;
                }
            });
        }
        return productNotFoundInCart;
    }

    checkProductQuantityInCart(productName) {
        const { orderProducts } = this.props.shoppingCart;
        let productQuantity;
        let quantityToAdd = 0;
        let productNameFoundInCart = 0;

        if (orderProducts.length > 0) {
            orderProducts.map(productProp => {
                if (productProp.name === productName) {
                    productNameFoundInCart++;
                    if (productProp.quantity > 1) {
                        quantityToAdd += (productProp.quantity - 1);
                    }
                }
            });
            productQuantity = productNameFoundInCart + quantityToAdd;
        }
        return productQuantity;
    }

    renderNoThumbnailCardBodyMobile(product, productFoundInCart, productQuantityInCart, productIndex) {
        let { cardBody, largeImageBadge, badgeColMobileLargeImage, badgeColMobileLargeImageRTL, largeImageCardBody } = this.props.styles;
        const { menuItemsBackground, mainTextColor, fontFamilyType, fontBold, fontItalic, uppercase, primaryColor, tableHeadersBackground } = this.props.customerThemes.selectedTheme;
        const convertedColorToRgba = convertColorToRgba(menuItemsBackground, 0.8)

        cardBody = { ...cardBody, backgroundColor: 'transparent' };

        const productName = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
            fontFamily: fontFamilyType,
            fontWeight: fontBold,
            fontStyle: fontItalic,
            textTransform: uppercase,
            color: tableHeadersBackground,
            textAlign: 'center',
            fontSize: '25px',
            aspectRatio: '3 / 2'
        };

        return (
            <CardBody style={this.props.enableLargeImageDesign ? largeImageCardBody : {...cardBody, backgroundColor: convertedColorToRgba}}>
                {
                    this.props.enableLargeImageDesign ?
                    <div style={{maxWidth: '400px', maxHeight: '400px', minWidth: '100px', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ backgroundColor: primaryColor, maxWidth: '100%', maxHeight: '267px'}}>
                            <div style={productName}>{product.name}</div>
                            <Col style={this.props.isArabic ? {...badgeColMobileLargeImageRTL, right: 0} : {...badgeColMobileLargeImage, left: 0}} hidden={productFoundInCart}>
                                <Badge style={Object.assign({}, largeImageBadge, { background: primaryColor })}>{productQuantityInCart}x</Badge>
                            </Col>
                        </div>
                        <div style={{width: '100%', backgroundColor: convertedColorToRgba, padding: "12px 30px 12px 30px", height: '124px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                            <MenuProductCardContentLargeImage 
                                {...this.props}
                                productIndex={productIndex}
                                product={product}
                                productFoundInCart={productFoundInCart}
                                productQuantityInCart={productQuantityInCart}
                                selectedTheme={this.props.customerThemes.selectedTheme}
                                mobileOrApp={true}
                                enableLargeImageDesign={this.props.enableLargeImageDesign}
                            /> 
                        </div>
                    </div> :
                    <div>
                        <MenuProductCardContent
                            {...this.props}
                            productIndex={productIndex}
                            product={product}
                            productFoundInCart={productFoundInCart}
                            productQuantityInCart={productQuantityInCart}
                            selectedTheme={this.props.customerThemes.selectedTheme}
                            mobileOrApp={true} />
                    </div>
                    }

            </CardBody>
        );
    }

    renderThumbnailCardBodyMobile(product, productFoundInCart, productQuantityInCart, productIndex) {
        const { menuItemsBackground } = this.props.customerThemes.selectedTheme;
        let { cardBody, largeImageCardBody } = this.props.styles;
        cardBody = { ...cardBody, backgroundColor: 'transparent' };
        const convertedColorToRgba = convertColorToRgba(menuItemsBackground, 0.8)

        return (
            <CardBody style={this.props.enableLargeImageDesign ? largeImageCardBody : { display: 'flex', padding: 0 }}>

                {  // Product Card Large Image Design  
                    this.props.enableLargeImageDesign ?
                    <div style={{width: '100%', maxHeight: '400px', margin: '0 auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ maxWidth: '100%', maxHeight: '267px'}}>
                        <ProductCardLargeImgContainer 
                            {...this.props}
                            product={product}
                            productFoundInCart={productFoundInCart}
                            productQuantityInCart={productQuantityInCart}
                            selectedTheme={this.props.customerThemes.selectedTheme}
                            appOrMobile={true}
                            isArabic={this.props.isArabic} /> 
                        </div>
                        <div style={{width: '100%', backgroundColor: convertedColorToRgba, padding: "12px 30px", zIndex: '2', height: '124px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>

                            <MenuProductCardContentLargeImage 
                                {...this.props}
                                productIndex={productIndex}
                                product={product}
                                productFoundInCart={productFoundInCart}
                                productQuantityInCart={productQuantityInCart}
                                selectedTheme={this.props.customerThemes.selectedTheme}
                                mobileOrApp={true}
                            />
                        </div> 
                    </div>
                    : 
                    <Fragment>
                        <Col xs={8} sm={8} style={{ padding: "12px 30px 12px 30px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: convertedColorToRgba }}>
                            <div>
                                <MenuProductCardContent
                                    {...this.props}
                                    productIndex={productIndex}
                                    product={product}
                                    productFoundInCart={productFoundInCart}
                                    productQuantityInCart={productQuantityInCart}
                                    selectedTheme={this.props.customerThemes.selectedTheme}
                                    mobileOrApp={true} />
                            </div>
                        </Col>
                
                        <ProductCardImgContainer
                            {...this.props}
                            product={product}
                            productFoundInCart={productFoundInCart}
                            productQuantityInCart={productQuantityInCart}
                            selectedTheme={this.props.customerThemes.selectedTheme}
                            appOrMobile={true}
                        />
                    </Fragment>
                }

            </CardBody>
        );
    }

    renderSearchBar = () => {
		return (
			<div style={{maxWidth: '1600px'}}>
				<ProductSearchBar
						sendDataToParent={this.props.sendDataToParent}
						customerThemes={this.props.customerThemes}
						products={this.props.products}
						toggleSearchProductFocus={this.props.toggleSearchProductFocus} 
						isArabic={this.props.isArabic}
						enableLargeImageDesign={this.props.enableLargeImageDesign}
				/>
			</div>
		)
	}

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));

        styles = customizedStyles;
        return styles;
    }

    renderProductDescription(html, productIndex) {
        if (document.getElementById(`productDescription${productIndex}`)) {
            document.getElementById(`productDescription${productIndex}`).innerHTML = html;
        }
    }

    render() {
        return (
            <Fragment>
                {this.renderProductByCategory()}
            </Fragment>
        );
    }
}

let styles = {
    imageBackground: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        paddingBottom: 40
    },
    colorBackground: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        paddingBottom: 40
    }
}
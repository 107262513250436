import React from 'react';

const CreditCardSVG = ({ width, height, fill, style }) => {
    const viewBoxValues = "0 0 24 24";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style} >
            {/* Background */}
            {/* <path fill={fill} d="M0 0h24v24H0z" /> */}
            <path fill={fill} d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
        </svg>

    );
}

export default CreditCardSVG;
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button, Col, Row, NavLink, Modal } from "reactstrap";
import i18n from '../../i18next';
import moment from "moment/moment";
import { isMobileOnly } from 'mobile-device-detect';
import { Link } from "react-router-dom";

import {
    logOutCustomer,
    clearWizardData,
    clearState,
    clearTemporaryOrderTimeData,
    openChangeOrderTimeScreen,
    saveNewOrderTime,
    saveInStoreInfo,
    toggleUnavailableProductsScreenOpen,
    changeCodeManuallySet,
    isRestartedOrderFromComoUser,
    saveBrandIdToStore,
    saveComoTemporaryToken,
    openStartOrderScreen,
    changeSignInPressed
} from "../../actions/index";

import ConfirmationModal from '../common/ConfirmationModal';
import {
    dateAndTimeToUserFriendlyFormat,
    getLoggedInUserFirstName,
    preventBodyScrollOnSafari
} from '../../utils/CommonUtils';
import { COLLECTION_ID, DELIVERY_ID, CURBSIDE_ID, FRONT_END_URL, IN_STORE_ID, ARABIC } from "../../utils/Constants";

import AvailableDiscountsScreen from './discounts/AvailableDiscountsScreen';
import OrderTimeScreen from "./start/OrderTimeScreen";
import UnavailableProductsScreen from "./UnavailableProductsScreen";
import LocationIconSVG from '../../pwa-app/components/common/svgComponents/LocationIconSVG';
import ClockSVG from '../../pwa-app/components/common/svgComponents/ClockSVG';
import TickSVG from '../../pwa-app/components/common/svgComponents/TickSVG';
import LoyaltyIconSvg from "../../pwa-app/components/common/svgComponents/LoyaltyIconSVG";
import StarSVG from '../../pwa-app/components/common/svgComponents/StarSVG';
import TableSelectionScreen from "./instore/TableSelectionScreen";
import { loadAllAvailableDiscounts } from "../../actions";
import UserIconSVG from "../../pwa-app/components/common/svgComponents/UserIconSVG";

class RestaurantDetailsScreen extends Component {
    constructor(props) {
        super(props);

        let integrationDetected = false;
        if (new URLSearchParams(window.location.search).get('brandId')) {
            integrationDetected = true;
        }

        this.state = {
            openConfirmationDialog: false,
            discountsScreenOpened: false,
            isTableScreenOpened: false,
            isChangeTimeScreenOpened: false,
            isLoadedFromIntegration: integrationDetected,
            openTarget: '',
            // gets working restaurants counter to decide button text - collection flow only
            workingRestCounter: this.props.storeLocalStorage.workingRestCount ? parseInt(this.props.storeLocalStorage.workingRestCount) : null,
        };

        this.toggleChangeTimeScreen = this.toggleChangeTimeScreen.bind(this);
        this.confirmChangeTime = this.confirmChangeTime.bind(this);
        this.toggleDiscountsScreen = this.toggleDiscountsScreen.bind(this);
        this.toggleChangeTable = this.toggleChangeTable.bind(this);
    }

    componentWillMount() {
        this.applyTheme();
    }

    componentDidUpdate(prevProps) {
        const { restaurantId } = this.props.restaurant;

        if (this.props.customerThemes.selectedTheme.type !== prevProps.customerThemes.selectedTheme.type
            && window.location.href.includes('previewMode')) {
            this.applyTheme();
        }

        if (this.props.availableDiscounts.length !== prevProps.availableDiscounts.length
            && window.location.href.includes('previewMode')) {
            this.renderOrderDetails();
        }

        // check for available discounts when change time is pressed
        if ((this.props.estimateOrderTime.wantedTime !== prevProps.estimateOrderTime.wantedTime) && this.props.estimateOrderTime.wantedTime) {
            this.props.loadAllAvailableDiscounts(restaurantId);
        }
    }

    confirmChangeTime() {
        this.props.saveNewOrderTime(undefined, true);
    }

    toggleChangeTable() {
        // this.props.history.push('/menu-screen/in-store');

        this.setState({
            ...this.state,
            isTableScreenOpened: !this.state.isTableScreenOpened
        });
    }

    toggleChangeTimeScreen(isClickYes) {
        // const path = '/menu-screen/order-time';
        // this.props.openChangeOrderTimeScreen(path);
        if (!isClickYes) {
            this.setState({ ...this.state, isChangeTimeScreenOpened: !this.state.isChangeTimeScreenOpened });
        }
    }

    exitChangeTime() {
        this.props.toggleUnavailableProductsScreenOpen();
        this.props.clearTemporaryOrderTimeData();
    }

    getRestaurantFullAddress(restaurant) {
        let restaurantFullAddress = '';

        if (restaurant.address.floor) { restaurantFullAddress += restaurant.address.floor + ' Floor, ' }
        // if (restaurant.address.streetNumber) {restaurantFullAddress += restaurant.address.streetNumber + ' '}
        if (restaurant.address.street) { restaurantFullAddress += restaurant.address.street + ', ' }
        if (restaurant.address.city) { restaurantFullAddress += restaurant.address.city }

        return restaurantFullAddress;
    }

    // getRestaurantClosingHour(openingHours) {
    //     let restaurantOpenUntil = '';

    //     if (openingHours.closeHour) restaurantOpenUntil += openingHours.closeHour + ':';
    //     if (openingHours.closeHour === 0) restaurantOpenUntil += '00:';
    //     if (openingHours.closeMinute) restaurantOpenUntil += openingHours.closeMinute;
    //     if (openingHours.closeMinute === 0) restaurantOpenUntil += '00';

    //     return restaurantOpenUntil;
    // }

    getOrderTimeLabel(selectedOrderType) {
        let orderTimeLabel = '';
        // if (estimateOrderTime.time === null) {
        //     orderTimeLabel = 'Estimated Delivery Time: ';
        // } else if (estimateOrderTime.time !== null) {
        //     orderTimeLabel = 'Estimated Collection Time: ';
        // }
        if (selectedOrderType.id === DELIVERY_ID) {
            orderTimeLabel = i18n.t('screens:restaurantDetailsScreen.deliveryTime') + ': ';
        } else if (selectedOrderType.id === COLLECTION_ID) {
            orderTimeLabel = i18n.t('screens:restaurantDetailsScreen.collectionTime') + ': ';
        } else if (selectedOrderType.id === CURBSIDE_ID) {
            orderTimeLabel = i18n.t('screens:restaurantDetailsScreen.curbsideTime') + ': ';
        } else if (selectedOrderType.id === IN_STORE_ID) {
            orderTimeLabel = i18n.t('screens:restaurantDetailsScreen.instoreTime') + ': ';
        }

        return orderTimeLabel;
    }

    openLoginScreen(signInPressed) {
        const path = '/menu-screen';

        if (this.props.menuPreviewMode == false || this.props.menuPreviewMode == 'false') {
            if (this.props.shoppingCart.orderProducts.length && this.props.shoppingCart.total > this.props.restaurant.minimumOrderAmount) {
                document.getElementById('shoppingCartButton').click();
            } else {
                this.props.openStartOrderScreen(path);
            }

            if (signInPressed) {
                this.props.changeSignInPressed(true);
            }
        }
    }

    renderMyProfileLink() {
        const { imageStyle, imageStyleRTL, profileNameStyle, profileNameLink, profileNameLinkRTL, profileNameStyleRTL, signInButton, signInButtonRTL } = styles;
        const { menuPreviewMode, customer } = this.props;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const profileIconColor = type === 'DARK' ? secondaryColor : primaryColor;
        const userFirstName = getLoggedInUserFirstName(customer.name);
        const isArabic = this.props.language === ARABIC;

        let customerNameClass = 'profileName';
        if (window.outerWidth <= 500) {
            customerNameClass += ' customer-long-text-back-button';
        }

        if (menuPreviewMode == true || menuPreviewMode == 'true') {

            return (
                <div className="previewModeText" style={{ margin: isArabic ? '0px 0px 3px 12px' : '0px 12px 3px 0px', cursor: 'pointer', color: primaryFontColor }}>
                    <p>{i18n.t('common:alerts.previewMode')}</p>
                </div>
            );
        } else if (this.props.customer.id === -1 || this.props.customer.id === null || !this.props.customer.token) {
            return (
                <button style={isArabic && this.props.logoImagePath ? signInButtonRTL : isArabic && !this.props.logoImagePath ? {...signInButtonRTL, top: 25} : !this.props.logoImagePath ? {...signInButton, top: 25} :  signInButton} onClick={() => this.openLoginScreen(true)}>
                    <UserIconSVG width={25} height={25} fill={profileIconColor} style={isArabic ? {...imageStyleRTL, marginLeft: '5px'} : {...imageStyle, marginRight: '5px'}} />
                    {i18n.t('common:buttons.signin')}
                </button>
            );
        } else {
            return (
                <NavLink
                    tag={Link} to={{ pathname: "/profile/details", state: "/menu-screen" }} style={isArabic && !this.props.logoImagePath ? {...signInButtonRTL, top: 16} : isArabic && this.props.logoImagePath ? profileNameLinkRTL : !this.props.logoImagePath ? {...signInButton, top: 16} : profileNameLink} >
                    <UserIconSVG width={25} height={25} fill={profileIconColor} style={isArabic ? imageStyleRTL : imageStyle} />
                    <span style={isArabic ? profileNameStyleRTL : profileNameStyle} className={customerNameClass}>{userFirstName}</span>
                </NavLink>
            )
        }
    }

    renderOrderDetails() {

        const {
            orderDetailsContainer,
            orderDetailsContainerRTL,
            deliveryOptionsContainer,
            deliveryLabel,
            deliveryLabelRTL,
            deliveryInfoLabel,
            deliveryInfoLabelRTL,
            changeDeliveryInformation,
            restaurantDataContainer,
            restaurantDataLabels,
            restaurantPlaceholderContainer,
            restaurantPlaceholderContainerRTL,
            restaurantLargeImagePlaceholderContainer,
            restaurantLargeImagePlaceholderContainerRTL,
            restaurantNameLabelStyle,
            restaurantNameLabelStyleRTL,
            disabledLink,
            restaurantDataContainerLargeImage
        } = styles;

        const { restaurant, orderTypes, selectedOrderType, brandName, estimateOrderTime, menuPreviewMode, loyalty } = this.props;
        const { primaryColor, restInfoBackground, primaryFontColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        const inPreviewMode = window.location.href.includes('previewMode');

        // let restaurantFullAddress = this.getRestaurantFullAddress(restaurant);
        let restaurantFullAddress = this.props.restaurant.displayAddress;

        // TODO ask Stefo about workingDay
        let restaurantOpenUntil = null;
        let closedTime = null;
        if (restaurant.workingDay) {
            // restaurantOpenUntil = i18n.t('screens:restaurantDetailsScreen.openUntil') + ' ' + this.getRestaurantClosingHour(restaurant.workingDay.openingHour);
            closedTime = moment(`${restaurant.workingDay.openingHour.closeHour}:${restaurant.workingDay.openingHour.closeMinute}`, 'HH:mm').format('HH:mm')
            restaurantOpenUntil = i18n.t('screens:restaurantDetailsScreen.openUntil')
        }

        let orderTime;
        if (!inPreviewMode && !this.props.estimateOrderTime.timeSlotBased) {
            orderTime = dateAndTimeToUserFriendlyFormat(estimateOrderTime, restaurant, isArabic);
        } else if (!inPreviewMode && this.props.estimateOrderTime.timeSlotBased) {
            let fromTime = moment(`${estimateOrderTime.time.fromHour}:${estimateOrderTime.time.fromMinute}`, 'HH:mm').format('HH:mm');
            let toTime = moment(`${estimateOrderTime.time.toHour}:${estimateOrderTime.time.toMinute}`, 'HH:mm').format('HH:mm');
            let day = moment(estimateOrderTime.time.date, 'DD-MM-YYYY').format('d');
            let month = moment(estimateOrderTime.time.date, 'DD-MM-YYYY').format('MM');
            let dateDay = moment(estimateOrderTime.time.date, 'DD-MM-YYYY').format('D');
            // moment works with day values from 0 to 6 while backend sends 1 to 7
            day++;
            orderTime = `${i18n.t(`common:days.weekday_${day}`)} ${dateDay} ${i18n.t(`common:months.month_${month}`)} ${fromTime} - ${toTime}`;
        } else {
            orderTime = i18n.t('screens:restaurantDetailsScreen.asap');
        }

        let orderTimeLabel = this.getOrderTimeLabel(selectedOrderType);

        const changeLinkStyle = (menuPreviewMode == true || menuPreviewMode == 'true') ? disabledLink : changeDeliveryInformation;
        const isDisabled = (menuPreviewMode == true || menuPreviewMode == 'true') ? true : false;
        let isLoyaltyAvailable = false;
        let discountBtnText = window.innerWidth <= 768 ? i18n.t('screens:restaurantDetailsScreen.discounts') : i18n.t('screens:discountsScreen.heading');

        if (loyalty.loyalty.id) {
            loyalty.loyalty.orderTypes.map(loyaltyOrderType => {
                if (loyaltyOrderType.id === selectedOrderType.id) {
                    isLoyaltyAvailable = true;
                }
            });
        }

        const fontStyles = {
            fontFamily: fontFamilyType,
            fontWeight: fontBold === 'bold' ? fontBold : 300,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        // Hides all discounts if they are only with promo code
        let availableDiscounts = this.props.availableDiscounts;
        let allDiscountWithPromoCode = availableDiscounts.every(function (discount) {
            return discount.withPromoCodes == true;
        });

        return (
            <Row style={isArabic ? { ...orderDetailsContainerRTL, ...(this.props.isSearchProductOnFocus) && { display: 'none' } } :
                { ...orderDetailsContainer, ...(this.props.isSearchProductOnFocus) && { display: 'none' } }} className="orderContainer">
                <Col xs={12} sm={12} md={8} className={"restaurantLargeImagePlaceholderContainer"} style={isArabic ? restaurantLargeImagePlaceholderContainerRTL : restaurantLargeImagePlaceholderContainer }>
                    <img src={`${FRONT_END_URL}/${this.props.bannerImagePath}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Col>
                <Col xs={12} sm={12} md={4} className="orderDetailsContainer" style={isArabic ? { paddingRight: 30, backgroundColor: restInfoBackground } : { paddingLeft: 30, backgroundColor: restInfoBackground }}>
                    {
                        this.props.logoImagePath && !isMobileOnly ?
                            <div style={{ maxWidth: '85px', maxHeight: '85px', marginTop: '20px' }}>
                                <img src={`${FRONT_END_URL}/${this.props.logoImagePath}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                            : null
                    }
                    {
                        !isMobileOnly && !this.props.pwaAppRunning ?
                        this.renderMyProfileLink() : null
                    }
                    <h5 className='orderDetailsRestaurantName'>
                        <span style={isArabic ? { ...fontStyles, color: primaryFontColor, float: 'right' } : { ...fontStyles, color: primaryFontColor }}>{brandName}</span>
                        <span style={isArabic ? { ...restaurantNameLabelStyleRTL, ...fontStyles } : { ...restaurantNameLabelStyle, ...fontStyles }}>{restaurant.name}</span>
                    </h5>

                    <div style={restaurantDataContainerLargeImage}>
                        <div>
                            <span style={restaurantDataLabels}>{restaurantFullAddress}</span>
                        </div>
                        <div>
                            <span style={Object.assign({}, restaurantDataLabels, isArabic ? { marginLeft: '5px' } : { marginRight: '5px' })}>{restaurantOpenUntil}</span>
                            <span style={restaurantDataLabels}>{closedTime}</span>
                        </div>
                    </div>

                    <div style={{ marginTop: -12 }}>
                        {orderTypes.map((orderType, i) => {
                            if (orderType.id === this.props.selectedOrderType.id) {
                                let buttonName = '';

                                if (orderType.value === 'Delivery') buttonName = i18n.t('screens:restaurantDetailsScreen.delivery');
                                if (orderType.value === 'Collection') buttonName = i18n.t('screens:restaurantDetailsScreen.collection');
                                if (orderType.value === 'Dine In') buttonName = i18n.t('screens:restaurantDetailsScreen.dineIn');
                                if (orderType.value === 'Curbside') buttonName = i18n.t('screens:restaurantDetailsScreen.curbside');
                                if (orderType.value === 'In Store') buttonName = i18n.t('screens:restaurantDetailsScreen.inStore');

                                return <Button key={`orderTypebutton${i}`}
                                    style={isArabic ? { color: primaryColor, borderRadius: 0, border: `1px solid ${primaryColor}`, backgroundColor: 'transparent' } : { color: primaryColor, borderRadius: 0, border: `1px solid ${primaryColor}`, backgroundColor: 'transparent' }}>
                                    <span style={fontStyles}>{buttonName}</span>
                                </Button>;
                            }
                        })}
                    </div>

                    {(selectedOrderType.id === DELIVERY_ID) ?
                        <div style={deliveryOptionsContainer}>
                            <div>
                                <span style={isArabic ? deliveryInfoLabelRTL : deliveryInfoLabel}>{this.props.selectedDeliveryAddress.fullAddress}</span>
                                <NavLink onClick={this.toggleConfirmationModal.bind(this)} style={changeDeliveryInformation}>
                                    {i18n.t('screens:savedCustomerAddressesScreen.changeAddress')}
                                </NavLink>
                            </div>
                            <div>
                                <span style={isArabic ? deliveryInfoLabelRTL : deliveryInfoLabel}>{orderTime}</span>
                                <NavLink onClick={() => this.toggleChangeTimeScreen()} style={changeDeliveryInformation}>
                                    {i18n.t('screens:checkoutScreen.changeTime')}
                                </NavLink>
                            </div>
                        </div> : null
                    }

                    {(selectedOrderType.id === COLLECTION_ID) || (selectedOrderType.id === CURBSIDE_ID) ?
                        <div style={deliveryOptionsContainer}>
                            <div>
                                <span style={isArabic ? deliveryInfoLabelRTL : deliveryInfoLabel}>{restaurantFullAddress}</span>
                                <NavLink disabled={isDisabled} onClick={this.toggleConfirmationModal.bind(this)} style={changeLinkStyle}>
                                    {/* Changes btn text when only one rest. 'workingRestCounter' cant be a false otherwise data is broken */}
                                    {this.state.workingRestCounter && this.state.workingRestCounter > 1 ? i18n.t('screens:savedCustomerAddressesScreen.changeLocation') : i18n.t('screens:restaurantDetailsScreen.restartOrder')}
                                </NavLink>
                            </div>
                            <div>
                                <span dir="ltr" style={isArabic ? deliveryInfoLabelRTL : deliveryInfoLabel}>{orderTime}</span>
                                <NavLink disabled={isDisabled} onClick={() => this.toggleChangeTimeScreen()} style={changeLinkStyle}>
                                    {i18n.t('screens:checkoutScreen.changeTime')}
                                </NavLink>
                            </div>
                        </div> : null
                    }

                    {
                        selectedOrderType.id === IN_STORE_ID ?
                            <div style={deliveryOptionsContainer}>
                                <div>
                                    <span style={isArabic ? deliveryInfoLabelRTL : deliveryInfoLabel}>{this.props.restaurant.orderInStoreInfo.value}</span>
                                    <NavLink disabled={isDisabled} style={changeLinkStyle} onClick={this.toggleChangeTable}>
                                        {`${i18n.t('screens:tableSelectionScreen.changeLabel')} ${this.props.restaurant.orderInStoreInfo.label}`}
                                    </NavLink>
                                </div>
                                {
                                    !this.props.restaurant.type.futureOrderEnabled ?
                                        <div>
                                            <span style={isArabic ? deliveryLabelRTL : deliveryLabel}>{orderTimeLabel}</span>
                                            <span style={isArabic ? deliveryInfoLabelRTL : deliveryInfoLabel}>{`${this.props.estimateOrderTime.estimateOrderTime} ${i18n.t('screens:restaurantDetailsScreen.mins')}`}</span>
                                        </div> :
                                        <div>
                                            <span style={isArabic ? deliveryLabelRTL : deliveryLabel}>{orderTimeLabel}</span>
                                            <span dir="ltr" style={isArabic ? deliveryInfoLabelRTL : deliveryInfoLabel}>{orderTime}</span>
                                            <NavLink disabled={isDisabled} onClick={() => this.toggleChangeTimeScreen()} style={changeLinkStyle}>
                                                {i18n.t('screens:checkoutScreen.changeTime')}
                                            </NavLink>
                                        </div>
                                }
                            </div> : null
                    }

                    <Button
                        className="availableDiscountsButton"
                        onClick={() => this.toggleDiscountsScreen('discounts')}
                        // hidden={this.props.availableDiscounts.length === 0 || allDiscountWithPromoCode || enableLargeImageDesign}
                        hidden={true}
                        disabled={isDisabled}
                        style={isDisabled ? { backgroundColor: primaryColor, color: buttonsFont } : null}
                    >
                        <span style={fontStyles}>{discountBtnText}</span>
                        <StarSVG width={22} height={22} fill={buttonsFont} style={isArabic ? { marginRight: 5 } :
                            { marginLeft: 5 }} />
                    </Button>

                    {
                        isLoyaltyAvailable ?
                            <Button
                                className="availableDiscountsButton"
                                onClick={() => this.toggleDiscountsScreen('loyalty')}
                                // hidden={Object.keys(this.props.loyalty).length === 0 || !this.props.loyalty.loyalty.displayPromotion || enableLargeImageDesign}
                                hidden={true}
                                disabled={isDisabled}
                                style={isArabic ? { marginRight: 10 } : isDisabled ? { marginLeft: 10, backgroundColor: primaryColor, color: buttonsFont } : { marginLeft: 10 }}>
                                <span style={fontStyles}>{i18n.t('screens:discountsScreen.headingTwo')}</span>
                                <LoyaltyIconSvg width={22} height={20} fill={buttonsFont} style={isArabic ? { marginRight: 5, transform: 'scaleX(-1)' } : { marginLeft: 5 }} />
                            </Button> : null
                    }

                </Col>
                <div id='compareBoundingDiv' style={{ width: '100%' }}></div>
            </Row>
        );
    }

    confirmOrderReset() {
        const isRedirectFromComo = this.props.como ? this.props.como.isRedirectFromComo : false;
        const brandId = this.props.brandId;
        const comoTemporaryToken = this.props.como ? this.props.como.temporaryToken : null;
        this.props.clearWizardData();
        this.props.clearState();

        if (isRedirectFromComo) {
            this.props.isRestartedOrderFromComoUser(true);
            this.props.saveBrandIdToStore(brandId);
            this.props.saveComoTemporaryToken(comoTemporaryToken);
        }

        this.props.history.push('/start-order/order-type');
        this.props.changeCodeManuallySet(null);


    }

    toggleConfirmationModal() {
        this.setState({ ...this.state, openConfirmationDialog: !this.state.openConfirmationDialog });
    }

    toggleDiscountsScreen(openTarget) {
        this.setState({
            ...this.state,
            discountsScreenOpened: !this.state.discountsScreenOpened,
            openTarget: openTarget
        }, () => {
            // iOS and integration
            const userAgent = window.navigator.userAgent;
            let userOnMobileIos = userAgent.match(/iPhone; CPU iPhone OS/) || userAgent.match(/CPU iPhone/) || userAgent.match(/iPad;/);
            if (userOnMobileIos && this.state.isLoadedFromIntegration) {

                if (this.state.discountsScreenOpened) {
                    const appWrapper = document.getElementById('appWrapper');
                    const selectedProductModal = document.getElementsByClassName('modal')[0];

                    selectedProductModal.ontouchstart = (() => {
                        appWrapper.style.overflow = 'hidden';
                    });

                    selectedProductModal.ontouchend = (() => {
                        appWrapper.style.overflow = 'auto';
                    });
                }
            } else if (userOnMobileIos) {
                if (this.state.discountsScreenOpened) {
                    preventBodyScrollOnSafari(true);
                    // Setting the prev scroll position in state
                    this.setState({
                        ...this.state,
                        prevScrollPostion: window.pageYOffset
                    });
                } else {
                    preventBodyScrollOnSafari(false, this.state.prevScrollPostion);
                }
            }

        });
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, textDecoration, fontWeight, mainTextColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.changeDeliveryInformation.color = primaryColor;
        customizedStyles.changeDeliveryInformation.textDecoration = textDecoration;
        customizedStyles.changeDeliveryInformation.fontWeight = fontWeight;
        customizedStyles.deliveryLabel.color = mainTextColor;
        customizedStyles.deliveryLabelRTL.color = mainTextColor;
        customizedStyles.restaurantDataLabels.color = primaryFontColor;
        customizedStyles.deliveryInfoLabel.color = primaryFontColor;
        customizedStyles.deliveryInfoLabelRTL.color = primaryFontColor;
        customizedStyles.restaurantNameLabelStyle.color = mainTextColor;
        customizedStyles.restaurantNameLabelStyleRTL.color = mainTextColor;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.profileNameStyleRTL.color = primaryFontColor;
        customizedStyles.signInButton.color = primaryFontColor;
        customizedStyles.signInButtonRTL.color = primaryFontColor;
        window.location.href.includes('previewMode') && (customizedStyles.disabledLink.color = mainTextColor);
        styles = customizedStyles;

        return styles;
    }

    render() {
        const { workingRestCounter, openConfirmationDialog, discountsScreenOpened, isChangeTimeScreenOpened } = this.state;
        const textData = {
            informationMessage: workingRestCounter && workingRestCounter > 1 ? i18n.t('screens:restaurantDetailsScreen.informationMessage')
                : i18n.t('screens:restaurantDetailsScreen.restartMessage'),
            confirmationMessage: i18n.t('screens:restaurantDetailsScreen.confirmMsg')
        };

        return (
            <React.Fragment>
                {this.renderOrderDetails()}
                <ConfirmationModal
                    isOpen={openConfirmationDialog}
                    onToggle={this.toggleConfirmationModal.bind(this)}
                    onConfirm={this.confirmOrderReset.bind(this)}
                    message={textData}
                    customerThemes={this.props.customerThemes}
                />
                {
                    discountsScreenOpened ?
                        <AvailableDiscountsScreen
                            isOpen={discountsScreenOpened}
                            toggle={this.toggleDiscountsScreen}
                            openTarget={this.state.openTarget}
                        /> : null
                }

                <Modal className="modalStep startOrder restaurantDetailContainer" isOpen={isChangeTimeScreenOpened} size='lg'>
                    <OrderTimeScreen
                        isChangeTime={true}
                        isOpen={isChangeTimeScreenOpened}
                        toggle={() => this.toggleChangeTimeScreen()}
                        isCheckoutChangeTime={true}
                        isMenuScreen={true}
                        changeOrderTimeScreenOpened={this.props.changeOrderTimeScreenOpened} 
                        dinePayTypeId={this.props.restaurant.type ? this.props.restaurant.type.id : null}/>
                </Modal>

                {
                    this.state.isTableScreenOpened &&
                    <Modal className="modalStep startOrder restaurantDetailContainer" isOpen={this.state.isTableScreenOpened} size='lg'>
                        <TableSelectionScreen
                            {...this.props}
                            toggle={this.toggleChangeTable}
                            isMenuScreen={true}
                        />
                    </Modal>
                }

                {
                    this.props.isUnavailableProductsScreenOpen &&
                    <Modal className="modalStep" isOpen={this.props.isUnavailableProductsScreenOpen} size='lg'>
                        <UnavailableProductsScreen  {...this.props}
                            isOpen={this.props.isUnavailableProductsScreenOpen}
                            toggle={(isClickYes) => this.toggleChangeTimeScreen(isClickYes)}
                            onConfirm={() => this.confirmChangeTime()}
                            isCheckoutChangeTime={true}
                            isMenuScreen={true}
                            clearTemporaryOrderTimeData={() => this.props.clearTemporaryOrderTimeData()}
                            onExit={() => this.exitChangeTime()} />
                    </Modal>
                }


            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        brandName: state.brand.name,
        restaurant: state.selectedRestaurant,
        bannerImagePath: state.orderTypes.bannerImagePath,
        orderTypes: state.orderTypes.orderTypes,
        selectedOrderType: state.orderTypes.selectedOrderType,
        estimateOrderTime: state.estimateOrderTime,
        temporaryOrderTime: state.temporaryOrderTime,
        changeOrderTimeScreenOpened: state.temporaryOrderTime.changeOrderTimeScreenOpened,
        isUnavailableProductsScreenOpen: state.temporaryOrderTime.isUnavailableProductsScreenOpen,
        selectedDeliveryAddress: state.customer.selectedDeliveryAddress,
        menuPreviewMode: state.menuPreviewMode.previewMode,
        customerThemes: state.customerThemes,
        shoppingCart: state.shoppingCart,
        availableDiscounts: state.availableDiscounts,
        customerSavedRestaurants: state.customerSavedRestaurants,
        customerSavedAddresses: state.customerSavedAddresses,
        loyalty: state.loyalty,
        loading: state.loading,
        storeLocalStorage: state.storeLocalStorage,
        isSearchProductOnFocus: state.commonReducer.isSearchProductOnFocus,
        pwaAppRunning: state.pwaAppRunning,
        como: state.como,
        brandId: state.brand.id,
        logoImagePath: state.orderTypes.logoImagePath,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps,
    {
        logOutCustomer,
        clearWizardData,
        clearState,
        clearTemporaryOrderTimeData,
        openChangeOrderTimeScreen,
        saveNewOrderTime,
        saveInStoreInfo,
        toggleUnavailableProductsScreenOpen,
        changeCodeManuallySet,
        loadAllAvailableDiscounts,
        isRestartedOrderFromComoUser,
        saveBrandIdToStore,
        saveComoTemporaryToken,
        getLoggedInUserFirstName,
        openStartOrderScreen,
        changeSignInPressed
    })(RestaurantDetailsScreen);

let styles = {
    orderDetailsContainer: {
        marginRight: 0,
        backgroundColor: '#fff',
        height: 'auto'
    },
    orderDetailsContainerRTL: {
        marginLeft: 0,
        backgroundColor: '#fff',
        height: 'auto'
    },
    locationIconStyle: {
        marginTop: 1,
        marginRight: 7
    },
    locationIconStyleRTL: {
        marginTop: 1,
        marginLeft: 7
    },
    clockIconStyle: {
        marginTop: 1,
        marginRight: 7
    },
    clockIconStyleRTL: {
        marginTop: 1,
        marginLeft: 7
    },
    deliveryOptionsContainer: {
        margin: '10px 0'
    },
    deliveryLabel: {
        fontSize: 13,
        color: '#a7a7a7',
        marginRight: 5,
        fontStyle: 'italic'
    },
    deliveryLabelRTL: {
        fontSize: 13,
        color: '#a7a7a7',
        marginLeft: 5,
        marginTop: 3,
        fontStyle: 'italic',
        float: 'right'
    },
    deliveryInfoLabel: {
        fontSize: 13,
        marginRight: 5
    },
    deliveryInfoLabelRTL: {
        fontSize: 13,
        marginLeft: 5,
    },
    changeDeliveryInformation: {
        fontSize: 13,
        color: '#62B522',
        display: 'inline-block',
        cursor: 'pointer',
        padding: 0
    },
    disabledLink: {
        fontSize: 13,
        color: 'grey',
        display: 'inline-block',
        padding: 0
    },
    restaurantDataContainer: {
        margin: '22px 0 32px 0'
    },
    restaurantDataContainerLargeImage: {
        margin: '10px 0 25px'
    },
    restaurantDataLabels: {
        position: 'relative',
        top: 2,
        fontSize: 13
    },
    restaurantPlaceholderContainer: {
        paddingRight: 0,
        maxHeight: 350
    },
    restaurantPlaceholderContainerRTL: {
        paddingLeft: 0,
        maxHeight: 350
    },
    restaurantLargeImagePlaceholderContainer: {
        paddingRight: 0,
        maxHeight: '370px'
    },
    restaurantLargeImagePlaceholderContainerRTL: {
        paddingLeft: 0,
        maxHeight: '370px'
    },
    discountIconStyle: {
        height: 20,
        width: 20,
        marginLeft: 10,
        position: 'relative',
        bottom: 2
    },
    restaurantNameLabelStyle: {
        marginLeft: 5,
        color: '#a7a7a7'
    },
    restaurantNameLabelStyleRTL: {
        marginRight: 5,
        color: '#a7a7a7'
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center'
    },
    floatRight: {
        float: 'right'
    },
    imageStyle: {
        margin: '0px 16px 1px 0px'
    },
    imageStyleRTL: {
        margin: '0px 0px 1px 16px'
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: '1.0625rem'
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: '1.0625rem'
    },
    profileNameLink: {
        position: 'absolute',
        top: '40px',
        right: '15px'
    },
    profileNameLinkRTL: {
        position: 'absolute',
        top: '40px',
        left: '15px'
    },
    signInButton: {
        position: 'absolute', 
        top: '45px', 
        right: '25px', 
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#000'
    },
    signInButtonRTL: {
        position: 'absolute', 
        top: '45px', 
        left: '25px', 
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#000'
    }
};
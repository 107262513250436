import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeCodeManuallySet, clearState, clearWizardData, comoMemberStartOrder, doClearState, getOrderTypes, openStartOrderScreen, saveBrandIdToStore } from '../../../../actions';

class ComoLoginScreen extends Component {
    constructor() {
        super();

    }

    componentWillMount() {

        const idParam = this.props.match.params.id;
        const temporaryTokenParam = new URLSearchParams(this.props.location.search).get('temporaryToken');

        this.props.clearWizardData();
        this.props.clearState();

        this.props.comoMemberStartOrder(idParam, temporaryTokenParam, () => {
            this.startOrder(idParam)
        });

    }

    startOrder(brandId) {

        this.props.saveBrandIdToStore(brandId);
        const path = '/start-order/order-type';
        this.props.openStartOrderScreen(path);
    }

    render() {
        return <div></div>
    }
}

export default connect(null, { 
    comoMemberStartOrder,
    saveBrandIdToStore,
    openStartOrderScreen,
    getOrderTypes,
    clearState,
    clearWizardData
})(ComoLoginScreen);
import {
    SAVE_CAR_DETAILS,
    SAVE_FIRST_STAMP,
    TOGGLE_MENU_ORDER_HISTORY_SCREEN,
    CHANGE_APPLE_GOOGLE_PAY_AVAILABILITY,
    SAVE_NAVIGATION_TYPE,
    CHANGE_AUTHIPAY_BACK_BUTTON_FLAG,
    SAVE_BRANDID_TO_STORE_LOCALSTORAGE,
    SAVE_WORKING_REST_COUNT,
    CHANGE_SIGN_IN_PRESSED,
    CHANGE_CODE_MANUALLY_SET,
    CLEAR_STORE_LOCALSTORAGE_STATE,
    GET_LANGUAGE_SUCCESS,
    GET_SELECTED_LANGUAGE_SUCCESS,
    IS_DEFAULT_LANGUAGE
} from '../actions/types';

const INITIAL_STATE = {
    carDetails: null,
    firstStamp: null,
    isMenuOrOrderHistory: null,
    isAppleGooglePayAvailable: null,
    navigationType: null,
    authipayBackBtnFlag: null,
    brandId: null,
    workingRestCount: null,
    signInPressed: null,
    codeManuallySet: null,
    language: 'en',
    selectedLanguage: null,
    isDefaultLanguage: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SAVE_CAR_DETAILS:
            return { ...state, carDetails: action.payload };
        case SAVE_FIRST_STAMP:
            return { ...state, firstStamp: action.payload };
        case TOGGLE_MENU_ORDER_HISTORY_SCREEN:
            return { ...state, isMenuOrOrderHistory: action.payload };
        case CHANGE_APPLE_GOOGLE_PAY_AVAILABILITY:
            return { ...state, isAppleGooglePayAvailable: action.payload };
        case SAVE_NAVIGATION_TYPE:
            return { ...state, navigationType: action.payload };
        case CHANGE_AUTHIPAY_BACK_BUTTON_FLAG:
            return { ...state, authipayBackBtnFlag: action.payload };
        case SAVE_BRANDID_TO_STORE_LOCALSTORAGE:
            return { ...state, brandId: action.payload };
        case SAVE_WORKING_REST_COUNT:
            return { ...state, workingRestCount: action.payload };
        case CHANGE_SIGN_IN_PRESSED:
            return { ...state, signInPressed: action.payload };
        case CHANGE_CODE_MANUALLY_SET:
            return { ...state, codeManuallySet: action.payload };
        case GET_LANGUAGE_SUCCESS:
            return {
                ...state,
                language: action.payload
            };
        case GET_SELECTED_LANGUAGE_SUCCESS:
            return {
                ...state,
                selectedLanguage: action.payload
            };
        case IS_DEFAULT_LANGUAGE:
            return {
                ...state,
                isDefaultLanguage: action.payload
            };
        case CLEAR_STORE_LOCALSTORAGE_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
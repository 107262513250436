import React, { Fragment } from 'react';
import { GridLoader } from 'react-spinners';

const MenuLoaderContainer = props => {

    const renderTransparentBackgroundLoaderContainer = () => {
        const { loadingIndicator } = styles;
        const { primaryColor } = props.customerThemes.selectedTheme;
        const { loadingContainerTransparent } = props.customerThemes.constantStyles;

        if (!props.loading.transparentLoader && props.shoppingCart.promoCallsCount === 0) return null;

        return (
            <div style={loadingContainerTransparent}>
                <div style={loadingIndicator} className='text-center loadingIndicator'>
                    <GridLoader color={primaryColor} size={20} />
                </div>
            </div>
        );
    }

    const renderLoaderContainer = () => {
        const { loadingIndicator } = styles;
        const customerThemeDataNotLoaded = !props.customerThemes && !props.customerThemes.selectedTheme && !props.customerThemes.constantStyles;
        const { primaryColor, menuBackground } = props.customerThemes.selectedTheme;
        let { loadingContainer } = props.customerThemes.constantStyles;
        loadingContainer = {...loadingContainer, background: menuBackground};

        if (customerThemeDataNotLoaded || !props.loading.backgroundLoader) return null;

        return (
            <div style={loadingContainer}>
                <div style={loadingIndicator} className='text-center loadingIndicator'>
                    <GridLoader color={primaryColor} size={20} />
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {renderLoaderContainer()}
            {renderTransparentBackgroundLoaderContainer()}
        </Fragment>
    )
}

export default MenuLoaderContainer;

const styles = {
    loadingContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        background: '#F8F8F8',
        zIndex: 100000000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingIndicator: {
        width: '72px',
        height: '72px',
    },
    loadingContainerTransparent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        zIndex: 100000000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}
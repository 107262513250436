import axios from '../../utils/AxiosUtils';

export const registerAppDevice = (brandId, token, uuid, platform) => {
    return (_, getState) => {
        const url = `/app/device-info?brandId=${brandId}`;

        let data = {
            'deviceId': uuid,
            'deviceToken': token,
            'devicePlatform': platform === 'Android' ? 'ANDROID' : 'IOS'
        };

        axios(getState).post(url, data);
    }
};
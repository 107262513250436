import React from 'react';

const BackButtonSVG = ({ width, height, color, stroke, strokeWidth, svgClicked }) => {
    // Viewbox values are the ones that move the svg in its plane
    const viewBoxValues = '0 0 24 24';

    return (
        <svg id="backButtonSvg" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} onClick={() => svgClicked()}>
            {/* The line below is about background */}
            {/* <path fill={'none'} d="M0 0h24v24H0V0z" /> */}
            <path fill={color} width={width} height={height} stroke={stroke} strokeWidth={strokeWidth} d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
    )
};

export default BackButtonSVG;
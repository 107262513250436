import React, { Component, Fragment } from "react";
import { Col, Button, Row, Badge } from 'reactstrap';
import { connect } from "react-redux";
import { AvForm } from 'availity-reactstrap-validation';
import MaterialIcon from 'material-icons-react';
import i18n from '../../../i18next';

import ModalStep from '../../common/ModalStep';
import {
    loadCustomerSavedRestaurants,
    loadCustomerSavedDeliveryAddresses,
    saveCustomerDeliveryAddress,
    getRestaurantByDeliveryAddress,
    resetCustomerSavedDeliveryAddresses,
    resetCustomerSavedRestaurants,
    loadOrderTypeData,
    saveSelectedRestaurantData,
    selectRestaurantWorkingTime,
    selectedOrderTypeData,
    restaurantChainService,
    loadRestaurantsInDeliveryArea
} from '../../../actions/index';
import { getLoggedInUserFirstName, getRestaurantStatus, getRestaurantStatusBadge, getRestaurantStylesBasedOnStatusBadge, setOpenedRestCount, getGoogleAddressParts } from "../../../utils/CommonUtils";
import { COLLECTION_ID, DELIVERY_ID, GOOGLE_API_KEY, ARABIC } from "../../../utils/Constants";
import UserIconSVG from '../../../pwa-app/components/common/svgComponents/UserIconSVG';
import ShoppingCartSVG from '../../../pwa-app/components/common/svgComponents/ShoppingCartSVG';
import DeliverySVG from '../../../pwa-app/components/common/svgComponents/DeliverySVG';

class SavedAddressesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDeliverySelected: false,
            isCollectionSelected: false
        }
        this.goBack = this.goBack.bind(this);
    }

    componentWillMount() {
        this.applyTheme();

        if (this.props.selectedOrderType.id == COLLECTION_ID) {
            this.props.loadCustomerSavedRestaurants();
        } else if (this.props.selectedOrderType.id == DELIVERY_ID) {
            this.props.loadCustomerSavedDeliveryAddresses();
        }
    }

    goBack() {
        this.props.history.push('/start-order/order-type');
    }

    renderModalHeader() {
        const customerLoggedIn = this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined
            && this.props.customer.id !== undefined && this.props.customer.id !== null && this.props.customer.id !== -1 && this.props.customer.email !== undefined
            && this.props.customer.email !== null && this.props.customer.email !== "null" && this.props.customer.email !== 'undefined';
        const isArabic = this.props.language === ARABIC;

        return (
            <Fragment>
                <div className='start-order-header-container'>
                    <Button className="start-order-custom-back-button" onClick={() => this.goBack()}>
                        <span className="backButtonIcon"><MaterialIcon icon="navigate_before" color="#000" size={32} /></span>
                        <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                    </Button>
                    {(customerLoggedIn) ? this.renderCustomer() : ''}
                    <div style={{ width: 90, textAlign: isArabic ? 'left' : 'right' }}>
                        <Button className='start-order-custom-close-button' onClick={() => { this.props.toggle() }}>
                            <MaterialIcon icon="close" color="#000" size={18} />
                        </Button>
                    </div>
                </div>
            </Fragment>
        );
    }

    renderCustomer() {
        const { imageStyle, imageStyleRTL, profileNameStyle, profileNameStyleRTL, centredItem } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass += ' customer-long-text-back-button';
        }

        return (
            <div style={Object.assign({}, centredItem)} className='customer-name-mobile-style'>
                <UserIconSVG width={23} height={23} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                <span style={isArabic ? profileNameStyleRTL : profileNameStyle} className={customerNameClass}>{userFirstName}</span>
            </div>
        )
    }

    renderModalBody() {
        const { centredItem, fontStyle, marginStyle, heading } = styles;
        const isMobileView = window.outerWidth <= 800;

        let renderCustomerAddresses = false;
        let renderRestaurantsAddresses = false;
        const selectedOrderType = (this.props.selectedOrderType.id == COLLECTION_ID) ? renderRestaurantsAddresses = true : (this.props.selectedOrderType.id == DELIVERY_ID) ? renderCustomerAddresses = true : false;

        let modalBodyClass = 'modal-body-custom';
        let mobileHeading = {
            paddingTop: 0,
        };

        if (isMobileView) {
            modalBodyClass = 'modal-body-custom';

            mobileHeading = {
                paddingTop: 0,
                marginTop: 0
            };
        }

        return (
            <React.Fragment>
                {this.renderModalHeader()}
                <div className={modalBodyClass}>
                    <Row>
                        <Col sm={12} className='modal-custom-body-title' style={{ height: 57, maxHeight: 57, minHeight: 57 }}>
                            <p className='saved-addresses' style={heading}>{i18n.t('screens:selectOrderTypeScreen.heading')}</p>
                        </Col>
                    </Row>
                    <Col xs={12} className='modal-custom-body-inner-content here'>
                        {(this.props.selectedOrderType.id == COLLECTION_ID) ? this.renderCustomerRestaurants(renderRestaurantsAddresses) : (this.props.selectedOrderType.id !== null) ? this.renderCustomerDeliveredAddresses(renderCustomerAddresses) : this.renderCustomerDeliveredAddresses()}
                        {
                            this.props.orderTypes.length === 1 ? null :
                                <Fragment>
                                    <p style={Object.assign({}, fontStyle, marginStyle, {
                                        textAlign: 'center',
                                        color: this.props.customerThemes.selectedTheme.primaryFontColor,
                                        fontFamily: this.props.customerThemes.selectedTheme.fontFamilyType,
                                        fontWeight: this.props.customerThemes.selectedTheme.fontBold === 'bold' ? this.props.customerThemes.selectedTheme.fontBold : 300,
                                        fontStyle: this.props.customerThemes.selectedTheme.fontItalic,
                                        textTransform: this.props.customerThemes.selectedTheme.uppercase
                                    })}>{i18n.t('screens:selectOrderTypeScreen.or')}</p>
                                    {(this.props.selectedOrderType.id == DELIVERY_ID) ? this.renderCustomerRestaurants(renderRestaurantsAddresses) : (this.props.selectedOrderType.id !== null) ? this.renderCustomerDeliveredAddresses(renderCustomerAddresses) : this.renderCustomerRestaurants()}
                                </Fragment>
                        }

                    </Col>
                </div>
            </React.Fragment>
        );
    }

    renderCustomerDeliveredAddresses(renderCustomerAddresses) {
        const { centredItem, changeAddress } = styles;
        const isChangeAddressHidden = (renderCustomerAddresses == true && this.props.customerSavedAddresses.length > 0) ? false : true;
        const isArabic = this.props.language === ARABIC;

        return (
            <React.Fragment>
                <Col xs={12} sm={10} style={centredItem} className={isArabic ? "text-left" : "text-right"} >
                    <p style={changeAddress} hidden={isChangeAddressHidden} onClick={() => this.enterCustomerAddress('Delivery')}>{i18n.t('screens:savedCustomerAddressesScreen.changeAddress')}</p>
                </Col>
                {this.renderDeliveryAddresses(renderCustomerAddresses)}
            </React.Fragment>
        )
    }

    enterCustomerAddress(orderType) {
        const { skipLocationStep } = this.props.brand;
        this.props.orderTypes.map((type) => {
            if (type.value == orderType) {
                this.props.selectedOrderTypeData(type);
                if (skipLocationStep && orderType === 'Collection') {
                    this.props.history.push('/start-order/restaurant-selection');
                } else {
                    this.props.history.push('/start-order/user-address');
                }
            }
        })
    }

    renderDeliveryAddresses(renderCustomerAddresses) {
        const { addressesMainContainer, centredItem, addressesContainer, orderTypeIconStyle, orderTypeIconStyleRTL, orderType, orderTypeRTL } = styles;
        const { type, primaryColor, secondaryColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;
        let innerContentStyles = { ...addressesMainContainer };
        if (this.props.orderTypes.length === 1) {
            innerContentStyles = {
                ...innerContentStyles,
                maxHeight: 268
            }
            if (window.innerWidth <= 767) {
                innerContentStyles.maxHeight = '73vh';
            }
        }
        if (renderCustomerAddresses == true && this.props.customerSavedAddresses.length > 0) {
            return (
                <Col xs={12} sm={10} style={innerContentStyles} className="deliveryAddressesMainContainer">
                    {
                        this.props.customerSavedAddresses.map((address, i) => {

                            const streetNumber = (address.streetNumber !== undefined && address.streetNumber !== null) ? address.streetNumber : '';
                            const street = (address.street !== undefined && address.street !== null) ? ' ' + address.street : '';
                            const city = (address.city !== undefined && address.city !== null) ? ', ' + address.city : '';
                            const country = (address.country !== undefined && address.country !== null) ? ', ' + address.country : '';
                            const floor = (address.floor !== undefined && address.floor !== null) ? ', fl.' + address.floor : '';
                            const apartment = (address.apartment !== undefined && address.apartment !== null) ? ', ap.' + address.apartment : '';

                            const fullAddress = (address.fullAddress !== undefined && address.fullAddress !== null) ? address.fullAddress : streetNumber + street + city + country + floor + apartment;

                            return (
                                <Col xs={12} sm={12} style={centredItem} onClick={() => this.selectDeliveryAddress(address)}>
                                    <Button className="savedAddresses" color="outline-success" style={addressesContainer}>
                                        <Col xs={1} sm={1} style={isArabic ? Object.assign({}, orderTypeIconStyleRTL, { padding: 0 }) : Object.assign({}, orderTypeIconStyle, { padding: 0 })}>
                                            <DeliverySVG style={isArabic ? { transform: 'scaleX(-1)' } : null} width={38} height={38} fill={type === 'DARK' ? secondaryColor : primaryColor} />
                                        </Col>
                                        <Col xs={10} sm={11} style={isArabic ? { paddingLeft: 5, paddingRight: 15, overflowX: 'hidden' } : { paddingRight: 5, paddingLeft: 15, overflowX: 'hidden' }}>
                                            <Row style={{ display: 'initial' }}>
                                                <span
                                                    style={isArabic ? orderTypeRTL : orderType}>{i18n.t('screens:savedCustomerAddressesScreen.deliverTo')}
                                                </span>
                                            </Row>
                                            <Row className="test" style={{ display: 'table-cell' }}>
                                                <span className='long-text-saved-addresses' style={{ color: primaryFontColor, fontSize: 14 }}>
                                                    {fullAddress}
                                                </span>
                                            </Row>
                                        </Col>
                                    </Button>
                                </Col>
                            )
                        })
                    }
                </Col>
            )
        } else {
            return (
                <Col xs={12} sm={10} style={centredItem} onClick={() => this.enterCustomerAddress('Delivery')}>
                    <Button className="savedAddresses" color="outline-success" style={addressesContainer} >
                        <Col xs={1} sm={1} style={{ float: isArabic ? 'right' : 'left', paddingTop: 0 }}>
                            <DeliverySVG style={isArabic ? { transform: 'scaleX(-1)' } : null} width={38} height={38} fill={type === 'DARK' ? secondaryColor : primaryColor} />
                        </Col>
                        <Col xs={10} sm={11} style={isArabic ? { paddingLeft: 5, paddingRight: 65 } : { paddingRight: 5, paddingLeft: 65 }}>
                            <Row style={{ display: 'initial' }} >
                                <span style={isArabic ? orderTypeRTL : orderType}>{i18n.t('screens:selectOrderTypeScreen.delivery')}</span>
                            </Row>
                        </Col>
                    </Button>
                </Col>
            )
        }
    }

    selectOrderType(orderType) {

        this.props.orderTypes.map((type) => {
            if (type.value == orderType) {
                this.props.loadOrderTypeData(type);
            }
        })

    }

    renderCustomerRestaurants(renderRestaurantsAddresses) {
        const { centredItem, changeAddress } = styles;
        const isChangeAddressHidden = (renderRestaurantsAddresses == true && this.props.customerSavedRestaurants.length > 0) ? false : true;
        const isArabic = this.props.language === ARABIC;

        return (
            <React.Fragment>
                <Col xs={12} sm={10} style={centredItem} className={isArabic ? "text-left" : "text-right"} >
                    <p style={changeAddress} hidden={isChangeAddressHidden} onClick={() => this.enterCustomerAddress('Collection')}>{i18n.t('screens:savedCustomerAddressesScreen.changeLocation')}</p>
                </Col>
                {this.renderCollectionRestaurants(renderRestaurantsAddresses)}
            </React.Fragment>
        )
    }

    renderCollectionRestaurants(renderRestaurantsAddresses) {
        const { addressesMainContainer, centredItem, imageStyle, imageStyleRTL, orderTypeIconStyle, orderTypeIconStyleRTL, addressesContainer, orderType, orderTypeRTL, pointerEvents } = styles;
        const { type, primaryColor, secondaryColor, inactiveColor, fontWeight, mainTextColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        let innerContentStyles = { ...addressesMainContainer };

        if (this.props.orderTypes.length === 1) {
            innerContentStyles = {
                ...innerContentStyles,
                maxHeight: 268
            }

            if (window.innerWidth <= 767) {
                innerContentStyles.maxHeight = '73vh';
            }
        }
        // Sets working restaurants counter
        setOpenedRestCount(this.props.customerSavedRestaurants);

        if (renderRestaurantsAddresses == true && this.props.customerSavedRestaurants.length > 0) {
            return (
                <Col xs={12} sm={10} style={innerContentStyles} className='addressesMainContainer'>
                    {
                        this.props.customerSavedRestaurants.map((restaurant, i) => {

                            const restaurantAddress = restaurant.address;

                            const streetNumber = (restaurantAddress.streetNumber !== undefined && restaurantAddress.streetNumber !== null) ? restaurantAddress.streetNumber : '';
                            const street = (restaurantAddress.street !== undefined && restaurantAddress.street !== null) ? ' ' + restaurantAddress.street : '';
                            const city = (restaurantAddress.city !== undefined && restaurantAddress.city !== null) ? ', ' + restaurantAddress.city : '';
                            const country = (restaurantAddress.country !== undefined && restaurantAddress.country !== null) ? ', ' + restaurantAddress.country : '';
                            const floor = (restaurantAddress.floor !== undefined && restaurantAddress.floor !== null) ? ', fl.' + restaurantAddress.floor : '';
                            const apartment = (restaurantAddress.apartment !== undefined && restaurantAddress.apartment !== null) ? ', ap.' + restaurantAddress.apartment : '';

                            const brandAndLocation = isArabic ? restaurant.name + ', ' + this.props.brand.name : this.props.brand.name + ', ' + restaurant.name;
                            const collectionAddress = (restaurant.displayAddress !== undefined && restaurant.displayAddress !== null) ? brandAndLocation + ', ' + restaurant.displayAddress : brandAndLocation + ', ' + streetNumber + street + city + country + floor + apartment;
                            const collectionAddressRTL = (restaurant.displayAddress !== undefined && restaurant.displayAddress !== null) ? restaurant.displayAddress + ', ' + brandAndLocation : apartment + floor + country + city + street + streetNumber + ', ' + brandAndLocation;

                            const isRestaurantOpenStatus = getRestaurantStatus(restaurant);

                            const noOnlineOrdersText = restaurant.openingHourText === i18n.t('screens:savedCustomerAddressesScreen.closed')

                            const restaurantStatusColor = (isRestaurantOpenStatus && !noOnlineOrdersText) ? primaryColor : mainTextColor;

                            let restaurantBadge = getRestaurantStatusBadge(restaurant.badge);

                            // Styles based on restaurant status badge
                            const colors = { primaryColor: primaryColor, inactiveColor: inactiveColor };
                            const badgeStyles = getRestaurantStylesBasedOnStatusBadge(restaurantBadge, colors);

                            const openingHourText = restaurant.openingHourText;
                            let closedRestaurant = badgeStyles.closedRestaurant;
                            closedRestaurant.border = 'none!important';
                            const collectionSvgColor = badgeStyles.locationIconColor;
                            const savedAddressClassName = badgeStyles.liClassName;

                            return (
                                <Col xs={12} sm={12} style={centredItem} style={closedRestaurant}>
                                    <Button className={savedAddressClassName} color="outline-success" style={Object.assign({}, addressesContainer)} onClick={() => this.selectRestaurant(restaurant, openingHourText)}>
                                        <Col xs={1} sm={1} style={isArabic ? orderTypeIconStyleRTL : orderTypeIconStyle}>
                                            <ShoppingCartSVG width={28} height={28} fill={collectionSvgColor} style={isArabic ? imageStyleRTL : imageStyle} />
                                        </Col>
                                        <Col xs={11} sm={11} style={isArabic ? { paddingLeft: 5, paddingRight: 60 } : { paddingRight: 5, paddingLeft: 60 }}>
                                            <Row style={{ display: 'initial' }}>
                                                <span
                                                    style={isArabic ? orderTypeRTL : orderType}>{i18n.t('screens:savedCustomerAddressesScreen.collectFrom')}</span>
                                            </Row>
                                            <Row style={isArabic ? { overflow: 'hidden', height: '1.2em', paddingRight: 14 } : { overflow: 'hidden', height: '1.2em', paddingLeft: 14 }}>
                                                <span style={{ color: primaryFontColor, fontSize: 14, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                    {isArabic ? collectionAddressRTL : collectionAddress}
                                                </span>
                                            </Row>
                                            <Row style={isArabic ? { marginRight: 0, justifyContent: 'space-between' } : { marginLeft: 0, justifyContent: 'space-between' }}>
                                                <span style={{ color: restaurantStatusColor, fontSize: 14, fontWeight }} >
                                                    {openingHourText}
                                                </span>
                                                <span className="futureOrdersBadge">
                                                    {restaurantBadge}
                                                </span>
                                            </Row>
                                        </Col>
                                    </Button>
                                </Col>
                            );
                        })
                    }
                </Col>
            )

        } else {

            return (
                <Col xs={12} sm={10} style={centredItem} onClick={() => this.enterCustomerAddress('Collection')}>
                    <Button className="savedAddresses" color="outline-success" style={addressesContainer}>
                        <Col xs={1} sm={1} style={{ float: isArabic ? 'right' : 'left', paddingTop: 0 }}>
                            <ShoppingCartSVG width={28} height={28} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                        </Col>
                        <Col xs={10} sm={11} style={isArabic ? { paddingLeft: 5, paddingRight: 60 } : { paddingRight: 5, paddingLeft: 60 }} >
                            <Row style={{ display: 'initial' }}>
                                <span style={isArabic ? orderTypeRTL : orderType}>{i18n.t('screens:selectOrderTypeScreen.collection')}</span>
                            </Row>
                        </Col>
                    </Button>
                </Col>
            )
        }
    }

    selectRestaurant(restaurant, restaurantWorkingTime) {
        this.props.selectRestaurantWorkingTime(restaurantWorkingTime);
        this.props.saveSelectedRestaurantData(restaurant, () => {
            this.props.history.push('/start-order/order-time');
        });
    }

    selectDeliveryAddress(address) {
        const orderType = 'Delivery';
        if (!address.placeId) {     //this geocoding is for ordermark to add placeId for existing addresses

            if (!window.google) {
                let s = document.createElement('script');
                s.type = 'text/javascript';
                s.src = `https://maps.google.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places,geometry&language=en`;
                let x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
                // Below is important.
                //We cannot access google.maps until it's finished loading
                s.addEventListener('load', e => {
                    this.getPlaceIdFromGeocoder(orderType, address);
                });
            } else {
                this.getPlaceIdFromGeocoder(orderType, address);
            }
        } else {
            this.sendAddressDataToStore(orderType, address);
        }
    }

    getPlaceIdFromGeocoder(orderType, address) {
        const geocoder = new window.google.maps.Geocoder();

        let latlng = {
            lat: address.latitude,
            lng: address.longitude
        };

        geocoder.geocode({ 'location': latlng }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                let addressParts = getGoogleAddressParts(results);
                address = { ...address, placeId: addressParts.placeId };

                this.sendAddressDataToStore(orderType, address);
            }
        });
    }

    sendAddressDataToStore(orderType, address) {

        if (orderType === 'Delivery') {
            this.props.restaurantChainService(address, (restaurant) => {
                //in case restaurant object is array there are restaurants in given delivery area, but they are offline
                if (Array.isArray(restaurant)) {
                    this.props.loadRestaurantsInDeliveryArea(restaurant, () => {
                        this.props.history.push({
                            pathname: '/start-order/restaurant-selection',
                            state: { isRedirectedFromDelivery: true }
                        });
                    });
                    this.props.saveCustomerDeliveryAddress(address);
                } else {
                    this.props.saveSelectedRestaurantData(restaurant, () => {

                        this.props.history.push('/start-order/order-time');
                    });
                    this.props.saveCustomerDeliveryAddress(address);
                }
            });
        } else {
            this.selectOrderType(orderType);
            this.props.getRestaurantByDeliveryAddress(address);
            this.props.saveCustomerDeliveryAddress(address);
        }
    }

    renderModalFooter() {

        return (
            <React.Fragment />
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { primaryColor, fontWeight, textDecoration, startOrderFontSize, mainTextColor, primaryFontColor, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        customizedStyles.heading.fontSize = startOrderFontSize;
        customizedStyles.changeAddress.color = primaryColor;
        customizedStyles.changeAddress.fontWeight = fontWeight;
        customizedStyles.changeAddress.textDecoration = textDecoration;
        customizedStyles.orderType.color = mainTextColor;
        customizedStyles.orderTypeRTL.color = mainTextColor;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.profileNameStyleRTL.color = primaryFontColor;
        customizedStyles.heading.color = primaryFontColor;
        customizedStyles.heading.fontFamily = fontFamilyType;
        customizedStyles.heading.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.heading.fontStyle = fontItalic;
        customizedStyles.heading.textTransform = uppercase;
        customizedStyles.orderType.fontFamily = fontFamilyType;
        customizedStyles.orderTypeRTL.fontFamily = fontFamilyType;
        customizedStyles.orderType.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.orderTypeRTL.fontWeight = fontBold === 'bold' ? fontBold : 300;
        customizedStyles.orderType.fontStyle = fontItalic;
        customizedStyles.orderTypeRTL.fontStyle = fontItalic;
        customizedStyles.orderType.textTransform = uppercase;
        customizedStyles.orderTypeRTL.textTransform = uppercase;
        styles = customizedStyles;

        return styles;
    }

    render() {
        return (
            <AvForm className="start-order">
                <ModalStep
                    onToggle={this.props.toggle}
                    // renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </AvForm>
        );
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        brand: state.brand,
        orderTypes: state.orderTypes.orderTypes,
        selectedOrderType: state.orderTypes.selectedOrderType,
        customerSavedRestaurants: state.customerSavedRestaurants,
        customerSavedAddresses: state.customerSavedAddresses,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, {
    loadCustomerSavedRestaurants,
    saveCustomerDeliveryAddress,
    loadOrderTypeData,
    getRestaurantByDeliveryAddress,
    resetCustomerSavedRestaurants,
    loadCustomerSavedDeliveryAddresses,
    resetCustomerSavedDeliveryAddresses,
    saveSelectedRestaurantData,
    selectedOrderTypeData,
    selectRestaurantWorkingTime,
    getRestaurantStatusBadge,
    getRestaurantStylesBasedOnStatusBadge,
    restaurantChainService,
    loadRestaurantsInDeliveryArea
})(SavedAddressesScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        maxHeight: '480px',
        backgroundColor: '#ffffff'
    },
    headerColumn: {
        paddingLeft: 0,
        paddingRight: 0,
        width: 72
    },
    login: {
        fontSize: 22,
        marginLeft: 12,
        marginRight: 12
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 14,
        color: '#939393',
        paddingBottom: 12
    },
    imageStyle: {
        marginRight: 16
    },
    imageStyleRTL: {
        marginLeft: 16
    },
    alignMiddle: {
        verticalAlign: 'middle'
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center'
    },
    fontStyle: {
        fontSize: 22,
        textAlign: 'center'
    },
    marginStyle: {
        marginBottom: 0,
        marginTop: 5
    },
    changeAddress: {
        fontSize: 13,
        color: '#62B522',
        cursor: 'pointer',
        textDecoration: 'underline',
        marginBottom: 0,
        marginTop: 0
    },
    addressesMainContainer: {
        margin: '0 auto',
        maxHeight: 168,
        overflowY: 'auto',
        paddingLeft: 0,
        paddingRight: 0
    },
    addressesContainer: {
        height: "auto",
        minHeight: 85,
        width: '100%',
        marginBottom: 5,
        marginTop: 10
    },
    orderTypeIconStyle: {
        float: 'left',
        paddingTop: 10
    },
    orderTypeIconStyleRTL: {
        float: 'right',
        paddingTop: 10
    },
    alignRight: {
        float: 'right'
    },
    alignLeft: {
        float: 'left'
    },
    cardBrand: {
        marginRight: 20
    },
    cardBrandRTL: {
        marginLeft: 20
    },
    orderType: {
        float: 'left',
        color: '#9a9a9a'
    },
    orderTypeRTL: {
        float: 'right',
        color: '#9a9a9a'
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    pointerEvents: {
        pointerEvents: 'none'
    },
    greenBorder: {
        border: '1px solid #62b400'
    },
    greyBorder: {
        border: '1px solid #9a9a9a'
    },
}
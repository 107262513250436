import React from 'react';
import {Row} from "reactstrap";

const CashSVG = ({ width, height, fill, style, viewBoxNewValues }) => {
    const viewBoxValues = viewBoxNewValues ? viewBoxNewValues : "0 0 512 210.2";

    return (
        <Row>
            <svg  width={width} height={height} style={style} viewBox="0 0 512.000000 512.000000">
                <g transform="translate(0.000000,520.000000) scale(0.100000,-0.100000)"
                   fill={fill} stroke="none" cx="1">
                    <path d="M1800 4030 l0 -900 1635 0 1635 0 0 900 0 900 -1635 0 -1635 0 0
-900z m1804 650 c121 -31 208 -82 307 -180 94 -94 153 -192 185 -310 26 -97
24 -254 -5 -350 -68 -222 -224 -387 -440 -464 -79 -28 -96 -31 -216 -31 -120
0 -137 3 -216 31 -176 63 -317 188 -394 349 -47 98 -61 146 -71 245 -31 308
198 629 508 710 100 26 241 26 342 0z m-1262 -578 c59 -58 40 -155 -37 -187
-44 -19 -89 -10 -124 26 -36 35 -45 80 -26 124 32 77 129 96 187 37z m2329 15
c62 -42 71 -124 18 -176 -54 -54 -138 -45 -179 21 -65 104 59 224 161 155z"/>
                    <path d="M3321 4464 c-153 -41 -276 -158 -323 -308 -15 -47 -19 -82 -16 -157
3 -87 7 -103 41 -172 115 -233 384 -328 612 -217 115 55 193 146 235 271 18
53 21 81 18 159 -3 85 -8 103 -41 171 -97 201 -320 308 -526 253z"/>
                    <path d="M1325 4398 c-3 -7 -4 -407 -3 -888 l3 -875 1625 0 1625 0 3 128 3
127 -1501 0 -1500 0 -2 758 -3 757 -123 3 c-92 2 -124 -1 -127 -10z"/>
                    <path d="M2975 2303 c-11 -2 -112 -26 -225 -53 -264 -63 -370 -80 -506 -80
-127 0 -228 21 -321 68 -227 115 -517 30 -821 -241 -46 -41 -89 -83 -96 -93
-12 -17 18 -87 241 -585 l254 -566 387 -6 c363 -6 549 -16 937 -51 88 -8 185
-17 215 -20 79 -6 261 11 360 35 137 32 658 208 743 251 168 84 446 260 732
464 155 110 180 143 179 239 0 65 -12 94 -71 176 -82 114 -292 166 -498 124
-202 -42 -347 -139 -546 -366 -117 -132 -222 -167 -464 -155 -71 3 -209 18
-305 31 -182 27 -543 95 -536 102 2 2 48 10 102 18 260 37 516 109 679 193
200 103 308 278 229 372 -50 58 -195 115 -361 139 -70 11 -268 13 -308 4z"/>
                    <path d="M485 1875 c-381 -157 -425 -177 -425 -186 0 -8 629 -1491 633 -1495
4 -3 673 278 681 286 9 9 -641 1490 -653 1490 -3 0 -109 -43 -236 -95z"/>
                </g>
            </svg>
        </Row>

    );
}

export default CashSVG;

import i18n from '../../i18next';
import {SIMPLE_BILLING_KEY, SUPPORTED_APPLE_PAY_CARDS} from "../../utils/Constants";
import {clickAppleGooglePay} from "../../actions";

export const initiateApplePayOption = (paymentProvider,orderData, callback ) => {
    console.log('orderData', orderData);

    const publicKey = paymentProvider.stripePremenuData.publicKey;
    window.ApplePay.setPublishableKey(publicKey)

    const brandId = orderData.brand.id;
    const brandName = orderData.brand.name;

    const restaurantId = orderData.restaurant.restaurantId;
    const restaurantName = orderData.restaurant.name;

    const label = generateApplePayLabel(paymentProvider, brandName, restaurantName);
    const amount = orderData.totalAmount;

    const supportedNetworks = SUPPORTED_APPLE_PAY_CARDS;
    const merchantCapabilities = ['debit', 'credit'];
    const merchantIdentifier = generateMerchantIdentifier(paymentProvider, brandId, restaurantId);

    console.log('label',label,'merchantIdentifier',merchantIdentifier);

    const currencyCode = orderData.restaurant.currency.toUpperCase();
    const countryCode = orderData.brand.countryCode.toUpperCase();

    const order =     {
        items: [
            {
                label: label,
                amount: amount
            }
        ],
        supportedNetworks: supportedNetworks,
        merchantCapabilities: merchantCapabilities,
        merchantIdentifier: merchantIdentifier,
        currencyCode: currencyCode,
        countryCode: countryCode
    };


    window.ApplePay.makePaymentRequest(order)
        .then((paymentResponse) => {
            // Make payment request with Apple Pay
            // console.log('result paymentResponse', paymentResponse);

            // Displays the 'done' green tick and closes the sheet.
            // window.ApplePay.completeLastTransaction('success');

            callback(paymentResponse.stripeToken);
        })
        .catch((err) => {
            console.log('ApplePay makePaymentRequest err',err);

            window.ApplePay.completeLastTransaction('failure');
        });
};

const generateMerchantIdentifier = (paymentProvider, brandId, restaurantId) => {
    const publicKey = paymentProvider.stripePremenuData.publicKey;

    let merchantIdentifier = 'merchant.com.menuu.';

    if (SIMPLE_BILLING_KEY === publicKey) {
        merchantIdentifier = merchantIdentifier + brandId;
        return merchantIdentifier;
    } else {
        merchantIdentifier = merchantIdentifier + restaurantId;
        return merchantIdentifier;
    }

}

const generateApplePayLabel = (paymentProvider, brandName, restaurantName) => {
    const publicKey = paymentProvider.stripePremenuData.publicKey;

    // if (SIMPLE_BILLING_KEY === publicKey) {
    //     return brandName;
    // } else {
        return restaurantName + ' ( VIA ' + brandName + ' ) ';
    // }

}

export const deviceSupportApplePay = (callback) => {

    window.ApplePay.canMakePayments()
        .then((message) => {

            clickAppleGooglePay();
            // Apple Pay is enabled and a supported card is setup. Expect:
            // 'This device can make payments and has a supported card'
            callback(true);
        })
        .catch((error) => {
            console.log('Error',error);

            callback(false);
            // There is an issue, examine the message to see the details, will be:
            // 'This device cannot make payments.''
            // 'This device can make payments but has no supported cards'
        });
};

export const makeApplePayPaymentSuccess = () => {
    // Displays the 'done' green tick and closes the sheet.
    window.ApplePay.completeLastTransaction('success');
}
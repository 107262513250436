import React, { Component } from 'react';
import { Card, Col, ListGroupItem } from 'reactstrap';
import MaterialIcon from 'material-icons-react';

import { getPriceWithCurrencySymbol, transformProductPrice } from '../../../utils/CommonUtils';
import { calculateProductPrice } from '../../../utils/ProductWizardUtils';

class ShoppingCartItem extends Component {
    constructor(props) {
        super(props);
        this.editProductFunction = this.editProductFunction.bind(this);
    }

    editProductFunction() {
        this.props.editProductFromCart(this.props.product, this.props.index)
    }

    render() {
        const { listGroupItem, card, addRemoveItemsCol, iconsSpanDisabled, upIconStyle, qtyStyle, downIconStyle, downIconStyleRTL, itemPrice, itemPriceGreen, cursorPointer } = this.props.styles;
        const { promotionFreeProduct, price, quantity, } = this.props.product;

        const changeQtyArrows = promotionFreeProduct ? iconsSpanDisabled : {};
        // const productPrice = this.props.product.mealDeal || productType === GENERAL_PRODUCT ? transformProductPrice(calculateProductPrice(this.props.restaurant.allowToppingSubstitution, price, quantity, this.props.product)) : transformProductPrice(price * quantity);
        const productPrice = transformProductPrice(calculateProductPrice(this.props.restaurant.allowToppingSubstitution, price, quantity, this.props.product));

        const priceWithCurrency = getPriceWithCurrencySymbol(productPrice, this.props.currency, this.props.isArabic);

        const regProductOrBonus = promotionFreeProduct ? 'Free' : priceWithCurrency;
        const priceStyle = promotionFreeProduct ? itemPriceGreen : itemPrice;

        const { type, primaryFontColor } = this.props.customerThemes.selectedTheme;
        let qtyBtnColor = type === 'CUSTOM' || 'DARK' || 'LIGHT' ? primaryFontColor : '#656565';

        return (
            <ListGroupItem className='shoppingCartProducts' style={listGroupItem}>
                <Card style={{ ...card, backgroundColor: this.props.customerThemes.selectedTheme.menuItemsBackground }}>
                    <div style={
                        {
                            ...addRemoveItemsCol,
                            justifyContent: this.props.stopCartInteractions ? 'center': 'flex-start'
                        }
                    }>
                        <span style={changeQtyArrows}>
                            <span
                                hidden={this.props.stopCartInteractions}
                                style={this.props.isArabic ? downIconStyleRTL : downIconStyle}
                                onClick={() => this.props.addOrSubstractItemQty(false, this.props.product, this.props.index)}>
                                {/* <span style={minusOperator}>-</span><MaterialIcon icon="remove" color="#656565" size={10}/> */}
                                <MaterialIcon icon="remove" color={qtyBtnColor} size={15} />
                            </span>
                        </span>
                        <span style={{ ...qtyStyle, color: primaryFontColor }}>{quantity}</span>
                        <span style={changeQtyArrows}>
                            <span
                                hidden={this.props.stopCartInteractions}
                                style={upIconStyle}
                                onClick={() => this.props.addOrSubstractItemQty(true, this.props.product, this.props.index)}>
                                {/* <span style={plusOperator}>+</span><MaterialIcon icon="add" color="#656565" size={10} /> */}
                                <MaterialIcon icon="add" color={qtyBtnColor} size={15} />
                            </span>
                        </span>
                    </div>
                    <div style={{...cursorPointer, cursor: this.props.stopCartInteractions ? 'auto' : 'pointer'}}>
                        {this.props.renderProductInfo}
                    </div>
                    <div style={priceStyle} onClick={this.props.stopCartInteractions ? null : this.editProductFunction}>
                        {regProductOrBonus}
                    </div>
                </Card>
            </ListGroupItem>
        );
    }
}

export default ShoppingCartItem;
export const calculateProductPrice = (allowToppingSubstitution, productPrice, productQty, product) => {
    const basePrice = productPrice;
    let addedPrice = 0;

    if (product.mealDeal) {
        product.questions.map(question => {
            const selectedProduct = question.selectedProduct; // No desctructuring cuz it can be undefined
            if (selectedProduct) {
                // Add MealDealProduct price to the total added price
                addedPrice = addedPriceCalculations(selectedProduct, allowToppingSubstitution, product.mealDeal, addedPrice);
            }
        });
    } else {
        addedPrice = addedPriceCalculations(product, allowToppingSubstitution, product.mealDeal);
    }

    return (basePrice + addedPrice) * productQty;
}

const addedPriceCalculations = (product, allowToppingSubstitution, mealDeal, prevQuestionPrice) => {
    let addedPrice = prevQuestionPrice ? prevQuestionPrice : 0, productQuestions = mealDeal ? product.subQuestions : product.questions;
    const freeToppingsPrice = productQuestions ? getAllFreeToppingsPriceCalc(productQuestions, product.freeToppingsCount) : 0;
    let sizeQuestionsPrice = calcSizeQuestionPrice(productQuestions);

    if (mealDeal) {
        addedPrice += product.price;
    }
    addedPrice += freeToppingsPrice;
    addedPrice += sizeQuestionsPrice;

    if (allowToppingSubstitution) {
        // calculate the price of removed ingredients
        const removedIngredientsPrice = productQuestions ? getProductRemovedIngredientsPrice(productQuestions) : 0;
        // substract removed ingredients price from all selected toppings
        addedPrice -= removedIngredientsPrice;
        // if result < 0 make it equal to 0 else add resylt to the total sum
        if (addedPrice < 0 + sizeQuestionsPrice) {
            addedPrice = sizeQuestionsPrice;
        }
    }

    return addedPrice;
}

const calcSizeQuestionPrice = (questions) => {
    if (!questions) return 0;
    let sizesPrice = 0;
    let sizeQuestions = questions.filter(question => {
        if (question.id === -111 && question.selectedAnswers) {
            return question;
        }
    });

    sizeQuestions.map(question => {
        if (question.selectedAnswers) {
            sizesPrice += question.selectedAnswers[0].price;
        }
    });

    return sizesPrice;
}

export const calculateMealDealQuestionPrice = (allowToppingSubstitution, productPrice, productQty, product) => {
    const modifyPrice = product.price;
    const basePrice = productPrice;
    let addedPrice = 0;

    addedPrice = addedMealDealQuestionPriceCalculations(product, allowToppingSubstitution);

    return (basePrice + addedPrice + modifyPrice) * productQty;
}

const addedMealDealQuestionPriceCalculations = (product, allowToppingSubstitution) => {
    let addedPrice = 0, productQuestions = product.subQuestions;
    const freeToppingsPrice = productQuestions ? getAllFreeToppingsPriceCalc(productQuestions, product.freeToppingsCount) : 0;
    let sizeQuestionsPrice = calcSizeQuestionPrice(productQuestions);

    addedPrice += freeToppingsPrice;
    addedPrice += sizeQuestionsPrice;

    if (allowToppingSubstitution) {
        // calculate the price of removed ingredients
        const removedIngredientsPrice = productQuestions ? getProductRemovedIngredientsPrice(productQuestions) : 0;
        // substract removed ingredients price from all selected toppings
        addedPrice -= removedIngredientsPrice;
        // if result < 0 make it equal to 0 else add resylt to the total sum
        if (addedPrice < 0 + sizeQuestionsPrice) {
            addedPrice = sizeQuestionsPrice;
        }
    }

    return addedPrice;
}

export const getFreeToppingsCount = (product, step) => { // TODO check if usable for all products /should be/
    let freeToppingsCount = product.freeToppingsCount;
    let productQuestions = product.questions ? product.questions : product.subQuestions; // first variant is for regular products even though this wizard will start working only for meal deals
    let currQ = productQuestions[step];
    // Get product topping question for regular products question if any
    if (productQuestions && currQ) {// maybe should check for length

        let isIngredient = currQ.id === -111 && currQ.posDefault;

        if (!isIngredient && currQ.freeToppingsCount) {
            freeToppingsCount = currQ.freeToppingsCount;
        }
        // console.log('getFreeToppingsCount ', ' step ', step, ' productQuestions ', productQuestions)
        // let toppingQuestion = productQuestions.filter((question) => question.id !== -111 && !question.posDefault);
        // console.log('toppingQuestion ', toppingQuestion)
        // if (toppingQuestion.length > 1) { // in PixelPoint there are no size and ingredients questions
        //     toppingQuestion = toppingQuestion[step] ? toppingQuestion[step] : toppingQuestion[toppingQuestion.length - 1];
        // } else {
        //     toppingQuestion = toppingQuestion[0];
        // }
        // // console.log('TOPPING QUESTION', toppingQuestion, ' productQuestions ', productQuestions[step], ' step ', step)
        // If topping question have freeToppingsCount get the question freeToppingsCount instead
        // if (toppingQuestion && toppingQuestion.freeToppingsCount) freeToppingsCount = toppingQuestion.freeToppingsCount;
    }

    return freeToppingsCount;
}
// TODO - legacy method could have better way
export const getProductRemovedIngredientsPrice = (stateQuestions) => {
    // Get Ingredients question
    let ingredientQuestion = stateQuestions.filter((question) => {
        if (question.posDefault) {
            return question;
        }
    })[0];

    // IF product DO NOT have Ingredient question
    if (!ingredientQuestion) {
        return 0;
    }

    let removedIngredients = ingredientQuestion.answers.filter((answer) => {
        if (ingredientQuestion.selectedAnswers) {
            if (!ingredientQuestion.selectedAnswers.some(selectedAnswer => (selectedAnswer.id === answer.id))) {
                return answer;
            }
        }

    });

    let removedIngredientsPrice = 0;

    removedIngredients.map((removedIngredient) => {
        if (removedIngredient.price && removedIngredient.count) {
            removedIngredientsPrice += removedIngredient.price * removedIngredient.count;
        } else if (removedIngredient.price && !removedIngredient.count) { // Catch case when in orderHistory removed ingredients do not have count property
            removedIngredientsPrice += removedIngredient.price;
        }
    });

    return removedIngredientsPrice;
};

const getAllFreeToppingsPriceCalc = (questions, productFreeToppings) => {
    let freeProductsPriceTotal = 0;
    let toppingsQuestions = questions.filter((question) => question.id !== -111 && !question.posDefault);
    // check if more at least one answer has modify price to define if this is a PixelProduct
    let answersWithModifyPrice = 0;
    toppingsQuestions.map(question => {
        if (question.answers) {
            question.answers.map(answer => {
                if (answer.modifyPrice > 0) {
                    answersWithModifyPrice++;
                }
            });
        }
    });

    let allSelectedAnswers = [];
    toppingsQuestions.map(question => {
        if (question.selectedAnswers) {
            allSelectedAnswers.push(...question.selectedAnswers);
        }
    });

    toppingsQuestions.map(question => {
        let currQPriceTotal = question.price ? question.price : 0; // TODO check for size qustions
        if (question.selectedAnswers) {

            const useProductFreeToppings = !question.freeToppingsCount && productFreeToppings;
            let freeToppingsCount = question.freeToppingsCount ? question.freeToppingsCount : productFreeToppings ? productFreeToppings : 0, selectedAnswersCopy = [...question.selectedAnswers];


            selectedAnswersCopy.map(answer => {
                let currAnswerIsFree = false, currentAnswerIndex = null;
                let usedAnswers = usedFreeToppings(useProductFreeToppings ? allSelectedAnswers : selectedAnswersCopy, freeToppingsCount);

                usedAnswers.map((freeAnswer, freeAnswerIndex) => {
                    if (freeAnswer.id === answer.id) {
                        currAnswerIsFree = true;
                        currentAnswerIndex = freeAnswerIndex;
                    }
                });

                if (currAnswerIsFree) {
                    if (answer.count > usedAnswers[currentAnswerIndex].count) {
                        if (answer.modifyPrice) {
                            // free answer + rest of the answers prices
                            currQPriceTotal += ((usedAnswers[currentAnswerIndex].count * answer.modifyPrice) + (answer.count - usedAnswers[currentAnswerIndex].count) * answer.price);
                        } else {
                            currQPriceTotal += ((answer.count - usedAnswers[currentAnswerIndex].count) * answer.price);
                        }
                    } else {
                        if (answer.modifyPrice) { // In case of Pixelpoint we use modify price
                            currQPriceTotal += (answer.modifyPrice * answer.count);
                        }
                        currQPriceTotal += 0;
                    }
                } else {
                    currQPriceTotal += (answer.price * answer.count);
                }
            });
        }
        freeProductsPriceTotal += currQPriceTotal;
    });

    return freeProductsPriceTotal;
}
// Free topping and topping substitution calculations
export const freeToppingsPriceCalc = (answer, freeToppingsCount, questions, currStep, freeToppingsOnProductLevel) => {

    let allSelectedAnswers = [];
    questions.map(question => {
        if (question.selectedAnswers && question.id !== -111 && !question.posDefault) {//&& index === currStep
            allSelectedAnswers.push(...question.selectedAnswers);
        }
    });

    if (!allSelectedAnswers) return 0;

    let selectedAnswersCopy = [];
    if (freeToppingsOnProductLevel) {
        selectedAnswersCopy = [...allSelectedAnswers];// ALL SELECTED ANSWERS
    } else {
        selectedAnswersCopy = questions[currStep] && questions[currStep].selectedAnswers ? questions[currStep].selectedAnswers : [];
    }

    // map sorted array and check if first answers are less than freetoppingscount
    let currAnswerIsFree = false, currentAnswerIndex = null;
    let usedAnswers = usedFreeToppings(selectedAnswersCopy, freeToppingsCount);

    usedAnswers.map((freeAnswer, freeAnswerIndex) => {
        if (freeAnswer.id === answer.id) {
            currAnswerIsFree = true;
            currentAnswerIndex = freeAnswerIndex;
        }
    });

    if (currAnswerIsFree) {
        if (answer.count > usedAnswers[currentAnswerIndex].count) {
            if (answer.modifyPrice) {
                // free answer + rest of the answers prices
                return (usedAnswers[currentAnswerIndex].count * answer.modifyPrice) + (answer.count - usedAnswers[currentAnswerIndex].count) * answer.price;
            }
            return (answer.count - usedAnswers[currentAnswerIndex].count) * answer.price;
        } else {
            if (answer.modifyPrice) { // In case of Pixelpoint we use modify price
                return answer.modifyPrice * answer.count;
            }
            return 0;
        }
    } else {
        if (!answer.price) {
            return 0;
        }
        return answer.price * answer.count;
    }
}

const usedFreeToppings = (selectedAnswers, freeToppingsCount) => {
    let toppingsUsed = 0, usedAnswers = [];
    let sortedAnswersArray = selectedAnswers.slice();
    sortedAnswersArray.sort((currentAnswer, nextAnswer) => { return nextAnswer.price - currentAnswer.price });

    sortedAnswersArray.map(sortedAnswer => {
        toppingsUsed += sortedAnswer.count;

        if (toppingsUsed <= freeToppingsCount) {
            return usedAnswers.push(sortedAnswer);
        } else if (sortedAnswer.count > freeToppingsCount && toppingsUsed <= freeToppingsCount) {
            let copyOfSortedAnswer = { ...sortedAnswer };
            copyOfSortedAnswer.count = freeToppingsCount;
            return usedAnswers.push(copyOfSortedAnswer);
        } else if (checkFreeAnswersQty(usedAnswers) < freeToppingsCount && toppingsUsed >= freeToppingsCount) {
            // When current answer qty exceeds what has to be added to the free toppings array
            let diff = freeToppingsCount - checkFreeAnswersQty(usedAnswers);
            let sortedAnswerCopy = { ...sortedAnswer };
            sortedAnswerCopy.count = diff;
            usedAnswers.push(sortedAnswerCopy);
        }
    });

    return usedAnswers;
}

const checkFreeAnswersQty = (freeAnswersArr) => {
    let totalQty = 0;
    freeAnswersArr.map(freeAnswer => {
        return totalQty += freeAnswer.count;
    });

    return totalQty;
}

export const substitutionPriceCalc = (answer, freeToppingsCount, subQuestions, currStep, freeToppingsOnProductLevel) => {

    if (subQuestions[currStep] && subQuestions[currStep].id === -111 && subQuestions[currStep].selectedAnswers && subQuestions[currStep].selectedAnswers[0].price) {

        const selectedAnswer = subQuestions[currStep].selectedAnswers[0];

        return selectedAnswer.price * selectedAnswer.count;
    }

    let questionsCopy = arrayDeepCopy(subQuestions);
    questionsCopy = modifyQuestionsCopy(questionsCopy, freeToppingsCount, freeToppingsOnProductLevel);

    const currQ = questionsCopy[currStep];

    if (!currQ) {
        return 0;
    }

    if (!currQ.selectedAnswers) {
        return 0;
    }

    let currA = currQ.selectedAnswers.find(selectedAnswer => selectedAnswer.id === answer.id);

    if (!currA) {
        return 0;
    }

    return currA.price;
}

const modifyQuestionsCopy = (questionsCopy, freeToppingsCount, freeToppingsOnProductLevel) => {

    let removedIngredientsPrice = 0;

    let questions = questionsCopy.map(question => {
        if (question.posDefault && question.selectedAnswers && question.selectedAnswers.length < question.answers.length) {

            question.answers.map(answer => {
                const isContains = question.selectedAnswers.some(selectedAnswer => {
                    if (selectedAnswer.id === answer.id) {
                        return true;
                    }
                })

                if (!isContains) {
                    removedIngredientsPrice += answer.price;
                }
            });
        }

        if (!question.posDefault && question.selectedAnswers && question.id !== -111) {
            // Returns an array with removed free toppings
            // answersWithPrice == empty array when all selected answers are free and when no free answers exist
            const freeTopingsQty = freeToppingsOnProductLevel ? freeToppingsCount : question.freeToppingsCount;
            const hasFreeToppings = freeToppingsOnProductLevel && freeToppingsCount ? true : freeToppingsCount ? true : false;
            const answersWithPrice = filterFreeAndPriceAnswers(arrayDeepCopy(question.selectedAnswers), freeTopingsQty);

            question.selectedAnswers.map(selectedAnswer => {

                const answerWithPrice = answersWithPrice.find(answerWithPrice => answerWithPrice.id === selectedAnswer.id);

                if (!answerWithPrice && hasFreeToppings) {
                    // free answer
                    selectedAnswer.price = 0;
                    return selectedAnswer;
                } else {

                    let answerPriceTotal = selectedAnswer.price * selectedAnswer.count;

                    if (answerWithPrice) {
                        answerPriceTotal = answerWithPrice.price * answerWithPrice.count;
                    }

                    if (!removedIngredientsPrice || removedIngredientsPrice < 0) {// === 0

                        selectedAnswer.price = answerPriceTotal;
                        return selectedAnswer;
                    } else if (removedIngredientsPrice > 0) {

                        removedIngredientsPrice -= answerPriceTotal;

                        if (!removedIngredientsPrice || removedIngredientsPrice > 0) {
                            selectedAnswer.price = 0;
                        } else if (removedIngredientsPrice < 0) {// total was bigger
                            selectedAnswer.price = Number(Math.round(Math.abs(removedIngredientsPrice) + 'e2') + 'e-2'); // equal to the diff e.g (2 - 2.2)
                        }

                        return selectedAnswer;
                    }
                }
            });
        }

        return question;
    });

    return questions;
}

const filterFreeAndPriceAnswers = (selectedAnswersCopy, freeToppings) => {

    const freeToppingsCount = freeToppings ? freeToppings : 0;
    let selectedAnswersWithPrice = [];

    let freeAnswers = usedFreeToppings(selectedAnswersCopy, freeToppingsCount);// returns all the toppings that are NOT free ??????

    if (freeToppingsCount) {
        // !IMPORTANT! Remove the freeAnswers from selectedAnswersCopy - everything that remains is not free !IMPORTANT!
        let newArrCopy = [];

        selectedAnswersCopy.map(selectedAnswer => {
            let isFreeAnswer = isFreeAnswerCheck(freeAnswers, selectedAnswer);
            if (isFreeAnswer.isFree) {
                // if qty <= freeAnswer.qty do nothing
                // if > freeAnswer.qty add the diff qty
                if (isFreeAnswer.diffInQty) {
                    let selectedAnswerCopy = { ...selectedAnswer };
                    selectedAnswerCopy.count = isFreeAnswer.diffInQty;
                    newArrCopy.push(selectedAnswerCopy);
                }
            } else {
                newArrCopy.push(selectedAnswer);
            }
        });

        selectedAnswersWithPrice = newArrCopy;
    }

    return selectedAnswersWithPrice;
}

const isFreeAnswerCheck = (freeAnswersArr, currentAnswer) => {
    let isFreeAnswer = {
        isFree: false,
        diffInQty: null
    };

    freeAnswersArr.map(freeAnswer => {
        if (freeAnswer.id === currentAnswer.id) {
            isFreeAnswer = {
                isFree: true
            };
            if (freeAnswer.count < currentAnswer.count) {
                isFreeAnswer.diffInQty = currentAnswer.count - freeAnswer.count;
            }
        }
    });

    return isFreeAnswer;
}

export const areAllGroupsEmpty = (currQ) => {
    let areAllGroupsEmpty = true;
    currQ.answers.map(answer => {
        if (answer.answerGroup) {
            areAllGroupsEmpty = false;
        }
    });

    return areAllGroupsEmpty;
}
// AUTOADD, DEPENDS-ON, STEP calculation
export const wizardStepCalculation = (questions, step, direction) => {
    // This method MUST return { step, questions(with selected answers), isLastStep}
    let finalData = { questions: [...questions], step, direction, isLastStep: false, skipToNext: false };
    if (questions.length === 0) {
        return finalData = {
            ...finalData,
            isLastStep: true
        };
    }
    // when direction === null we check the first question
    // when direction === (1 || -1) we check the next/prev question
    const checkConditions = (currQs, index) => {
        let fulfilledDepOn = areDepOnReqFulfilled(currQs, currQs[index]); // true when no depOn too
        let autoAddCondsMet = !fulfilledDepOn ? false : shouldAutoAddBeUsed(currQs[index]); // checks if min/max qty + questions.answers.length === 1
        let skipToNext = !fulfilledDepOn ? true : !autoAddCondsMet ? false : shouldSkip(currQs[index]); // checks question.answer's price
        let isLastStep = currQs.length - 1 === index || (fulfilledDepOn && noRemainingQuestions(currQs, index));
        if (direction && direction < 0 && fulfilledDepOn) { // When direction is backwards
            skipToNext = false;
        }

        return {
            fulfilledDepOn, autoAddCondsMet, skipToNext, isLastStep
        };
    }

    let incrCondition = !direction ? 1 : finalData.direction;
    for (let i = step; i < questions.length; i += incrCondition) {
        let conditions = checkConditions(questions, i);

        if (conditions.fulfilledDepOn && !conditions.autoAddCondsMet) {

            return finalData = {
                ...finalData,
                isLastStep: conditions.isLastStep
            };
        } else if (conditions.fulfilledDepOn && conditions.autoAddCondsMet && conditions.isLastStep) {
            // skip does not matter if isLastStep === true
            let newQuestions = [];
            finalData.questions.map((question, index) => {
                let newQ = { ...question }
                if (index === i) {
                    newQ = addQuestionSelectedAnswers(question);
                }

                newQuestions.push(newQ);
            });

            return finalData = {
                ...finalData,
                skipToNext: conditions.skipToNext,
                isLastStep: conditions.isLastStep,
                questions: newQuestions
            };
        } else if (conditions.fulfilledDepOn && conditions.autoAddCondsMet && !conditions.isLastStep && conditions.skipToNext) {
            let newQuestions = [];
            finalData.questions.map((question, index) => {
                let newQ = { ...question };
                if (index === i) {
                    newQ = addQuestionSelectedAnswers(question);
                }

                newQuestions.push(newQ);
            });

            finalData = {
                ...finalData,
                isLastStep: conditions.isLastStep,
                questions: newQuestions,
                step: finalData.step + incrCondition
            };

        } else if (conditions.fulfilledDepOn && conditions.autoAddCondsMet && !conditions.isLastStep && !conditions.skipToNext) {
            let newQuestions = [];
            finalData.questions.map((question, index) => {
                let newQ = { ...question }
                if (index === i) {
                    newQ = addQuestionSelectedAnswers(question);
                }

                newQuestions.push(newQ);
            });

            return finalData = {
                ...finalData,
                isLastStep: conditions.isLastStep,
                questions: newQuestions
            };
        } else if (!conditions.fulfilledDepOn && !conditions.isLastStep && conditions.skipToNext) {
            // depOn conditions are not met so skip is the only option
            finalData = {
                ...finalData,
                step: finalData.step + incrCondition
            };
        } else { // isLastStep === true
            return finalData = {
                ...finalData,
                isLastStep: conditions.isLastStep
            };
        }
    }
}

export const noRemainingQuestions = (currQs, currIndex) => {
    let remainingQs = currQs.filter((question, questionIndex) => {
        let dependsOnRequirementsFulfilled = areDepOnReqFulfilled(currQs, currQs[questionIndex]);
        if (questionIndex > currIndex && dependsOnRequirementsFulfilled) {
            return question;
        }
    });

    if (!remainingQs.length) {
        return true;
    }

    return false;
}

export const areDepOnReqFulfilled = (currQs, currQ) => {
    // current question -> check if has dependsOn
    // if false just render(questions[step])
    if (!currQ.dependsOnQuestion) return true;
    // if true check if currQ dependsOnAnswer is selected in its dependentQuestion
    let dependantQ = currQs.filter(question => {
        if (question.id === currQ.dependsOnQuestion.id) {
            if (question.posDefault && !question.selectedAnswers) {
                question.selectedAnswers = question.answers;
            }
            return { ...question };
        }
    });

    let dependsOnAnswersFound = [];
    // check if any of the next questions depends on current and current is autoAdded
    if (!dependantQ[0].selectedAnswers && !shouldAutoAddBeUsed(currQ)) return false; // called from isLastStep only
    if (!dependantQ[0].selectedAnswers && shouldAutoAddBeUsed(currQ)) return true;
    // if dependantQ.selectedAnswers have all dependsOn answers then render it
    dependantQ[0].selectedAnswers.map(selectedAnswer => {
        currQ.dependsOnAnswers.map(dependsOnAnswer => {
            if (selectedAnswer.id === dependsOnAnswer.id) {
                dependsOnAnswersFound.push(selectedAnswer);
            }
        });
    });

    // if true just render it
    if (dependsOnAnswersFound.length) {// === currQ.dependsOnAnswers.length
        return true;
    }
    // if false skip it/ go to next/prev question
    return false;
}

const shouldAutoAddBeUsed = (question) => {
    if (question.minimumQuantity === 1 && question.maximumQuantity === 1 && question.answers.length === 1) {
        return true;
    }

    return false;
}

const shouldSkip = (question) => {
    if (!question.answers[0].price) {
        return true;
    }

    return false;
}

const addQuestionSelectedAnswers = (question) => {
    if (question.selectedAnswers) return question;

    let quesCopy = { ...question };

    quesCopy.selectedAnswers = [];
    question.answers.map(answer => {
        let answerCopy = {
            ...answer,
            count: 1
        };
        quesCopy.selectedAnswers.push(answerCopy);
    });

    return quesCopy;
}

export const areAllItemsDefault = (currentQuestion) => {
    let areAllDefault = true;

    if (currentQuestion.posDefault) {
        return areAllDefault;
    }
    if (!currentQuestion.answers) return false;
    currentQuestion.answers.map(answer => {
        if (!answer.default) {
            areAllDefault = false;
        }
    });

    return areAllDefault;
}

export const getProductQuestions = (params) => {
    let answersCopy = [];
    let { questionsCopy, step, eventTargetChecked, allItemsDefault, item } = params;

    answersCopy = getAnswersArr(questionsCopy[step], eventTargetChecked, allItemsDefault, item);

    if (item.mealDealProduct) {
        questionsCopy[step].selectedProduct = answersCopy[0];
    } else {
        questionsCopy[step].selectedAnswers = answersCopy;
    }
    // Sanitizes the questions that have selected answers but should not - e.g. if user goes back and choses another question
    questionsCopy = sanitizeQuestions(questionsCopy);

    return questionsCopy;
}

const getAnswersArr = (question, isChecked, areAllItemsDefault, item) => {
    let answersCopy = [], itemCopy = {};
    let isSingleAnswerQuestion = question.minimumQuantity === 1 && question.maximumQuantity === 1;
    if (areAllItemsDefault) { // INGREDIENTS case
        answersCopy = question.answers.slice();
        if (question.selectedAnswers && !isSingleAnswerQuestion) {
            answersCopy = question.selectedAnswers.slice();
        }

        if (isChecked) {
            if (!isSingleAnswerQuestion) {
                answersCopy = answersCopy.filter(answerToRemove => {
                    if (answerToRemove.id !== item.id) {
                        return answerToRemove;
                    }
                });
            } else { // TODO this might not be used at all or might be wrong
                answersCopy = answersCopy.filter(answerToSelect => {
                    if (answerToSelect.id === item.id) {
                        let itemCopy = { ...answerToSelect };
                        itemCopy.count = 1;
                        return itemCopy;
                    }
                });
            }
        } else {
            answersCopy.push(item);
        }
    } else if (!areAllItemsDefault) { // TOPPINGS case
        if (question.selectedAnswers) {
            answersCopy = question.selectedAnswers.slice();
        }

        if (!item.count) {
            itemCopy = { ...item, count: 1 };
        }

        if (isChecked) {
            if (!isSingleAnswerQuestion) {
                answersCopy.push(itemCopy);
            } else {
                answersCopy.splice(0, 1, itemCopy);
            }
        } else {
            answersCopy = answersCopy.filter(answerToRemove => {
                if (answerToRemove.id !== item.id) {
                    return answerToRemove;
                }
            });

            if (question.answers.length === 1 && answersCopy.length > 0) {
                answersCopy[0].selectedAnswers = question.answers[0];
            }
        }
    }

    return answersCopy;
}

const sanitizeQuestions = (questions) => {
    let questionsCopy = [...questions];
    // map all the questions and check if their 
    questions.map((question, questionIndex) => {
        let isDepOnReqFulfilled = areDepOnReqFulfilled(questions, question);

        if (!isDepOnReqFulfilled && question.selectedAnswers) {
            delete questionsCopy[questionIndex].selectedAnswers;
        }
        return;
    });

    return questionsCopy;
}

export const getAnswerPrice = (params) => {
    const { item, allowToppingSubstitution, product, productMainQuestion, step, innerStep, useInnerStep } = params;
    let itemPrice = item.price;

    const currProduct = product.mealDeal ? productMainQuestion.selectedProduct : product;
    const currStep = useInnerStep ? innerStep : step;

    if (!currProduct) return 0;
    const freeToppingsCount = getFreeToppingsCount(currProduct, currStep);
    
    if (!allowToppingSubstitution) {
        const currProductQuestions = product.mealDeal ? currProduct.subQuestions : currProduct.questions;
        const freeToppingsOnProductLevel = currProductQuestions[currStep] && !currProductQuestions[currStep].freeToppingsCount && freeToppingsCount ? true : false;
        itemPrice = freeToppingsPriceCalc(item, freeToppingsCount, currProductQuestions, currStep, freeToppingsOnProductLevel);
    } else if (allowToppingSubstitution) {
        const currProductQuestions = product.mealDeal ? currProduct.subQuestions : currProduct.questions;
        const freeToppingsOnProductLevel = currProductQuestions[currStep] && !currProductQuestions[currStep].freeToppingsCount && freeToppingsCount ? true : false;
        itemPrice = substitutionPriceCalc(item, freeToppingsCount, currProductQuestions, currStep, freeToppingsOnProductLevel);
    }

    return itemPrice;
}

// DEEPCOPYING
export const newDeepCopy = (product) => {
    let destructuredProduct = Object.entries(product);
    let productCopy = {};

    destructuredProduct.map(keyValuePair => {
        if (Array.isArray(keyValuePair[1])) {
            productCopy = {
                ...productCopy,
                [keyValuePair[0]]: arrayDeepCopy(keyValuePair[1])
            };
        } else {
            productCopy = {
                ...productCopy,
                [keyValuePair[0]]: keyValuePair[1]
            };
        }
    });

    return productCopy;
}

export const arrayDeepCopy = (arr) => {
    let newArr = [];
    arr.map(arrEl => {
        let newArrEl = newDeepCopy(arrEl);
        newArr.push(newArrEl);
    });

    return newArr;
}

/** Nutrients */
export const calcQuesLvlNutris = (questions, nutri, totalValue) => {
    questions.map(question => {
        if (question.id !== -111 && question.selectedAnswers && !question.posDefault) {
            return question.selectedAnswers.map(answer => {
                if (answer.nutrients) {
                    return answer.nutrients.map(answerNutri => {
                        if (answerNutri.nutrition.nutritionCode === nutri) {
                            totalValue += (answerNutri.value * answer.count);
                        }
                    });
                }
            });
        } else if (question.id !== -111 && question.posDefault) {
            /**Check for selectedAnswers if not do nothing
            *Check if the missing answers' values (if any) have to be removed */
            if (question.selectedAnswers && question.selectedAnswers.length !== question.answers.length) {
                return question.answers.map(answer => {
                    let answerRemoved = question.selectedAnswers.filter(selectedAnswer => selectedAnswer.id === answer.id);
                    if (!answerRemoved.length && answer.nutrients) {

                        return answer.nutrients.map(answerNutri => {
                            if (answerNutri.nutrition.nutritionCode === nutri) {
                                totalValue -= answerNutri.value;
                            }
                        });
                    }
                });
            }
        }
    });

    return totalValue;
}

export const checkCartProductsNutrients = (products) => {
    let nutrientsInCart = [];

    if (!products.length) return false;

    products.map(product => {
        if (product.mealDeal) {
            return product.questions.map(mdQuestion => {
                if (mdQuestion.selectedProduct && mdQuestion.selectedProduct.nutrients && mdQuestion.selectedProduct.nutrients.length) {
                    return nutrientsInCart.push(mdQuestion.selectedProduct.nutrients);
                }
            });

        } else if (product.nutrients.length) {
            return nutrientsInCart.push(product.nutrients);
        }
    });

    return nutrientsInCart.length;
}
import React from 'react';

const SendingOrderSVG = ({ width, height, fill, style, stroke }) => {
    const viewBoxValues = "0 0 1380 840";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} >
            {/* <defs id="svgEditorDefs"> */}
            {/* <path id="svgEditorClosePathDefs" /> */}
            {/* </defs> */}
            {/* <rect fill={fill} id="svgEditorBackground" x="0" y="0" width="1380" height="840" /> */}
            <path fill={fill} d="M260,460l778,-366l-208,648l-150,-70l-118,140l-26,-224l162,-170l-208,156l-230,-112Z" />
        </svg>
    );
}

export default SendingOrderSVG;
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Col, Table, Alert } from "reactstrap";
import MaterialIcon from "material-icons-react";
import i18n from '../../i18next';

import { deleteCustomerCard } from "../../actions/index";
import { isCardAlreadyExpired, transformExpirationDate } from "../../utils/CommonUtils";
import { clearMessageAlert, showMessageAlert, resetMessageAlert } from "../../actions";
import ConfirmationModal from "../common/ConfirmationModal";
import { GridLoader } from "react-spinners";

class SavedCardsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openConfirmationDialog: false,
            selectedCard: ''
        };

        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.confirmDeleteCard = this.confirmDeleteCard.bind(this);
    }

    clearState() {
        this.state.openConfirmationDialog = false;
        this.state.selectedCard = '';
    }

    toggleConfirmationModal(selectedCard) {
        this.setState({
            openConfirmationDialog: !this.state.openConfirmationDialog,
            selectedCard: selectedCard
        });
    }

    confirmDeleteCard() {
        const paymentCardId = this.state.selectedCard.paymentCardId;

        this.props.deleteCustomerCard(paymentCardId, () => {
            this.clearState();
        });
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={20} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    componentWillMount() {
        this.props.resetMessageAlert();
    }

    renderSavedCardsTable() {
        const { mainContainer } = this.props.styles;
        let { menuItemsBackground, tableHeadersBackground, tableHeadersFont } = this.props.customerThemes.selectedTheme;
        const isMobileView = window.innerWidth <= 768;

        return (
            <Col xs={12} sm={12} md={10} style={isMobileView ? { ...mainContainer, margin: '0, 5px, 20px' } : mainContainer} className="sideContent table">
                <div className='text-center alert-container'>
                    {this.renderSuccessAlert()}
                    {this.renderErrorAlert()}
                </div>
                <Table responsive>
                    <thead style={{ backgroundColor: menuItemsBackground }}>
                        <tr className="d-flex" style={{ background: tableHeadersBackground, marginTop: 10, paddingRight: 10, color: tableHeadersFont }}>
                            <th className="col-2" style={{ border: 'none' }}>{i18n.t('screens:profileScreen.cardBrand')}</th>
                            <th className="col-6" style={{ border: 'none' }}>{i18n.t('screens:profileScreen.cardNumber')}</th>
                            <th className="col-2" style={{ border: 'none' }}>{i18n.t('screens:profileScreen.cardExp')}</th>
                            <th className="col-2" style={{ border: 'none', paddingLeft: '0px !important' }}>
                                <span style={{ marginLeft: -10 }}>{i18n.t('common:buttons.delete')}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableRows()}
                    </tbody>
                </Table>
            </Col>
        );
    }

    renderTableRows() {
        const { secondaryColor, inactiveColor, menuItemsBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;
        const asterixes = window.outerWidth <= 480 ? '****' : '**** ***** *****';

        if (this.props.customer !== undefined && this.props.customer !== null
            && this.props.customer.savedPaymentCards !== undefined && this.props.customer.savedPaymentCards !== null
            && this.props.customer.savedPaymentCards.length > 0) {
            return this.props.customer.savedPaymentCards.map((card) => {
                const isCardExpired = isCardAlreadyExpired(card);
                const fieldColor = (isCardExpired) ? inactiveColor : primaryFontColor;

                return (
                    <tr className="d-flex" style={{ backgroundColor: menuItemsBackground }}>
                        <td className="col-2 creditCardName" style={{ fontSize: 14, color: fieldColor }}>{card.brand}</td>
                        <td className="col-6" style={{ fontSize: 14, color: fieldColor }}>{asterixes} {card.lastDigits}</td>
                        <td className="col-2" style={{ fontSize: 14, color: fieldColor, paddingLeft: 6 }}>{card.expirationDate ? transformExpirationDate(card.expirationDate) : null}</td>
                        <td className="col-2" style={{ paddingLeft: '0px !important', cursor: 'pointer', paddingLeft: 10 }} onClick={() => this.toggleConfirmationModal(card)}>
                            <MaterialIcon icon="delete" color={secondaryColor} />
                        </td>
                    </tr>
                )
            });
        } else {
            return (
                <tr className="d-flex">
                    <td className="col-2 creditCardName" style={{ fontSize: 14, color: '#000' }}>N/A</td>
                    <td className="col-6" style={{ fontSize: 14, color: '#000' }}>{i18n.t('screens:profileScreen.noPrevSavedCards')}</td>
                    <td className="col-2" style={{ fontSize: 14, color: '#000', paddingLeft: 6 }}>N/A</td>
                    <td className="col-2" style={{ paddingLeft: '0px !important', cursor: 'pointer', paddingLeft: 10 }}>
                        <MaterialIcon icon="delete" color={secondaryColor} />
                    </td>
                </tr>
            );
        }
    }

    renderNoCardsMsg() {
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;

        return (
            <div style={{ width: '100%', textAlign: 'center', color: primaryFontColor }}>{i18n.t('screens:profileScreen.noPrevSavedCards')}</div>
        );
    }

    renderSuccessAlert() {
        const successAlert = this.props.alertMessage.showSuccessAlert;

        if (successAlert) {
            setTimeout(() => this.props.clearMessageAlert('success'), 1500);
        }

        const dismiss = this.props.clearMessageAlert.bind(this, 'success');

        if (successAlert) {
            return (
                <div className='alertContainer'>
                    <Alert color='success' className='success-alert' toggle={dismiss}>
                        {i18n.t('screens:profileScreen.cardRemovedSuccess')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    renderErrorAlert() {
        const errorAlert = this.props.alertMessage.showErrorAlert;

        // if (errorAlert) {
        //     setTimeout(() => this.props.clearMessageAlert('error'), 1500);
        // }

        const dismiss = this.props.clearMessageAlert.bind(this, 'error');

        if (errorAlert) {
            return (
                <div className='alertContainer'>
                    <Alert color='danger' className='error-alert' toggle={dismiss}>
                        {i18n.t('screens:profileScreen.cardRemovedFailure')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const textData = {
            informationMessage: i18n.t('screens:profileScreen.deleteCardConfirmation'),
            confirmationMessage: null
        };

        let loader = this.renderTransparentBackgroundLoaderContainer();

        return (
            <Fragment>
                {this.props.customer.savedPaymentCards && this.props.customer.savedPaymentCards.length > 0 ? this.renderSavedCardsTable() : this.renderNoCardsMsg()}
                <ConfirmationModal
                    isOpen={this.state.openConfirmationDialog}
                    onToggle={this.toggleConfirmationModal}
                    onConfirm={this.confirmDeleteCard}
                    message={textData}
                    customerThemes={this.props.customerThemes}
                />
                {loader}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        alertMessage: state.alertMessage,
        customerThemes: state.customerThemes,
        loading: state.loading
    };
};

export default connect(mapStateToProps, { deleteCustomerCard, showMessageAlert, clearMessageAlert, resetMessageAlert })(SavedCardsScreen);

let styles = {
    loadingIndicator: {
        width: '72px',
        height: '72px',
    },
    loadingContainerTransparent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        zIndex: 100000000
    }
};
import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Label, Alert } from "reactstrap";
import i18n from "../../i18next";

import { updateMarketingPreferences } from "../../actions/index";
import { clearMessageAlert, showMessageAlert, resetMessageAlert } from "../../actions/index";
import MarketingIconSVG from '../../pwa-app/components/common/svgComponents/MarketingIconSVG';
import { ARABIC } from "../../utils/Constants";

class MarketingPreferencesScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            stateAlreadyCopiedFromProps: false
        };

        this.changeState = this.changeState.bind(this);

    }

    copyStateFromProps() {

        if (this.props.marketingPreferences !== undefined && this.props.marketingPreferences !== null && !this.state.stateAlreadyCopiedFromProps) {
            this.setState({
                active: this.props.marketingPreferences,
                stateAlreadyCopiedFromProps: true
            });
        }
    }

    componentWillMount() {
        this.props.resetMessageAlert();
    }

    changeState({ target }) {
        this.setState({
            [target.name]: !this.state.active
        }, () => this.props.updateMarketingPreferences(this.state.active))
        this.props.showMessageAlert('success');
    }

    renderSuccessAlert() {
        const successAlert = this.props.alertMessage.showSuccessAlert;

        if (successAlert && this.state.active) {
            setTimeout(() => this.props.clearMessageAlert('success'), 1500);
        } else if (successAlert && !this.state.active) {
            setTimeout(() => this.props.clearMessageAlert('success'), 1500);
        }

        const dismiss = this.props.clearMessageAlert.bind(this, 'success');

        if (successAlert && this.state.active) {
            return (
                <div className='alertContainer'>
                    <Alert color='success' className='success-alert' toggle={dismiss} style={{ borderRadius: 0 }}>
                        {i18n.t('screens:profileScreen.sendNotifications')}
                    </Alert>
                </div>
            );
        } else if (successAlert && !this.state.active) {
            return (
                <div className='alertContainer'>
                    <Alert color='success' className='success-alert' toggle={dismiss} style={{ borderRadius: 0 }}>
                        {i18n.t('screens:profileScreen.noSendNotifications')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    renderErrorAlert() {
        const errorAlert = this.props.alertMessage.showErrorAlert;

        const dismiss = this.props.clearMessageAlert.bind(this, 'error');

        if (errorAlert) {
            return (
                <div className='alertContainer'>
                    <Alert color='danger' className='error-alert' toggle={dismiss}>
                        {i18n.t('screens:profileScreen.notificationSendErr')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { mainContainer, formStyle, checkboxInput, checkboxInputRTL } = this.props.styles;
        const { type, mainTextColor, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;
        const isMobileView = window.innerWidth <= 768;

        this.copyStateFromProps();

        return (
            <Fragment>
                <Col sm={5} md={5} style={isMobileView ? { ...mainContainer, margin: '0, 5px, 20px' } : mainContainer} className="sideContent">
                    <div className='text-center alert-container'>
                        {this.renderSuccessAlert()}
                        {this.renderErrorAlert()}
                    </div>
                    <div style={{ textAlign: 'center', paddingTop: 16, marginBottom: -10 }}>
                        <MarketingIconSVG width={50} height={50} fill={type === 'DARK' ? secondaryColor : primaryColor} />
                    </div>
                    <Form style={formStyle}>
                        <FormGroup style={{ marginBottom: 0, textAlign: 'center' }}>
                            <Label check style={{ fontSize: 14, padding: 5, marginТop: 1, color: mainTextColor }} className='marketingCheckbox-label'>
                                <Input type="checkbox" id="active" name="active"
                                    checked={this.state.active}
                                    onChange={this.changeState}
                                    style={Object.assign({}, checkboxInput, { marginTop: 9 })} />
                                <span className='marketingCheckbox-custom' style={isArabic ? { right: '-22px' } : null}></span>
                                {i18n.t('screens:marketingPreferencesScreen.sendMeInfoMsg')}
                            </Label>
                        </FormGroup>
                    </Form>
                </Col>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        marketingPreferences: state.marketingPreferences.allowReceivingMarketingMaterials,
        alertMessage: state.alertMessage,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { updateMarketingPreferences, showMessageAlert, clearMessageAlert, resetMessageAlert })(MarketingPreferencesScreen);
import React from 'react';
import { Card } from 'reactstrap';

import ItemCard from './ItemCard';
import ItemCardThumbnail from './ItemCardThumbnail';

const ItemCardContainer = props => {

    return (
        <Card
            id={props.cardId}
            className={props.cardClassName}
            style={props.cardStyle}
        >

            {
                !props.cardContentItem.image ?
                    <ItemCard />// Not done yet
                    :
                    <ItemCardThumbnail
                        {...props}
                        imagePath={props.cardContentItem.image}
                    />
            }

        </Card>
    );
}

export default ItemCardContainer;
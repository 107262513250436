import {CLEAR_APP_STATE, VERSION_CONTROL} from "../actions/types";

const INITIAL_STATE = {
    status: "UP_TO_DATE",
    message: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VERSION_CONTROL:
            return {
                ...state,
                status: action.payload.status,
                message: action.payload.message
            };
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}


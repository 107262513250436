import _ from 'lodash';
import { calculateProductPrice, calculateMealDealQuestionPrice } from './ProductWizardUtils';
import { DELIVERY_ID, GENERAL_PRODUCT } from './Constants';
import { correctRounding, determineProductType, pixelTaxRaunding } from './CommonUtils';

export const hidePromoCodesField = (availableDiscounts, loyalty) => {

    let copyOfAvailableDiscounts, filteredDiscounts;
    copyOfAvailableDiscounts = availableDiscounts.slice(0);

    filteredDiscounts = copyOfAvailableDiscounts.filter(function (discount) {
        return discount.withPromoCodes === true;
    });

    if (filteredDiscounts.length > 0 || loyalty.id) {
        return false;
    }

    return true;
}

export const shoppingCartCalculations = (getState, updatedProducts) => {
    const shoppingCart = getState().shoppingCart;
    const selectedRestaurant = getState().selectedRestaurant;
    const estimateOrderTime = getState().estimateOrderTime;
    const promotionPartners = getState().promotionPartners;
    const orderTypes = getState().orderTypes;
    const comoBenefitsResponse = getState().como.userSavedData.getBenefitsResponse;
    const taxIncludeInPrice = estimateOrderTime.taxIncludeInPrice;
    const orderTypeId = orderTypes.selectedOrderType.id;

    let subtotalSum, temporarySum, deliveryFee, finalDiscount, totalTip, calculatedVatTotal, totalAmount, shoppingCartState, products, serviceFee, depositsTotal;
    depositsTotal = 0;
    products = shoppingCart.orderProducts.slice(0);

    if (updatedProducts) {
        products = updatedProducts;
    }

    subtotalSum = 0;
    temporarySum = 0;
    // Subtotal
    products.map(product => {
        let updatedPrice = product.price;
        const productType = determineProductType(product);
        // if (product.mealDeal || productType === GENERAL_PRODUCT) {
        updatedPrice = calculateProductPrice(selectedRestaurant.allowToppingSubstitution, product.price, product.quantity, product);
        // }

        if (!productType === GENERAL_PRODUCT) {
            temporarySum = updatedPrice * product.quantity;
        } else {
            temporarySum = updatedPrice;
        }
        subtotalSum += temporarySum;
    });

    // Delivery Fee
    const { deliveryCharge, deliveryChargeInPercentage } = selectedRestaurant;
    const { freeDelivery } = shoppingCart;
    deliveryFee = 0;

    if (shoppingCart.orderProducts.length) {
        if (deliveryCharge !== 0 && deliveryChargeInPercentage) {
            deliveryFee = Number(subtotalSum) * (deliveryCharge / 100);
        } else {
            deliveryFee = deliveryCharge ? Number(deliveryCharge) : 0;
        }
    }

    // Total Discount
    finalDiscount = 0;

    if (freeDelivery && orderTypeId === DELIVERY_ID) {
        finalDiscount = deliveryFee;
    }

    const { appliedPromotions } = shoppingCart;

    if (promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2) {

        finalDiscount += promotionPartners.promoPartnerDiscountTotal;
    } else {

        appliedPromotions.map(promotion => {
            finalDiscount += promotion.totalDiscount;
        });
    }

    // Total tip
    const { minimumDeliveryTip, deliveryTipInPercentage } = selectedRestaurant;
    const { orderProducts, bonusTip } = shoppingCart;
    let minTipValue = minimumDeliveryTip ? minimumDeliveryTip : 0;
    totalTip = 0;

    if (deliveryTipInPercentage && orderProducts.length > 0) {
        if (freeDelivery && orderTypeId === DELIVERY_ID) {
            totalTip = Number((subtotalSum - finalDiscount + deliveryCharge) * Number(minimumDeliveryTip / 100));
        } else {
            totalTip = Number((subtotalSum - finalDiscount) * Number(minimumDeliveryTip / 100));
        }
        totalTip = Number(Number(Math.round(totalTip + 'e2') + 'e-2').toFixed(2));
        if (totalTip < 0) {    // if discount is greater than subtotal
            totalTip = 0 + Number(bonusTip);
        } else {
            totalTip = totalTip + Number(bonusTip);
        }
    } else if (deliveryTipInPercentage && orderProducts <= 0) {
        totalTip = 0 + Number(bonusTip);
    } else if (!deliveryTipInPercentage && orderProducts <= 0) {
        totalTip = 0 + Number(bonusTip);
    } else {
        totalTip = Number(minTipValue) + Number(bonusTip);
    }
    totalTip = Number(Number(Math.round(totalTip + 'e2') + 'e-2').toFixed(2))

    // Deposits 
    products.map(product => {
        if (product.mealDeal) {
            product.questions.map(question => {
                // calc deposits
                if (question.selectedProduct) {
                    if (question.selectedProduct.deposit) {
                        if (!depositsTotal) {
                            depositsTotal = 0;
                        }

                        depositsTotal += (question.selectedProduct.deposit * product.quantity);
                    }
                }
            });
        } else {
            // calc deposits
            if (product.deposit && !product.promotionFreeProduct) {
                if (!depositsTotal) {
                    depositsTotal = 0;
                }

                let currProdDeposit = product.deposit * product.quantity;
                depositsTotal += Number(Number(Math.round(currProdDeposit + 'e2') + 'e-2').toFixed(2));
            }
        }
    });

    // VATs
    calculatedVatTotal = 0;
    
    const taxes = destructureTaxes(products, selectedRestaurant, comoBenefitsResponse, promotionPartners)
    taxes.map(tax => {
        calculatedVatTotal += tax.total;
    });

    // Total 
    totalAmount = 0;
    serviceFee = !shoppingCart.serviceFee ? 0 : shoppingCart.serviceFee;
    taxIncludeInPrice ? calculatedVatTotal = 0 : calculatedVatTotal = Number(Math.round(calculatedVatTotal + 'e2') + 'e-2');

    if (freeDelivery && orderTypeId === DELIVERY_ID) {
        totalAmount = Number((subtotalSum + calculatedVatTotal + deliveryCharge - finalDiscount).toFixed(2));
    } else {
        totalAmount = Number((subtotalSum + calculatedVatTotal - finalDiscount).toFixed(2));
    }

    if (totalAmount <= 0) {
        totalAmount = 0;
    }

    if (freeDelivery && orderTypeId === DELIVERY_ID) {
        totalAmount = Number((totalAmount + totalTip + serviceFee + depositsTotal).toFixed(2));
    } else {
        totalAmount = Number((totalAmount + deliveryFee + totalTip + serviceFee + depositsTotal).toFixed(2));
    }

    shoppingCartState = {
        orderProducts: products,
        subtotal: subtotalSum,
        deliveryFee: deliveryFee,
        tip: totalTip,
        total: totalAmount,
        discount: finalDiscount,
        vat: { total: calculatedVatTotal },
        deposits: depositsTotal
    }


    let destructuredTaxes = destructureTaxes(products, selectedRestaurant, comoBenefitsResponse, promotionPartners);
    shoppingCartState.vat = {
        total: 0,
        taxes: destructuredTaxes
    }

    return shoppingCartState;
}

export const destructureTaxes = (products, selectedRestaurant, comoBenefitsResponse, promotionPartners) => {
    let taxes = [];
    let isPixelPos = selectedRestaurant.posType ? selectedRestaurant.posType.id === 5 : false;
    const comoProductDiscounts = comoBenefitsResponse ? comoBenefitsResponse.productDiscounts : null;

    products.map((product, productIndex) => {
        let tax = {}, secondaryTax = {};
        if (product.mealDeal) {
            if (isPixelPos) {           // Pixel tax calculations with different flow and without rounding
                let totalProductPrice = calculateProductPrice(selectedRestaurant.allowToppingSubstitution, product.price, 1, product);
                
                if (promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2 && promotionPartners.promoPartnerAppliedPromos && promotionPartners.promoPartnerAppliedPromos.length) {

                    totalProductPrice = calculateProductPriceAfterComoDiscount(product, productIndex, totalProductPrice, comoProductDiscounts, product.quantity)

                }

                if (product.tax !== null) {
                    if (product.tax.taxPercent) {
                        let alreadyExists = taxAlreadyExists(taxes, product.tax);
                        if (!alreadyExists.exists) {
                            tax = { ...product.tax };
                            tax.total = totalProductPrice * product.tax.taxPercent / 100;
                            tax.total = tax.total * product.quantity;
                            taxes.push(tax);
                        } else {
                            let currProductTax = { ...product.tax };
                            currProductTax.total = totalProductPrice * product.tax.taxPercent / 100;
                            currProductTax.total = currProductTax.total * product.quantity;
                            taxes[alreadyExists.index].total += currProductTax.total;
                        }
                    }
                }

                if (product.secondaryTax !== null) {
                    if (product.secondaryTax.taxPercent) {
                        let alreadyExists = taxAlreadyExists(taxes, product.secondaryTax);
                        if (!alreadyExists.exists) {
                            secondaryTax = { ...product.secondaryTax };
                            secondaryTax.total = totalProductPrice * product.secondaryTax.taxPercent / 100;
                            secondaryTax.total = secondaryTax.total * product.quantity;
                            taxes.push(secondaryTax);
                        } else {
                            let currProductTax = { ...product.secondaryTax };
                            currProductTax.total = totalProductPrice * product.secondaryTax.taxPercent / 100;
                            currProductTax.total = currProductTax.total * product.quantity;
                            taxes[alreadyExists.index].total += currProductTax.total;
                        }
                    }
                }
            } else {
                product.questions.map(question => {
                    let questionProduct = { ...question.selectedProduct };
                    let currentQuestionProductPrice = calculateMealDealQuestionPrice(selectedRestaurant.allowToppingSubstitution, questionProduct.originalProductPrice, 1, questionProduct);

                    if (promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2 && promotionPartners.promoPartnerAppliedPromos && promotionPartners.promoPartnerAppliedPromos.length) {

                        currentQuestionProductPrice = calculateProductPriceAfterComoDiscount(questionProduct, productIndex, currentQuestionProductPrice, comoProductDiscounts, product.quantity)

                    }

                    if (questionProduct.tax !== null) {
                        if (questionProduct.tax.taxPercent) {
                            let alreadyExists = taxAlreadyExists(taxes, questionProduct.tax);
                            if (!alreadyExists.exists) {
                                tax = { ...questionProduct.tax };
                                tax.total = currentQuestionProductPrice * questionProduct.tax.taxPercent / 100;
                                tax.total = ((Number(Math.round(tax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                tax.total = Number(tax.total.toFixed(2));
                                taxes.push(tax);
                            } else {
                                let currProductTax = { ...questionProduct.tax };
                                currProductTax.total = currentQuestionProductPrice * questionProduct.tax.taxPercent / 100;
                                currProductTax.total = ((Number(Math.round(currProductTax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                currProductTax.total = Number(currProductTax.total);
                                taxes[alreadyExists.index].total = Number(taxes[alreadyExists.index].total);
                                taxes[alreadyExists.index].total += currProductTax.total;
                                taxes[alreadyExists.index].total = Number((Number(Math.round(taxes[alreadyExists.index].total + 'e2') + 'e-2')).toFixed(2));
                            }
                        }
                    }

                    if (questionProduct.secondaryTax !== null) {
                        if (questionProduct.secondaryTax.taxPercent) {
                            let alreadyExists = taxAlreadyExists(taxes, questionProduct.secondaryTax);
                            if (!alreadyExists.exists) {
                                secondaryTax = { ...questionProduct.secondaryTax };
                                secondaryTax.total = currentQuestionProductPrice * questionProduct.secondaryTax.taxPercent / 100;
                                secondaryTax.total = ((Number(Math.round(secondaryTax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                secondaryTax.total = Number(secondaryTax.total.toFixed(2));
                                taxes.push(secondaryTax);
                            } else {
                                let currProductTax = { ...questionProduct.secondaryTax };
                                currProductTax.total = currentQuestionProductPrice * questionProduct.secondaryTax.taxPercent / 100;
                                currProductTax.total = ((Number(Math.round(currProductTax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                taxes[alreadyExists.index].total += currProductTax.total;
                                taxes[alreadyExists.index].total = Number((Number(Math.round(taxes[alreadyExists.index].total + 'e2') + 'e-2')).toFixed(2));
                            }
                        }
                    }
                });
            }
        } else {

            // hardcoded qty to 1 in order to calc single product price correctly
            let currentProductPrice = calculateProductPrice(selectedRestaurant.allowToppingSubstitution, product.price, 1, product);

            if (promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2 && promotionPartners.promoPartnerAppliedPromos && promotionPartners.promoPartnerAppliedPromos.length) {

                currentProductPrice = calculateProductPriceAfterComoDiscount(product, productIndex, currentProductPrice, comoProductDiscounts, product.quantity)

            }

            if (!product.promotionFreeProduct) {
                if (product.tax) {
                    if (product.tax.taxPercent) {
                        let alreadyExists = taxAlreadyExists(taxes, product.tax);
                        if (!alreadyExists.exists) {
                            if (isPixelPos) {  // Pixel tax calculations without rounding
                                tax = { ...product.tax };
                                tax.total = currentProductPrice * product.tax.taxPercent / 100;
                                tax.total = tax.total * product.quantity;
                                taxes.push(tax);
                            } else {
                                tax = { ...product.tax };
                                tax.total = currentProductPrice * product.tax.taxPercent / 100;
                                tax.total = ((Number(Math.round(tax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                tax.total = Number(tax.total.toFixed(2));
                                taxes.push(tax);
                            }
                        } else {
                            if (isPixelPos) {  // Pixel tax calculations without rounding
                                let currProductTax = { ...product.tax };
                                currProductTax.total = currentProductPrice * product.tax.taxPercent / 100;
                                currProductTax.total = currProductTax.total * product.quantity;
                                taxes[alreadyExists.index].total += currProductTax.total;
                            } else {
                                let currProductTax = { ...product.tax };
                                currProductTax.total = currentProductPrice * product.tax.taxPercent / 100;
                                currProductTax.total = ((Number(Math.round(currProductTax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                currProductTax.total = Number(currProductTax.total);
                                taxes[alreadyExists.index].total = Number(taxes[alreadyExists.index].total);
                                taxes[alreadyExists.index].total += Number(currProductTax.total);
                                taxes[alreadyExists.index].total = Number((Number(Math.round(taxes[alreadyExists.index].total + 'e2') + 'e-2')).toFixed(2));
                            }
                        }
                    }
                }

                if (product.secondaryTax) {
                    if (product.secondaryTax.taxPercent) {
                        let alreadyExists = taxAlreadyExists(taxes, product.secondaryTax);
                        if (!alreadyExists.exists) {
                            if (isPixelPos) {  // Pixel tax calculations without rounding
                                secondaryTax = { ...product.secondaryTax };
                                secondaryTax.total = currentProductPrice * product.secondaryTax.taxPercent / 100;
                                secondaryTax.total = secondaryTax.total * product.quantity;
                                taxes.push(secondaryTax);
                            } else {
                                secondaryTax = { ...product.secondaryTax };
                                secondaryTax.total = currentProductPrice * product.secondaryTax.taxPercent / 100;
                                secondaryTax.total = ((Number(Math.round(secondaryTax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                secondaryTax.total = Number(secondaryTax.total.toFixed(2));
                                taxes.push(secondaryTax);
                            }
                        } else {
                            if (isPixelPos) {  // Pixel tax calculations without rounding
                                let currProductTax = { ...product.secondaryTax };
                                currProductTax.total = currentProductPrice * product.secondaryTax.taxPercent / 100;
                                currProductTax.total = currProductTax.total * product.quantity;
                                taxes[alreadyExists.index].total += currProductTax.total;
                            } else {
                                let currProductTax = { ...product.secondaryTax };
                                currProductTax.total = currentProductPrice * product.secondaryTax.taxPercent / 100;
                                currProductTax.total = ((Number(Math.round(currProductTax.total + 'e2') + 'e-2')).toFixed(2)) * product.quantity;
                                taxes[alreadyExists.index].total = Number(taxes[alreadyExists.index].total);
                                taxes[alreadyExists.index].total += Number(currProductTax.total);
                                taxes[alreadyExists.index].total = Number((Number(Math.round(taxes[alreadyExists.index].total + 'e2') + 'e-2')).toFixed(2));
                            }
                        }
                    }
                }
            }
        }
    });

    return isPixelPos ? pixelTaxRaunding(taxes) : taxes;
}

const taxAlreadyExists = (taxes, currTax) => {
    let taxExists = { exists: false, index: null };
    taxes.filter((tax, i) => {
        if (currTax.id === tax.id) {
            taxExists = { exists: true, index: i };
            return tax;
        }
    });

    return taxExists;
}

export const handleItemToShoppingCart = (existingProductsInCart, productToBeAdded, editMode) => {
    let productFound = false;
    let edit = editMode;

    if (productToBeAdded.productIndex || productToBeAdded.productIndex === 0) {
        edit = true;
    }

    if (existingProductsInCart.length) {

        for (let index = 0; index < existingProductsInCart.length; index++) {
            let product = existingProductsInCart[index];
            // console.log('handleItemToShoppingCart product ', product, ' productToBeAdded ', productToBeAdded);
            // let allergens = _.isEqual(product.allergens, productToBeAdded.allergens);
            let ids = _.isEqual(product.id, productToBeAdded.id);
            let posIds = _.isEqual(product.posId, productToBeAdded.posId);
            let names = _.isEqual(product.name, productToBeAdded.name);
            let prices = _.isEqual(product.price, productToBeAdded.price);
            let freeProduct = _.isEqual(product.promotionFreeProduct, productToBeAdded.promotionFreeProduct);
            let questions = _.isEqual(product.questions, productToBeAdded.questions);
            let notes = _.isEqual(product.instruction, productToBeAdded.instruction);

            let equalProduct = false;

            if (ids && posIds && names && prices && freeProduct && questions && notes) {
                equalProduct = true;
            }

            if (equalProduct && (productToBeAdded.productIndex === undefined)) {
                productFound = true;
                productToBeAdded.quantity += product.quantity;
                existingProductsInCart.splice(index, 1, productToBeAdded);
            } else if (product.id === productToBeAdded.id && (index === productToBeAdded.productIndex || index === productToBeAdded.index)) {
                productFound = true;
                // productToBeAdded.quantity = product.quantity;
                existingProductsInCart.splice(index, 1, productToBeAdded);
                // When size question changes and deletes all the previously selected options in edit mode
            } else if (index === productToBeAdded.productIndex && edit && !equalProduct) {
                productFound = true;
                existingProductsInCart.splice(index, 1, productToBeAdded);
            }
        }

        if (!productFound) {
            existingProductsInCart.push(productToBeAdded);
        }
    } else {
        existingProductsInCart.push(productToBeAdded);
    }

    return existingProductsInCart;
}

export const displayProductInformation = (fullProductDescription, orderProduct, isArabic) => {
    let itemSize = null;
    let noItems = [];
    let extraItems = [];
    let displayExtraItems = [];
    let questions = null;
    let alreadyFilledNoItems = false;
    let sizeName = orderProduct.sizeName;
    questions = orderProduct.mealDealProduct === true ? orderProduct.subQuestions : orderProduct.questions;

    if (orderProduct.questions === null) {
        questions = [];
    }

    questions.map(question => {                        // When it finds a match checks the id of Question (-111 system ID) to see
        if (question.id === -111) {                    // if the cart item has size
            itemSize = question.selectedAnswers[0].name;
        }

        if (!question.selectedAnswers) {
            return;
        }

        if (question.selectedAnswers.length > 0) {
            if ((question.selectedAnswers[0].default === true || question.posDefault) && !alreadyFilledNoItems) {
                alreadyFilledNoItems = true;
                let copyOfAnswers = question.answers.slice(0);

                question.selectedAnswers.map(selectedAnswer => {
                    question.answers.map((answer, answerIndex) => {
                        if (selectedAnswer.id === answer.id) {
                            copyOfAnswers.splice(answerIndex, 1, null);
                        }
                    });
                });

                copyOfAnswers.map(answer => {
                    if (answer !== null) {
                        noItems.push(answer.name);
                    }
                });
            }
        }

        question.selectedAnswers.map(answer => {     // Maps through the answers and makes a push in the right array for displaying items
            let displayProperties = {
                name: '',
                count: ''
            };

            if (answer.count > 0 && question.id !== -111 && !question.posDefault) {         // Extra additional items
                displayProperties.name = answer.name;
                displayProperties.count = answer.count;
                extraItems.push(displayProperties);
            }
        });


    });

    extraItems.map(item => {                                          // Stringifies the array to make it user readable and friendly
        let stringfiedValue;
        if (item.count === 1) {
            stringfiedValue = `${item.name}`
        } else {
            stringfiedValue = isArabic ? `(${item.count})${item.name}` : `${item.name}(${item.count})`;
        }
        displayExtraItems.push(stringfiedValue);
    });

    if (!itemSize && sizeName) {
        itemSize = sizeName + ' ' + orderProduct.name;
    }

    fullProductDescription = {
        itemSize: itemSize,
        noItems: noItems,
        extraItems: displayExtraItems
    };

    return fullProductDescription;
}

export const reEvaluateProductPriceComingFromOrderHistory = (products, selectedRestaurant) => {
    let copyOfProducts;
    copyOfProducts = products.slice();
    copyOfProducts.map(product => {
        if (!product.mealDeal) {
            let updatedProductPrice = calculateQuestionPrice(product.questions, selectedRestaurant.allowToppingSubstitution);
            product.price += updatedProductPrice;
        } else {
            product.questions.map(mealDealQuestion => {
                mealDealQuestion.selectedAnswers.map(selectedAnswer => {
                    let updatedProductPrice = calculateQuestionPrice(selectedAnswer);
                    product.price += updatedProductPrice;
                })
            });
        }

    });

    return copyOfProducts;
}

const calculateQuestionPrice = (productQuestion, allowToppingSubstitution) => {
    let totalAnswerPrice, questions, cheapestDefaultAnswer, cheapestAnswer, combinedSelectedAnswers;
    totalAnswerPrice = 0;
    combinedSelectedAnswers = new Array;

    questions = productQuestion.mealDealProduct === true ? productQuestion.subQuestions : productQuestion;
    if (questions === null || questions.id === -1) {
        questions = [];
    }
    questions.map(question => {
        if (!question.posDefault) {
            question.selectedAnswers.map(selectedAnswer => {
                totalAnswerPrice += (selectedAnswer.count * selectedAnswer.price);
            });
        }
    });

    return totalAnswerPrice;
}

export const totalItemsCount = (shoppingCart) => {
    let totalItemsCount = 0;

    shoppingCart.orderProducts.map(product => {
        totalItemsCount += product.quantity;
    });

    return totalItemsCount;
}

const calculateProductPriceAfterComoDiscount = (product, productIndex, calculatedProductPrice, comoProductDiscounts, productQuantity) => {

    let updatedProductPrice;

    if(comoProductDiscounts) {

        comoProductDiscounts.map((discountedProduct, discountedProductIndex) => {
           
            if((product.id === discountedProduct.id) && (productIndex === discountedProduct.index)) {

                if((calculatedProductPrice + (((discountedProduct.comoDiscount / 100) + discountedProduct.mealDealDiscount) / productQuantity)) < 0) {
                
                    updatedProductPrice = 0
                } else {
                    if(discountedProduct.mealDealDiscount) {

                        updatedProductPrice = calculatedProductPrice + (((discountedProduct.comoDiscount / 100) + discountedProduct.mealDealDiscount) / productQuantity)
                    } else {

                        updatedProductPrice = calculatedProductPrice + ((discountedProduct.comoDiscount / 100) / productQuantity)
                    }
                }

            }
        })

        return Number(updatedProductPrice)

    } else {
    
        return calculatedProductPrice
    }
}
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements/dist/react-stripe-elements';
import { Elements } from 'react-stripe-elements';

import { checkIfProviderScriptExists, addScriptToIndexHtml, removeThirdPartyScripts } from '../../../utils/PaymentProviderUtils';
import { getQueryInfoFromUrl } from '../../../utils/CommonUtils';
import { sendMessageToParentSite } from '../../../utils/CrossOriginUtils';
import {
    placeOrder,
    processingPayment,
    errorProcessingPayment,
    resetSendingOrderModal,
    validateCardInput,
    sendEmailReport,
    dispatchTransactionId,
    saveAuthipayData,
    changeAuthipayBackBtnFlag,
    saveWorldPayData,
    clearWorldPayData,
    saveBamboraData,
    clearBamboraData,
    saveMonerisData,
    clearMonerisData,
    clearTbkData,
    clearConfirmEnrollmentErrMsg,
    sendTbkToken
} from '../../../actions';
import BraintreeForm from './braintree/BraintreeForm';
import StripeForm from './stripe/StripeForm';
import MollieForm from './mollie/MollieForm';
import MonerisForm from './moneris/MonerisForm';
import AuthipayForm from './authipay/AuthipayForm';
import BamboraForm from './bambora/BamboraForm';
import WorldPayFormUS from './worldpayUS/WorldPayFormUS';
import TbkChileForm from './tbkChile/TbkChileForm';

class PaymentAdapter extends Component {
    constructor() {
        super();

        this.state = {
            stripe: null,
            mollie: null
        }
    }

    componentDidMount() {
        const { id } = this.props.paymentProvider.providerType;
        if (id === 1) {
            this.detectedStripe();
        } else if (id === 2) {
            this.detectedBraintree();
        } else if (id === 3) {
            // Mollie - lets do drugs :D
            this.detectedMollie();
        }
    }

    componentWillUnmount() {
        const { id } = this.props.paymentProvider.providerType;
        removeThirdPartyScripts(id);
    }

    detectedStripe() {
        const { stripePremenuData, providerType } = this.props.paymentProvider;
        let isIntegration = getQueryInfoFromUrl().brandId;

        if (window.Stripe) {
            this.setState({
                stripe: window.Stripe(stripePremenuData.publicKey)
            });
        } else {
            let isProviderScriptAdded = checkIfProviderScriptExists(providerType.id);
            if (!isProviderScriptAdded) {
                addScriptToIndexHtml(this.props.paymentProvider, () => {
                    document.getElementById('stripe-js').addEventListener('load', () => {
                        // Create Stripe instance once Stripe.js loads
                        this.setState({
                            stripe: window.Stripe(stripePremenuData.publicKey)
                        });
                    });
                });
            }
        }

        if (isIntegration) {
            sendMessageToParentSite('callStripe');
        }
        // console.log('STRIPE ');
    }

    detectedBraintree() {
        const { paymentProvider } = this.props;
        const isProviderScriptAdded = checkIfProviderScriptExists(paymentProvider.providerType.id);

        if (!isProviderScriptAdded) {
            addScriptToIndexHtml(paymentProvider);
        }
        console.log('BRAINTREE ');
    }

    detectedMollie() {
        const { paymentProvider } = this.props;
        const isProviderScriptAdded = checkIfProviderScriptExists(paymentProvider.providerType.id);
        if (!isProviderScriptAdded) {
            addScriptToIndexHtml(paymentProvider, () => {
                document.getElementById('mollie').addEventListener('load', () => {
                    // Triggers rerendering when Mollie script is loaded
                    this.setState({
                        mollie: true
                    });
                });
            });
        }
        console.log('MOLLIE');
    }

    renderStripeForm() { // TODO MUST test cuz might not work after adding the ADAPTER
        const isAppleGooglePaySelected = (this.props.selectedPaymentId === 3) ? true : false;
        const isPayCashSelected = this.props.payment.selectedPaymentType.id !== 1;

        return (
            <StripeProvider stripe={this.state.stripe}>
                <Elements>
                    <StripeForm
                        {...this.props}
                        paymentWithCard={isPayCashSelected}
                        isAppleGooglePaySelected={isAppleGooglePaySelected}
                        // Calls selectCard method because it handles selection/deselection of savedCards
                        clearPaymentCard={this.props.selectCard}
                    />
                </Elements>
            </StripeProvider>
        );
    }

    render() {
        const { id } = this.props.paymentProvider.providerType;
        let providerCode;

        switch (id) {
            case 1:
                providerCode = this.renderStripeForm();
                break;
            case 2:
                providerCode = this.props.payment.selectedPaymentType.id !== 1 ? <div /> : <BraintreeForm {...this.props} />;
                break;
            case 3:
                providerCode = <MollieForm {...this.props} />;
                break;   
            case 4:
                providerCode = <AuthipayForm {...this.props} />;
                break;
            case 5:
                providerCode = <MonerisForm {...this.props} />;
                break;
            case 7:
                providerCode = <WorldPayFormUS {...this.props} />
                break;
            case 8:
                providerCode = <BamboraForm {...this.props} />;
                break;
            case 9:
                providerCode = <TbkChileForm {...this.props} />;
                break;
            default:
                providerCode = <div />;
        }

        return <Fragment>{providerCode}</Fragment>;
    }
}

const mapStateToProps = state => {
    return {
        customer: state.customer,
        selectedRestaurant: state.selectedRestaurant,
        shoppingCart: state.shoppingCart,
        payment: state.payment,
        paymentProvider: state.paymentProvider,
        customerThemes: state.customerThemes,
        estimateOrderTime: state.estimateOrderTime,
        brand: state.brand,
        pwaAppRunning: state.pwaAppRunning,
        authipayBackBtnFlag: state.storeLocalStorage.authipayBackBtnFlag,
        storeLocalStorage: state.storeLocalStorage,
        language: state.storeLocalStorage.language,
        giftCards: state.giftCards,
        orderTypes: state.orderTypes,
    }
}

export default connect(mapStateToProps, {
    placeOrder,
    processingPayment,
    errorProcessingPayment,
    resetSendingOrderModal,
    validateCardInput,
    sendEmailReport,
    dispatchTransactionId,
    saveAuthipayData,
    changeAuthipayBackBtnFlag,
    saveBamboraData,
    clearBamboraData,
    saveWorldPayData,
    clearWorldPayData,
    saveMonerisData,
    clearMonerisData,
    clearTbkData,
    clearConfirmEnrollmentErrMsg,
    sendTbkToken
})(PaymentAdapter);
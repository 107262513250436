import React, { Fragment } from 'react';
import { Row, Col, Badge } from 'reactstrap';
import i18n from '../../../i18next';

import { transformProductPrice, getPriceWithCurrencySymbol, nutriValueTotal } from '../../../utils/CommonUtils';

const MenuProductCardContentLargeImage = props => {

    const { product, currency, productIndex, mobileOrApp, productFoundInCart, productQuantityInCart, isArabic } = props;
    const { productNameStyle, productDescriptionStyle, productPriceStyle, productPriceStyleRTL, badgeColMobile, badge } = props.styles;
    const { fontFamilyType, fontBold, fontItalic, uppercase, mainTextColor, primaryColor, primaryFontColor } = props.selectedTheme;
    const { priceCol, priceColRTL, descriptionStyles, nutrientStyles } = constantStyles;

    const hideDescriptionWhenEmpty = product.description === "";
    const productPrice = transformProductPrice(product.price);
    const priceWithCurrency = getPriceWithCurrencySymbol(productPrice, currency, props.isArabic);

    const fontStylesName = {
        fontFamily: fontFamilyType,
        fontWeight: fontBold,
        fontStyle: fontItalic,
        textTransform: uppercase,
        color: primaryFontColor,
    };

    const fontStylesPrice = {
        fontFamily: fontFamilyType,
        fontWeight: fontBold === 'bold' ? fontBold : 300,
        fontStyle: fontItalic,
        textTransform: uppercase,
        color: primaryColor
    };

    const renderProductDescription = (description, index) => {
        if (document.getElementById(`productDescription${index}`)) {
            document.getElementById(`productDescription${index}`).innerHTML = description;
        }
    }

    const nameStype = mobileOrApp ? { ...productNameStyle, maxWidth: product.thumbnailPath ? '98%' : '89%' } : productNameStyle;

    return (
        <Fragment>
            <Row>
                <p id={'#' + product.name} className="long-text" style={{
                    ...nameStype,
                    ...fontStylesName
                }} >{product.name}</p>

                {
                    mobileOrApp && !product.thumbnailPath && !props.enableLargeImageDesign ?
                        <Col style={badgeColMobile} hidden={productFoundInCart}>
                            <Badge style={Object.assign({}, badge, { background: primaryColor })}>{productQuantityInCart}x</Badge>
                        </Col> : null
                }

            </Row>

            <Row hidden={hideDescriptionWhenEmpty} className='productDescription'>
                <p id={`productDescription${productIndex}`} style={{
                    ...productDescriptionStyle,
                    ...descriptionStyles,
                    color: mainTextColor
                }}>{renderProductDescription(product.description, productIndex)}</p>
            </Row>

            <Row className='productRow'>
                <Col sm={mobileOrApp ? 8 : 12} style={isArabic ? priceColRTL : priceCol}>
                    <p hidden={product.price === 0} style={isArabic ? {
                        ...productPriceStyleRTL,
                        ...fontStylesPrice} : {
                        ...productPriceStyle,
                        ...fontStylesPrice
                    }}>{priceWithCurrency}</p>
                    <p hidden={product.nutrients && !product.nutrients.length} style={{
                        ...fontStylesName,
                        ...nutrientStyles,
                        color: mainTextColor
                    }}>{nutriValueTotal(product.nutrients)} {i18n.t('common:nutritions.kcal')}</p>
                </Col>
            </Row>
        </Fragment>
    );
}

export default MenuProductCardContentLargeImage;

const constantStyles = {
    priceCol: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 0
    },
    priceColRTL: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: 0
    },
    descriptionStyles: {
        maxWidth: '100%',
        height: 42,
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical'
    },
    nutrientStyles: {
        marginBottom: 0,
        alignSelf: 'center',
        fontSize: 12,
    }
}
import i18next from 'i18next';

import en from "./translations/en.json";
import bg from "./translations/bg.json";
import fr from "./translations/fr.json";
import it from "./translations/it.json";
import pt from "./translations/pt.json";
import ar from "./translations/ar.json";
import es from "./translations/es.json";

i18next.init({
    // debug: true,       //uncomment if you want to see logs
    ns: ['translation'],
    defaultNS: 'translation',
    interpolation: { 
        escapeValue: false 
    },
    lng: 'en',
    resources: {
        'en': en.translation,
        'bg': bg.translation,
        'fr': fr.translation,
        'it': it.translation,
        'pt': pt.translation,
        'ar': ar.translation,
        'es': es.translation
    },
    fallbackLng: 'en'
});

export default i18next;
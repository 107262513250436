import React, { Component } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
// import { Switch, } from 'react-router';
import { HashRouter, Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18next';
import reducers from './reducers';
import { GridLoader } from 'react-spinners';

import StartOrderScreen from './components/order/start/StartOrderScreen';
import MenuContainerScreen from './components/order/MenuContainerScreen';
import CheckoutScreen from './components/order/CheckoutScreen';
import CustomerProfileContainer from './components/profile/ProfileMainContainer';
import CustomerOrderHistoryScreen from './components/profile/OrderHistoryScreen';

import throttle from 'lodash/throttle';
import { saveState, savePreviewState } from './utils/StateManager';

import { changeRTL, getQueryInfoFromUrl } from './utils/CommonUtils';
import { parentLocalStorage } from './utils/CrossOriginUtils';

// PWA imports
import PwaStartContainer from './pwa-app/components/start/PwaStartContainer';
import OrderContainer from './pwa-app/components/order/OrderContainer';
import ProfileContainer from './pwa-app/components/profile/ProfileContainer';
import OfflineScreen from './pwa-app/components/common/OfflineScreen';
import PwaSplashScreen from "./pwa-app/components/common/PwaSplashScreen";
import TimeWarningPopup from "./components/common/TimeWarningPopup";
// InStore Ordering
import InStoreOrderContainer from './components/order/instore/InStoreOrderContainer';
import OrderConfirmationScreen from './components/order/OrderConfirmationScreen';
import ComoLoginScreen from './components/order/promotions/como/ComoLoginScreen';
import UnsubscribeScreen from './components/others/UnsubscribeScreen';
import TbkConfirmEnrollmentScreen from './components/order/TbkConfirmEnrollmentScreen';

class App extends Component {

    constructor(props) {
        super(props);

        const userAgent = window.navigator.userAgent;
        let userOnMobileIos = userAgent.match(/iPhone; CPU iPhone OS/) || userAgent.match(/CPU iPhone/) || userAgent.match(/iPad;/);

        parentLocalStorage('get', 'menuuReduxStore')
            .then(localstorageData => {
                const menuuReduxStore = JSON.parse(localstorageData);
                const language = menuuReduxStore ? menuuReduxStore.storeLocalStorage ? menuuReduxStore.storeLocalStorage.language : null : null;
                i18next.changeLanguage(language);
                changeRTL(language)
            })

        this.state = {
            isIntegrationDetected: false,
            isUserOnMobileIos: userOnMobileIos,
            menuuReduxStore: null,
            menuuReduxStorePreviewMode: null
        }
    }
    componentDidMount() {

        const isPreviewMode = window.location.href.includes('previewMode=')

        parentLocalStorage('get', ['menuuReduxStore', 'menuuReduxStorePreviewMode'])
            .then(localStorageData => {

                if (Array.isArray(localStorageData) || isPreviewMode) {
                    this.setState({
                        ...this.state,
                        menuuReduxStore: localStorageData[0] ? JSON.parse(localStorageData[0]) : {},
                        menuuReduxStorePreviewMode: localStorageData[1] ? JSON.parse(localStorageData[1]) : {}
                    })
                } else {
                    // For Integration
                    this.setState({
                        ...this.state,
                        menuuReduxStore: localStorageData ? JSON.parse(localStorageData) : {},
                        menuuReduxStorePreviewMode: {}
                    })
                }
            })

        let queryInformation = getQueryInfoFromUrl();
        if (queryInformation.brandId) {
            // parentLocalStorage('set', 'navigationType', queryInformation.navigationType);
            parentLocalStorage('set', 'integrationDetected', 'true');
            parentLocalStorage('set', 'documentReferrer', document.referrer);

            this.setState({ ...this.state, isIntegrationDetected: true })
        }
    }

    render() {
        if (this.state.menuuReduxStore && this.state.menuuReduxStorePreviewMode) {
            const pageOpened = performance.navigation.type == 0;
            const pageRefreshed = performance.navigation.type == 1 || performance.navigation.type == 2;
            const pageOpenedFromStripe = performance.navigation.type == 255;
            const currentUrl = window.location.href;
            const previewModeSearchQuery = 'previewMode=';

            // Choosing different preloadedState for the store based on the condition if we load the live or previewMode of the app
            let loadCurrentStore = null;
            let loadedInLiveMode = null;

            if (currentUrl.includes(previewModeSearchQuery)) { // Opened in preview mode

                loadCurrentStore = (window.performance && pageRefreshed) ? this.state.menuuReduxStorePreviewMode : {};
                loadedInLiveMode = false;

            } else { // Opened in live mode

                loadCurrentStore = (window.performance && (pageRefreshed || pageOpened || pageOpenedFromStripe)) ? this.state.menuuReduxStore : {};
                loadedInLiveMode = true;
            }

            const history = createHashHistory();
            const store = createStore(
                // connectRouter(history)(reducers), // new root reducer with router state
                // {},
                reducers,
                loadCurrentStore, // preloadedState
                compose(
                    applyMiddleware(
                        routerMiddleware(history), // for dispatching history actions
                        ReduxThunk
                    ),
                )
            );

            // Save and subscribe the store to different key in local storage based on condition if we view the app in live or preview mode
            store.subscribe(throttle(() => {
                (loadedInLiveMode) ? saveState(store.getState()) : savePreviewState(store.getState());
            }, 2000));
            // Render iframe application
            if (new URLSearchParams(window.location.search).get('TBK_TOKEN') && !this.state.isIntegrationDetected) {
                return (
                    <I18nextProvider i18n={i18next}>
                        <Provider store={store}>
                            <Router>
                                <React.Fragment>
                                    <TimeWarningPopup history={history} />
                                    <Switch>
                                        <Route path='/tbk-confirm-enrollment' component={TbkConfirmEnrollmentScreen}/>
                                    </Switch>
                                </React.Fragment>
                            </Router>
                        </Provider>
                    </I18nextProvider>
                );
            } else if (this.state.isUserOnMobileIos && this.state.isIntegrationDetected) {
                return (
                    <I18nextProvider i18n={i18next}>
                        <Provider store={store}>
                            <HashRouter>
                                <div id="appWrapper" style={{ height: '100vh', overflowY: 'scroll', WebkitOverflowScrolling: 'touch' }}>
                                    <TimeWarningPopup history={history} />
                                    <Switch>
                                        <Route path='/start-order' component={StartOrderScreen} />
                                        <Route path='/menu-screen' component={MenuContainerScreen} />
                                        <Route path='/checkout' component={CheckoutScreen} />
                                        <Route path='/profile' component={CustomerProfileContainer} />
                                        <Route path='/order-history' component={CustomerOrderHistoryScreen} />
                                        <Route path='/order-confirmation' component={OrderConfirmationScreen} />
                                        <Route path='/como-login/:id' component={ComoLoginScreen} />
                                        <Route path='/unsubscribe' component={UnsubscribeScreen} />
                                    </Switch>
                                </div>
                            </HashRouter>
                        </Provider>
                    </I18nextProvider>
                )
            } else {
                return (
                    <I18nextProvider i18n={i18next}>
                        <Provider store={store}>
                            <HashRouter>
                                <React.Fragment>
                                    <TimeWarningPopup history={history} />
                                    <Switch>
                                        <Route path='/start-order' component={StartOrderScreen} />
                                        <Route path='/menu-screen' component={MenuContainerScreen} />
                                        <Route path='/checkout' component={CheckoutScreen} />
                                        <Route path='/profile' component={CustomerProfileContainer} />
                                        <Route path='/order-history' component={CustomerOrderHistoryScreen} />
                                        <Route path='/order-confirmation' component={OrderConfirmationScreen} />
                                        <Route path='/unsubscribe' component={UnsubscribeScreen} />
                                        {/* Here start the PWA app routes */}
                                        <Route exact path='/brand/:id/splash' component={PwaSplashScreen} />
                                        <Route path='/brand/:id/start-order' component={PwaStartContainer} />
                                        <Route path='/brand/:id/order' component={OrderContainer} />
                                        <Route path='/brand/:id/profile' component={ProfileContainer} />
                                        {/* Offline fallback PWA route */}
                                        <Route path='/brand/:id/offline' component={OfflineScreen} />
                                        {/* InStore Ordering */}
                                        <Route path='/dine-pay' component={InStoreOrderContainer} />
                                        <Route path='/como-login/:id' component={ComoLoginScreen} />
                                    </Switch>
                                </React.Fragment>
                            </HashRouter>
                        </Provider>
                    </I18nextProvider>
                );
            }
        } else {
            return (
                <div style={styles.loadingContainerTransparent}>
                    <div style={styles.loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader size={20} />
                    </div>
                </div>
            )
        }
    }
}

export default App;

const styles = {
    loadingIndicator: {
        width: '72px',
        height: '72px',
    },

    loadingContainerTransparent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        zIndex: 100000000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}
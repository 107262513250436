import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
    isUserLoggedInPartner,
    resetOldDiscountFlag
} from '../../../actions';
import ComoContainer from './como/ComoContainer';
import MenuLoaderContainer from '../menu/MenuLoaderContainer';

class PromotionPartnerAdapter extends Component {

    userLoggedInPromotionPartner = (currentPartner) => {

        const { promotionPartners } = this.props;
        // TODO find a better way in near future when there are more than one external providers
        if (promotionPartners.promotionPartner.id === 2 && currentPartner.membership) {
 
            return this.props.isUserLoggedInPartner(true);
        } else if (promotionPartners.promotionPartner.id === 1) {
            
            return this.props.isUserLoggedInPartner(true);
        }

        return this.props.isUserLoggedInPartner(false);
    }

    render() {

        if (!this.props.promotionPartners.promotionPartner) return null;

        const { id } = this.props.promotionPartners.promotionPartner;
        let partnerCode = null;

        switch (id) {
            case 2:
                partnerCode = <ComoContainer 
                                    {...this.props} 
                                    userLoggedInPromotionPartner={this.userLoggedInPromotionPartner} 
                                    sendInfoToShoppingCart={this.props.sendInfoToShoppingCart}
                                    rejectedRegistrationOnClick={this.props.rejectedRegistrationOnClick}
                                    redirectNotVerifiedUser={this.props.redirectNotVerifiedUser}/>;
                break;
            case 1: // MENUU -> probably will have own container and stuff if time allows it
            default:
                partnerCode = null;
                break;
        }

        return (
            <Fragment>
                {partnerCode}
                { // TODO move this to a parent
                    this.props.loading && !this.props.sendOrderModal.sendOrderModalOpened && <MenuLoaderContainer {...this.props} />
                }
            </Fragment>);
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        promotionPartners: state.promotionPartners,
        customerThemes: state.customerThemes,
        restaurant: state.selectedRestaurant,
        customer: state.customer,
        sendOrderModal: state.sendOrderModal,
        shoppingCart: state.shoppingCart,
        estimateOrderTime: state.estimateOrderTime
    };
};

export default connect(
    mapStateToProps,
    {
        isUserLoggedInPartner,
        resetOldDiscountFlag
    }
)(PromotionPartnerAdapter);
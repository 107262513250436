import {
    SAVE_CAR_DETAILS,
    SAVE_FIRST_STAMP,
    CHANGE_APPLE_GOOGLE_PAY_AVAILABILITY,
    SAVE_NAVIGATION_TYPE,
    CHANGE_AUTHIPAY_BACK_BUTTON_FLAG,
    SAVE_BRANDID_TO_STORE_LOCALSTORAGE,
    SAVE_WORKING_REST_COUNT,
    CHANGE_SIGN_IN_PRESSED,
    CHANGE_CODE_MANUALLY_SET,
    CLEAR_STORE_LOCALSTORAGE_STATE
} from "./types";

export const saveCarDetails = (carDetails) => {
    return (dispatch) => {

        dispatch({ type: SAVE_CAR_DETAILS, payload: carDetails });
    };
};

export const saveFirstStamp = (firstStamp) => {
    return (dispatch) => {

        dispatch({ type: SAVE_FIRST_STAMP, payload: firstStamp });
    };
};

export const changeAppleGooglePayAvailability = (isAppleGooglePayAvailable) => {
    return (dispatch) => {

        dispatch({ type: CHANGE_APPLE_GOOGLE_PAY_AVAILABILITY, payload: isAppleGooglePayAvailable });
    };
};

export const saveNavigationType = (navigationType) => {
    return (dispatch) => {

        dispatch({ type: SAVE_NAVIGATION_TYPE, payload: navigationType });
    };
};

export const changeAuthipayBackBtnFlag = (authipayBackBtnFlag) => {
    return (dispatch) => {

        dispatch({ type: CHANGE_AUTHIPAY_BACK_BUTTON_FLAG, payload: authipayBackBtnFlag });
    };
};

export const saveBrandIdToStoreLocalStorage = (brandId) => {
    return (dispatch) => {

        dispatch({ type: SAVE_BRANDID_TO_STORE_LOCALSTORAGE, payload: brandId });
    };
};

export const saveWorkingRestCount = (workingRestCount) => {
    return (dispatch) => {

        dispatch({ type: SAVE_WORKING_REST_COUNT, payload: workingRestCount });
    };
};

export const changeSignInPressed = (signInPressed) => {
    return (dispatch) => {

        dispatch({ type: CHANGE_SIGN_IN_PRESSED, payload: signInPressed });
    };
};

export const changeCodeManuallySet = (codeManuallySet) => {
    return (dispatch) => {

        dispatch({ type: CHANGE_CODE_MANUALLY_SET, payload: codeManuallySet });
    };
};

export const clearStoreLocalStorageState = () => {
    return (dispatch) => {

        dispatch({ type: CLEAR_STORE_LOCALSTORAGE_STATE });
    };
};
import React from 'react';

const CommentsSVG = ({ width, height, fill, style }) => {
	const viewBoxValues = "0 0 119.9 96.3";

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style} >
			<g>
				{/* Inner background */}
				{/* <path fill={fill} d="M101.5,7H42c-6.3,0-11.4,5.1-11.4,11.4v5.1l-15.5,1.1l15.5,16.6v36.7c0,6.3,5.1,11.4,11.4,11.4h59.5
					c6.3,0,11.4-5.1,11.4-11.4V18.4C112.9,12.1,107.8,7,101.5,7z M55.9,51.2c4.7,0,8.9,3,8.9,8.5c0,5.8-3.7,9.3-8.9,9.3
					c-6.7,0-10.6-5.5-10.6-15.2c0-13.4,6.3-23.1,17.9-29.8l3.9,6.5C58.8,36,54.5,41.9,54.4,51.4C54.9,51.3,55.4,51.2,55.9,51.2z
					 M85.5,51.2c4.7,0,8.9,3,8.9,8.5c0,5.8-3.7,9.3-8.9,9.3C78.9,69,75,63.5,75,53.8c0-13.4,6.3-23.1,17.9-29.8l3.9,6.5
					C88.4,36,84.1,41.9,84.1,51.4C84.5,51.3,85,51.2,85.5,51.2z"/> */}
				<path fill={fill} d="M101.5,0H42c-9.7,0-17.6,7.5-18.3,17L0,18.7l23.6,25.3v34c0,10.1,8.3,18.4,18.4,18.4h59.5
					c10.1,0,18.4-8.3,18.4-18.4V18.4C119.9,8.3,111.6,0,101.5,0z M112.9,77.9c0,6.3-5.1,11.4-11.4,11.4H42c-6.3,0-11.4-5.1-11.4-11.4
					V41.2L15.1,24.6l15.5-1.1v-5.1C30.6,12.1,35.7,7,42,7h59.5c6.3,0,11.4,5.1,11.4,11.4V77.9z"/>
				<path fill={fill} d="M67.2,30.5l-3.9-6.5c-11.6,6.7-17.9,16.4-17.9,29.8c0,9.7,3.9,15.2,10.6,15.2c5.2,0,8.9-3.5,8.9-9.3
					c0-5.4-4.3-8.5-8.9-8.5c-0.5,0-1,0-1.5,0.1C54.5,41.9,58.8,36,67.2,30.5z"/>
				<path fill={fill} d="M96.8,30.5l-3.9-6.5C81.2,30.7,75,40.4,75,53.8C75,63.5,78.9,69,85.5,69c5.2,0,8.9-3.5,8.9-9.3
					c0-5.4-4.3-8.5-8.9-8.5c-0.5,0-1,0-1.5,0.1C84.1,41.9,88.4,36,96.8,30.5z"/>
			</g>
		</svg>
	);
}

export default CommentsSVG;
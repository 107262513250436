import React from 'react';
import '../../../styles/BackButton.css';
import BackButtonSVG from "../../common/svgComponents/BackButtonSVG";
import {NAV_ICON_HEIGHT, NAV_ICON_WIDTH, NAV_ICON_COLOR, NAV_ICON_STROKE_OPTIONS} from "../../../../utils/Constants";

const BackButton = (props) => {

    return (
        <BackButtonSVG
            width={NAV_ICON_WIDTH}
            height={NAV_ICON_HEIGHT}
            color={props.color}
            stroke={props.isShadowed ? NAV_ICON_STROKE_OPTIONS.stroke : ''}
            strokeWidth={props.isShadowed ? NAV_ICON_STROKE_OPTIONS.strokeWidth : ''}
            svgClicked={() => props.goBack()}
        />
    );

    /*return (
        <Button
            id="actionBarBackButton"
            onClick={() => props.goBack()}
        >
            <span id="actionBarBackButtonIcon">
                <MaterialIcon icon="navigate_before" color="#FFF" size={32} />
            </span>
            {/!* <span>{i18n.t('common:buttons.backBtn')}</span> *!/}
        </Button>
    );*/
}

export default BackButton;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    getComoMemberDetails,
    resetComoReducer,
    setTransactionIdAndOpenTime,
    getComoBenefits,
    applyComoCredits,
    cancelComoPayment,
    togglePromoPartnerModal,
    registerToComo,
    setUserAnswer,
    voidPurchase,
    resetPromoCodeField,
    resetErrorsOnly,
    togglePromotionsModal,
    resetOldDiscountFlag
} from '../../../../actions';
import { ARABIC } from '../../../../utils/Constants';
import { arrayDeepCopy } from '../../../../utils/ProductWizardUtils';
import { correctRounding } from '../../../../utils/CommonUtils';
import PromotionSVG from '../../../../pwa-app/components/common/svgComponents/PromotionSVG';
import UserNotLoggedIn from './UserNotLoggedIn';
import UserLoggedIn from './UserLoggedIn';
import UserNotVerified from './UserNotVerified';
import ComoPromotionsList from './ComoPromotionsList';
import GiftCardSVG from '../../../../pwa-app/components/common/svgComponents/GiftCardSVG';
import ComoModalHandler from './ComoModalHandler';
import CheckoutSectionContainer from '../../../common/CheckoutSectionContainer';
import ComoPointShop from './ComoPointShop';

class ComoContainer extends Component {
    constructor() {
        super();

        this.state = {
            usedCreditBalance: null,
            pointsBalance: null,
            creditBalance: null,
            selectedPromotions: [],
            pointShopPromos: [],
            triggerRerender: false
        };
    }

    componentDidMount() {

        const { como, promotionPartners } = this.props;
        const { membership, userSavedData } = this.props.como;

        this.props.userLoggedInPromotionPartner(como);

        if (!this.props.como.userSavedData.openTime) {

            let now = Date.now();// TODO use currentTime for openTime when asap is selected
            const openTime = this.getOpenTime(this.props.estimateOrderTime.wantedTime);

            this.props.setTransactionIdAndOpenTime({ transactionId: now, openTime: openTime });
        }

        const onSuccess = () => this.props.getComoBenefits(this.state.promotions);

        this.props.getComoMemberDetails(onSuccess);

        this.setState({
            selectedPromotions: this.checkUserSelectedPromos().selectedPromotions,
            pointShopPromos: this.checkUserSelectedPromos().pointShopPromos,
            pointsBalance: membership ? membership.pointsBalance : null,
            creditBalance: membership ? membership.creditBalance : null,
            usedCreditBalance: userSavedData.paymentResponse ? correctRounding(Math.abs(userSavedData.paymentResponse.payments[0].amount / 100), 2) : null
        });
    }

    componentDidUpdate = (prevProps) => {

        this.listenForGetBenefitsResponseChanges(prevProps);
    }

    componentWillUnmount() {
        const { brandId } = this.props;
        const isCheckoutScreen = window.location.href.includes('checkout');
        const isTipScreen = window.location.href.includes(`/brand/${brandId}/order/tip`);

        if (!isCheckoutScreen && !isTipScreen) {
            this.props.voidPurchase(() => this.props.resetOldDiscountFlag());
            this.props.resetPromoCodeField();
        }
    }

    getOpenTime = (wantedTime) => {

        let time = new Date(wantedTime);
        // TODO use the restorants zone when branches are merged
        if (!wantedTime) {
            time = new Date();
            // time = momentTimezone().tz(this.props.restaurant.restaurantTimeZone).format('YYYY-MM-DD HH:mm:ss');
        }

        if (!Object.keys(time).length && wantedTime) {

            const dateAndTimeArr = wantedTime.split(' ');
            const dateArr = dateAndTimeArr[0].split('-').reverse().join('-').concat(' ');

            time = dateArr.concat(dateAndTimeArr[1]);
        }

        time = moment(time, moment.ISO_8601).toISOString();

        return time;
    }

    listenForGetBenefitsResponseChanges = (prevProps) => {

        const { membership, userSavedData } = this.props.como;
        let stateObject = null;

        if (userSavedData.getBenefitsResponse &&
            prevProps.como.userSavedData.getBenefitsResponse !== userSavedData.getBenefitsResponse) {
            stateObject = {
                ...stateObject,
                selectedPromotions: this.checkUserSelectedPromos().selectedPromotions,
                pointShopPromos: this.checkUserSelectedPromos().pointShopPromos
            };
        }

        if (this.props.como !== prevProps.como && membership) {
            stateObject = {
                ...stateObject,
                pointsBalance: membership.pointsBalance,
                creditBalance: membership.creditBalance
            }
        }

        if (userSavedData.paymentResponse !== prevProps.como.userSavedData.paymentResponse) {

            stateObject = {
                ...stateObject,
                usedCreditBalance: userSavedData.paymentResponse ? correctRounding(Math.abs(userSavedData.paymentResponse.payments[0].amount / 100), 2) : null
            };
        }

        if (!stateObject) return null;

        this.setState({
            ...stateObject
        });
    }

    selectPromotion = (promotion) => {

        let selectedPromos = arrayDeepCopy(this.state.selectedPromotions);
        const existingPromo = selectedPromos.find(selectedPromo => selectedPromo.key === promotion.key);
        let selectedPromoIndex = -1;

        if (!existingPromo) {
            selectedPromos.push(promotion);
        } else {
            selectedPromoIndex = selectedPromos.findIndex((selectedPromo) => selectedPromo.key === promotion.key);
            selectedPromos.splice(selectedPromoIndex, 1);
        }

        this.setState({
            selectedPromotions: selectedPromos
        },
            () => this.props.getComoBenefits(selectedPromos)
        );
    }

    checkUserSelectedPromos = () => {

        const { getBenefitsResponse } = this.props.como.userSavedData;
        const pointShopPromos = this.props.como.membership && this.props.como.membership.assets && this.props.como.membership.assets.filter(asset => asset.key.substring(0, 3) === 'ps_');

        if (!getBenefitsResponse || !getBenefitsResponse.redeemAssets) {

            return {
                selectedPromotions: [],
                pointShopPromos: pointShopPromos ? pointShopPromos : []
            };
        }

        const selectedPromotions = [];
        
        if (this.props.como.membership) {
            getBenefitsResponse.redeemAssets.map(redeemedAsset => {
                const assetFoundInState = this.props.como.membership.assets.find(selectedPromo => selectedPromo.key === redeemedAsset.key)

                if (assetFoundInState) { // TODO check to redeemedAsset if there is relevent data

                    selectedPromotions.push(assetFoundInState);
                }
            });
        }

        return { selectedPromotions, pointShopPromos };
    }

    changeState = ({ target }) => {

        const changeStringToNumber = (value) => isNaN(parseFloat(value)) ? '' : parseFloat(value)

        this.setState({
            [target.name]: changeStringToNumber(target.value)
        })
    }

    getButtonAttributes = () => {

        const { isPromoPartnerModalOpen, isUserLoggedInPartner } = this.props.promotionPartners;
        const { userRegistrationTriggered, status, errors } = this.props.como;
        const userNotRegistered = status === 'error' && errors[0].code === '4001012';
        const membershipStatus = this.props.como.membership ? this.props.como.membership.status : null;
 
        let attributes = {
            ...this.props,
            onClick: () => this.props.getComoMemberDetails(true)
        };

        if (membershipStatus === 'Inactive') {
           return;
        }
        else if (!isPromoPartnerModalOpen && !isUserLoggedInPartner && !userRegistrationTriggered && !userNotRegistered) {

            attributes = {
                ...this.props,
                onClick: () => this.props.togglePromoPartnerModal()
            };
        } else if (userNotRegistered) {
            attributes = {
                ...this.props,
                onClick: () => this.props.registerToComo()
            };
        }

        return attributes;
    }

    handleBalancePayment = () => {
        const { userSavedData } = this.props.como;
        const { usedCreditBalance } = this.state;

        if (userSavedData.paymentResponse) {

            const paymentAmount = correctRounding(Math.abs(userSavedData.paymentResponse.payments[0].amount / 100), 2);
            if (paymentAmount === usedCreditBalance) {

                return;
            }

            const onSuccess = () => this.props.applyComoCredits(usedCreditBalance)
            this.props.cancelComoPayment(onSuccess);

            return;
        }

        this.props.applyComoCredits(usedCreditBalance);
    }

    openTermsPage() {
        let termsAndConditionsPage = window.open('http://app4mobilebiz.wpengine.com/business-end-user-terms-of-use.html', '_blank');
        termsAndConditionsPage.focus();
    }

    openPrivacyPolicyPage() {
        let privacyPolicy = window.open('https://app4mobilebiz.wpengine.com/business-end-user-privacy-policy.html', '_blank');
        privacyPolicy.focus();
    }

    render() {

        const { promotionPartners, como } = this.props;
        const {
            type,
            primaryColor,
            secondaryColor
        } = this.props.customerThemes.selectedTheme;

        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImageTwo = <PromotionSVG width={22} height={22} fill={svgColor} />;
        const iconImage = <GiftCardSVG width={22} height={22} fill={svgColor} />;
        const isArabic = this.props.language === ARABIC;
        const membershipStatus = this.props.como.membership ? this.props.como.membership.status : null;

        return (
            <Fragment>
                <CheckoutSectionContainer
                    {...this.props}
                    isHidden={false}
                    iconImage={promotionPartners.isUserLoggedInPartner ? iconImage : null}
                    isPromotionsScreen={true}
                >
                    {
                        membershipStatus === 'Inactive' ? 
                            <UserNotVerified
                                {...this.props}
                                loading={this.props.loading.transparentLoader}
                                brandId={this.props.brandId}
                                pwaAppRunning={this.props.pwaAppRunning}
                                redirectNotVerifiedUser={this.props.redirectNotVerifiedUser}
                                isArabic={isArabic} /> :
                        !promotionPartners.isUserLoggedInPartner && !this.props.rejectedRegistration ?
                            <UserNotLoggedIn
                                rejectedRegistrationOnClick={this.props.rejectedRegistrationOnClick}
                                togglePromotionsModal={this.props.togglePromotionsModal}
                                loading={this.props.loading.transparentLoader}
                                brandId={this.props.brandId}
                                pwaAppRunning={this.props.pwaAppRunning}
                                openTermsPage={this.openTermsPage}
                                openPrivacyPolicyPage={this.openPrivacyPolicyPage}
                                isArabic={isArabic}
                                {...this.getButtonAttributes()} /> : 
                            // TODO make a function to add attributes dynamically
                            <UserLoggedIn
                                {...this.props}
                                state={this.state}
                                selectedItems={this.state.selectedPromotions}
                                selectPromotion={this.selectPromotion}
                                changeState={this.changeState}
                                applyPromotion={this.props.applyComoCredits}
                                handleBalancePayment={this.handleBalancePayment}
                                correctRounding={correctRounding}
                                sendInfoToShoppingCart={this.props.sendInfoToShoppingCart}
                                isArabic={isArabic}
                            />
                    }
                </CheckoutSectionContainer>

                <CheckoutSectionContainer
                    {...this.props}
                    isHidden={!(como.membership && como.membership.pointsBalance && como.membership.pointsBalance.balance.monetary) || membershipStatus === 'Inactive'}
                    iconImage={iconImageTwo}
                    isPromotionsScreen={true}
                    isArabic={isArabic}
                >
                    
                    <ComoPointShop
                        {...this.props}
                        statePointsBalance={this.state.pointsBalance}
                        isArabic={isArabic}
                    >
                        {
                            this.state.pointShopPromos &&
                            <ComoPromotionsList
                                {...this.props}
                                assets={this.state.pointShopPromos}
                                selectedItems={this.state.selectedPromotions}
                                selectPromotion={this.selectPromotion}
                                isArabic={isArabic} />
                        }
                    </ComoPointShop>
                </CheckoutSectionContainer>

                <ComoModalHandler
                    {...this.props}
                    getButtonAttributes={this.getButtonAttributes}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {
        como: state.como,
        isPromotionsModalOpen: state.commonReducer.isPromotionsModalOpen,
        loading: state.loading,
        brandId: state.brand.id,
        pwaAppRunning: state.pwaAppRunning,
        language: state.storeLocalStorage.language
    };
};

export default connect(
    mapStateToProps,
    {
        getComoMemberDetails,
        resetComoReducer,
        setTransactionIdAndOpenTime,
        getComoBenefits,
        applyComoCredits,
        cancelComoPayment,
        togglePromoPartnerModal,
        registerToComo,
        setUserAnswer,
        voidPurchase,
        resetPromoCodeField,
        resetErrorsOnly,
        togglePromotionsModal,
        resetOldDiscountFlag
    }
)(ComoContainer);
import React from 'react';
import { isMobile, isTablet } from 'mobile-device-detect';

import { FRONT_END_URL } from '../../../utils/Constants';

const ImageContainer = props => {
    // If mobile resolution (check tablets later) check the device width
    // the set the width and height === device width (window.innerWidth)
    // TABLETS - for tablets get the wizard width (probably passed by props and taken from a ref)
    let imgStyles = {};
    // console.log('isMobile ', isMobile, ' isTablet ', isTablet, ' props.wizardRef ', props.wizardRef)
    if (isMobile && !isTablet) {
        imgStyles = { width: window.innerWidth, maxHeight: '100%' };
    } else if (!props.enableLargeImageDesign && props.product.mealDeal && !isTablet && !isMobile) {
        imgStyles = { width: 520, height: 520 };
    } else if (props.enableLargeImageDesign && (props.product.questions.length > 0) && !isTablet && !isMobile) {
        imgStyles = { width: '50%' }
    } else if (props.enableLargeImageDesign && !(props.product.questions.length > 0) && !isTablet && !isMobile) {
        imgStyles = { maxWidth: '100%', maxHeight: '100%'  }
    } else {
        imgStyles = { width: 500, height: 500 };
    }

    return (
        <div className='wizardImageContainer' style={imgStyles}>
            <img
                // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                style={props.isMobileDevice ? imgStyles : { width: '100%', height: '100%', objectFit: 'cover' }}
                src={`${FRONT_END_URL}/${props.enableLargeImageDesign ? props.product.rectImagePath : props.product.imagePath}`} />
        </div>
    )

};

export default ImageContainer;
import {
    CLEAR_APP_STATE,
    CLEAR_SELECTED_PAYMENT_CARD,
    CLEAR_STATE,
    LOAD_PAYMENT_TYPES_SUCCESS,
    SELECT_PAYMENT_CARD,
    SELECT_PAYMENT_TYPE,
    CLEAR_PAYMENT_REDUCER,
    CARD_INPUT_VALIDATION
} from '../../actions/types';

const INITIAL_STATE = {
    selectedPaymentType: {
        id: null,
        value: ''
    },
    selectedPaymentCard: {},
    paymentToken: '',
    paymentTypes: [],
    // New props for the refactored payment providers
    isCardValid: false // this one is for the place order btn
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOAD_PAYMENT_TYPES_SUCCESS:
            return {
                ...state,
                paymentTypes: payload
            };
        case SELECT_PAYMENT_TYPE:
            return {
                ...state,
                selectedPaymentType: payload
            };
        case SELECT_PAYMENT_CARD:
            return {
                ...state,
                selectedPaymentCard: payload.paymentCardDTO,
                paymentToken:  payload.paymentToken
            };
        case CLEAR_SELECTED_PAYMENT_CARD:
            return {
                ...state,
                selectedPaymentCard: {},
                isCardValid: false
            };
        case CARD_INPUT_VALIDATION:
            return {
                ...state,
                isCardValid: payload
            }
        case CLEAR_PAYMENT_REDUCER:
            return INITIAL_STATE;
        case CLEAR_STATE:
            return INITIAL_STATE;
        case CLEAR_APP_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
}
import React from 'react';

const InStoreSVG = ({ width, height, fill, style }) => {
	return (
		<svg 
			width={width} height={height} style={style}
			version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 102.4 94.1"  space="preserve"
			// style="enable-background:new 0 0 102.4 94.1;"
			>
			<g>
				<path fill={fill} class="st0" d="M59.9,85.6L57.5,76c-1.6-1-3.3-1.8-6.1-1.8c-1.1,0-2,0.1-2.8,0.3l-3.7,11.9c-0.3,0.9-1,1.5-1.9,1.8l-4.9,1.2
		c4.2,0.1,9.3,0.2,15.6,0.2c6.2,0,11.2-0.1,15.2-0.2l-7.1-1.9C60.8,87.3,60.1,86.5,59.9,85.6z"/>
				<path fill={fill} class="st1" d="M41.2,12.2c0,2,1.4,3,2.6,3.9c0.3,0.2,0.5,0.4,0.8,0.6l1.9-0.8c-0.3-0.2-0.6-0.5-0.9-0.7
		c-1.1-0.8-2.1-1.6-2.1-3.1c0-1.5,1-2.2,2.1-3.1c1.2-0.9,2.6-1.9,2.6-3.9c0-2-1.4-3-2.6-3.9c-0.3-0.2-0.5-0.4-0.8-0.6l-1.9,0.8
		c0.3,0.2,0.6,0.5,0.9,0.7c1.1,0.8,2.1,1.6,2.1,3.1c0,1.5-1,2.2-2.1,3.1C42.5,9.2,41.2,10.2,41.2,12.2z"/>
				<path fill={fill} class="st1" d="M52.2,5.2c0,1.5-1,2.2-2.1,3.1c-1.2,0.9-2.6,1.9-2.6,3.9c0,2,1.4,3,2.6,3.9c0.3,0.2,0.5,0.4,0.8,0.6l1.9-0.8
		c-0.3-0.2-0.6-0.5-0.9-0.7c-1.1-0.8-2.1-1.6-2.1-3.1c0-1.5,1-2.2,2.1-3.1c1.2-0.9,2.6-1.9,2.6-3.9s-1.4-3-2.6-3.9
		c-0.3-0.2-0.5-0.4-0.8-0.6l-1.9,0.8c0.3,0.2,0.6,0.5,0.9,0.7C51.2,3,52.2,3.7,52.2,5.2z"/>
				<path fill={fill} class="st1" d="M58.5,5.2c0,1.5-1,2.2-2.1,3.1c-1.2,0.9-2.6,1.9-2.6,3.9c0,2,1.4,3,2.6,3.9c0.3,0.2,0.5,0.4,0.8,0.6l1.9-0.8
		c-0.3-0.2-0.6-0.5-0.9-0.7c-1.1-0.8-2.1-1.6-2.1-3.1c0-1.5,1-2.2,2.1-3.1c1.2-0.9,2.6-1.9,2.6-3.9s-1.4-3-2.6-3.9
		c-0.3-0.2-0.5-0.4-0.8-0.6l-1.9,0.8c0.3,0.2,0.6,0.5,0.9,0.7C57.6,3,58.5,3.7,58.5,5.2z"/>
				<path fill={fill} class="st1" d="M93.1,28H72.9v-1.5c7-1.9,11.4-4.8,11.5-7.9H17.6c0.1,3.2,4.6,6,11.5,7.9V28H8.9c-1.4,0-2.6,1.2-2.6,2.6v46.2
		c0,1,0.6,1.9,1.4,2.3c0.9,0.4,1.9,0.3,2.7-0.2c1.7-1.3,7.2-4.7,11.5-4.7c2.2,0,3.6,0.9,5.4,2c2.3,1.4,5.1,3.2,9.6,3.2
		c2.1,0,3.7-0.4,5-0.9l-1.6,5l-20.2,4.9c-1.2,0.3-2,1.4-2,2.6c0,1.2,0.9,2.3,2.1,2.5c0.3,0.1,7.5,1.3,33.4,1.3
		c25.6,0,31.3-1.2,31.9-1.4c1.2-0.3,2-1.3,2-2.5c0-1.2-0.8-2.2-1.9-2.5l-21-5.5L63.6,79c0.9,0.2,1.8,0.3,2.8,0.3
		c4.1,0,5.8-1.8,7.1-3.2c1.1-1.2,1.9-2,5-2c7,0,12.7,4.5,12.8,4.6c0.5,0.4,1.1,0.6,1.6,0.6c0.4,0,0.8-0.1,1.1-0.3
		c0.9-0.4,1.5-1.3,1.5-2.4V30.6C95.7,29.1,94.5,28,93.1,28z M90.5,72c-2.9-1.5-7.1-3.1-11.9-3.1c-5.4,0-7.4,2.1-8.8,3.7
		c-1,1.1-1.4,1.5-3.3,1.5c-2.2,0-3.5-0.8-5.2-2c-2.2-1.4-4.9-3.2-9.8-3.2c-4.8,0-7.2,1.8-9.1,3.2c-1.6,1.2-2.7,2-5.3,2
		c-3,0-4.8-1.1-6.8-2.4c-2.1-1.3-4.5-2.8-8.2-2.8c-3.7,0-7.6,1.6-10.4,3.1V33.2h78.9V72z M68.9,89.4c-4,0.1-9,0.2-15.2,0.2
		c-6.2,0-11.4-0.1-15.6-0.2l4.9-1.2c0.9-0.2,1.6-0.9,1.9-1.8l3.7-11.9c0.7-0.2,1.6-0.3,2.8-0.3c2.8,0,4.5,0.8,6.1,1.8l2.3,9.6
		c0.2,0.9,0.9,1.7,1.9,1.9L68.9,89.4z"/>
				<path fill={fill} class="st1" d="M52,61.8c15.6,0,27.8-19.1,27.8-19.1S62.4,57.5,51.1,57.5S24.2,42.7,24.2,42.7S36.4,61.8,52,61.8z" />
			</g>
		</svg>
	);
}

export default InStoreSVG;
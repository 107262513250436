import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendTbkToken } from '../../actions';
import { parentLocalStorage } from '../../utils/CrossOriginUtils';

class TbkConfirmEnrollmentScreen extends Component {
    constructor() {
        super();
    }

    componentWillMount() {
        const tbkTokenParam = new URLSearchParams(this.props.location.search).get('TBK_TOKEN');

        parentLocalStorage('get', 'dataForTbk')
            .then(tbkData => {
                const data = JSON.parse(tbkData);

                const dataForTbkRedirects = {
                    isIntegration: data.isIntegration,
                    brandId: data.brandId,
                    navigationType: data.navigationType,
                    restaurantId: data.restaurantId,
                    restaurantTypeId: data.restaurantTypeId,
                    selectedOrderTypeId: data.selectedOrderTypeId,
                    selectedThemeType: data.selectedThemeType,
                    selectedThemePrimaryColor: data.selectedThemePrimaryColor,
                    pwaAppRunning: data.pwaAppRunning
                }

                if (data && !data.pwaAppRunning) {
                    this.props.sendTbkToken(tbkTokenParam, dataForTbkRedirects, this.props.history);
                }
        });
    }

    render() {
        return <div></div>
    }
}

export default connect(null, {sendTbkToken})(TbkConfirmEnrollmentScreen);
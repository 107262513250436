import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Modal, Input, Col, InputGroup, InputGroupAddon, Button, FormGroup, ListGroup, ListGroupItem } from 'reactstrap';
import i18n from '../../../../i18next';
import moment from 'moment/moment';
import { isMobileOnly } from 'mobile-device-detect';

import {
    DELIVERY_ID,
    IN_STORE_ID,
    COLLECTION_ID,
    CURBSIDE_ID
} from '../../../../utils/Constants';
import { dateAndTimeToUserFriendlyFormat, negativeDecimalsValidation, getPriceWithCurrencySymbol, transformProductPrice } from '../../../../utils/CommonUtils';
import {
    saveInStoreInfo,
    changeCodeManuallySet,
    cancelComoPayment,
    restartOrderComo,
} from '../../../../actions/index';
import { ARABIC } from "../../../../utils/Constants";
import TableSelectionScreen from '../../../../components/order/instore/TableSelectionScreen';
import CheckoutRow from './CheckoutRow';
import ConfirmationModal from '../../../../components/common/ConfirmationModal';
import UserIconSVG from '../../common/svgComponents/UserIconSVG';
import TickSVG from '../../common/svgComponents/TickSVG';
import LocationIconSVG from '../../common/svgComponents/LocationIconSVG';
import ClockSVG from '../../common/svgComponents/ClockSVG';
import HomeSVG from '../../common/svgComponents/HomeSVG';
import CommentsSVG from '../../common/svgComponents/CommentsSVG';
import InStoreSVG from '../../common/svgComponents/InStoreSVG';
import CardsSVG from '../../common/svgComponents/CardsSVG';
import GiftCardSVG from '../../common/svgComponents/GiftCardSVG';
import GiftRibbonSVG from '../../common/svgComponents/GiftRibbonSVG';

class CheckoutInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openConfirmationDialog: false,
            workingRestCounter: this.props.storeLocalStorage.workingRestCount ? parseInt(this.props.storeLocalStorage.workingRestCount) : null,
            isOpen: false,
            currentNote: 0,
            isRestartOrder: false
        }

        this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
        this.toggleChangeTable = this.toggleChangeTable.bind(this);
    }

    toggleChangeTable() {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        });
    }

    toggleConfirmationModal(changeTime, isRestartOrder) {
        this.setState({
            ...this.state,
            openConfirmationDialog: !this.state.openConfirmationDialog,
            changeTime: changeTime ? true : false,
            isRestartOrder: isRestartOrder ? true : false
        });
    }

    confirmOrderReset = () => {

        const { promotionPartners } = this.props;
        let brandId = this.props.brand.id, path;

        if (promotionPartners.promotionPartner && promotionPartners.promotionPartner.id === 2) {

            this.props.restartOrderComo(this.state.changeTime, this.props.pwaAppRunning, this.props.history, this.state.isRestartOrder);
        } else { // MENUU
            // TODO MOVE THESE TO ACTIONS
            if (this.state.changeTime && this.props.pwaAppRunning) {
                this.props.history.push({ pathname: `/brand/${brandId}/start-order/order-time`, state: window.location.hash });
            } else {
                if (this.props.pwaAppRunning) {
                    path = `/brand/${brandId}/start-order/main-screen`;
                } else {
                    path = `/start-order`;
                }

                this.resetReduxState(path);
            }
            this.props.changeCodeManuallySet(null);
        }
    }

    resetReduxState = (path) => {
        this.props.clearState();
        this.props.openChangeOrderTimeScreen(path);
    }
    // TODO find a way to render these programatically and not hardcoded like this
    tableNumberInfo(isPwa) {
        const { selectedRestaurant } = this.props;
        const { updateInfoStyle } = this.props.styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <InStoreSVG width={22} height={22} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />

        const sectionTitleText = selectedRestaurant.orderInStoreInfo.label;
        const isDisabled = false;
        const updateDetailsStyle = updateInfoStyle;

        return (
            <CheckoutRow
                pwaMode={isPwa}
                handleClick={this.toggleChangeTable}
                thirdSpanElement={null}
                aboveSpansElement={selectedRestaurant.orderInStoreInfo.value}
                sectionTitleText={`${sectionTitleText}`}
                isDisabled={isDisabled}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                updateDetailsStyle={updateDetailsStyle}
                removeSeparatorLine={true}
                buttonText={`${i18n.t('screens:tableSelectionScreen.changeLabel')} ${selectedRestaurant.orderInStoreInfo.label}`}
                styles={this.props.styles}
            />
        );
    }

    renderOrderTypeText(orderType, rowIndex) {
        switch (orderType) {
            case DELIVERY_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.deliverTo');
                    case 2:
                        return i18n.t('screens:checkoutScreen.deliverFrom');
                    case 3:
                        return i18n.t('screens:checkoutScreen.deliverAt');
                    case 4:
                        return i18n.t('screens:checkoutScreen.deliveryInstructions');
                }
            case COLLECTION_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.collectBy');
                    case 2:
                        return i18n.t('screens:checkoutScreen.collectFrom');
                    case 3:
                        return i18n.t('screens:checkoutScreen.collectAt');
                    case 4:
                        return i18n.t('screens:checkoutScreen.collectionInstructions');
                }
            case CURBSIDE_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.curbsideBy');
                    case 2:
                        return i18n.t('screens:checkoutScreen.curbsideFrom');
                    case 3:
                        return i18n.t('screens:checkoutScreen.curbsideAt');
                    case 4:
                        return i18n.t('screens:checkoutScreen.curbsideInstructions');
                }
                return
            case IN_STORE_ID:
                switch (rowIndex) {
                    case 1:
                        return i18n.t('screens:checkoutScreen.orderBy');
                    case 3:
                        return i18n.t('screens:restaurantDetailsScreen.instoreTime');
                    case 4:
                        return i18n.t('screens:checkoutScreen.orderInstructions');
                }
            default:
                return orderType
        }
    }

    renderCheckoutInfo(isPwa) {
        const { customer, orderTypes, menuPreviewMode } = this.props;
        const { disabledLink, updateInfoStyle } = this.props.styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <UserIconSVG width={20} height={20} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />

        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 1);
        const isDisabled = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? true : false;
        const updateDetailsStyle = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? disabledLink : updateInfoStyle;
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;

        return (
            <CheckoutRow
                pwaMode={isPwa}
                handleClick={this.toggleConfirmationModal}
                thirdSpanElement={customer.email}
                aboveSpansElement={customer.phone}
                sectionTitleText={`${sectionTitleText} ${isDisabled ? i18n.t('screens:checkoutScreen.customerName') : customer.name}`}
                isDisabled={isDisabled}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                updateDetailsStyle={updateDetailsStyle}
                // buttonText={i18n.t('screens:checkoutScreen.updateDetails')}
                styles={this.props.styles}
                isCurbside={orderTypes.selectedOrderType.id === CURBSIDE_ID}
                carDetails={this.props.storeLocalStorage.carDetails}
                isAppliedGiftCards={isAppliedGiftCards}
            />
        );
    }

    renderGiftCardInfo() {
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <GiftCardSVG width={21} height={21} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;
        const isMobileView = window.innerWidth <= 865;
        const renderSavedGiftCards = isMobileView ? null : this.renderCustomerSavedGiftCards();
        const renderGiftCardInput = isMobileView ? null : this.giftCardNumberInput();
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;

        return (
            <CheckoutRow
                pwaMode={this.props.pwaAppRunning}
                component={'giftCardNumber'}
                hideTickInitially={true}
                savedGiftCards = {renderSavedGiftCards}
                aboveSpansElement={renderGiftCardInput}
                sectionTitleText= {i18n.t('screens:checkoutScreen.giftCard')}
                isDisabled={null}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                updateDetailsStyle={null}
                styles={this.props.styles}
                removeSeparatorLine={true}
                currentGiftCard={this.props.currentGiftCard}
                isGiftCardRow={true}
                isAppliedGiftCards={isAppliedGiftCards}
            />
        )
    }

    giftCardNumberInput() {
        const { primaryColor, chefNoteBackground, buttonsFont, inputsFontColor } = this.props.customerThemes.selectedTheme;
        // const { buttonsFont } = this.props.styles;
        // const { giftCards, appliedGiftCards } = this.props.giftCards;
        const { giftCardsErrorMessage, currentGiftCard, giftCards } = this.props; 
        const { applyBtnStyle, errorMsg } = styles;
        const isMobileView = window.innerWidth <= 865;
        const currentAppliedGiftCards = giftCards.giftCards ? giftCards.giftCards.appliedGiftCards : null;
        const appliedGiftCardsContent = currentAppliedGiftCards ? currentAppliedGiftCards : [];
        const giftCardsTotal = giftCards.giftCards ? giftCards.giftCards.total : null;
        const isGiftCardTotalZero = (giftCardsTotal === 0 && (currentAppliedGiftCards && currentAppliedGiftCards.length > 0)) ? true : false;
        
        return (
            <FormGroup style={isMobileView ? {width: '85%'} : {width: '70%'}}>
            <InputGroup>
                <Input 
                  type="text" 
                  name="giftCardNumber"
                  id="giftCardNumber"
                  disabled={isGiftCardTotalZero || this.props.shoppingCart.total === 0}
                  value={this.props.currentGiftCard.cardId}
                  onChange={this.props.changeState}
                //   min={0} 
                  //onKeyPress={(e) => negativeDecimalsValidation(e)}
                  style={
                      {
                            backgroundColor: chefNoteBackground,
                            borderRadius: 0,
                            fontSize: 14,
                            paddingLeft: 12,
                            zIndex: 1,
                            color: inputsFontColor
                      }
                  }
                />
                <InputGroupAddon>
                <Button
                    onClick={() => this.props.applyGiftCardInformation(appliedGiftCardsContent, currentGiftCard)}
                    disabled = {(this.props.currentGiftCard.cardId === '') ? true : false}
                    style={
                        {
                            ...applyBtnStyle,
                            color: buttonsFont,
                            background: primaryColor,
                            width: '100%',
                            height: '100%',
                            border: 'none'
                        }
                    }
                >
                    {i18n.t('common:buttons.apply')}
                </Button>
                </InputGroupAddon>
            </InputGroup>
            <p hidden={!giftCardsErrorMessage} style={errorMsg}>{giftCardsErrorMessage}</p>
            </FormGroup>
        );
    }

    renderCustomerSavedGiftCards() {
        const { giftCards } = this.props;
        const currency = this.props.selectedRestaurant.currency;
        const { customCloseBtnStyleGiftCardMobileView, customCloseBtnStyleGiftCard, giftCardContainerStyle, giftCardMiddleLineStyle } = styles;
        const isArabic = this.props.language === ARABIC;
        const isMobileView = window.innerWidth <= 865;
        const isDesktopView = window.innerWidth >= 1200;
        const giftCardDetailsStyle = isMobileView ? 'inline' : 'inline-flex';
        const customCloseBtnStyle = isMobileView ? customCloseBtnStyleGiftCardMobileView : customCloseBtnStyleGiftCard;
        const { type, primaryColor, secondaryColor, menuItemsBackground } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <GiftRibbonSVG width={40} height={40} fill={svgColor} style={{justifyContent: 'center', zIndex: '10'}} />;

        if((giftCards.giftCards === null) || (giftCards.giftCards === undefined) || (!giftCards.giftCards)) return;
        if (giftCards.giftCards.appliedGiftCards && giftCards.giftCards.appliedGiftCards.length > 0) {
            return (
                <Col sm={12} className="userSavedCardsList" style={{ padding: 0 }}>
                    <ListGroup flush style={isMobileView ? {width: '85%'} : {width: '100%', flexDirection: isDesktopView ? 'row' : 'column', paddingRight: '0px', flexWrap: 'wrap'}}>
                        {
                            giftCards.giftCards.appliedGiftCards.map((card, index) => {
                                if (!card.amount) return;
                                const cardAmount = transformProductPrice(card.amount);
                                const cardAmountWithCurrency = getPriceWithCurrencySymbol(cardAmount, currency, isArabic);
                                return (
                                    
                                    <ListGroupItem key={index} style={{...giftCardContainerStyle, backgroundColor: menuItemsBackground}}>
                                        <div style={{ textAlign: 'center', fontWeight: 'bold'}}>
                                            <span>{card.cardId}</span>
                                        </div>
                                        <div style={{textAlign: 'center'}}>
                                            <div style={{...giftCardMiddleLineStyle, backgroundColor: primaryColor}}></div>
                                            <div style={{zIndex: '50'}}>{iconImage}</div>
                                        </div>
                                        <div style={{ display:'flex', justifyContent: 'space-between'}}>
                                            <div>
                                               <span style={{fontSize: '20px', color: primaryColor, fontWeight: 'bold' }}>{i18n.t('screens:checkoutScreen.giftCard')}</span> 
                                            </div>
                                            <div>
                                               <span style={{fontWeight: 'bold'}}>{cardAmountWithCurrency}</span>
                                            </div>
                                        </div>
                                        <div style={customCloseBtnStyle}>
                                        <div id="customCloseBtnGiftCard" 
                                            style={isArabic ? {position: 'absolute', top: 9, left: 10, border: 'none', zIndex: 'unset'} : {position: 'absolute', top: 9, right: 10, border: 'none', zIndex: 'unset'}}
                                            onClick = {() => this.props.removeGiftCard(index, giftCards.giftCards.appliedGiftCards)}>
                                        </div>
                                        </div>
                                    </ListGroupItem>
                                );
                            })
                        } 
                    </ListGroup>
                </Col>
            );
        }
    }

    renderRestaurantInfo(isPwa) {
        const { selectedRestaurant, orderTypes, brand, menuPreviewMode } = this.props;
        const { disabledLink, updateInfoStyle } = this.props.styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <LocationIconSVG width={24} height={24} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;

        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 2);
        const isDisabled = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? true : false;
        const updateDetailsStyle = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? disabledLink : updateInfoStyle;

        return (
            <CheckoutRow
                pwaMode={isPwa}
                handleClick={this.props.pwaAppRunning ? () => this.toggleConfirmationModal(null, true) : () => this.toggleConfirmationModal()}
                aboveSpansElement={this.props.isArabic ? `${selectedRestaurant.displayAddress} ${brand.name}` : `${brand.name} ${selectedRestaurant.displayAddress}`}
                sectionTitleText={sectionTitleText}
                isDisabled={isDisabled}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                updateDetailsStyle={updateDetailsStyle}
                buttonText={this.state.workingRestCounter && this.state.workingRestCounter > 1 ? i18n.t('screens:checkoutScreen.chooseAnotherStore') : i18n.t('screens:checkoutScreen.restartOrder')}
                styles={this.props.styles}
                removeSeparatorLine={true}
            />
        );
    }

    renderCustomerAddress(isPwa) {
        const { customer, menuPreviewMode } = this.props;
        const { updateInfoStyle, disabledLink } = this.props.styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <HomeSVG width={20} height={20} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;

        const fullAddress = customer.selectedDeliveryAddress.fullAddress;
        const customerStreetAddress = customer.selectedDeliveryAddress.street;
        const customerDeliveryAddress = (fullAddress !== undefined) ? fullAddress : customerStreetAddress;

        const updateDetailsStyle = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? disabledLink : updateInfoStyle;
        const isDisabled = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? true : false;

        return (
            <CheckoutRow
                pwaMode={isPwa}
                handleClick={this.toggleConfirmationModal}
                aboveSpansElement={customerDeliveryAddress}
                sectionTitleText={i18n.t('screens:checkoutScreen.deliverTo')}
                isDisabled={isDisabled}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                updateDetailsStyle={updateDetailsStyle}
                buttonText={i18n.t('screens:checkoutScreen.changeAddress')}
                styles={this.props.styles}
                removeSeparatorLine={true}
            />
        );
    }

    renderCustomerOrderTime(isPwa) {
        const { estimateOrderTime, orderTypes, menuPreviewMode, restaurant } = this.props;
        const { disabledLink, updateInfoStyle } = this.props.styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <ClockSVG width={21} height={21} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />;

        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 3);
        const updateDetailsStyle = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? disabledLink : updateInfoStyle;
        const isDisabled = (menuPreviewMode.previewMode == true || menuPreviewMode.previewMode == 'true') ? true : false;
        let orderTime = null;
        if (estimateOrderTime.timeSlotBased) {
            let fromTime = moment(`${estimateOrderTime.time.fromHour}:${estimateOrderTime.time.fromMinute}`, 'HH:mm').format('HH:mm');
            let toTime = moment(`${estimateOrderTime.time.toHour}:${estimateOrderTime.time.toMinute}`, 'HH:mm').format('HH:mm');
            let day = moment(estimateOrderTime.time.date, 'DD-MM-YYYY').format('d');
            let month = moment(estimateOrderTime.time.date, 'DD-MM-YYYY').format('MM');
            let dateDay = moment(estimateOrderTime.time.date, 'DD-MM-YYYY').format('D');
            // moment works with day values from 0 to 6 while backend sends 1 to 7
            day++;
            orderTime = `${i18n.t(`common:days.weekday_${day}`)} ${dateDay} ${i18n.t(`common:months.month_${month}`)} ${fromTime} - ${toTime}`;
        }
        const aboveSpansElement = estimateOrderTime.timeSlotBased ? orderTime : orderTypes.selectedOrderType.id === IN_STORE_ID && !restaurant.type.futureOrderEnabled ? `${estimateOrderTime.estimateOrderTime} ${i18n.t('screens:restaurantDetailsScreen.mins')}` : `${dateAndTimeToUserFriendlyFormat(estimateOrderTime, restaurant, this.props.isArabic)} ${estimateOrderTime.cookTime && isMobileOnly ? ` (+${this.props.estimateOrderTime.cookTime} ${i18n.t('screens:shoppingCart.minsPrepRquired')})` : ''}`;
        let buttonText = orderTypes.selectedOrderType.id === IN_STORE_ID && !restaurant.type.futureOrderEnabled ? '' : i18n.t('screens:checkoutScreen.changeTime');

        if (this.props.promotionPartners.promotionPartner && this.props.promotionPartners.promotionPartner.id === 2) {
            buttonText = null;
        }

        return (
            <CheckoutRow
                pwaMode={isPwa}
                handleClick={this.props.pwaAppRunning ? () => this.toggleConfirmationModal('changeTime') : () => this.changeTime()}
                aboveSpansElement={aboveSpansElement}
                sectionTitleText={sectionTitleText}
                isDisabled={isDisabled}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                updateDetailsStyle={updateDetailsStyle}
                buttonText={buttonText}
                styles={this.props.styles}
                isOrderTime={true}
                removeSeparatorLine={true}
            />
        );
    }

    changeTime() {
        let urlHash;
        urlHash = window.location.hash;

        if (urlHash.includes('/checkout')) {
            this.props.clearTemporaryOrderTimeData();
            this.props.toggleChangeTimeScreen()
        }
    }

    renderGeneralInfo() {
        const { orderTypes, pwaAppRunning } = this.props;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const svgColor = type === 'DARK' ? secondaryColor : primaryColor;
        const iconImage = <CommentsSVG width={20} height={20} fill={svgColor} />;
        const secondIconImage = <TickSVG width={32} height={32} fill={svgColor} />
        const sectionTitleText = this.renderOrderTypeText(orderTypes.selectedOrderType.id, 4);
        const isAppliedGiftCards = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.appliedGiftCards && this.props.giftCards.giftCards.appliedGiftCards.length > 0 : false;

        return (
            <CheckoutRow
                pwaMode={pwaAppRunning}
                component={'generalInstructions'}
                hideTickInitially={true}
                generalInstructions={this.props.generalInstructions}
                removeSeparatorLine={true}
                aboveSpansElement={this.generalInformationInput()}
                sectionTitleText={sectionTitleText}
                isDisabled={null}
                iconImage={iconImage}
                secondIconImage={secondIconImage}
                updateDetailsStyle={null}
                styles={this.props.styles}
                isAppliedGiftCards={isAppliedGiftCards}
            />
        );
    }

    generalInformationInput() {
        const { instructionsInput, charactersInfo } = this.props.styles;
        const { inputsFontColor, chefNoteBackground } = this.props.customerThemes.selectedTheme;

        return (
            <Fragment>
                {/* <Label style={sectionTitle}>{sectionTitleText}</Label> */}
                <Col sm={12} style={{ paddingLeft: 0, paddingRight: 0, marginBottom: '5px' }} hidden={this.props.orderTypes.selectedOrderType.id !== 1}>
                    <Input type='select' name='currentNote' style={{ fontSize: 16, borderRadius: 0, color: inputsFontColor, backgroudColor: chefNoteBackground }} value={this.state.currentNote} onChange={this.changeNotes.bind(this)}>
                        <option selected={true} disabled={true} value='0'>{i18n.t('screens:checkoutScreen.selectNote')}</option>
                        <option value={i18n.t('screens:checkoutScreen.note1')}>{i18n.t('screens:checkoutScreen.note1')}</option>
                        <option value={i18n.t('screens:checkoutScreen.note2')}>{i18n.t('screens:checkoutScreen.note2')}</option>
                        <option value={i18n.t('screens:checkoutScreen.note3')}>{i18n.t('screens:checkoutScreen.note3')}</option>
                        <option value=''>{i18n.t('screens:checkoutScreen.customNote')}</option>
                    </Input>
                </Col>
                <input type="text" name="generalInstructions"
                    disabled={this.state.currentNote === 0 && this.props.orderTypes.selectedOrderType.id === 1}
                    value={this.props.generalInstructions}
                    onChange={this.props.changeState}
                    style={instructionsInput}
                    maxLength="85"
                    className='addressInputs'
                />
                <span style={charactersInfo}>{i18n.t('screens:checkoutScreen.remainingCharacters')} {85 - this.props.generalInstructions.length}</span>
            </Fragment>
        );
    }

    changeNotes({ target }) {
        this.setState({
            ...this.state,
            [target.name]: target.value
        }, () => {
            this.props.changeNotes(target.value);
        });
    }

    render() {
        const { orderTypes } = this.props;
        const changeTimeMsg = i18n.t('screens:checkoutScreen.changeTimeMSg');
        const changeLocationMsg = i18n.t('screens:checkoutScreen.changeLocationMsg');
        const { giftCardProviderId } = this.props.restaurant;
        const textData = {
            informationMessage: this.state.changeTime ? changeTimeMsg : changeLocationMsg,
            confirmationMessage: i18n.t('screens:restaurantDetailsScreen.confirmMsg')
        };

        const isPwa = window.location.href.includes('brand');
        const borderStyle = isPwa ? 'none' : '1px solid rgba(167, 167, 167, 0.5)';

        const { primaryFontColor } = this.props.customerThemes.selectedTheme;

        const isMobileView = window.innerWidth <= 865;

        return (
            <Fragment>
                <ul style={this.props.isArabic ? { marginBottom: 0, paddingRight: 0 } : { marginBottom: 0, paddingLeft: 0 }}>
                    
                    {
                        (isMobileView || this.props.pwaAppRunning) && giftCardProviderId ? 
                            <li style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '0 15px', padding: this.props.isArabic ? '15px 0 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>
                                {this.renderGiftCardInfo()}
                                {this.giftCardNumberInput()}
                                {this.renderCustomerSavedGiftCards()}
                            </li> : giftCardProviderId ?
                            <li style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '0 15px', padding: this.props.isArabic ? '15px 0 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>
                                {this.renderGiftCardInfo()}
                            </li> : null
                    }

                    {
                        orderTypes.selectedOrderType.id === IN_STORE_ID ?
                            <li style={{ display: 'flex', flexDirection: 'row', margin: '0 15px', padding: this.props.isArabic ? '15px 0px 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>
                                {this.tableNumberInfo(isPwa)}
                            </li> : null
                    }
                    
                    <li style={{ display: 'flex', flexDirection: 'row', margin: '0 15px', padding: this.props.isArabic ? '15px 0 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>
                        {this.renderCheckoutInfo(isPwa)}
                    </li>
                    {
                        orderTypes.selectedOrderType.id === IN_STORE_ID ? null :
                            <li style={{ display: 'flex', flexDirection: 'row', margin: '0 15px', padding: this.props.isArabic ? '15px 0 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>
                                {this.renderRestaurantInfo(isPwa)}
                            </li>
                    }
                    {
                        this.props.orderTypes.selectedOrderType.id == DELIVERY_ID ?
                            <li style={{ display: 'flex', flexDirection: 'row', margin: '0 15px', padding: this.props.isArabic ? '15px 0 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>
                                {this.renderCustomerAddress(isPwa)}
                            </li> :
                            null
                    }

                    <li style={{ display: 'flex', flexDirection: 'row', margin: '0 15px', padding: this.props.isArabic ? '15px 0 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>
                        {this.renderCustomerOrderTime(isPwa)}
                    </li>
                    <li style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '0 15px', padding: this.props.isArabic ? '15px 0 15px 15px' : '15px 15px 15px 0px', borderTop: borderStyle, color: primaryFontColor }}>

                        {this.renderGeneralInfo()}
                    </li>
                </ul>
                <ConfirmationModal
                    isOpen={this.state.openConfirmationDialog}
                    onToggle={this.toggleConfirmationModal.bind(this)}
                    onConfirm={this.confirmOrderReset.bind(this)}
                    message={textData}
                    customerThemes={this.props.customerThemes}
                />
                {
                    this.state.isOpen &&
                    <Modal className="modalStep startOrder restaurantDetailContainer" isOpen={this.state.isOpen} size='lg'>
                        <TableSelectionScreen
                            {...this.props}
                            toggle={this.toggleChangeTable}
                            isMenuScreen={true}
                        />
                    </Modal>
                }
            </Fragment>
        );
    }
}



const mapStateToProps = state => {
    return {
        pwaAppRunning: state.pwaAppRunning,
        restaurant: state.selectedRestaurant,
        brandName: state.brand.name,
        loading: state.loading,
        storeLocalStorage: state.storeLocalStorage,
        promotionPartners: state.promotionPartners,
        como: state.como,
        estimateOrderTime: state.estimateOrderTime,
        language: state.storeLocalStorage.language,
        shoppingCart: state.shoppingCart
    };
};

export default connect(
    mapStateToProps,
    {
        saveInStoreInfo,
        changeCodeManuallySet,
        cancelComoPayment,
        restartOrderComo
    }
)(CheckoutInformation);

const styles = {
    inputStyle: {
        borderRadius: 0
    },
    applyBtnStyle: {
        borderRadius: 0
    },
    customCloseBtnStyleGiftCardMobileView: {
        display: 'flex', 
        justifyContent: 'end'
    },
    customCloseBtnStyleGiftCard: {
        display: 'flex'
    },
    giftCardNumberInputMobileViewStyle: {
        width: '90%'
    },
    errorMsg: {
        marginBottom: 0,
        fontSize: 12,
        color: 'red'
    },
    giftCardContainerStyle: {
        width: '300px',
        height: '140px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '15px',
        boxShadow: '1px 0px 24px 2px rgba(0,0,0,0.15)',
        marginRight: '15px',
        marginBottom: '15px'
    },
    giftCardMiddleLineStyle: {
        width: '100%',
        backgroundColor: '#000',
        height: '7px',
        position: 'absolute',
        top: '40%',
        left: '0%'
    }
};
import React from 'react';

const TickSVG = ({ width, height, fill, style, hidden }) => {
	const viewBoxValues = "0 0 96.4 73.3";

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBoxValues} style={style} hidden={hidden}>
			<polygon fill={fill} points="32.3,73.3 0,40.9 9.2,31.8 32.3,54.9 87.2,0 96.4,9.2 " />
		</svg>
	);
}

export default TickSVG;
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { convertColorToRgba } from '../../../utils/CommonUtils';

import MenuProductCardContent from './MenuProductCardContent';
import ProductCardImgContainer from './ProductCardImgContainer';

const MenuProductCard = props => {
    const { menuItemsBackground } = props.selectedTheme;
    const convertedColorToRgba = convertColorToRgba(menuItemsBackground, 0.8);
    const renderThumbnailCardBody = () => {

        return (
            <CardBody style={{ display: 'flex', padding: 0 }}>

                <div style={{ padding: "12px 18px 12px 30px", width: 'calc(100% - 125px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: convertedColorToRgba }}>
                    <MenuProductCardContent {...props} styles={styles} />
                </div>

                <ProductCardImgContainer {...props} appOrMobile={false} />

            </CardBody>
        );
    };

    const renderNoThumbnailCardBody = () => {
        const { cardBody } = styles;

        return (
            <CardBody style={{...cardBody, backgroundColor: convertedColorToRgba}}>
                <MenuProductCardContent {...props} styles={styles} />
            </CardBody>
        );
    };

    const hasThumbnail = props.product.thumbnailPath === null;

    return (
        <Card style={props.cardStyle} className='product-card' id={props.product.id}>
            {hasThumbnail ? renderNoThumbnailCardBody() : renderThumbnailCardBody()}
        </Card>
    );
}

export default MenuProductCard;

const styles = {
    productNameStyle: {
        marginBottom: 0,
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '1.125rem',
        padding: '0 5px 0 0'
    },
    productDescriptionStyle: {
        fontSize: '0.875rem',
        padding: 0,
        color: '#6e7676',
        marginBottom: 0
    },
    productPriceStyle: {
        fontSize: '0.875rem',
        padding: '5px 5px 5px 0',
        color: '#62b400',
        marginLeft: 0,
        marginBottom: 0
    },
    cardBody: {
        padding: "12px 30px 12px 30px",
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between'
    }
}
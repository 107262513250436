import React from 'react';
import { Col, Badge } from 'reactstrap';

import { FRONT_END_URL } from '../../../utils/Constants';

const ProductCardLargeImgContainer = props => {

    const { appOrMobile, product, selectedTheme, styles, productFoundInCart, productQuantityInCart } = props;
    const { webImgContainer, imgStyles, appImgContainer, badgeContainer } = constantStyles;

    if (!appOrMobile) {
        return (
            <div style={webImgContainer}>
                <img
                    // className='img-thumbnail'
                    src={`${FRONT_END_URL}/${product.rectImagePath}`}
                    style={{
                        ...imgStyles,
                        backgroundColor: selectedTheme.menuItemsBackground
                    }} />
            </div>
        );
    }

    return (
        <Col style={appImgContainer}>
            <img
                // className='img-thumbnail'
                src={`${FRONT_END_URL}/${product.rectImagePath}`}
                style={{
                    ...imgStyles,
                    backgroundColor: selectedTheme.menuItemsBackground
                }} />
            <Col
                hidden={productFoundInCart}
                style={props.isArabic ? styles.badgeColMobileLargeImageRTL
                 : styles.badgeColMobileLargeImage } >
                <Badge style={{
                    ...styles.largeImageBadge,
                    background: selectedTheme.primaryColor
                }}>{productQuantityInCart}x</Badge>
            </Col>
        </Col>
    );
}

export default ProductCardLargeImgContainer;

const constantStyles = {
    webImgContainer: {
        paddingRight: 0,
        paddingLeft: 0,
        width: '100%',
        maxHeight: '267px',
        aspectRatio: '3 / 2'
    },
    imgStyles: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    appImgContainer: {
        width: '100%',
        textAlign: 'right',
        padding: 0,
        aspectRatio: '3 / 2'
    }
};
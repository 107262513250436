import React from 'react';
import { Button } from 'reactstrap';

const ComoButton = props => {

    return (
        <Button
            className='promotionPartnerButton'
            outline
            onClick={() => props.onClick()}
            style={{
                width: '100%',
                border: 'none',
                color: props.color,
                backgroundColor: !props.backgroundColor ? '#fff' : props.backgroundColor,
                fontFamily: props.fontFamilyType,
                fontWeight: props.fontBold === 'bold' ? props.fontBold : 300,
                fontStyle: props.fontItalic,
                textTransform: props.uppercase,
            }}>
            {props.children}
        </Button>
    );
}

export default ComoButton;
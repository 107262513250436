import React, { Fragment } from 'react';
import { Col, NavLink } from 'reactstrap';
import { isMobile, isTablet } from 'mobile-device-detect';

const CheckoutRow = ({
    pwaMode,
    component,
    savedCards,
    savedGiftCards,
    handleClick,
    selectedPaymentId,
    thirdSpanElement,
    hideTickInitially,
    generalInstructions,
    removeSeparatorLine,
    aboveSpansElement,
    sectionTitleText,
    isDisabled,
    iconImage,
    secondIconImage,
    updateDetailsStyle,
    buttonText,
    styles,
    isCurbside,
    carDetails,
    isAppleGooglePayAvailable,
    isArabic,
    isOrderTime,
    currentGiftCard,
    isGiftCardRow,
    isAppliedGiftCards}) => {
    
    let renderIconCondition = null;

    if (component === 'generalInstructions') {
        renderIconCondition = generalInstructions.length > 0;
    } else if (component === 'paymentType') {
        renderIconCondition = selectedPaymentId !== null;
    } else if (component === 'giftCardNumber') {
        renderIconCondition = currentGiftCard.cardId ? true : false;
     }

    let isPwaStyle = window.location.href.includes('brand') ? 'center' : 'flex-start';
    let screenWidth = window.screen.width < 768;
    const isMobileView = window.innerWidth <= 865;
    const columnWidth = component == 'paymentType'
        && isAppleGooglePayAvailable
        && isMobile ? 10 : 10;
    const columnStyle = component == 'paymentType'
        && isAppleGooglePayAvailable
        ? pwaMode ? { padding: "0 0px 15px 0px" } : isMobile ? isTablet ? { padding: '0px 12px 15px 12px' } : { padding: '0px 0px  15px 15px' } : { padding: 0 } : { padding: 0 };
    const tickColumnStyle = component == 'paymentType'
        && isAppleGooglePayAvailable
        && isMobile ? { display: 'none' } : { display: 'flex' };
    const tickColumnStyleGiftCardRow = isMobileView ? { right: '18px', top: '40px', paddingRight: '0px' } : { left: '8px', top: '30px' };
    const isComponentGiftCardNumber = component === 'giftCardNumber' ? 'block' : 'flex';
    const giftCardNumberFieldsWidth = isComponentGiftCardNumber ? '100%' : '70%';
    const sectionTitleStyle = isMobile && !pwaMode && isAppleGooglePayAvailable ? {...styles.sectionTitle, paddingLeft: '10px', marginBottom: '10px'} : styles.sectionTitle

    return (
        <Fragment>
            <Col xs={1} sm={1} style={{ padding: 0, display: 'flex', alignItems: 'flex-start', justifyContent: isPwaStyle }}>
                <span>
                    {iconImage}
                </span>
            </Col>
            <Col xs={columnWidth} sm={columnWidth} style={columnStyle}>
                <Col style={sectionTitleStyle}>{sectionTitleText}</Col>
                <Col style={{ display: `${isComponentGiftCardNumber}`, flexWrap: 'wrap', marginBottom: 12, width:`${giftCardNumberFieldsWidth}` }}>
                    {/* aboveSpansElement renders the heading of this column - in case of General Instructions renders html elements */}
                    {isOrderTime ?
                        <div dir='ltr' style={ (isAppleGooglePayAvailable || buttonText) ? { width: '100%' } : null}>{aboveSpansElement}</div> :
                        <div style={ (isAppleGooglePayAvailable || buttonText) ? { width: '100%' } : null}>{aboveSpansElement}</div>
                    }

                    {/* thirdSpanElement is true when we have to render both phone number and email */}
                    {(thirdSpanElement && !removeSeparatorLine) ? (<span style={styles.separatorStyle}> {pwaMode ? '' : (screenWidth ? '' : '|')} </span>) : null}
                    {thirdSpanElement ? thirdSpanElement : null}

                    {/* Renders saved cards for the PaymentType component */}
                    {savedCards ? savedCards : null}

                    {savedGiftCards ? savedGiftCards : null}

                    {/* removeSeparatorLine does what its name says and is true only when rendering General Instructions */}
                    {
                        removeSeparatorLine ? null :
                            screenWidth ? null :
                                pwaMode ? null :
                                    !buttonText ? null :
                                        <span style={styles.separatorStyle}> {'|'} </span>
                    }

                    {/* isDisabled is null when rendering General Instructions */}
                    {
                        isDisabled === null ? null :
                            (
                                <NavLink disabled={isDisabled}
                                    onClick={() => handleClick()}
                                    style={Object.assign({}, updateDetailsStyle, { padding: 0 })}>{buttonText}</NavLink>
                            )
                    }
                    {
                        isCurbside ?
                            <div style={{ flexBasis: '100%' }}>
                                {`Car Details: ${carDetails.makeBy} ${carDetails.model}, ${carDetails.plate}, ${carDetails.color}`}
                            </div>
                            : null
                    }
                </Col>
            </Col>
            <Col xs={1} sm={1} style={isGiftCardRow ? Object.assign({}, { display: 'unset' }, tickColumnStyleGiftCardRow): Object.assign({}, { padding: 0, alignItems: 'center', justifyContent: 'center'}, tickColumnStyle)}>
                {/* hideTickInitially is true when rendering General Instructions and Gift Cards- hides the tick so the check above it can render ONLY 1 tick */}
                {
                    component ? (renderIconCondition || isAppliedGiftCards ? <span style={isMobile
                        && isAppleGooglePayAvailable
                        ? { display: 'none' } : {}}>{secondIconImage}</span> : null) :
                        (hideTickInitially ? null : <span>{
                            secondIconImage
                        }</span>)
                }

            </Col>
        </Fragment>
    );
}

export default CheckoutRow;